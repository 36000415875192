import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import BugsScreen from "../../components/bugs/bugs";
import { END_POINT } from "../../assets/constants/global";

export async function loader({ request }) {
  try {
    const url = new URL(request.url);
    const pageNumber = url.searchParams.get("pageNumber") || 1;
    const API_URL = `${END_POINT}/v1/bugs/?page=${pageNumber}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data?.bugs ? data?.bugs : null,
    });
  } catch (error) {
    return "Failed to load data";
  }
}
function Bugs() {
  return (
    <div>
      <BugsScreen />
    </div>
  );
}

export default Bugs;
