import React from "react";

import { Button, Typography, Card } from "antd";
import { useLoaderData } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

import BillingModal from "../../models/billingModal";

function BillingInfoScreen() {
  const { data } = useLoaderData();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <Card style={{ width: 400 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <Typography level={2}>
            <b className="text-hide">Charges Per User:</b>{" "}
            {data?.chargesPerUser || <span className="text-hide">15</span>}
          </Typography>
          <BillingModal amount={data?.chargesPerUser}>
            <Button
              className="text-hide"
              type="primary"
              icon={<EditOutlined />}
            >
              Update Charges
            </Button>
          </BillingModal>
        </div>
      </Card>
    </div>
  );
}

export default BillingInfoScreen;
