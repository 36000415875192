import React from "react";

import Tier4MemberScreen from "../../components/tier4/tier4Member";

const tier4Member = () => {
  return (
    <>
      <Tier4MemberScreen />
    </>
  );
};

export default tier4Member;
