import { Menu } from "antd";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

export function generateMenuItems(items) {
  return items?.map((menuItem) => {
    if (menuItem?.children || menuItem?.subItems) {
      // Render submenu for items with children or subItems
      return (
        <SubMenu
          key={menuItem?.key}
          icon={menuItem?.icon}
          title={menuItem?.label}
        >
          {generateMenuItems(menuItem?.children || menuItem?.subItems)}
        </SubMenu>
      );
    }

    // Render regular menu item
    return (
      <Menu.Item key={menuItem?.key} icon={menuItem?.icon}>
        <Link to={menuItem?.path}>{menuItem?.label}</Link>
      </Menu.Item>
    );
  });
}
