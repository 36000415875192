import React, { useEffect, useState } from "react";

import axios from "axios";
import { useLocation } from "react-router-dom";
import { Avatar, Image, Table, Typography } from "antd";

import { END_POINT } from "../../assets/constants/global";

const Tier4MemberScreen = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const { vendor } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${END_POINT}/v1/vendors`); // Replace with your API endpoint
        setData(response.data.vendors);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [location]);

  const categoryColumns = [
    {
      title: "Thumbnail",
      dataIndex: ["category", "thumbnail"],
      key: "thumbnail",
      render: (text) => <Image src={text} width={50} />,
    },
    {
      title: "Name",
      dataIndex: ["category", "name"],
      key: "name",
    },
  ];

  const ownerColumns = [
    {
      title: "Profile Image",
      dataIndex: ["owner", "profileImage"],
      key: "profileImage",
      render: (text, record) => {
        const firstName = record?.owner?.firstName || "";
        return text ? (
          <Image src={text} width={50} />
        ) : (
          <Avatar>{firstName.charAt(0)}</Avatar>
        );
      },
    },
    {
      title: "First Name",
      dataIndex: ["owner", "firstName"],
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: ["owner", "lastName"],
      key: "lastName",
    },
    {
      title: "Member ID",
      dataIndex: ["owner", "memberID"],
      key: "memberID",
    },
  ];

  const categoriesData = data.map((item) => ({
    category: item.category,
    _id: item._id,
  }));
  const ownersData = data
    .filter((item) => item.owner)
    .map((item) => ({ owner: item.owner, _id: item._id }));

  return (
    <div className="main_container_div">
      <div className="main_inner_div">
        <Typography
          className="heading_text"
          style={{ color: "black", marginBottom: "20px" }}
        >
          Categories
        </Typography>
        <Table
          columns={categoryColumns}
          dataSource={vendor ? [vendor] : categoriesData} // Show specific vendor category if available
          isLoading={isLoading}
          rowKey={(record) => record._id}
          pagination={false}
        />
        <Typography
          className="heading_text"
          style={{ color: "black", marginBottom: "20px", marginTop: "20px" }}
        >
          Owners
        </Typography>
        <Table
          columns={ownerColumns}
          dataSource={vendor && vendor.owner ? [vendor] : ownersData} // Show specific vendor owner if available
          isLoading={isLoading}
          rowKey={(record) => record._id}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Tier4MemberScreen;
