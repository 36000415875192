import React from "react";

import { message } from "antd";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TbCategoryPlus } from "react-icons/tb";
import { FaHome, FaInfoCircle, FaUser } from "react-icons/fa";

import { SiteMap } from "../../navigation/SiteMap";

export default function useHeader() {
  const navigate = useNavigate();

  const rightItems = [
    {
      label: "Profile",
      key: "1",
      icon: <FaUser />,
      onClick: () => message?.error("No Profile Page."),
    },
    {
      label: "Logout",
      key: "2",
      icon: <IoIosLogOut />,
      onClick: () => {
        navigate("/");
        localStorage.removeItem("token");
      },
    },
  ];
  const leftItems = [
    {
      label: "Home",
      key: "1",
      icon: <FaHome />,
      onClick: () => navigate(SiteMap?.home?.path),
    },
    {
      label: "Contact",
      key: "2",
      icon: <TbCategoryPlus />,
      onClick: () => navigate(SiteMap?.contact?.path),
    },
    {
      label: "About",
      key: "3",
      icon: <FaInfoCircle />,
      onClick: () => navigate(SiteMap?.about?.path),
    },
  ];
  return { rightItems, leftItems, navigate };
}
