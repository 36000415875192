import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import EditBusinessScreen from "../../components/tier1/editBusiness";

export async function loader({ params }) {
  try {
    const id = params.id;
    const API_URL = `${END_POINT}/v1/recruiter/${id}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data.recruiter ? data.recruiter : [],
      length: data?.total || 0,
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}
function EditBusiness() {
  return (
    <div>
      <EditBusinessScreen />
    </div>
  );
}

export default EditBusiness;
