import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import DashboardScreen from "../../components/dashboard/dashboard";

export async function loader() {
  try {
    const PAGE_NUMBER = 1;
    const API_URL_Tier_1 = `${END_POINT}/v1/recruiter/list/all?page=${PAGE_NUMBER}`;
    const API_URL_Tier_2 = `${END_POINT}/v1/interviewers?page=${PAGE_NUMBER}`;
    const API_URL_Tier_3 = `${END_POINT}/v1/applications?page=${PAGE_NUMBER}`;

    async function fetchData() {
      try {
        const [response1, response2, response3] = await Promise.all([
          axios.get(API_URL_Tier_1),
          axios.get(API_URL_Tier_2),
          axios.get(API_URL_Tier_3),
        ]);
        return {
          recruiters: response1.data,
          interviewers: response2.data,
          applications: response3.data,
        };
      } catch (error) {
        return {};
      }
    }

    const data = await fetchData();

    return defer({
      data: {
        recruiters: data.recruiters || [],
        interviewers: data.interviewers || [],
        applications: data.applications || [],
      },
      length: data.recruiters ? data.recruiters.length : 0, // Assuming length of recruiters data
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}
function Dashboard() {
  return (
    <div>
      <DashboardScreen />
    </div>
  );
}

export default Dashboard;
