import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import Tier1Screen from "../../components/tier1/tier1";
import { END_POINT } from "../../assets/constants/global";

export async function loader({ request }) {
  try {
    const url = new URL(request.url);
    const pageNumber = url.searchParams.get("pageNumber") || "";
    const firstName = url.searchParams.get("firstName") || "";
    const API_URL = firstName
      ? `${END_POINT}/v1/recruiter/list/all?query=${firstName}&page=${pageNumber}`
      : `${END_POINT}/v1/recruiter/list/all?page=${pageNumber}`;

    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data.recruiters ? data.recruiters : [],
      length: data?.total || 0,
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}
function tier1() {
  return (
    <>
      <Tier1Screen />
    </>
  );
}

export default tier1;
