
export const statesArray = [
    {
        "stateId": "AL",
        "stateName": "Alabama",
        "county": [
            {
                "countyName": "Autauga County",
                "cities": [
                    "Autaugaville",
                    "Billingsley"
                ]
            },
            {
                "countyName": "Autauga County\nElmore County",
                "cities": [
                    "Prattville†"
                ]
            },
            {
                "countyName": "Baldwin County",
                "cities": [
                    "Bay Minette†",
                    "Daphne",
                    "Elberta",
                    "Fairhope",
                    "Foley",
                    "Gulf Shores",
                    "Loxley",
                    "Magnolia Springs",
                    "Orange Beach",
                    "Perdido Beach",
                    "Robertsdale",
                    "Silverhill",
                    "Spanish Fort",
                    "Summerdale"
                ]
            },
            {
                "countyName": "Barbour County",
                "cities": [
                    "Bakerhill",
                    "Blue Springs",
                    "Clayton†",
                    "Clio",
                    "Eufaula",
                    "Louisville"
                ]
            },
            {
                "countyName": "Bibb County",
                "cities": [
                    "Brent",
                    "Centreville†",
                    "West Blocton"
                ]
            },
            {
                "countyName": "Bibb County\nTuscaloosa County",
                "cities": [
                    "Woodstock"
                ]
            },
            {
                "countyName": "Blount County",
                "cities": [
                    "Allgood",
                    "Blountsville",
                    "Cleveland",
                    "Hayden",
                    "Highland Lake",
                    "Locust Fork",
                    "Nectar",
                    "Oneonta†",
                    "Rosa",
                    "Snead",
                    "Susan Moore"
                ]
            },
            {
                "countyName": "Blount County\nJefferson County",
                "cities": [
                    "County Line"
                ]
            },
            {
                "countyName": "Bullock County",
                "cities": [
                    "Midway",
                    "Union Springs†"
                ]
            },
            {
                "countyName": "Butler County",
                "cities": [
                    "Georgiana",
                    "Greenville†"
                ]
            },
            {
                "countyName": "Butler County\nConecuh County",
                "cities": [
                    "McKenzie"
                ]
            },
            {
                "countyName": "Calhoun County",
                "cities": [
                    "Anniston†",
                    "Hobson City",
                    "Jacksonville",
                    "Ohatchee",
                    "Weaver"
                ]
            },
            {
                "countyName": "Calhoun County\nCherokee County",
                "cities": [
                    "Piedmont"
                ]
            },
            {
                "countyName": "Calhoun County\nTalladega County",
                "cities": [
                    "Oxford"
                ]
            },
            {
                "countyName": "Chambers County",
                "cities": [
                    "Cusseta",
                    "Five Points",
                    "LaFayette†",
                    "Lanett",
                    "Valley"
                ]
            },
            {
                "countyName": "Chambers County\nLee County",
                "cities": [
                    "Waverly"
                ]
            },
            {
                "countyName": "Cherokee County",
                "cities": [
                    "Cedar Bluff",
                    "Centre†",
                    "Gaylesville",
                    "Leesburg"
                ]
            },
            {
                "countyName": "Cherokee County\nDeKalb County",
                "cities": [
                    "Sand Rock"
                ]
            },
            {
                "countyName": "Chilton County",
                "cities": [
                    "Clanton†",
                    "Jemison",
                    "Maplesville",
                    "Thorsby"
                ]
            },
            {
                "countyName": "Choctaw County",
                "cities": [
                    "Butler†",
                    "Gilbertown",
                    "Lisman",
                    "Needham",
                    "Pennington",
                    "Silas",
                    "Toxey"
                ]
            },
            {
                "countyName": "Clarke County",
                "cities": [
                    "Coffeeville",
                    "Fulton",
                    "Grove Hill†",
                    "Jackson",
                    "Thomasville"
                ]
            },
            {
                "countyName": "Clay County",
                "cities": [
                    "Ashland†",
                    "Lineville"
                ]
            },
            {
                "countyName": "Cleburne County",
                "cities": [
                    "Edwardsville",
                    "Fruithurst",
                    "Heflin†",
                    "Ranburne"
                ]
            },
            {
                "countyName": "Coffee County",
                "cities": [
                    "Elba†",
                    "Kinston",
                    "New Brockton"
                ]
            },
            {
                "countyName": "Coffee County\nDale County",
                "cities": [
                    "Enterprise"
                ]
            },
            {
                "countyName": "Colbert County",
                "cities": [
                    "Cherokee",
                    "Leighton",
                    "Littleville",
                    "Muscle Shoals",
                    "Sheffield",
                    "Tuscumbia†"
                ]
            },
            {
                "countyName": "Conecuh County",
                "cities": [
                    "Castleberry",
                    "Evergreen†",
                    "Repton"
                ]
            },
            {
                "countyName": "Coosa County",
                "cities": [
                    "Goodwater",
                    "Kellyton",
                    "Rockford†"
                ]
            },
            {
                "countyName": "Covington County",
                "cities": [
                    "Andalusia†",
                    "Babbie",
                    "Carolina",
                    "Florala",
                    "Gantt",
                    "Heath",
                    "Horn Hill",
                    "Libertyville",
                    "Lockhart",
                    "Onycha",
                    "Opp",
                    "Red Level",
                    "River Falls",
                    "Sanford"
                ]
            },
            {
                "countyName": "Crenshaw County",
                "cities": [
                    "Brantley",
                    "Dozier",
                    "Glenwood",
                    "Luverne†",
                    "Petrey",
                    "Rutledge"
                ]
            },
            {
                "countyName": "Cullman County",
                "cities": [
                    "Baileyton",
                    "Berlin[b]",
                    "Colony",
                    "Cullman†",
                    "Dodge City",
                    "Fairview",
                    "Good Hope",
                    "Hanceville",
                    "Holly Pond",
                    "South Vinemont",
                    "West Point"
                ]
            },
            {
                "countyName": "Cullman County\nBlount County",
                "cities": [
                    "Garden City"
                ]
            },
            {
                "countyName": "Dale County",
                "cities": [
                    "Ariton",
                    "Clayhatchee",
                    "Daleville",
                    "Grimes",
                    "Level Plains",
                    "Midland City",
                    "Napier Field",
                    "Newton",
                    "Ozark†",
                    "Pinckard"
                ]
            },
            {
                "countyName": "Dallas County",
                "cities": [
                    "Orrville",
                    "Selma†",
                    "Valley Grande"
                ]
            },
            {
                "countyName": "DeKalb County",
                "cities": [
                    "Crossville",
                    "Fort Payne†",
                    "Fyffe",
                    "Geraldine",
                    "Hammondville",
                    "Henagar",
                    "Ider",
                    "Lakeview",
                    "Mentone",
                    "Pine Ridge",
                    "Powell",
                    "Rainsville",
                    "Shiloh",
                    "Sylvania",
                    "Valley Head"
                ]
            },
            {
                "countyName": "DeKalb County\nCherokee County",
                "cities": [
                    "Collinsville"
                ]
            },
            {
                "countyName": "Elmore County",
                "cities": [
                    "Coosada",
                    "Deatsville",
                    "Eclectic",
                    "Elmore",
                    "Wetumpka†"
                ]
            },
            {
                "countyName": "Elmore County\nAutauga County",
                "cities": [
                    "Millbrook"
                ]
            },
            {
                "countyName": "Elmore County\nTallapoosa County",
                "cities": [
                    "Tallassee"
                ]
            },
            {
                "countyName": "Escambia County",
                "cities": [
                    "Atmore",
                    "Brewton†",
                    "East Brewton",
                    "Flomaton",
                    "Pollard",
                    "Riverview"
                ]
            },
            {
                "countyName": "Etowah County",
                "cities": [
                    "Attalla",
                    "Gadsden†",
                    "Hokes Bluff",
                    "Rainbow City",
                    "Reece City",
                    "Ridgeville",
                    "Sardis City",
                    "Walnut Grove"
                ]
            },
            {
                "countyName": "Etowah County\nBlount County",
                "cities": [
                    "Altoona"
                ]
            },
            {
                "countyName": "Etowah County\nCalhoun County",
                "cities": [
                    "Glencoe",
                    "Southside"
                ]
            },
            {
                "countyName": "Fayette County",
                "cities": [
                    "Belk",
                    "Berry",
                    "Fayette†"
                ]
            },
            {
                "countyName": "Fayette County\nMarion County",
                "cities": [
                    "Glen Allen"
                ]
            },
            {
                "countyName": "Franklin County",
                "cities": [
                    "Hodges",
                    "Phil Campbell",
                    "Red Bay",
                    "Russellville†",
                    "Vina"
                ]
            },
            {
                "countyName": "Geneva County",
                "cities": [
                    "Black",
                    "Coffee Springs",
                    "Geneva†",
                    "Hartford",
                    "Malvern",
                    "Samson",
                    "Slocomb"
                ]
            },
            {
                "countyName": "Greene County",
                "cities": [
                    "Boligee",
                    "Eutaw†",
                    "Forkland",
                    "Union"
                ]
            },
            {
                "countyName": "Hale County",
                "cities": [
                    "Akron",
                    "Greensboro†",
                    "Newbern"
                ]
            },
            {
                "countyName": "Hale County\nTuscaloosa County",
                "cities": [
                    "Moundville"
                ]
            },
            {
                "countyName": "Henry County",
                "cities": [
                    "Abbeville†",
                    "Haleburg",
                    "Headland",
                    "Newville"
                ]
            },
            {
                "countyName": "Houston County",
                "cities": [
                    "Ashford",
                    "Avon",
                    "Columbia",
                    "Cottonwood",
                    "Cowarts",
                    "Gordon",
                    "Kinsey",
                    "Madrid",
                    "Rehobeth",
                    "Webb"
                ]
            },
            {
                "countyName": "Houston County\nDale County\nHenry County",
                "cities": [
                    "Dothan†"
                ]
            },
            {
                "countyName": "Houston County\nGeneva County",
                "cities": [
                    "Taylor"
                ]
            },
            {
                "countyName": "Jackson County",
                "cities": [
                    "Bridgeport",
                    "Dutton",
                    "Hollywood",
                    "Hytop",
                    "Langston",
                    "Paint Rock",
                    "Pisgah",
                    "Pleasant Groves",
                    "Scottsboro†",
                    "Section",
                    "Skyline",
                    "Stevenson",
                    "Woodville"
                ]
            },
            {
                "countyName": "Jefferson County",
                "cities": [
                    "Adamsville",
                    "Bessemer",
                    "Brighton",
                    "Brookside",
                    "Cardiff",
                    "Center Point",
                    "Clay",
                    "Fairfield",
                    "Fultondale",
                    "Gardendale",
                    "Graysville",
                    "Homewood",
                    "Hueytown",
                    "Irondale",
                    "Kimberly",
                    "Lipscomb",
                    "Maytown",
                    "Midfield",
                    "Morris",
                    "Mountain Brook",
                    "Mulga",
                    "North Johns",
                    "Pinson",
                    "Pleasant Grove",
                    "Sylvan Springs",
                    "Tarrant",
                    "Trafford",
                    "West Jefferson"
                ]
            },
            {
                "countyName": "Jefferson County\nBlount County",
                "cities": [
                    "Warrior"
                ]
            },
            {
                "countyName": "Jefferson County\nShelby County",
                "cities": [
                    "Birmingham†",
                    "Hoover",
                    "Vestavia Hills"
                ]
            },
            {
                "countyName": "Jefferson County\nSt. Clair County",
                "cities": [
                    "Trussville"
                ]
            },
            {
                "countyName": "Jefferson County\nSt. Clair County\nShelby County",
                "cities": [
                    "Leeds"
                ]
            },
            {
                "countyName": "Lamar County",
                "cities": [
                    "Beaverton",
                    "Detroit",
                    "Kennedy",
                    "Millport",
                    "Sulligent",
                    "Vernon†"
                ]
            },
            {
                "countyName": "Lauderdale County",
                "cities": [
                    "Anderson",
                    "Florence†",
                    "Killen",
                    "Lexington",
                    "Rogersville",
                    "St. Florian",
                    "Waterloo"
                ]
            },
            {
                "countyName": "Lawrence County",
                "cities": [
                    "Courtland",
                    "Hillsboro",
                    "Moulton†",
                    "North Courtland",
                    "Town Creek"
                ]
            },
            {
                "countyName": "Lee County",
                "cities": [
                    "Auburn",
                    "Loachapoka",
                    "Opelika†",
                    "Smiths Station"
                ]
            },
            {
                "countyName": "Limestone County",
                "cities": [
                    "Ardmore",
                    "Athens†",
                    "Elkmont",
                    "Lester",
                    "Mooresville"
                ]
            },
            {
                "countyName": "Lowndes County",
                "cities": [
                    "Benton",
                    "Fort Deposit",
                    "Gordonville",
                    "Hayneville†",
                    "Lowndesboro",
                    "Mosses",
                    "White Hall"
                ]
            },
            {
                "countyName": "Macon County",
                "cities": [
                    "Franklin",
                    "Shorter",
                    "Tuskegee†"
                ]
            },
            {
                "countyName": "Macon County\nLee County",
                "cities": [
                    "Notasulga"
                ]
            },
            {
                "countyName": "Madison County",
                "cities": [
                    "Gurley",
                    "New Hope",
                    "Owens Cross Roads",
                    "Triana"
                ]
            },
            {
                "countyName": "Madison County\nLimestone County",
                "cities": [
                    "Madison"
                ]
            },
            {
                "countyName": "Madison County\nLimestone County\nMorgan County[10]",
                "cities": [
                    "Huntsville†"
                ]
            },
            {
                "countyName": "Marengo County",
                "cities": [
                    "Dayton",
                    "Demopolis",
                    "Faunsdale",
                    "Linden†",
                    "Myrtlewood",
                    "Providence",
                    "Sweet Water",
                    "Thomaston"
                ]
            },
            {
                "countyName": "Marion County",
                "cities": [
                    "Bear Creek",
                    "Brilliant",
                    "Guin",
                    "Hackleburg",
                    "Hamilton†",
                    "Twin"
                ]
            },
            {
                "countyName": "Marion County\nFayette County",
                "cities": [
                    "Gu-Win",
                    "Winfield"
                ]
            },
            {
                "countyName": "Marshall County",
                "cities": [
                    "Albertville",
                    "Douglas",
                    "Grant",
                    "Guntersville†",
                    "Union Grove"
                ]
            },
            {
                "countyName": "Marshall County\nCullman County",
                "cities": [
                    "Arab"
                ]
            },
            {
                "countyName": "Marshall County\nEtowah County",
                "cities": [
                    "Boaz"
                ]
            },
            {
                "countyName": "Mobile County",
                "cities": [
                    "Bayou La Batre",
                    "Chickasaw",
                    "Citronelle",
                    "Creola",
                    "Dauphin Island",
                    "Mobile†[c]",
                    "Mount Vernon",
                    "Prichard",
                    "Saraland",
                    "Satsuma"
                ]
            },
            {
                "countyName": "Monroe County",
                "cities": [
                    "Beatrice",
                    "Excel",
                    "Frisco City",
                    "Monroeville†",
                    "Vredenburgh"
                ]
            },
            {
                "countyName": "Montgomery County",
                "cities": [
                    "Montgomery‡",
                    "Pike Road"
                ]
            },
            {
                "countyName": "Morgan County",
                "cities": [
                    "Eva",
                    "Falkville",
                    "Hartselle",
                    "Priceville",
                    "Somerville",
                    "Trinity"
                ]
            },
            {
                "countyName": "Morgan County\nLimestone County",
                "cities": [
                    "Decatur†"
                ]
            },
            {
                "countyName": "Perry County",
                "cities": [
                    "Marion†",
                    "Uniontown"
                ]
            },
            {
                "countyName": "Pickens County",
                "cities": [
                    "Aliceville",
                    "Carrollton†",
                    "Ethelsville",
                    "Gordo",
                    "McMullen",
                    "Memphis",
                    "Pickensville",
                    "Reform"
                ]
            },
            {
                "countyName": "Pike County",
                "cities": [
                    "Banks",
                    "Brundidge",
                    "Goshen",
                    "Troy†"
                ]
            },
            {
                "countyName": "Randolph County",
                "cities": [
                    "Roanoke",
                    "Wadley",
                    "Wedowee†",
                    "Woodland"
                ]
            },
            {
                "countyName": "Russell County",
                "cities": [
                    "Hurtsboro"
                ]
            },
            {
                "countyName": "Russell County\nLee County",
                "cities": [
                    "Phenix City†"
                ]
            },
            {
                "countyName": "Shelby County",
                "cities": [
                    "Alabaster",
                    "Chelsea",
                    "Columbiana†",
                    "Harpersville",
                    "Indian Springs Village",
                    "Montevallo",
                    "Pelham",
                    "Westover",
                    "Wilsonville",
                    "Wilton"
                ]
            },
            {
                "countyName": "Shelby County\nChilton County",
                "cities": [
                    "Calera"
                ]
            },
            {
                "countyName": "Shelby County\nJefferson County",
                "cities": [
                    "Helena"
                ]
            },
            {
                "countyName": "Shelby County\nSt. Clair County\nTalladega County",
                "cities": [
                    "Vincent"
                ]
            },
            {
                "countyName": "St. Clair County",
                "cities": [
                    "Ashville†",
                    "Margaret",
                    "Moody",
                    "Odenville",
                    "Pell City†",
                    "Ragland",
                    "Riverside",
                    "Springville",
                    "Steele"
                ]
            },
            {
                "countyName": "St. Clair County\nJefferson County",
                "cities": [
                    "Argo"
                ]
            },
            {
                "countyName": "Sumter County",
                "cities": [
                    "Cuba",
                    "Emelle",
                    "Epes",
                    "Gainesville",
                    "Geiger",
                    "Livingston†",
                    "York"
                ]
            },
            {
                "countyName": "Talladega County",
                "cities": [
                    "Bon Air",
                    "Lincoln",
                    "Munford",
                    "Oak Grove",
                    "Sylacauga",
                    "Talladega Springs",
                    "Talladega†",
                    "Waldo"
                ]
            },
            {
                "countyName": "Talladega County\nShelby County",
                "cities": [
                    "Childersburg"
                ]
            },
            {
                "countyName": "Tallapoosa County",
                "cities": [
                    "Alexander City",
                    "Camp Hill",
                    "Dadeville†",
                    "Daviston",
                    "Goldville",
                    "Jackson's Gap",
                    "New Site"
                ]
            },
            {
                "countyName": "Tuscaloosa County",
                "cities": [
                    "Brookwood",
                    "Coaling",
                    "Coker",
                    "Lake View",
                    "Northport",
                    "Tuscaloosa†"
                ]
            },
            {
                "countyName": "Tuscaloosa County\nBibb County",
                "cities": [
                    "Vance"
                ]
            },
            {
                "countyName": "Walker County",
                "cities": [
                    "Carbon Hill",
                    "Cordova",
                    "Dora",
                    "Eldridge",
                    "Jasper†",
                    "Kansas",
                    "Oakman",
                    "Parrish",
                    "Sipsey"
                ]
            },
            {
                "countyName": "Walker County\nJefferson County",
                "cities": [
                    "Sumiton"
                ]
            },
            {
                "countyName": "Walker County\nWinston County",
                "cities": [
                    "Nauvoo"
                ]
            },
            {
                "countyName": "Washington County",
                "cities": [
                    "Chatom†",
                    "McIntosh",
                    "Millry"
                ]
            },
            {
                "countyName": "Wilcox County",
                "cities": [
                    "Camden†",
                    "Oak Hill",
                    "Pine Apple",
                    "Pine Hill",
                    "Yellow Bluff"
                ]
            },
            {
                "countyName": "Winston County",
                "cities": [
                    "Addison",
                    "Arley",
                    "Double Springs†",
                    "Lynn",
                    "Natural Bridge"
                ]
            },
            {
                "countyName": "Winston County\nMarion County",
                "cities": [
                    "Haleyville"
                ]
            }
        ]
    },
    {
        "stateId": "AR",
        "stateName": "Arkansas",
        "county": [
            {
                "countyName": "Arkansas",
                "cities": [
                    "Almyra",
                    "DeWitt†",
                    "Gillett",
                    "St. Charles",
                    "Stuttgart†"
                ]
            },
            {
                "countyName": "Arkansas\nJefferson",
                "cities": [
                    "Humphrey"
                ]
            },
            {
                "countyName": "Ashley",
                "cities": [
                    "Crossett",
                    "Fountain Hill",
                    "Hamburg†",
                    "Montrose",
                    "Parkdale",
                    "Portland",
                    "Wilmot"
                ]
            },
            {
                "countyName": "Baxter",
                "cities": [
                    "Big Flat",
                    "Briarcliff",
                    "Cotter",
                    "Gassville",
                    "Lakeview",
                    "Mountain Home†",
                    "Norfork",
                    "Salesville"
                ]
            },
            {
                "countyName": "Benton",
                "cities": [
                    "Avoca",
                    "Bella Vista",
                    "Bentonville†",
                    "Bethel Heights[a]",
                    "Cave Springs",
                    "Centerton",
                    "Decatur",
                    "Garfield",
                    "Gateway",
                    "Gentry",
                    "Gravette",
                    "Highfill",
                    "Little Flock",
                    "Lowell",
                    "Pea Ridge",
                    "Rogers",
                    "Siloam Springs",
                    "Springtown",
                    "Sulphur Springs"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Bellefonte",
                    "Bergman",
                    "Diamond City",
                    "Everton",
                    "Harrison†",
                    "Lead Hill",
                    "Omaha",
                    "South Lead Hill",
                    "Valley Springs",
                    "Zinc"
                ]
            },
            {
                "countyName": "Boone\nCarroll",
                "cities": [
                    "Alpena"
                ]
            },
            {
                "countyName": "Bradley",
                "cities": [
                    "Banks",
                    "Hermitage",
                    "Warren†"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Hampton†",
                    "Harrell",
                    "Thornton",
                    "Tinsman"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Beaver",
                    "Berryville†",
                    "Blue Eye",
                    "Eureka Springs†",
                    "Green Forest",
                    "Oak Grove"
                ]
            },
            {
                "countyName": "Chicot",
                "cities": [
                    "Dermott",
                    "Eudora",
                    "Lake Village†"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Amity",
                    "Arkadelphia†",
                    "Caddo Valley",
                    "Gum Springs",
                    "Gurdon",
                    "Okolona",
                    "Whelen Springs"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Corning†",
                    "Datto",
                    "Greenway",
                    "Knobel",
                    "McDougal",
                    "Nimmons",
                    "Peach Orchard",
                    "Piggott†",
                    "Pollard",
                    "Rector",
                    "St. Francis",
                    "Success"
                ]
            },
            {
                "countyName": "Cleburne",
                "cities": [
                    "Concord",
                    "Greers Ferry",
                    "Heber Springs†",
                    "Higden"
                ]
            },
            {
                "countyName": "Cleburne\nFaulkner",
                "cities": [
                    "Quitman"
                ]
            },
            {
                "countyName": "Cleveland",
                "cities": [
                    "Kingsland",
                    "Rison†"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Emerson",
                    "Magnolia†",
                    "McNeil",
                    "Taylor",
                    "Waldo"
                ]
            },
            {
                "countyName": "Conway",
                "cities": [
                    "Menifee",
                    "Morrilton†",
                    "Oppelo",
                    "Plumerville"
                ]
            },
            {
                "countyName": "Craighead",
                "cities": [
                    "Bay",
                    "Black Oak",
                    "Bono",
                    "Brookland",
                    "Caraway",
                    "Cash",
                    "Egypt",
                    "Jonesboro†",
                    "Lake City",
                    "Monette"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Alma",
                    "Cedarville",
                    "Chester",
                    "Dyer",
                    "Kibler",
                    "Mountainburg",
                    "Mulberry",
                    "Rudy",
                    "Van Buren†"
                ]
            },
            {
                "countyName": "Crittenden",
                "cities": [
                    "Anthonyville",
                    "Clarkedale",
                    "Crawfordsville",
                    "Earle",
                    "Edmondson",
                    "Gilmore",
                    "Horseshoe Lake",
                    "Jennette",
                    "Jericho",
                    "Marion†",
                    "Sunset",
                    "Turrell",
                    "West Memphis"
                ]
            },
            {
                "countyName": "Cross",
                "cities": [
                    "Cherry Valley",
                    "Hickory Ridge",
                    "Parkin",
                    "Wynne†"
                ]
            },
            {
                "countyName": "Dallas",
                "cities": [
                    "Carthage",
                    "Fordyce†",
                    "Sparkman"
                ]
            },
            {
                "countyName": "Desha",
                "cities": [
                    "Arkansas City†",
                    "Dumas",
                    "McGehee",
                    "Mitchellville",
                    "Reed",
                    "Watson"
                ]
            },
            {
                "countyName": "Drew",
                "cities": [
                    "Monticello†",
                    "Wilmar",
                    "Winchester"
                ]
            },
            {
                "countyName": "Drew\nDesha",
                "cities": [
                    "Tillar"
                ]
            },
            {
                "countyName": "Faulkner",
                "cities": [
                    "Conway†",
                    "Enola",
                    "Greenbrier",
                    "Guy",
                    "Holland",
                    "Mayflower",
                    "Mount Vernon",
                    "Twin Groves",
                    "Vilonia",
                    "Wooster"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Altus",
                    "Branch",
                    "Charleston",
                    "Denning",
                    "Ozark†",
                    "Wiederkehr Village"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Mammoth Spring",
                    "Salem†",
                    "Viola"
                ]
            },
            {
                "countyName": "Garland",
                "cities": [
                    "Fountain Lake",
                    "Hot Springs†",
                    "Lonsdale",
                    "Mountain Pine"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Leola",
                    "Poyen",
                    "Prattsville",
                    "Sheridan†",
                    "Tull"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Delaplaine",
                    "Lafe",
                    "Marmaduke",
                    "Oak Grove Heights",
                    "Paragould†"
                ]
            },
            {
                "countyName": "Hempstead",
                "cities": [
                    "Blevins",
                    "Fulton",
                    "Hope†",
                    "McCaskill",
                    "McNab",
                    "Oakhaven",
                    "Ozan",
                    "Patmos",
                    "Perrytown",
                    "Washington"
                ]
            },
            {
                "countyName": "Hot Spring",
                "cities": [
                    "Donaldson",
                    "Friendship",
                    "Malvern†",
                    "Midway",
                    "Perla",
                    "Rockport"
                ]
            },
            {
                "countyName": "Howard",
                "cities": [
                    "Dierks",
                    "Mineral Springs",
                    "Nashville†",
                    "Tollette"
                ]
            },
            {
                "countyName": "Independence",
                "cities": [
                    "Batesville†",
                    "Cushman",
                    "Magness",
                    "Moorefield",
                    "Newark",
                    "Oil Trough",
                    "Pleasant Plains",
                    "Southside",
                    "Sulphur Rock"
                ]
            },
            {
                "countyName": "Izard",
                "cities": [
                    "Calico Rock",
                    "Franklin",
                    "Guion",
                    "Melbourne†",
                    "Mount Pleasant",
                    "Oxford",
                    "Pineville"
                ]
            },
            {
                "countyName": "Izard\nFulton\nSharp",
                "cities": [
                    "Horseshoe Bend"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Amagon",
                    "Beedeville",
                    "Campbell Station",
                    "Diaz",
                    "Grubbs",
                    "Jacksonport",
                    "Newport†",
                    "Swifton",
                    "Tuckerman",
                    "Tupelo",
                    "Weldon"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Altheimer",
                    "Pine Bluff†",
                    "Redfield",
                    "Sherrill",
                    "Wabbaseka",
                    "White Hall"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Clarksville†",
                    "Coal Hill",
                    "Hartman",
                    "Knoxville",
                    "Lamar"
                ]
            },
            {
                "countyName": "Lafayette",
                "cities": [
                    "Bradley",
                    "Buckner",
                    "Lewisville†",
                    "Stamps"
                ]
            },
            {
                "countyName": "Lawrence",
                "cities": [
                    "Alicia",
                    "Black Rock",
                    "Hoxie",
                    "Imboden",
                    "Lynn",
                    "Minturn",
                    "Portia",
                    "Powhatan",
                    "Ravenden",
                    "Sedgwick",
                    "Smithville",
                    "Strawberry",
                    "Walnut Ridge†"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Aubrey",
                    "Haynes",
                    "LaGrange",
                    "Marianna†",
                    "Moro",
                    "Rondo"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Gould",
                    "Grady",
                    "Star City†"
                ]
            },
            {
                "countyName": "Little River",
                "cities": [
                    "Ashdown†",
                    "Foreman",
                    "Ogden",
                    "Wilton",
                    "Winthrop"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Blue Mountain",
                    "Booneville†",
                    "Caulksville",
                    "Magazine",
                    "Morrison Bluff",
                    "Paris†",
                    "Ratcliff",
                    "Scranton",
                    "Subiaco"
                ]
            },
            {
                "countyName": "Lonoke",
                "cities": [
                    "Allport",
                    "Austin",
                    "Cabot",
                    "Carlisle",
                    "Coy",
                    "England",
                    "Humnoke",
                    "Keo",
                    "Lonoke†",
                    "Ward"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Hindsville",
                    "Huntsville†",
                    "St. Paul"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Bull Shoals",
                    "Flippin",
                    "Pyatt",
                    "Summit",
                    "Yellville†"
                ]
            },
            {
                "countyName": "Miller",
                "cities": [
                    "Fouke",
                    "Garland",
                    "Texarkana†"
                ]
            },
            {
                "countyName": "Mississippi",
                "cities": [
                    "Bassett",
                    "Birdsong",
                    "Blytheville†",
                    "Burdette",
                    "Dell",
                    "Dyess",
                    "Etowah",
                    "Gosnell",
                    "Joiner",
                    "Keiser",
                    "Leachville",
                    "Luxora",
                    "Manila",
                    "Marie",
                    "Osceola†",
                    "Victoria",
                    "Wilson"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Brinkley",
                    "Clarendon†",
                    "Fargo",
                    "Holly Grove",
                    "Roe"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Black Springs",
                    "Mount Ida†",
                    "Norman",
                    "Oden"
                ]
            },
            {
                "countyName": "Nevada",
                "cities": [
                    "Bluff City",
                    "Bodcaw",
                    "Cale",
                    "Prescott†",
                    "Rosston",
                    "Willisville"
                ]
            },
            {
                "countyName": "Nevada\nHempstead",
                "cities": [
                    "Emmet"
                ]
            },
            {
                "countyName": "Newton",
                "cities": [
                    "Jasper†",
                    "Western Grove"
                ]
            },
            {
                "countyName": "Ouachita",
                "cities": [
                    "Bearden",
                    "Camden†",
                    "Chidester",
                    "East Camden",
                    "Louann",
                    "Stephens"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Adona",
                    "Bigelow",
                    "Casa",
                    "Fourche",
                    "Houston",
                    "Perry",
                    "Perryville†"
                ]
            },
            {
                "countyName": "Phillips",
                "cities": [
                    "Elaine",
                    "Helena-West Helena†",
                    "Lake View",
                    "Lexa",
                    "Marvell"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Antoine",
                    "Daisy",
                    "Delight",
                    "Glenwood",
                    "Murfreesboro†"
                ]
            },
            {
                "countyName": "Poinsett",
                "cities": [
                    "Fisher",
                    "Harrisburg†",
                    "Lepanto",
                    "Marked Tree",
                    "Trumann",
                    "Tyronza",
                    "Waldenburg",
                    "Weiner"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Cove",
                    "Grannis",
                    "Hatfield",
                    "Mena†",
                    "Vandervoort",
                    "Wickes"
                ]
            },
            {
                "countyName": "Pope",
                "cities": [
                    "Atkins",
                    "Dover",
                    "Hector",
                    "London",
                    "Pottsville",
                    "Russellville†"
                ]
            },
            {
                "countyName": "Prairie",
                "cities": [
                    "De Valls Bluff",
                    "Des Arc†",
                    "Fredonia (Biscoe)",
                    "Hazen",
                    "Ulm"
                ]
            },
            {
                "countyName": "Pulaski",
                "cities": [
                    "Cammack Village",
                    "Jacksonville",
                    "Little Rock‡",
                    "Maumelle",
                    "North Little Rock",
                    "Sherwood",
                    "Wrightsville"
                ]
            },
            {
                "countyName": "Pulaski\nSaline",
                "cities": [
                    "Alexander"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Biggers",
                    "Maynard",
                    "O'Kean",
                    "Pocahontas†",
                    "Ravenden Springs",
                    "Reyno"
                ]
            },
            {
                "countyName": "Saline",
                "cities": [
                    "Bauxite",
                    "Benton†",
                    "Bryant",
                    "Haskell",
                    "Shannon Hills",
                    "Traskwood"
                ]
            },
            {
                "countyName": "Scott",
                "cities": [
                    "Waldron†"
                ]
            },
            {
                "countyName": "Searcy",
                "cities": [
                    "Gilbert",
                    "Leslie",
                    "Marshall†",
                    "Pindall",
                    "St. Joe"
                ]
            },
            {
                "countyName": "Sebastian",
                "cities": [
                    "Barling",
                    "Bonanza",
                    "Central City",
                    "Fort Smith†",
                    "Greenwood†",
                    "Hackett",
                    "Hartford",
                    "Huntington",
                    "Lavaca",
                    "Midland"
                ]
            },
            {
                "countyName": "Sebastian\nScott",
                "cities": [
                    "Mansfield"
                ]
            },
            {
                "countyName": "Sevier",
                "cities": [
                    "Ben Lomond",
                    "De Queen†",
                    "Gillham",
                    "Horatio",
                    "Lockesburg"
                ]
            },
            {
                "countyName": "Sharp",
                "cities": [
                    "Evening Shade",
                    "Hardy",
                    "Highland",
                    "Sidney",
                    "Williford"
                ]
            },
            {
                "countyName": "Sharp\nFulton",
                "cities": [
                    "Ash Flat†",
                    "Cherokee Village"
                ]
            },
            {
                "countyName": "Sharp\nIndependence",
                "cities": [
                    "Cave City"
                ]
            },
            {
                "countyName": "St. Francis",
                "cities": [
                    "Caldwell",
                    "Colt",
                    "Forrest City†",
                    "Hughes",
                    "Madison",
                    "Palestine",
                    "Wheatley",
                    "Widener"
                ]
            },
            {
                "countyName": "Stone",
                "cities": [
                    "Fifty-Six",
                    "Mountain View†"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Calion",
                    "El Dorado†",
                    "Felsenthal",
                    "Huttig",
                    "Junction City",
                    "Norphlet",
                    "Smackover",
                    "Strong"
                ]
            },
            {
                "countyName": "Van Buren",
                "cities": [
                    "Clinton†",
                    "Shirley"
                ]
            },
            {
                "countyName": "Van Buren\nCleburne",
                "cities": [
                    "Fairfield Bay"
                ]
            },
            {
                "countyName": "Van Buren\nFaulkner",
                "cities": [
                    "Damascus"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Elkins",
                    "Farmington",
                    "Fayetteville†",
                    "Goshen",
                    "Greenland",
                    "Johnson",
                    "Lincoln",
                    "Prairie Grove",
                    "Tontitown",
                    "West Fork",
                    "Winslow"
                ]
            },
            {
                "countyName": "Washington\nBenton",
                "cities": [
                    "Elm Springs",
                    "Springdale"
                ]
            },
            {
                "countyName": "White",
                "cities": [
                    "Bald Knob",
                    "Beebe",
                    "Bradford",
                    "Garner",
                    "Georgetown",
                    "Griffithville",
                    "Higginson",
                    "Judsonia",
                    "Kensett",
                    "Letona",
                    "McRae",
                    "Pangburn",
                    "Rose Bud",
                    "Russell",
                    "Searcy†",
                    "West Point"
                ]
            },
            {
                "countyName": "Woodruff",
                "cities": [
                    "Augusta†",
                    "Cotton Plant",
                    "Hunter",
                    "McCrory",
                    "Patterson"
                ]
            },
            {
                "countyName": "Yell",
                "cities": [
                    "Belleville",
                    "Corinth",
                    "Danville†",
                    "Dardanelle†",
                    "Havana",
                    "Ola",
                    "Plainview"
                ]
            }
        ]
    },
    {
        "stateId": "AZ",
        "stateName": "Arizona",
        "county": [
            {
                "countyName": "Apache",
                "cities": [
                    "Eagar",
                    "Springerville",
                    "St. Johns†"
                ]
            },
            {
                "countyName": "Cochise",
                "cities": [
                    "Benson",
                    "Bisbee",
                    "Douglas",
                    "Huachuca City",
                    "Sierra Vista",
                    "Tombstone",
                    "Willcox"
                ]
            },
            {
                "countyName": "Coconino",
                "cities": [
                    "Flagstaff†",
                    "Fredonia",
                    "Page",
                    "Tusayan[a]",
                    "Williams"
                ]
            },
            {
                "countyName": "Gila",
                "cities": [
                    "Globe†",
                    "Miami",
                    "Payson",
                    "Star Valley"
                ]
            },
            {
                "countyName": "Gila, Pinal",
                "cities": [
                    "Hayden",
                    "Winkelman"
                ]
            },
            {
                "countyName": "Graham",
                "cities": [
                    "Pima",
                    "Safford†",
                    "Thatcher"
                ]
            },
            {
                "countyName": "Greenlee",
                "cities": [
                    "Clifton†",
                    "Duncan"
                ]
            },
            {
                "countyName": "La Paz",
                "cities": [
                    "Parker†",
                    "Quartzsite"
                ]
            },
            {
                "countyName": "Maricopa",
                "cities": [
                    "Avondale",
                    "Buckeye",
                    "Carefree",
                    "Cave Creek",
                    "Chandler",
                    "El Mirage",
                    "Fountain Hills",
                    "Gila Bend",
                    "Gilbert",
                    "Glendale",
                    "Goodyear",
                    "Guadalupe",
                    "Litchfield Park",
                    "Mesa",
                    "Paradise Valley",
                    "Phoenix‡",
                    "Scottsdale",
                    "Surprise",
                    "Tempe",
                    "Tolleson",
                    "Youngtown"
                ]
            },
            {
                "countyName": "Maricopa, Pinal",
                "cities": [
                    "Queen Creek"
                ]
            },
            {
                "countyName": "Maricopa, Yavapai",
                "cities": [
                    "Peoria",
                    "Wickenburg"
                ]
            },
            {
                "countyName": "Mohave",
                "cities": [
                    "Bullhead City",
                    "Colorado City",
                    "Kingman†",
                    "Lake Havasu City"
                ]
            },
            {
                "countyName": "Navajo",
                "cities": [
                    "Holbrook†",
                    "Pinetop-Lakeside",
                    "Show Low",
                    "Snowflake",
                    "Taylor",
                    "Winslow"
                ]
            },
            {
                "countyName": "Pima",
                "cities": [
                    "Oro Valley",
                    "Sahuarita",
                    "South Tucson",
                    "Tucson†"
                ]
            },
            {
                "countyName": "Pima, Pinal",
                "cities": [
                    "Marana"
                ]
            },
            {
                "countyName": "Pinal",
                "cities": [
                    "Casa Grande",
                    "Coolidge",
                    "Eloy",
                    "Florence†",
                    "Kearny",
                    "Mammoth",
                    "Maricopa",
                    "Superior"
                ]
            },
            {
                "countyName": "Pinal, Maricopa",
                "cities": [
                    "Apache Junction"
                ]
            },
            {
                "countyName": "Santa Cruz",
                "cities": [
                    "Nogales†",
                    "Patagonia"
                ]
            },
            {
                "countyName": "Yavapai",
                "cities": [
                    "Camp Verde",
                    "Chino Valley",
                    "Clarkdale",
                    "Cottonwood",
                    "Dewey-Humboldt",
                    "Jerome",
                    "Prescott†",
                    "Prescott Valley"
                ]
            },
            {
                "countyName": "Yavapai, Coconino",
                "cities": [
                    "Sedona"
                ]
            },
            {
                "countyName": "Yuma",
                "cities": [
                    "San Luis",
                    "Somerton",
                    "Wellton",
                    "Yuma†"
                ]
            }
        ]
    },
    {
        "stateId": "CA",
        "stateName": "California",
        "county": [
            {
                "countyName": "Alameda",
                "cities": [
                    "Alameda",
                    "Albany",
                    "Berkeley",
                    "Dublin",
                    "Emeryville",
                    "Fremont",
                    "Hayward",
                    "Livermore",
                    "Newark",
                    "Oakland†",
                    "Piedmont",
                    "Pleasanton",
                    "San Leandro",
                    "Union City"
                ]
            },
            {
                "countyName": "Amador",
                "cities": [
                    "Amador City",
                    "Ione",
                    "Jackson†",
                    "Plymouth",
                    "Sutter Creek"
                ]
            },
            {
                "countyName": "Butte",
                "cities": [
                    "Biggs",
                    "Chico",
                    "Gridley",
                    "Oroville†",
                    "Paradise"
                ]
            },
            {
                "countyName": "Calaveras",
                "cities": [
                    "Angels Camp"
                ]
            },
            {
                "countyName": "Colusa",
                "cities": [
                    "Colusa†",
                    "Williams"
                ]
            },
            {
                "countyName": "Contra Costa",
                "cities": [
                    "Antioch",
                    "Brentwood",
                    "Clayton",
                    "Concord",
                    "Danville",
                    "El Cerrito",
                    "Hercules",
                    "Lafayette",
                    "Martinez†",
                    "Moraga",
                    "Oakley",
                    "Orinda",
                    "Pinole",
                    "Pittsburg",
                    "Pleasant Hill",
                    "Richmond",
                    "San Pablo",
                    "San Ramon",
                    "Walnut Creek"
                ]
            },
            {
                "countyName": "Del Norte",
                "cities": [
                    "Crescent City†"
                ]
            },
            {
                "countyName": "El Dorado",
                "cities": [
                    "Placerville†",
                    "South Lake Tahoe"
                ]
            },
            {
                "countyName": "Fresno",
                "cities": [
                    "Clovis",
                    "Coalinga",
                    "Firebaugh",
                    "Fowler",
                    "Fresno†",
                    "Huron",
                    "Kerman",
                    "Kingsburg",
                    "Mendota",
                    "Orange Cove",
                    "Parlier",
                    "Reedley",
                    "San Joaquin",
                    "Sanger",
                    "Selma"
                ]
            },
            {
                "countyName": "Glenn",
                "cities": [
                    "Orland",
                    "Willows†"
                ]
            },
            {
                "countyName": "Humboldt",
                "cities": [
                    "Arcata",
                    "Blue Lake",
                    "Eureka†",
                    "Ferndale",
                    "Fortuna",
                    "Rio Dell",
                    "Trinidad"
                ]
            },
            {
                "countyName": "Imperial",
                "cities": [
                    "Brawley",
                    "Calexico",
                    "Calipatria",
                    "El Centro†",
                    "Holtville",
                    "Imperial",
                    "Westmorland"
                ]
            },
            {
                "countyName": "Inyo",
                "cities": [
                    "Bishop"
                ]
            },
            {
                "countyName": "Kern",
                "cities": [
                    "Arvin",
                    "Bakersfield†",
                    "California City",
                    "Delano",
                    "Maricopa",
                    "McFarland",
                    "Ridgecrest",
                    "Shafter",
                    "Taft",
                    "Tehachapi",
                    "Wasco"
                ]
            },
            {
                "countyName": "Kings",
                "cities": [
                    "Avenal",
                    "Corcoran",
                    "Hanford†",
                    "Lemoore"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Clearlake",
                    "Lakeport†"
                ]
            },
            {
                "countyName": "Lassen",
                "cities": [
                    "Susanville†"
                ]
            },
            {
                "countyName": "Los Angeles",
                "cities": [
                    "Agoura Hills",
                    "Alhambra",
                    "Arcadia",
                    "Artesia",
                    "Avalon",
                    "Azusa",
                    "Baldwin Park",
                    "Bell",
                    "Bell Gardens",
                    "Bellflower",
                    "Beverly Hills",
                    "Bradbury",
                    "Burbank",
                    "Calabasas",
                    "Carson",
                    "Cerritos",
                    "Claremont",
                    "Commerce",
                    "Compton",
                    "Covina",
                    "Cudahy",
                    "Culver City",
                    "Diamond Bar",
                    "Downey",
                    "Duarte",
                    "El Monte",
                    "El Segundo",
                    "Gardena",
                    "Glendale",
                    "Glendora",
                    "Hawaiian Gardens",
                    "Hawthorne",
                    "Hermosa Beach",
                    "Hidden Hills",
                    "Huntington Park",
                    "City of Industry",
                    "Inglewood",
                    "Irwindale",
                    "La Cañada Flintridge",
                    "La Habra Heights",
                    "La Mirada",
                    "La Puente",
                    "La Verne",
                    "Lakewood",
                    "Lancaster",
                    "Lawndale",
                    "Lomita",
                    "Long Beach",
                    "Los Angeles†",
                    "Lynwood",
                    "Malibu",
                    "Manhattan Beach",
                    "Maywood",
                    "Monrovia",
                    "Montebello",
                    "Monterey Park",
                    "Norwalk",
                    "Palmdale",
                    "Palos Verdes Estates",
                    "Paramount",
                    "Pasadena",
                    "Pico Rivera",
                    "Pomona",
                    "Rancho Palos Verdes",
                    "Redondo Beach",
                    "Rolling Hills",
                    "Rolling Hills Estates",
                    "Rosemead",
                    "San Dimas",
                    "San Fernando",
                    "San Gabriel",
                    "San Marino",
                    "Santa Clarita",
                    "Santa Fe Springs",
                    "Santa Monica",
                    "Sierra Madre",
                    "Signal Hill",
                    "South El Monte",
                    "South Gate",
                    "South Pasadena",
                    "Temple City",
                    "Torrance",
                    "Vernon",
                    "Walnut",
                    "West Covina",
                    "West Hollywood",
                    "Westlake Village",
                    "Whittier"
                ]
            },
            {
                "countyName": "Madera",
                "cities": [
                    "Chowchilla",
                    "Madera†"
                ]
            },
            {
                "countyName": "Marin",
                "cities": [
                    "Belvedere",
                    "Corte Madera",
                    "Fairfax",
                    "Larkspur",
                    "Mill Valley",
                    "Novato",
                    "Ross",
                    "San Anselmo",
                    "San Rafael†",
                    "Sausalito",
                    "Tiburon"
                ]
            },
            {
                "countyName": "Mendocino",
                "cities": [
                    "Fort Bragg",
                    "Point Arena",
                    "Ukiah†",
                    "Willits"
                ]
            },
            {
                "countyName": "Merced",
                "cities": [
                    "Atwater",
                    "Dos Palos",
                    "Gustine",
                    "Livingston",
                    "Los Banos",
                    "Merced†"
                ]
            },
            {
                "countyName": "Modoc",
                "cities": [
                    "Alturas†"
                ]
            },
            {
                "countyName": "Mono",
                "cities": [
                    "Mammoth Lakes"
                ]
            },
            {
                "countyName": "Monterey",
                "cities": [
                    "Carmel-by-the-Sea",
                    "Del Rey Oaks",
                    "Gonzales",
                    "Greenfield",
                    "King City",
                    "Marina",
                    "Monterey",
                    "Pacific Grove",
                    "Salinas†",
                    "Sand City",
                    "Seaside",
                    "Soledad"
                ]
            },
            {
                "countyName": "Napa",
                "cities": [
                    "American Canyon",
                    "Calistoga",
                    "Napa†",
                    "St. Helena",
                    "Yountville"
                ]
            },
            {
                "countyName": "Nevada",
                "cities": [
                    "Grass Valley",
                    "Nevada City†",
                    "Truckee"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Aliso Viejo",
                    "Anaheim",
                    "Brea",
                    "Buena Park",
                    "Costa Mesa",
                    "Cypress",
                    "Dana Point",
                    "Fountain Valley",
                    "Fullerton",
                    "Garden Grove",
                    "Huntington Beach",
                    "Irvine",
                    "La Habra",
                    "La Palma",
                    "Laguna Beach",
                    "Laguna Hills",
                    "Laguna Niguel",
                    "Laguna Woods",
                    "Lake Forest",
                    "Los Alamitos",
                    "Mission Viejo",
                    "Newport Beach",
                    "Orange",
                    "Placentia",
                    "Rancho Santa Margarita",
                    "San Clemente",
                    "San Juan Capistrano",
                    "Santa Ana†",
                    "Seal Beach",
                    "Stanton",
                    "Tustin",
                    "Villa Park",
                    "Westminster",
                    "Yorba Linda"
                ]
            },
            {
                "countyName": "Placer",
                "cities": [
                    "Auburn†",
                    "Colfax",
                    "Lincoln",
                    "Loomis",
                    "Rocklin",
                    "Roseville"
                ]
            },
            {
                "countyName": "Plumas",
                "cities": [
                    "Portola"
                ]
            },
            {
                "countyName": "Riverside",
                "cities": [
                    "Banning",
                    "Beaumont",
                    "Blythe",
                    "Calimesa",
                    "Canyon Lake",
                    "Cathedral City",
                    "Coachella",
                    "Corona",
                    "Desert Hot Springs",
                    "Hemet",
                    "Indian Wells",
                    "Indio",
                    "La Quinta",
                    "Lake Elsinore",
                    "Menifee",
                    "Moreno Valley",
                    "Murrieta",
                    "Norco",
                    "Palm Desert",
                    "Palm Springs",
                    "Perris",
                    "Rancho Mirage",
                    "Riverside†",
                    "San Jacinto",
                    "Temecula",
                    "Wildomar"
                ]
            },
            {
                "countyName": "Riverside[11]",
                "cities": [
                    "Eastvale"
                ]
            },
            {
                "countyName": "Riverside[12]",
                "cities": [
                    "Jurupa Valley"
                ]
            },
            {
                "countyName": "Sacramento",
                "cities": [
                    "Citrus Heights",
                    "Elk Grove",
                    "Folsom",
                    "Galt",
                    "Isleton",
                    "Rancho Cordova",
                    "Sacramento‡"
                ]
            },
            {
                "countyName": "San Benito",
                "cities": [
                    "Hollister†",
                    "San Juan Bautista"
                ]
            },
            {
                "countyName": "San Bernardino",
                "cities": [
                    "Adelanto",
                    "Apple Valley",
                    "Barstow",
                    "Big Bear Lake",
                    "Chino",
                    "Chino Hills",
                    "Colton",
                    "Fontana",
                    "Grand Terrace",
                    "Hesperia",
                    "Highland",
                    "Loma Linda",
                    "Montclair",
                    "Needles",
                    "Ontario",
                    "Rancho Cucamonga",
                    "Redlands",
                    "Rialto",
                    "San Bernardino†",
                    "Twentynine Palms",
                    "Upland",
                    "Victorville",
                    "Yucaipa",
                    "Yucca Valley"
                ]
            },
            {
                "countyName": "San Diego",
                "cities": [
                    "Carlsbad",
                    "Chula Vista",
                    "Coronado",
                    "Del Mar",
                    "El Cajon",
                    "Encinitas",
                    "Escondido",
                    "Imperial Beach",
                    "La Mesa",
                    "Lemon Grove",
                    "National City",
                    "Oceanside",
                    "Poway",
                    "San Diego†",
                    "San Marcos",
                    "Santee",
                    "Solana Beach",
                    "Vista"
                ]
            },
            {
                "countyName": "San Francisco",
                "cities": [
                    "San Francisco†"
                ]
            },
            {
                "countyName": "San Joaquin",
                "cities": [
                    "Escalon",
                    "Lathrop",
                    "Lodi",
                    "Manteca",
                    "Ripon",
                    "Stockton†",
                    "Tracy"
                ]
            },
            {
                "countyName": "San Luis Obispo",
                "cities": [
                    "Arroyo Grande",
                    "Atascadero",
                    "Grover Beach",
                    "Morro Bay",
                    "Paso Robles",
                    "Pismo Beach",
                    "San Luis Obispo†"
                ]
            },
            {
                "countyName": "San Mateo",
                "cities": [
                    "Atherton",
                    "Belmont",
                    "Brisbane",
                    "Burlingame",
                    "Colma",
                    "Daly City",
                    "East Palo Alto",
                    "Foster City",
                    "Half Moon Bay",
                    "Hillsborough",
                    "Menlo Park",
                    "Millbrae",
                    "Pacifica",
                    "Portola Valley",
                    "Redwood City†",
                    "San Bruno",
                    "San Carlos",
                    "San Mateo",
                    "South San Francisco",
                    "Woodside"
                ]
            },
            {
                "countyName": "Santa Barbara",
                "cities": [
                    "Buellton",
                    "Carpinteria",
                    "Goleta",
                    "Guadalupe",
                    "Lompoc",
                    "Santa Barbara†",
                    "Santa Maria",
                    "Solvang"
                ]
            },
            {
                "countyName": "Santa Clara",
                "cities": [
                    "Campbell",
                    "Cupertino",
                    "Gilroy",
                    "Los Altos",
                    "Los Altos Hills",
                    "Los Gatos",
                    "Milpitas",
                    "Monte Sereno",
                    "Morgan Hill",
                    "Mountain View",
                    "Palo Alto",
                    "San Jose†",
                    "Santa Clara",
                    "Saratoga",
                    "Sunnyvale"
                ]
            },
            {
                "countyName": "Santa Cruz",
                "cities": [
                    "Capitola",
                    "Santa Cruz†",
                    "Scotts Valley",
                    "Watsonville"
                ]
            },
            {
                "countyName": "Shasta",
                "cities": [
                    "Anderson",
                    "Redding†",
                    "Shasta Lake"
                ]
            },
            {
                "countyName": "Sierra",
                "cities": [
                    "Loyalton"
                ]
            },
            {
                "countyName": "Siskiyou",
                "cities": [
                    "Dorris",
                    "Dunsmuir",
                    "Etna",
                    "Fort Jones",
                    "Montague",
                    "Mount Shasta",
                    "Tulelake",
                    "Weed",
                    "Yreka†"
                ]
            },
            {
                "countyName": "Solano",
                "cities": [
                    "Benicia",
                    "Dixon",
                    "Fairfield†",
                    "Rio Vista",
                    "Suisun City",
                    "Vacaville",
                    "Vallejo"
                ]
            },
            {
                "countyName": "Sonoma",
                "cities": [
                    "Cloverdale",
                    "Cotati",
                    "Healdsburg",
                    "Petaluma",
                    "Rohnert Park",
                    "Santa Rosa†",
                    "Sebastopol",
                    "Sonoma",
                    "Windsor"
                ]
            },
            {
                "countyName": "Stanislaus",
                "cities": [
                    "Ceres",
                    "Hughson",
                    "Modesto†",
                    "Newman",
                    "Oakdale",
                    "Patterson",
                    "Riverbank",
                    "Turlock",
                    "Waterford"
                ]
            },
            {
                "countyName": "Sutter",
                "cities": [
                    "Live Oak",
                    "Yuba City†"
                ]
            },
            {
                "countyName": "Tehama",
                "cities": [
                    "Corning",
                    "Red Bluff†",
                    "Tehama"
                ]
            },
            {
                "countyName": "Tulare",
                "cities": [
                    "Dinuba",
                    "Exeter",
                    "Farmersville",
                    "Lindsay",
                    "Porterville",
                    "Tulare",
                    "Visalia†",
                    "Woodlake"
                ]
            },
            {
                "countyName": "Tuolumne",
                "cities": [
                    "Sonora†"
                ]
            },
            {
                "countyName": "Ventura",
                "cities": [
                    "Camarillo",
                    "Fillmore",
                    "Moorpark",
                    "Ojai",
                    "Oxnard",
                    "Port Hueneme",
                    "Santa Paula",
                    "Simi Valley",
                    "Thousand Oaks",
                    "Ventura†"
                ]
            },
            {
                "countyName": "Yolo",
                "cities": [
                    "Davis",
                    "West Sacramento",
                    "Winters",
                    "Woodland†"
                ]
            },
            {
                "countyName": "Yuba",
                "cities": [
                    "Marysville†",
                    "Wheatland"
                ]
            }
        ]
    },
    {
        "stateId": "CO",
        "stateName": "Colorado",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Commerce City",
                    "Federal Heights"
                ]
            },
            {
                "countyName": "Adams,\nArapahoe",
                "cities": [
                    "Bennett"
                ]
            },
            {
                "countyName": "Adams,\nJefferson",
                "cities": [
                    "Westminster"
                ]
            },
            {
                "countyName": "Adams,\nWeld",
                "cities": [
                    "Northglenn",
                    "Thornton"
                ]
            },
            {
                "countyName": "Adams,[r]\nWeld",
                "cities": [
                    "Brighton†[q]"
                ]
            },
            {
                "countyName": "Alamosa",
                "cities": [
                    "Alamosa†[e]",
                    "Hooper"
                ]
            },
            {
                "countyName": "Arapahoe",
                "cities": [
                    "Centennial",
                    "Cherry Hills Village",
                    "Columbine Valley",
                    "Deer Trail",
                    "Englewood",
                    "Foxfield",
                    "Glendale",
                    "Greenwood Village",
                    "Sheridan"
                ]
            },
            {
                "countyName": "Arapahoe,\nAdams,\nDouglas",
                "cities": [
                    "Aurora[i][j]"
                ]
            },
            {
                "countyName": "Arapahoe,\nJefferson",
                "cities": [
                    "Bow Mar"
                ]
            },
            {
                "countyName": "Arapahoe,[cm]\nJefferson,\nDouglas",
                "cities": [
                    "Littleton†[cl]"
                ]
            },
            {
                "countyName": "Archuleta",
                "cities": [
                    "Pagosa Springs†[cs]"
                ]
            },
            {
                "countyName": "Baca",
                "cities": [
                    "Campo",
                    "Pritchett",
                    "Springfield†[dg]",
                    "Two Buttes",
                    "Vilas",
                    "Walsh"
                ]
            },
            {
                "countyName": "Bent",
                "cities": [
                    "Las Animas†[ci]"
                ]
            },
            {
                "countyName": "Boulder",
                "cities": [
                    "Boulder†[m]",
                    "Jamestown",
                    "Lafayette",
                    "Louisville[14]",
                    "Lyons",
                    "Nederland",
                    "Ward"
                ]
            },
            {
                "countyName": "Boulder,\nJefferson",
                "cities": [
                    "Superior"
                ]
            },
            {
                "countyName": "Boulder,\nWeld",
                "cities": [
                    "Longmont"
                ]
            },
            {
                "countyName": "Broomfield[t]",
                "cities": [
                    "Broomfield†[s]"
                ]
            },
            {
                "countyName": "Chaffee",
                "cities": [
                    "Buena Vista[v][14]",
                    "Poncha Springs",
                    "Salida†[db]"
                ]
            },
            {
                "countyName": "Cheyenne",
                "cities": [
                    "Cheyenne Wells†[ae]",
                    "Kit Carson[cd]"
                ]
            },
            {
                "countyName": "Clear Creek",
                "cities": [
                    "Empire",
                    "Georgetown†[bi]",
                    "Idaho Springs[bx]",
                    "Silver Plume"
                ]
            },
            {
                "countyName": "Conejos",
                "cities": [
                    "Antonito",
                    "La Jara",
                    "Manassa",
                    "Romeo",
                    "Sanford"
                ]
            },
            {
                "countyName": "Costilla",
                "cities": [
                    "Blanca",
                    "San Luis†[dc]"
                ]
            },
            {
                "countyName": "Crowley",
                "cities": [
                    "Crowley",
                    "Olney Springs",
                    "Ordway†[cq]",
                    "Sugar City"
                ]
            },
            {
                "countyName": "Custer",
                "cities": [
                    "Silver Cliff[de]",
                    "Westcliffe†[dq]"
                ]
            },
            {
                "countyName": "Delta",
                "cities": [
                    "Cedaredge",
                    "Crawford",
                    "Delta†[aq]",
                    "Hotchkiss",
                    "Orchard City",
                    "Paonia"
                ]
            },
            {
                "countyName": "Denver[av]",
                "cities": [
                    "Denver‡[ar][as][at][au]"
                ]
            },
            {
                "countyName": "Dolores",
                "cities": [
                    "Dove Creek†[ax]",
                    "Rico[cy]"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Castle Pines",
                    "Castle Rock†[ab]",
                    "Larkspur",
                    "Lone Tree",
                    "Parker"
                ]
            },
            {
                "countyName": "Eagle",
                "cities": [
                    "Avon",
                    "Eagle†[bb]",
                    "Gypsum",
                    "Minturn",
                    "Red Cliff[cx]",
                    "Vail"
                ]
            },
            {
                "countyName": "Eagle,\nPitkin",
                "cities": [
                    "Basalt"
                ]
            },
            {
                "countyName": "El Paso",
                "cities": [
                    "Calhan",
                    "Colorado Springs†[ag][ah][ai]",
                    "Fountain",
                    "Manitou Springs",
                    "Monument",
                    "Palmer Lake",
                    "Ramah"
                ]
            },
            {
                "countyName": "El Paso,\nTeller",
                "cities": [
                    "Green Mountain Falls"
                ]
            },
            {
                "countyName": "Elbert",
                "cities": [
                    "Elizabeth",
                    "Kiowa†[cc]",
                    "Simla"
                ]
            },
            {
                "countyName": "Fremont",
                "cities": [
                    "Brookside",
                    "Cañon City†[x][y]",
                    "Coal Creek[af]",
                    "Florence",
                    "Rockvale",
                    "Williamsburg"
                ]
            },
            {
                "countyName": "Garfield",
                "cities": [
                    "Carbonate[z]",
                    "Carbondale",
                    "Glenwood Springs†[bj][bk]",
                    "New Castle",
                    "Parachute",
                    "Rifle",
                    "Silt"
                ]
            },
            {
                "countyName": "Gilpin",
                "cities": [
                    "Black Hawk"
                ]
            },
            {
                "countyName": "Gilpin,[ad]\nClear Creek",
                "cities": [
                    "Central City†[ac]"
                ]
            },
            {
                "countyName": "Grand",
                "cities": [
                    "Fraser",
                    "Granby",
                    "Grand Lake[bp]",
                    "Hot Sulphur Springs†[bv]",
                    "Kremmling",
                    "Winter Park"
                ]
            },
            {
                "countyName": "Gunnison",
                "cities": [
                    "Crested Butte",
                    "Gunnison†[bs]",
                    "Marble",
                    "Mount Crested Butte",
                    "Pitkin"
                ]
            },
            {
                "countyName": "Hinsdale",
                "cities": [
                    "Lake City†[cf]"
                ]
            },
            {
                "countyName": "Huerfano",
                "cities": [
                    "La Veta",
                    "Walsenburg†[dp]"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Walden†[do]"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Edgewater",
                    "Golden†[bl]",
                    "Lakeside",
                    "Lakewood[cg]",
                    "Morrison",
                    "Mountain View",
                    "Wheat Ridge"
                ]
            },
            {
                "countyName": "Jefferson,\nAdams",
                "cities": [
                    "Arvada[14]"
                ]
            },
            {
                "countyName": "Kiowa",
                "cities": [
                    "Eads†[ba]",
                    "Haswell",
                    "Sheridan Lake[dd]"
                ]
            },
            {
                "countyName": "Kit Carson",
                "cities": [
                    "Bethune",
                    "Burlington†[w]",
                    "Flagler",
                    "Seibert",
                    "Stratton",
                    "Vona"
                ]
            },
            {
                "countyName": "La Plata",
                "cities": [
                    "Bayfield",
                    "Durango†[ay][az]",
                    "Ignacio[14][by]"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Leadville†[ck]"
                ]
            },
            {
                "countyName": "Larimer",
                "cities": [
                    "Estes Park",
                    "Fort Collins†[be][bf]",
                    "Loveland",
                    "Timnath",
                    "Wellington"
                ]
            },
            {
                "countyName": "Larimer,\nWeld",
                "cities": [
                    "Berthoud"
                ]
            },
            {
                "countyName": "Las Animas",
                "cities": [
                    "Aguilar",
                    "Branson",
                    "Cokedale",
                    "Kim",
                    "Starkville",
                    "Trinidad†[dn]"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Arriba",
                    "Genoa",
                    "Hugo†[bw]",
                    "Limon"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Crook",
                    "Fleming",
                    "Iliff",
                    "Merino",
                    "Peetz",
                    "Sterling†[dj][dk]"
                ]
            },
            {
                "countyName": "Mesa",
                "cities": [
                    "Collbran",
                    "De Beque",
                    "Fruita",
                    "Grand Junction†[bn][bo]",
                    "Palisade"
                ]
            },
            {
                "countyName": "Mineral",
                "cities": [
                    "Creede†[an]"
                ]
            },
            {
                "countyName": "Moffat",
                "cities": [
                    "Craig†[al][am]",
                    "Dinosaur"
                ]
            },
            {
                "countyName": "Montezuma",
                "cities": [
                    "Cortez†[ak]",
                    "Dolores",
                    "Mancos"
                ]
            },
            {
                "countyName": "Montrose",
                "cities": [
                    "Montrose†[co][cp]",
                    "Naturita",
                    "Nucla",
                    "Olathe"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Brush",
                    "Fort Morgan†[bg][bh]",
                    "Hillrose",
                    "Log Lane Village",
                    "Wiggins"
                ]
            },
            {
                "countyName": "Otero",
                "cities": [
                    "Cheraw",
                    "Fowler",
                    "La Junta†[ce]",
                    "Manzanola",
                    "Rocky Ford",
                    "Swink"
                ]
            },
            {
                "countyName": "Ouray",
                "cities": [
                    "Ouray†[cr]",
                    "Ridgway"
                ]
            },
            {
                "countyName": "Park",
                "cities": [
                    "Alma",
                    "Fairplay†[bd]"
                ]
            },
            {
                "countyName": "Phillips",
                "cities": [
                    "Haxtun",
                    "Holyoke†[bu]",
                    "Paoli"
                ]
            },
            {
                "countyName": "Pitkin",
                "cities": [
                    "Aspen†[g]",
                    "Snowmass Village"
                ]
            },
            {
                "countyName": "Prowers",
                "cities": [
                    "Granada",
                    "Hartman",
                    "Holly",
                    "Lamar†[ch]",
                    "Wiley"
                ]
            },
            {
                "countyName": "Pueblo",
                "cities": [
                    "Boone",
                    "Pueblo†[cu][cv]",
                    "Rye"
                ]
            },
            {
                "countyName": "Rio Blanco",
                "cities": [
                    "Meeker†[cn]",
                    "Rangely"
                ]
            },
            {
                "countyName": "Rio Grande",
                "cities": [
                    "Del Norte†[ap]",
                    "Monte Vista",
                    "South Fork"
                ]
            },
            {
                "countyName": "Routt",
                "cities": [
                    "Hayden[bt]",
                    "Oak Creek",
                    "Steamboat Springs†[dh][di]",
                    "Yampa"
                ]
            },
            {
                "countyName": "Saguache",
                "cities": [
                    "Bonanza",
                    "Crestone",
                    "Moffat"
                ]
            },
            {
                "countyName": "Saguache,\nRio Grande",
                "cities": [
                    "Center"
                ]
            },
            {
                "countyName": "Saguache[da]",
                "cities": [
                    "Saguache†[cz][da][14]"
                ]
            },
            {
                "countyName": "San Juan",
                "cities": [
                    "Silverton†[df]"
                ]
            },
            {
                "countyName": "San Miguel",
                "cities": [
                    "Mountain Village",
                    "Norwood",
                    "Ophir",
                    "Sawpit",
                    "Telluride†[dl]"
                ]
            },
            {
                "countyName": "Sedgwick",
                "cities": [
                    "Julesburg†[bz]",
                    "Ovid",
                    "Sedgwick"
                ]
            },
            {
                "countyName": "Summit",
                "cities": [
                    "Blue River",
                    "Breckenridge†[o][p]",
                    "Dillon",
                    "Frisco",
                    "Keystone[ca]",
                    "Montezuma",
                    "Silverthorne"
                ]
            },
            {
                "countyName": "Teller",
                "cities": [
                    "Cripple Creek†[ao]",
                    "Victor",
                    "Woodland Park"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Akron†[d]",
                    "Otis"
                ]
            },
            {
                "countyName": "Weld",
                "cities": [
                    "Ault",
                    "Dacono",
                    "Eaton",
                    "Evans[bc]",
                    "Firestone",
                    "Fort Lupton",
                    "Frederick",
                    "Garden City",
                    "Gilcrest",
                    "Greeley†[bq][br]",
                    "Grover",
                    "Hudson",
                    "Keenesburg",
                    "Kersey",
                    "LaSalle",
                    "Mead",
                    "Milliken",
                    "Nunn",
                    "Pierce",
                    "Platteville",
                    "Raymer[cw]",
                    "Severance"
                ]
            },
            {
                "countyName": "Weld\nBoulder",
                "cities": [
                    "Erie"
                ]
            },
            {
                "countyName": "Weld,\nAdams",
                "cities": [
                    "Lochbuie"
                ]
            },
            {
                "countyName": "Weld,\nLarimer",
                "cities": [
                    "Johnstown",
                    "Windsor"
                ]
            },
            {
                "countyName": "Yuma",
                "cities": [
                    "Eckley",
                    "Wray†[dr]",
                    "Yuma[ds]"
                ]
            }
        ]
    },
    {
        "stateId": "CT",
        "stateName": "Conneticut",
        "county": [
            {
                "countyName": "Fairfield County",
                "cities": [
                    "Bethel",
                    "Bridgeport",
                    "Brookfield",
                    "Danbury",
                    "Darien",
                    "Easton",
                    "Fairfield",
                    "Greenwich",
                    "Monroe",
                    "New Canaan",
                    "New Fairfield",
                    "Newtown",
                    "Norwalk",
                    "Redding",
                    "Ridgefield",
                    "Shelton",
                    "Sherman",
                    "Stamford",
                    "Stratford",
                    "Trumbull",
                    "Weston",
                    "Westport",
                    "Wilton"
                ]
            },
            {
                "countyName": "Hartford County",
                "cities": [
                    "Avon",
                    "Berlin",
                    "Bloomfield",
                    "Bristol",
                    "Burlington",
                    "Canton",
                    "East Granby",
                    "East Hartford",
                    "East Windsor",
                    "Enfield",
                    "Farmington",
                    "Glastonbury",
                    "Granby",
                    "Hartford",
                    "Hartland",
                    "Manchester",
                    "Marlborough",
                    "New Britain",
                    "Newington",
                    "Plainville",
                    "Rocky Hill",
                    "Simsbury",
                    "South Windsor",
                    "Southington",
                    "Suffield",
                    "West Hartford",
                    "Wethersfield",
                    "Windsor",
                    "Windsor Locks"
                ]
            },
            {
                "countyName": "Litchfield County",
                "cities": [
                    "Barkhamsted",
                    "Bethlehem",
                    "Bridgewater",
                    "Canaan",
                    "Colebrook",
                    "Cornwall",
                    "Goshen",
                    "Harwinton",
                    "Kent",
                    "Litchfield",
                    "Morris",
                    "New Hartford",
                    "New Milford",
                    "Norfolk",
                    "North Canaan",
                    "Plymouth",
                    "Roxbury",
                    "Salisbury",
                    "Sharon",
                    "Thomaston",
                    "Torrington",
                    "Warren",
                    "Washington",
                    "Watertown",
                    "Winchester",
                    "Woodbury"
                ]
            },
            {
                "countyName": "Middlesex County",
                "cities": [
                    "Chester",
                    "Clinton",
                    "Cromwell",
                    "Deep River",
                    "Durham",
                    "East Haddam",
                    "East Hampton",
                    "Essex",
                    "Haddam",
                    "Killingworth",
                    "Middlefield",
                    "Middletown",
                    "Old Saybrook",
                    "Portland",
                    "Westbrook"
                ]
            },
            {
                "countyName": "New Haven County",
                "cities": [
                    "Ansonia",
                    "Beacon Falls",
                    "Bethany",
                    "Branford",
                    "Cheshire",
                    "Derby",
                    "East Haven",
                    "Guilford",
                    "Hamden",
                    "Madison",
                    "Meriden",
                    "Middlebury",
                    "Milford",
                    "Naugatuck",
                    "New Haven",
                    "North Branford",
                    "North Haven",
                    "Orange",
                    "Oxford",
                    "Prospect",
                    "Seymour",
                    "Southbury",
                    "Wallingford",
                    "Waterbury",
                    "West Haven",
                    "Wolcott",
                    "Woodbridge"
                ]
            },
            {
                "countyName": "New London County",
                "cities": [
                    "Bozrah",
                    "Colchester",
                    "East Lyme",
                    "Franklin",
                    "Griswold",
                    "Groton",
                    "Lebanon",
                    "Ledyard",
                    "Lisbon",
                    "Lyme",
                    "Montville",
                    "New London",
                    "North Stonington",
                    "Norwich",
                    "Old Lyme",
                    "Preston",
                    "Salem",
                    "Sprague",
                    "Stonington",
                    "Voluntown",
                    "Waterford"
                ]
            },
            {
                "countyName": "Tolland County",
                "cities": [
                    "Andover",
                    "Bolton",
                    "Columbia",
                    "Coventry",
                    "Ellington",
                    "Hebron",
                    "Mansfield",
                    "Somers",
                    "Stafford",
                    "Tolland",
                    "Union",
                    "Vernon",
                    "Willington"
                ]
            },
            {
                "countyName": "Windham County",
                "cities": [
                    "Ashford",
                    "Brooklyn",
                    "Canterbury",
                    "Chaplin",
                    "Eastford",
                    "Hampton",
                    "Killingly",
                    "Plainfield",
                    "Pomfret",
                    "Putnam",
                    "Scotland",
                    "Sterling",
                    "Thompson",
                    "Windham",
                    "Woodstock"
                ]
            }
        ]
    },
    {
        "stateId": "DE",
        "stateName": "Delaware",
        "county": [
            {
                "countyName": "Kent",
                "cities": [
                    "Bowers",
                    "Camden",
                    "Cheswold",
                    "Dover‡",
                    "Farmington",
                    "Felton",
                    "Frederica",
                    "Harrington",
                    "Hartly",
                    "Houston",
                    "Kenton",
                    "Leipsic",
                    "Little Creek",
                    "Magnolia",
                    "Viola",
                    "Woodside",
                    "Wyoming"
                ]
            },
            {
                "countyName": "Kent\nNew Castle",
                "cities": [
                    "Clayton",
                    "Smyrna"
                ]
            },
            {
                "countyName": "New Castle",
                "cities": [
                    "Arden",
                    "Ardencroft",
                    "Ardentown",
                    "Bellefonte",
                    "Delaware City",
                    "Elsmere",
                    "Middletown",
                    "New Castle",
                    "Newark",
                    "Newport",
                    "Odessa",
                    "Townsend",
                    "Wilmington†"
                ]
            },
            {
                "countyName": "Sussex",
                "cities": [
                    "Bethany Beach",
                    "Bethel",
                    "Blades",
                    "Bridgeville",
                    "Dagsboro",
                    "Delmar",
                    "Dewey Beach",
                    "Ellendale",
                    "Fenwick Island",
                    "Frankford",
                    "Georgetown†",
                    "Greenwood",
                    "Henlopen Acres",
                    "Laurel",
                    "Lewes",
                    "Millsboro",
                    "Millville",
                    "Milton",
                    "Ocean View",
                    "Rehoboth Beach",
                    "Seaford",
                    "Selbyville",
                    "Slaughter Beach",
                    "South Bethany"
                ]
            },
            {
                "countyName": "Sussex\nKent",
                "cities": [
                    "Milford"
                ]
            }
        ]
    },
    {
        "stateId": "FL",
        "stateName": "Florida",
        "county": [
            {
                "countyName": "Alachua",
                "cities": [
                    "Alachua",
                    "Archer",
                    "Gainesville†",
                    "Hawthorne",
                    "High Springs",
                    "LaCrosse",
                    "Micanopy",
                    "Newberry",
                    "Waldo"
                ]
            },
            {
                "countyName": "Baker",
                "cities": [
                    "Glen St. Mary",
                    "Macclenny†"
                ]
            },
            {
                "countyName": "Bay",
                "cities": [
                    "Callaway",
                    "Lynn Haven",
                    "Mexico Beach",
                    "Panama City†",
                    "Panama City Beach",
                    "Parker",
                    "Springfield"
                ]
            },
            {
                "countyName": "Bradford",
                "cities": [
                    "Brooker",
                    "Hampton",
                    "Lawtey",
                    "Starke†"
                ]
            },
            {
                "countyName": "Brevard",
                "cities": [
                    "Cape Canaveral",
                    "Cocoa",
                    "Cocoa Beach",
                    "Grant-Valkaria",
                    "Indialantic",
                    "Indian Harbour Beach",
                    "Malabar",
                    "Melbourne",
                    "Melbourne Beach",
                    "Melbourne Village",
                    "Palm Bay",
                    "Palm Shores",
                    "Rockledge",
                    "Satellite Beach",
                    "Titusville†",
                    "West Melbourne"
                ]
            },
            {
                "countyName": "Broward",
                "cities": [
                    "Coconut Creek",
                    "Cooper City",
                    "Coral Springs",
                    "Dania Beach",
                    "Davie",
                    "Deerfield Beach",
                    "Fort Lauderdale†",
                    "Hallandale Beach",
                    "Hillsboro Beach",
                    "Hollywood",
                    "Lauderdale Lakes",
                    "Lauderdale-by-the-Sea",
                    "Lauderhill",
                    "Lazy Lake",
                    "Lighthouse Point",
                    "Margate",
                    "Miramar",
                    "North Lauderdale",
                    "Oakland Park",
                    "Parkland",
                    "Pembroke Park",
                    "Pembroke Pines",
                    "Plantation",
                    "Pompano Beach",
                    "Sea Ranch Lakes",
                    "Southwest Ranches",
                    "Sunrise",
                    "Tamarac",
                    "West Park",
                    "Weston",
                    "Wilton Manors"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Altha",
                    "Blountstown†"
                ]
            },
            {
                "countyName": "Charlotte",
                "cities": [
                    "Punta Gorda†"
                ]
            },
            {
                "countyName": "Citrus",
                "cities": [
                    "Crystal River",
                    "Inverness†"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Green Cove Springs†",
                    "Keystone Heights",
                    "Orange Park",
                    "Penney Farms"
                ]
            },
            {
                "countyName": "Collier",
                "cities": [
                    "Everglades City",
                    "Marco Island",
                    "Naples"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Fort White",
                    "Lake City†"
                ]
            },
            {
                "countyName": "DeSoto",
                "cities": [
                    "Arcadia†"
                ]
            },
            {
                "countyName": "Dixie",
                "cities": [
                    "Cross City†",
                    "Horseshoe Beach"
                ]
            },
            {
                "countyName": "Duval",
                "cities": [
                    "Atlantic Beach",
                    "Baldwin",
                    "Jacksonville†",
                    "Jacksonville Beach",
                    "Neptune Beach"
                ]
            },
            {
                "countyName": "Escambia",
                "cities": [
                    "Century",
                    "Pensacola†"
                ]
            },
            {
                "countyName": "Flagler",
                "cities": [
                    "Beverly Beach",
                    "Bunnell†",
                    "Flagler Beach",
                    "Palm Coast"
                ]
            },
            {
                "countyName": "Flagler\nSt. Johns",
                "cities": [
                    "Marineland"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Apalachicola†",
                    "Carrabelle"
                ]
            },
            {
                "countyName": "Gadsden",
                "cities": [
                    "Chattahoochee",
                    "Greensboro",
                    "Gretna",
                    "Havana",
                    "Midway",
                    "Quincy†"
                ]
            },
            {
                "countyName": "Gilchrist",
                "cities": [
                    "Bell",
                    "Trenton†"
                ]
            },
            {
                "countyName": "Glades",
                "cities": [
                    "Moore Haven†"
                ]
            },
            {
                "countyName": "Gulf",
                "cities": [
                    "Port St. Joe†",
                    "Wewahitchka"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Jasper†",
                    "Jennings",
                    "White Springs"
                ]
            },
            {
                "countyName": "Hardee",
                "cities": [
                    "Bowling Green",
                    "Wauchula†",
                    "Zolfo Springs"
                ]
            },
            {
                "countyName": "Hendry",
                "cities": [
                    "Clewiston",
                    "LaBelle†"
                ]
            },
            {
                "countyName": "Hernando",
                "cities": [
                    "Brooksville†"
                ]
            },
            {
                "countyName": "Highlands",
                "cities": [
                    "Avon Park",
                    "Lake Placid",
                    "Sebring†"
                ]
            },
            {
                "countyName": "Hillsborough",
                "cities": [
                    "Plant City",
                    "Tampa†",
                    "Temple Terrace"
                ]
            },
            {
                "countyName": "Holmes",
                "cities": [
                    "Bonifay†",
                    "Esto",
                    "Noma",
                    "Ponce de Leon",
                    "Westville"
                ]
            },
            {
                "countyName": "Indian River",
                "cities": [
                    "Fellsmere",
                    "Indian River Shores",
                    "Orchid",
                    "Sebastian",
                    "Vero Beach†"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Alford",
                    "Bascom",
                    "Campbellton",
                    "Cottondale",
                    "Graceville",
                    "Grand Ridge",
                    "Greenwood",
                    "Jacob City",
                    "Malone",
                    "Marianna†",
                    "Sneads"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Monticello†"
                ]
            },
            {
                "countyName": "Lafayette",
                "cities": [
                    "Mayo†"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Astatula",
                    "Clermont",
                    "Eustis",
                    "Fruitland Park",
                    "Groveland",
                    "Howey-in-the-Hills",
                    "Lady Lake",
                    "Leesburg",
                    "Mascotte",
                    "Minneola",
                    "Montverde",
                    "Mount Dora",
                    "Tavares†",
                    "Umatilla"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Bonita Springs",
                    "Cape Coral",
                    "Estero",
                    "Fort Myers†",
                    "Fort Myers Beach",
                    "Sanibel"
                ]
            },
            {
                "countyName": "Leon",
                "cities": [
                    "Tallahassee # †"
                ]
            },
            {
                "countyName": "Levy",
                "cities": [
                    "Bronson†",
                    "Cedar Key",
                    "Chiefland",
                    "Inglis",
                    "Otter Creek",
                    "Williston",
                    "Yankeetown"
                ]
            },
            {
                "countyName": "Levy\nGilchrist",
                "cities": [
                    "Fanning Springs"
                ]
            },
            {
                "countyName": "Liberty",
                "cities": [
                    "Bristol†"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Greenville",
                    "Lee",
                    "Madison†"
                ]
            },
            {
                "countyName": "Manatee",
                "cities": [
                    "Anna Maria",
                    "Bradenton†",
                    "Bradenton Beach",
                    "Holmes Beach",
                    "Palmetto"
                ]
            },
            {
                "countyName": "Manatee\nSarasota",
                "cities": [
                    "Longboat Key"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Belleview",
                    "Dunnellon",
                    "McIntosh",
                    "Ocala†",
                    "Reddick"
                ]
            },
            {
                "countyName": "Martin",
                "cities": [
                    "Indiantown",
                    "Jupiter Island",
                    "Ocean Breeze",
                    "Sewall's Point",
                    "Stuart†"
                ]
            },
            {
                "countyName": "Miami-Dade",
                "cities": [
                    "Aventura",
                    "Bal Harbour",
                    "Bay Harbor Islands",
                    "Biscayne Park",
                    "Coral Gables",
                    "Cutler Bay",
                    "Doral",
                    "El Portal",
                    "Florida City",
                    "Golden Beach",
                    "Hialeah",
                    "Hialeah Gardens",
                    "Homestead",
                    "Indian Creek",
                    "Key Biscayne",
                    "Medley",
                    "Miami†",
                    "Miami Beach",
                    "Miami Gardens",
                    "Miami Lakes",
                    "Miami Shores",
                    "Miami Springs",
                    "North Bay Village",
                    "North Miami",
                    "North Miami Beach",
                    "Opa-locka",
                    "Palmetto Bay",
                    "Pinecrest",
                    "South Miami",
                    "Sunny Isles Beach",
                    "Surfside",
                    "Sweetwater",
                    "Virginia Gardens",
                    "West Miami"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Islamorada",
                    "Key Colony Beach",
                    "Key West†",
                    "Layton",
                    "Marathon"
                ]
            },
            {
                "countyName": "Nassau",
                "cities": [
                    "Callahan",
                    "Fernandina Beach†",
                    "Hilliard"
                ]
            },
            {
                "countyName": "Okaloosa",
                "cities": [
                    "Cinco Bayou",
                    "Crestview†",
                    "Destin",
                    "Fort Walton Beach",
                    "Laurel Hill",
                    "Mary Esther",
                    "Niceville",
                    "Shalimar",
                    "Valparaiso"
                ]
            },
            {
                "countyName": "Okeechobee",
                "cities": [
                    "Okeechobee†"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Apopka",
                    "Bay Lake",
                    "Belle Isle",
                    "Eatonville",
                    "Edgewood",
                    "Lake Buena Vista",
                    "Maitland",
                    "Oakland",
                    "Ocoee",
                    "Orlando†",
                    "Windermere",
                    "Winter Garden",
                    "Winter Park"
                ]
            },
            {
                "countyName": "Osceola",
                "cities": [
                    "Kissimmee†",
                    "St. Cloud"
                ]
            },
            {
                "countyName": "Palm Beach",
                "cities": [
                    "Atlantis",
                    "Belle Glade",
                    "Boca Raton",
                    "Boynton Beach",
                    "Briny Breezes",
                    "Cloud Lake",
                    "Delray Beach",
                    "Glen Ridge",
                    "Golf",
                    "Greenacres",
                    "Gulf Stream",
                    "Haverhill",
                    "Highland Beach",
                    "Hypoluxo",
                    "Juno Beach",
                    "Jupiter",
                    "Jupiter Inlet Colony",
                    "Lake Clarke Shores",
                    "Lake Park",
                    "Lake Worth Beach",
                    "Lantana",
                    "Loxahatchee Groves",
                    "Manalapan",
                    "Mangonia Park",
                    "North Palm Beach",
                    "Ocean Ridge",
                    "Pahokee",
                    "Palm Beach",
                    "Palm Beach Gardens",
                    "Palm Beach Shores",
                    "Palm Springs",
                    "Riviera Beach",
                    "Royal Palm Beach",
                    "South Bay",
                    "South Palm Beach",
                    "Tequesta",
                    "Wellington",
                    "West Palm Beach†",
                    "Westlake"
                ]
            },
            {
                "countyName": "Pasco",
                "cities": [
                    "Dade City†",
                    "New Port Richey",
                    "Port Richey",
                    "San Antonio",
                    "St. Leo",
                    "Zephyrhills"
                ]
            },
            {
                "countyName": "Pinellas",
                "cities": [
                    "Belleair",
                    "Belleair Beach",
                    "Belleair Bluffs",
                    "Belleair Shore",
                    "Clearwater†",
                    "Dunedin",
                    "Gulfport",
                    "Indian Rocks Beach",
                    "Indian Shores",
                    "Kenneth City",
                    "Largo",
                    "Madeira Beach",
                    "North Redington Beach",
                    "Oldsmar",
                    "Pinellas Park",
                    "Redington Beach",
                    "Redington Shores",
                    "Safety Harbor",
                    "Seminole",
                    "South Pasadena",
                    "St. Pete Beach",
                    "St. Petersburg",
                    "Tarpon Springs",
                    "Treasure Island"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Auburndale",
                    "Bartow†",
                    "Davenport",
                    "Dundee",
                    "Eagle Lake",
                    "Fort Meade",
                    "Frostproof",
                    "Haines City",
                    "Highland Park",
                    "Hillcrest Heights",
                    "Lake Alfred",
                    "Lake Hamilton",
                    "Lake Wales",
                    "Lakeland",
                    "Mulberry",
                    "Polk City",
                    "Winter Haven"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Crescent City",
                    "Interlachen",
                    "Palatka†",
                    "Pomona Park",
                    "Welaka"
                ]
            },
            {
                "countyName": "Santa Rosa",
                "cities": [
                    "Gulf Breeze",
                    "Jay",
                    "Milton†"
                ]
            },
            {
                "countyName": "Sarasota",
                "cities": [
                    "North Port",
                    "Sarasota†",
                    "Venice"
                ]
            },
            {
                "countyName": "Seminole",
                "cities": [
                    "Altamonte Springs",
                    "Casselberry",
                    "Lake Mary",
                    "Longwood",
                    "Oviedo",
                    "Sanford†",
                    "Winter Springs"
                ]
            },
            {
                "countyName": "St. Johns",
                "cities": [
                    "St. Augustine†",
                    "St. Augustine Beach"
                ]
            },
            {
                "countyName": "St. Lucie",
                "cities": [
                    "Fort Pierce†",
                    "Port St. Lucie",
                    "St. Lucie Village"
                ]
            },
            {
                "countyName": "Sumter",
                "cities": [
                    "Bushnell†",
                    "Center Hill",
                    "Coleman",
                    "Webster",
                    "Wildwood"
                ]
            },
            {
                "countyName": "Suwannee",
                "cities": [
                    "Branford",
                    "Live Oak†"
                ]
            },
            {
                "countyName": "Taylor",
                "cities": [
                    "Perry†"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Lake Butler†",
                    "Raiford",
                    "Worthington Springs"
                ]
            },
            {
                "countyName": "Volusia",
                "cities": [
                    "Daytona Beach",
                    "Daytona Beach Shores",
                    "DeBary",
                    "DeLand†",
                    "Deltona",
                    "Edgewater",
                    "Holly Hill",
                    "Lake Helen",
                    "New Smyrna Beach",
                    "Oak Hill",
                    "Orange City",
                    "Ormond Beach",
                    "Pierson",
                    "Ponce Inlet",
                    "Port Orange",
                    "South Daytona"
                ]
            },
            {
                "countyName": "Wakulla",
                "cities": [
                    "Sopchoppy",
                    "St. Marks"
                ]
            },
            {
                "countyName": "Walton",
                "cities": [
                    "DeFuniak Springs†",
                    "Freeport",
                    "Paxton"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Caryville",
                    "Chipley†",
                    "Ebro",
                    "Vernon",
                    "Wausau"
                ]
            }
        ]
    },
    {
        "stateId": "GA",
        "stateName": "Georgia",
        "county": [
            {
                "countyName": "Appling",
                "cities": [
                    "Baxley †",
                    "Graham",
                    "Surrency"
                ]
            },
            {
                "countyName": "Atkinson",
                "cities": [
                    "Pearson †",
                    "Willacoochee"
                ]
            },
            {
                "countyName": "Bacon",
                "cities": [
                    "Alma †"
                ]
            },
            {
                "countyName": "Baker",
                "cities": [
                    "Newton"
                ]
            },
            {
                "countyName": "Baldwin",
                "cities": [
                    "Milledgeville †"
                ]
            },
            {
                "countyName": "Banks",
                "cities": [
                    "Homer †"
                ]
            },
            {
                "countyName": "Banks, Habersham",
                "cities": [
                    "Alto",
                    "Baldwin"
                ]
            },
            {
                "countyName": "Banks, Hall",
                "cities": [
                    "Gillsville",
                    "Lula"
                ]
            },
            {
                "countyName": "Banks, Jackson",
                "cities": [
                    "Maysville"
                ]
            },
            {
                "countyName": "Barrow",
                "cities": [
                    "Bethlehem",
                    "Carl",
                    "Statham",
                    "Winder †"
                ]
            },
            {
                "countyName": "Barrow, Gwinnett",
                "cities": [
                    "Auburn"
                ]
            },
            {
                "countyName": "Barrow, Gwinnett, Hall, Jackson",
                "cities": [
                    "Braselton"
                ]
            },
            {
                "countyName": "Bartow",
                "cities": [
                    "Adairsville",
                    "Cartersville †",
                    "Emerson",
                    "Euharlee",
                    "Kingston",
                    "White"
                ]
            },
            {
                "countyName": "Bartow, Polk",
                "cities": [
                    "Taylorsville"
                ]
            },
            {
                "countyName": "Ben Hill, Irwin",
                "cities": [
                    "Fitzgerald †"
                ]
            },
            {
                "countyName": "Berrien",
                "cities": [
                    "Alapaha",
                    "Enigma",
                    "Nashville †",
                    "Ray City"
                ]
            },
            {
                "countyName": "Bibb",
                "cities": [
                    "Macon †[g]"
                ]
            },
            {
                "countyName": "Bleckley",
                "cities": [
                    "Cochran †"
                ]
            },
            {
                "countyName": "Bleckley, Laurens, Twiggs, Wilkinson",
                "cities": [
                    "Allentown"
                ]
            },
            {
                "countyName": "Brantley",
                "cities": [
                    "Hoboken",
                    "Nahunta †"
                ]
            },
            {
                "countyName": "Brooks",
                "cities": [
                    "Morven",
                    "Quitman †"
                ]
            },
            {
                "countyName": "Brooks, Thomas",
                "cities": [
                    "Barwick",
                    "Pavo"
                ]
            },
            {
                "countyName": "Bryan",
                "cities": [
                    "Pembroke †",
                    "Richmond Hill"
                ]
            },
            {
                "countyName": "Bulloch",
                "cities": [
                    "Brooklet",
                    "Portal",
                    "Register",
                    "Statesboro †"
                ]
            },
            {
                "countyName": "Burke",
                "cities": [
                    "Girard",
                    "Keysville",
                    "Midville",
                    "Sardis",
                    "Vidette",
                    "Waynesboro †"
                ]
            },
            {
                "countyName": "Burke, Richmond",
                "cities": [
                    "Blythe"
                ]
            },
            {
                "countyName": "Butts",
                "cities": [
                    "Flovilla",
                    "Jackson †",
                    "Jenkinsburg"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Edison",
                    "Leary",
                    "Morgan †"
                ]
            },
            {
                "countyName": "Calhoun, Early",
                "cities": [
                    "Arlington"
                ]
            },
            {
                "countyName": "Camden",
                "cities": [
                    "Kingsland",
                    "St. Marys",
                    "Woodbine †"
                ]
            },
            {
                "countyName": "Candler",
                "cities": [
                    "Metter †",
                    "Pulaski"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Bowdon",
                    "Carrollton †",
                    "Mount Zion",
                    "Roopville",
                    "Whitesburg"
                ]
            },
            {
                "countyName": "Carroll, Douglas",
                "cities": [
                    "Villa Rica"
                ]
            },
            {
                "countyName": "Carroll, Haralson",
                "cities": [
                    "Bremen",
                    "Temple"
                ]
            },
            {
                "countyName": "Catoosa",
                "cities": [
                    "Ringgold †"
                ]
            },
            {
                "countyName": "Catoosa, Walker",
                "cities": [
                    "Fort Oglethorpe"
                ]
            },
            {
                "countyName": "Charlton",
                "cities": [
                    "Folkston †",
                    "Homeland"
                ]
            },
            {
                "countyName": "Chatham",
                "cities": [
                    "Bloomingdale",
                    "Garden City",
                    "Pooler",
                    "Port Wentworth",
                    "Savannah †",
                    "Thunderbolt",
                    "Tybee Island",
                    "Vernonburg"
                ]
            },
            {
                "countyName": "Chattahoochee",
                "cities": [
                    "Cusseta †"
                ]
            },
            {
                "countyName": "Chattooga",
                "cities": [
                    "Lyerly",
                    "Menlo",
                    "Summerville †",
                    "Trion"
                ]
            },
            {
                "countyName": "Cherokee",
                "cities": [
                    "Ball Ground",
                    "Canton †",
                    "Holly Springs",
                    "Waleska",
                    "Woodstock"
                ]
            },
            {
                "countyName": "Cherokee, Fulton",
                "cities": [
                    "Mountain Park"
                ]
            },
            {
                "countyName": "Cherokee, Pickens",
                "cities": [
                    "Nelson"
                ]
            },
            {
                "countyName": "Clarke",
                "cities": [
                    "Athens †[b]",
                    "Winterville"
                ]
            },
            {
                "countyName": "Clarke, Oconee",
                "cities": [
                    "Bogart"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Bluffton",
                    "Fort Gaines †"
                ]
            },
            {
                "countyName": "Clayton",
                "cities": [
                    "Forest Park",
                    "Jonesboro †",
                    "Lake City",
                    "Lovejoy",
                    "Morrow",
                    "Riverdale"
                ]
            },
            {
                "countyName": "Clayton, Fulton",
                "cities": [
                    "College Park"
                ]
            },
            {
                "countyName": "Clinch",
                "cities": [
                    "Argyle",
                    "Du Pont",
                    "Fargo",
                    "Homerville †"
                ]
            },
            {
                "countyName": "Cobb",
                "cities": [
                    "Acworth",
                    "Kennesaw",
                    "Marietta †",
                    "Powder Springs",
                    "Smyrna"
                ]
            },
            {
                "countyName": "Cobb, Douglas",
                "cities": [
                    "Austell"
                ]
            },
            {
                "countyName": "Coffee",
                "cities": [
                    "Ambrose",
                    "Broxton",
                    "Douglas †",
                    "Nicholls"
                ]
            },
            {
                "countyName": "Colquitt",
                "cities": [
                    "Berlin",
                    "Doerun",
                    "Ellenton",
                    "Funston",
                    "Moultrie †",
                    "Norman Park"
                ]
            },
            {
                "countyName": "Colquitt, Tift",
                "cities": [
                    "Omega"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Grovetown",
                    "Harlem"
                ]
            },
            {
                "countyName": "Cook",
                "cities": [
                    "Adel †",
                    "Cecil",
                    "Lenox",
                    "Sparks"
                ]
            },
            {
                "countyName": "Coweta",
                "cities": [
                    "Grantville",
                    "Moreland",
                    "Newnan †",
                    "Senoia",
                    "Sharpsburg",
                    "Turin"
                ]
            },
            {
                "countyName": "Coweta, Fulton",
                "cities": [
                    "Palmetto"
                ]
            },
            {
                "countyName": "Coweta, Meriwether",
                "cities": [
                    "Haralson"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Roberta"
                ]
            },
            {
                "countyName": "Crisp",
                "cities": [
                    "Arabi",
                    "Cordele †"
                ]
            },
            {
                "countyName": "Dade",
                "cities": [
                    "Trenton †"
                ]
            },
            {
                "countyName": "Dawson",
                "cities": [
                    "Dawsonville †"
                ]
            },
            {
                "countyName": "Decatur",
                "cities": [
                    "Attapulgus",
                    "Bainbridge †",
                    "Brinson",
                    "Climax"
                ]
            },
            {
                "countyName": "DeKalb",
                "cities": [
                    "Avondale Estates",
                    "Chamblee",
                    "Clarkston",
                    "Decatur †",
                    "Doraville",
                    "Dunwoody",
                    "Lithonia",
                    "Pine Lake",
                    "Stone Mountain",
                    "Stonecrest",
                    "Tucker"
                ]
            },
            {
                "countyName": "Dekalb",
                "cities": [
                    "Brookhaven[d]"
                ]
            },
            {
                "countyName": "Dodge",
                "cities": [
                    "Chauncey",
                    "Chester",
                    "Eastman †",
                    "Rhine"
                ]
            },
            {
                "countyName": "Dodge, Telfair",
                "cities": [
                    "Milan"
                ]
            },
            {
                "countyName": "Dooly",
                "cities": [
                    "Byromville",
                    "Dooling",
                    "Lilly",
                    "Pinehurst",
                    "Unadilla",
                    "Vienna †"
                ]
            },
            {
                "countyName": "Dougherty",
                "cities": [
                    "Albany †"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Douglasville †"
                ]
            },
            {
                "countyName": "Early",
                "cities": [
                    "Blakely †",
                    "Damascus",
                    "Jakin"
                ]
            },
            {
                "countyName": "Echols County",
                "cities": [
                    "Echols County[e]"
                ]
            },
            {
                "countyName": "Effingham",
                "cities": [
                    "Guyton",
                    "Rincon",
                    "Springfield †"
                ]
            },
            {
                "countyName": "Elbert",
                "cities": [
                    "Bowman",
                    "Elberton †"
                ]
            },
            {
                "countyName": "Emanuel",
                "cities": [
                    "Garfield",
                    "Nunez",
                    "Oak Park",
                    "Stillmore",
                    "Summertown",
                    "Swainsboro †",
                    "Twin City"
                ]
            },
            {
                "countyName": "Emanuel, Johnson",
                "cities": [
                    "Adrian"
                ]
            },
            {
                "countyName": "Evans",
                "cities": [
                    "Bellville",
                    "Claxton †",
                    "Daisy",
                    "Hagan"
                ]
            },
            {
                "countyName": "Fannin",
                "cities": [
                    "Blue Ridge †",
                    "McCaysville",
                    "Morganton"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "Brooks",
                    "Fayetteville †",
                    "Peachtree City",
                    "Tyrone",
                    "Woolsey"
                ]
            },
            {
                "countyName": "Floyd",
                "cities": [
                    "Cave Spring",
                    "Rome †"
                ]
            },
            {
                "countyName": "Forsyth",
                "cities": [
                    "Cumming †"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Carnesville †",
                    "Franklin Springs"
                ]
            },
            {
                "countyName": "Franklin, Hart",
                "cities": [
                    "Canon",
                    "Lavonia"
                ]
            },
            {
                "countyName": "Franklin, Hart, Madison",
                "cities": [
                    "Royston"
                ]
            },
            {
                "countyName": "Franklin, Stephens",
                "cities": [
                    "Martin"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Alpharetta",
                    "Chattahoochee Hills",
                    "East Point",
                    "Fairburn",
                    "Hapeville",
                    "Johns Creek [f]",
                    "Milton",
                    "Roswell",
                    "Sandy Springs",
                    "South Fulton[11]",
                    "Union City"
                ]
            },
            {
                "countyName": "Fulton (seat), DeKalb",
                "cities": [
                    "Atlanta‡"
                ]
            },
            {
                "countyName": "Gilmer",
                "cities": [
                    "East Ellijay",
                    "Ellijay †"
                ]
            },
            {
                "countyName": "Glascock",
                "cities": [
                    "Edge Hill",
                    "Gibson †",
                    "Mitchell"
                ]
            },
            {
                "countyName": "Glynn",
                "cities": [
                    "Brunswick †"
                ]
            },
            {
                "countyName": "Gordon",
                "cities": [
                    "Calhoun †",
                    "Fairmount",
                    "Plainville",
                    "Ranger",
                    "Resaca"
                ]
            },
            {
                "countyName": "Grady",
                "cities": [
                    "Cairo †",
                    "Whigham"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Greensboro †",
                    "Siloam",
                    "Union Point",
                    "White Plains",
                    "Woodville"
                ]
            },
            {
                "countyName": "Gwinnett",
                "cities": [
                    "Berkeley Lake",
                    "Dacula",
                    "Duluth",
                    "Grayson",
                    "Lawrenceville †",
                    "Lilburn",
                    "Norcross",
                    "Peachtree Corners[i]",
                    "Snellville",
                    "Sugar Hill",
                    "Suwanee"
                ]
            },
            {
                "countyName": "Gwinnett, Hall",
                "cities": [
                    "Buford",
                    "Rest Haven"
                ]
            },
            {
                "countyName": "Gwinnett, Walton",
                "cities": [
                    "Loganville"
                ]
            },
            {
                "countyName": "Habersham",
                "cities": [
                    "Clarkesville †",
                    "Cornelia",
                    "Demorest",
                    "Mount Airy"
                ]
            },
            {
                "countyName": "Habersham, Rabun",
                "cities": [
                    "Tallulah Falls"
                ]
            },
            {
                "countyName": "Hall",
                "cities": [
                    "Clermont",
                    "Flowery Branch",
                    "Gainesville †",
                    "Oakwood"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Sparta †"
                ]
            },
            {
                "countyName": "Haralson",
                "cities": [
                    "Buchanan †",
                    "Tallapoosa",
                    "Waco"
                ]
            },
            {
                "countyName": "Harris",
                "cities": [
                    "Hamilton †",
                    "Shiloh",
                    "Waverly Hall"
                ]
            },
            {
                "countyName": "Harris, Meriwether",
                "cities": [
                    "Pine Mountain"
                ]
            },
            {
                "countyName": "Harris, Troup",
                "cities": [
                    "West Point"
                ]
            },
            {
                "countyName": "Hart",
                "cities": [
                    "Bowersville",
                    "Hartwell †"
                ]
            },
            {
                "countyName": "Heard",
                "cities": [
                    "Centralhatchee",
                    "Ephesus",
                    "Franklin †"
                ]
            },
            {
                "countyName": "Henry",
                "cities": [
                    "Hampton",
                    "Locust Grove",
                    "McDonough †",
                    "Stockbridge"
                ]
            },
            {
                "countyName": "Houston",
                "cities": [
                    "Centerville"
                ]
            },
            {
                "countyName": "Houston, Peach",
                "cities": [
                    "Byron",
                    "Perry †",
                    "Warner Robins"
                ]
            },
            {
                "countyName": "Irwin",
                "cities": [
                    "Ocilla †"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Arcade",
                    "Commerce",
                    "Hoschton",
                    "Jefferson",
                    "Nicholson",
                    "Pendergrass",
                    "Talmo"
                ]
            },
            {
                "countyName": "Jasper",
                "cities": [
                    "Monticello †",
                    "Shady Dale"
                ]
            },
            {
                "countyName": "Jeff Davis",
                "cities": [
                    "Denton",
                    "Hazlehurst †"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Avera",
                    "Bartow",
                    "Louisville †",
                    "Stapleton",
                    "Wadley",
                    "Wrens"
                ]
            },
            {
                "countyName": "Jenkins",
                "cities": [
                    "Millen †"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Kite",
                    "Wrightsville †"
                ]
            },
            {
                "countyName": "Jones",
                "cities": [
                    "Gray †"
                ]
            },
            {
                "countyName": "Lamar",
                "cities": [
                    "Aldora",
                    "Barnesville †",
                    "Milner"
                ]
            },
            {
                "countyName": "Lanier",
                "cities": [
                    "Lakeland †"
                ]
            },
            {
                "countyName": "Laurens",
                "cities": [
                    "Cadwell",
                    "Dexter",
                    "Dublin †",
                    "Dudley",
                    "East Dublin",
                    "Montrose",
                    "Rentz"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Leesburg †",
                    "Smithville"
                ]
            },
            {
                "countyName": "Liberty",
                "cities": [
                    "Allenhurst",
                    "Flemington",
                    "Gumbranch",
                    "Hinesville †",
                    "Midway",
                    "Riceboro",
                    "Walthourville"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Lincolnton †"
                ]
            },
            {
                "countyName": "Long",
                "cities": [
                    "Ludowici †"
                ]
            },
            {
                "countyName": "Lowndes",
                "cities": [
                    "Dasher",
                    "Hahira",
                    "Lake Park",
                    "Remerton",
                    "Valdosta †"
                ]
            },
            {
                "countyName": "Lumpkin",
                "cities": [
                    "Dahlonega †"
                ]
            },
            {
                "countyName": "Macon",
                "cities": [
                    "Ideal",
                    "Marshallville",
                    "Montezuma",
                    "Oglethorpe †"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Carlton",
                    "Colbert",
                    "Comer",
                    "Danielsville †",
                    "Hull",
                    "Ila"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Buena Vista †"
                ]
            },
            {
                "countyName": "McDuffie",
                "cities": [
                    "Dearing",
                    "Thomson †"
                ]
            },
            {
                "countyName": "McIntosh",
                "cities": [
                    "Darien †"
                ]
            },
            {
                "countyName": "Meriwether",
                "cities": [
                    "Gay",
                    "Greenville †",
                    "Lone Oak",
                    "Luthersville",
                    "Warm Springs",
                    "Woodbury"
                ]
            },
            {
                "countyName": "Meriwether, Talbot",
                "cities": [
                    "Manchester"
                ]
            },
            {
                "countyName": "Miller",
                "cities": [
                    "Colquitt †"
                ]
            },
            {
                "countyName": "Mitchell",
                "cities": [
                    "Baconton",
                    "Camilla †",
                    "Pelham",
                    "Sale City"
                ]
            },
            {
                "countyName": "Mitchell, Thomas",
                "cities": [
                    "Meigs"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Culloden",
                    "Forsyth †"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Ailey",
                    "Alston",
                    "Higgston",
                    "Mount Vernon †",
                    "Tarrytown",
                    "Uvalda"
                ]
            },
            {
                "countyName": "Montgomery, Toombs",
                "cities": [
                    "Vidalia"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Bostwick",
                    "Buckhead",
                    "Madison †",
                    "Rutledge"
                ]
            },
            {
                "countyName": "Murray",
                "cities": [
                    "Chatsworth †",
                    "Eton"
                ]
            },
            {
                "countyName": "Muscogee",
                "cities": [
                    "Columbus †"
                ]
            },
            {
                "countyName": "Newton",
                "cities": [
                    "Covington †",
                    "Mansfield",
                    "Newborn",
                    "Oxford",
                    "Porterdale"
                ]
            },
            {
                "countyName": "Newton, Walton",
                "cities": [
                    "Social Circle"
                ]
            },
            {
                "countyName": "Oconee",
                "cities": [
                    "Bishop",
                    "North High Shoals",
                    "Watkinsville †"
                ]
            },
            {
                "countyName": "Oglethorpe",
                "cities": [
                    "Arnoldsville",
                    "Crawford",
                    "Lexington †",
                    "Maxeys"
                ]
            },
            {
                "countyName": "Paulding",
                "cities": [
                    "Dallas †",
                    "Hiram"
                ]
            },
            {
                "countyName": "Paulding, Polk",
                "cities": [
                    "Braswell"
                ]
            },
            {
                "countyName": "Peach",
                "cities": [
                    "Fort Valley †"
                ]
            },
            {
                "countyName": "Pickens",
                "cities": [
                    "Jasper †",
                    "Talking Rock"
                ]
            },
            {
                "countyName": "Pierce",
                "cities": [
                    "Blackshear †",
                    "Offerman",
                    "Patterson"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Concord",
                    "Meansville",
                    "Molena",
                    "Williamson",
                    "Zebulon †"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Aragon",
                    "Cedartown †",
                    "Rockmart"
                ]
            },
            {
                "countyName": "Pulaski",
                "cities": [
                    "Hawkinsville †"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Eatonton †"
                ]
            },
            {
                "countyName": "Quitman",
                "cities": [
                    "Georgetown †"
                ]
            },
            {
                "countyName": "Rabun",
                "cities": [
                    "Clayton †",
                    "Dillard",
                    "Mountain City",
                    "Sky Valley",
                    "Tiger"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Cuthbert †",
                    "Shellman"
                ]
            },
            {
                "countyName": "Richmond",
                "cities": [
                    "Augusta †[c]",
                    "Hephzibah"
                ]
            },
            {
                "countyName": "Rockdale",
                "cities": [
                    "Conyers †"
                ]
            },
            {
                "countyName": "Schley",
                "cities": [
                    "Ellaville †"
                ]
            },
            {
                "countyName": "Screven",
                "cities": [
                    "Hiltonia",
                    "Newington",
                    "Oliver",
                    "Rocky Ford",
                    "Sylvania †"
                ]
            },
            {
                "countyName": "Seminole",
                "cities": [
                    "Donalsonville †",
                    "Iron City"
                ]
            },
            {
                "countyName": "Spalding",
                "cities": [
                    "Griffin †",
                    "Orchard Hill",
                    "Sunny Side"
                ]
            },
            {
                "countyName": "Stephens",
                "cities": [
                    "Avalon",
                    "Toccoa †"
                ]
            },
            {
                "countyName": "Stewart",
                "cities": [
                    "Lumpkin †",
                    "Richland"
                ]
            },
            {
                "countyName": "Sumter",
                "cities": [
                    "Americus †",
                    "Andersonville",
                    "De Soto",
                    "Leslie",
                    "Plains"
                ]
            },
            {
                "countyName": "Talbot",
                "cities": [
                    "Geneva",
                    "Junction City",
                    "Talbotton †",
                    "Woodland"
                ]
            },
            {
                "countyName": "Taliaferro",
                "cities": [
                    "Crawfordville †",
                    "Sharon"
                ]
            },
            {
                "countyName": "Tattnall",
                "cities": [
                    "Cobbtown",
                    "Collins",
                    "Glennville",
                    "Manassas",
                    "Reidsville †"
                ]
            },
            {
                "countyName": "Taylor",
                "cities": [
                    "Butler †",
                    "Reynolds"
                ]
            },
            {
                "countyName": "Telfair",
                "cities": [
                    "Jacksonville",
                    "Lumber City"
                ]
            },
            {
                "countyName": "Telfair (seat), Wheeler",
                "cities": [
                    "McRae–Helena † [h]"
                ]
            },
            {
                "countyName": "Telfair, Wheeler",
                "cities": [
                    "Scotland"
                ]
            },
            {
                "countyName": "Terrell",
                "cities": [
                    "Bronwood",
                    "Dawson †",
                    "Parrott",
                    "Sasser"
                ]
            },
            {
                "countyName": "Thomas",
                "cities": [
                    "Boston",
                    "Coolidge",
                    "Ochlocknee",
                    "Thomasville †"
                ]
            },
            {
                "countyName": "Tift",
                "cities": [
                    "Tifton †",
                    "Ty Ty"
                ]
            },
            {
                "countyName": "Toombs",
                "cities": [
                    "Lyons †",
                    "Santa Claus"
                ]
            },
            {
                "countyName": "Towns",
                "cities": [
                    "Hiawassee †",
                    "Young Harris"
                ]
            },
            {
                "countyName": "Treutlen",
                "cities": [
                    "Soperton †"
                ]
            },
            {
                "countyName": "Troup",
                "cities": [
                    "Hogansville",
                    "LaGrange †"
                ]
            },
            {
                "countyName": "Turner",
                "cities": [
                    "Ashburn †",
                    "Rebecca",
                    "Sycamore"
                ]
            },
            {
                "countyName": "Twiggs",
                "cities": [
                    "Jeffersonville †"
                ]
            },
            {
                "countyName": "Twiggs, Wilkinson",
                "cities": [
                    "Danville"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Blairsville †"
                ]
            },
            {
                "countyName": "Upson",
                "cities": [
                    "Thomaston †",
                    "Yatesville"
                ]
            },
            {
                "countyName": "Walker",
                "cities": [
                    "Chickamauga",
                    "LaFayette †",
                    "Lookout Mountain",
                    "Rossville"
                ]
            },
            {
                "countyName": "Walton",
                "cities": [
                    "Between",
                    "Good Hope",
                    "Jersey",
                    "Monroe †",
                    "Walnut Grove"
                ]
            },
            {
                "countyName": "Ware",
                "cities": [
                    "Waycross †"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Camak",
                    "Norwood",
                    "Warrenton †"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Davisboro",
                    "Deepstep",
                    "Harrison",
                    "Oconee",
                    "Riddleville",
                    "Sandersville †",
                    "Tennille"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Jesup †",
                    "Odum",
                    "Screven"
                ]
            },
            {
                "countyName": "Webster County",
                "cities": [
                    "Webster County[j]"
                ]
            },
            {
                "countyName": "Wheeler",
                "cities": [
                    "Alamo †",
                    "Glenwood"
                ]
            },
            {
                "countyName": "White",
                "cities": [
                    "Cleveland †",
                    "Helen"
                ]
            },
            {
                "countyName": "Whitfield",
                "cities": [
                    "Cohutta",
                    "Dalton †",
                    "Tunnel Hill",
                    "Varnell"
                ]
            },
            {
                "countyName": "Wilcox",
                "cities": [
                    "Abbeville †",
                    "Pineview",
                    "Pitts",
                    "Rochelle"
                ]
            },
            {
                "countyName": "Wilkes",
                "cities": [
                    "Rayle",
                    "Tignall",
                    "Washington †"
                ]
            },
            {
                "countyName": "Wilkinson",
                "cities": [
                    "Gordon",
                    "Irwinton †",
                    "Ivey",
                    "McIntyre",
                    "Toomsboro"
                ]
            },
            {
                "countyName": "Worth",
                "cities": [
                    "Poulan",
                    "Sumner",
                    "Sylvester †",
                    "Warwick"
                ]
            }
        ]
    },
    {
        "stateId": "HI",
        "stateName": "Hawaii",
        "county": [
            {
                "countyName": "Hawaii",
                "cities": [
                    "Hilo †",
                    "Kailua",
                    "Hawaiian Paradise Park",
                    "Kalaoa",
                    "Waimea",
                    "Waikoloa Village",
                    "Hawaiian Ocean View",
                    "Kahaluu-Keauhou",
                    "Mountain View",
                    "Hawaiian Beaches",
                    "Ainaloa",
                    "Hawaiian Acres",
                    "Captain Cook",
                    "Orchidlands Estates",
                    "Holualoa",
                    "Honokaa",
                    "Kurtistown",
                    "Honaunau-Napoopoo",
                    "Kealakekua",
                    "Hawi",
                    "Kapaau",
                    "Fern Acres",
                    "Pepeekeo",
                    "Nanawale Estates",
                    "Pahala",
                    "Eden Roc",
                    "Keaau",
                    "Discovery Harbour",
                    "Papaikou",
                    "Fern Forest",
                    "Wainaku",
                    "Laupahoehoe",
                    "Leilani Estates",
                    "Honalo",
                    "Pahoa",
                    "Naalehu",
                    "Halaula",
                    "Volcano",
                    "Paauilo",
                    "Honomu",
                    "Paukaa",
                    "Kukuihaele",
                    "Puako",
                    "Waiohinu"
                ]
            },
            {
                "countyName": "Honolulu",
                "cities": [
                    "Honolulu ††",
                    "East Honolulu",
                    "Pearl City",
                    "Waipahu",
                    "Kailua",
                    "Kaneohe",
                    "Mililani Town",
                    "Ewa Gentry",
                    "Kapolei",
                    "Mililani Mauka",
                    "Makakilo",
                    "Wahiawa",
                    "Ewa Beach",
                    "Halawa",
                    "Ocean Pointe",
                    "Schofield Barracks",
                    "Royal Kunia",
                    "Waimalu",
                    "Waianae",
                    "Nanakuli",
                    "Waipio",
                    "Maili",
                    "Aiea",
                    "Makaha",
                    "Kaneohe Station",
                    "Ahuimanu",
                    "Ewa Villages",
                    "Hickam Housing",
                    "Waikele",
                    "Waimanalo",
                    "Laie",
                    "Waipio Acres",
                    "West Loch Estate",
                    "Kahaluu",
                    "Pupukea",
                    "Heeia",
                    "Haleiwa",
                    "Whitmore Village",
                    "Waimanalo Beach",
                    "Iroquois Point",
                    "Waialua",
                    "Hauula",
                    "Kahuku",
                    "Wheeler AFB",
                    "Kalaeloa",
                    "Maunawili",
                    "Ko Olina",
                    "Mokuleia",
                    "Kaaawa",
                    "Punaluu",
                    "Waikane",
                    "Kawela Bay",
                    "Makaha Valley"
                ]
            },
            {
                "countyName": "Kauai",
                "cities": [
                    "Kapaa",
                    "Lihue †",
                    "Wailua Homesteads",
                    "Kalaheo",
                    "Hanamaulu",
                    "Kekaha",
                    "Puhi",
                    "Kilauea",
                    "Hanapepe",
                    "Lawai",
                    "Eleele",
                    "Wailua",
                    "Anahola",
                    "Koloa",
                    "Princeville",
                    "Waimea",
                    "Omao",
                    "Poipu",
                    "Kaumakani",
                    "Haena",
                    "Hanalei",
                    "Wainiha",
                    "Kalihiwai",
                    "Pakala Village"
                ]
            },
            {
                "countyName": "Maui",
                "cities": [
                    "Kahului",
                    "Kihei",
                    "Wailuku †",
                    "Lahaina",
                    "Waihee-Waiehu",
                    "Haiku-Pauwela",
                    "Pukalani",
                    "Makawao",
                    "Napili-Honokowai",
                    "Kula",
                    "Wailea",
                    "Waikapu",
                    "Kaunakakai",
                    "Lanai City",
                    "Paia",
                    "Keokea",
                    "Kualapuu",
                    "Hana",
                    "Olinda",
                    "Kaanapali",
                    "Haliimaile",
                    "Mahinahina",
                    "Launiupoko",
                    "Kapalua",
                    "Maunaloa",
                    "Ualapu'e",
                    "Maalaea",
                    "Makena",
                    "Olowalu",
                    "Manele"
                ]
            }
        ]
    },
   
    {
        "stateId": "ID",
        "stateName": "Idaho",
        "county": [
            {
                "countyName": "Ada",
                "cities": [
                    "Boise ††",
                    "Meridian",
                    "Eagle",
                    "Kuna",
                    "Star",
                    "Garden City"
                ]
            },
            {
                "countyName": "Adams",
                "cities": [
                    "Council †",
                    "New Meadows"
                ]
            },
            {
                "countyName": "Bannock",
                "cities": [
                    "Pocatello †",
                    "Chubbuck",
                    "Inkom",
                    "McCammon",
                    "Downey",
                    "Lava Hot Springs",
                    "Arimo"
                ]
            },
            {
                "countyName": "Bear Lake",
                "cities": [
                    "Montpelier",
                    "Paris †",
                    "Georgetown",
                    "Bloomington",
                    "St. Charles"
                ]
            },
            {
                "countyName": "Benewah",
                "cities": [
                    "St. Maries †",
                    "Plummer",
                    "Tensed"
                ]
            },
            {
                "countyName": "Bingham",
                "cities": [
                    "Blackfoot †",
                    "Shelley",
                    "Aberdeen",
                    "Firth",
                    "Basalt",
                    "Atomic City"
                ]
            },
            {
                "countyName": "Blaine",
                "cities": [
                    "Hailey †",
                    "Ketchum",
                    "Bellevue",
                    "Sun Valley",
                    "Carey"
                ]
            },
            {
                "countyName": "Boise",
                "cities": [
                    "Horseshoe Bend",
                    "Idaho City †",
                    "Crouch",
                    "Placerville"
                ]
            },
            {
                "countyName": "Bonner",
                "cities": [
                    "Sandpoint †",
                    "Priest River",
                    "Ponderay",
                    "Kootenai",
                    "Dover",
                    "Clark Fork",
                    "Oldtown",
                    "East Hope",
                    "Hope"
                ]
            },
            {
                "countyName": "Bonneville",
                "cities": [
                    "Idaho Falls †",
                    "Ammon",
                    "Iona",
                    "Ucon",
                    "Swan Valley",
                    "Irwin"
                ]
            },
            {
                "countyName": "Boundary",
                "cities": [
                    "Bonners Ferry †",
                    "Moyie Springs"
                ]
            },
            {
                "countyName": "Butte",
                "cities": [
                    "Arco †",
                    "Moore",
                    "Butte City"
                ]
            },
            {
                "countyName": "Camas",
                "cities": [
                    "Fairfield †"
                ]
            },
            {
                "countyName": "Canyon",
                "cities": [
                    "Nampa",
                    "Caldwell †",
                    "Middleton",
                    "Parma",
                    "Wilder",
                    "Greenleaf",
                    "Notus",
                    "Melba"
                ]
            },
            {
                "countyName": "Caribou",
                "cities": [
                    "Soda Springs †",
                    "Grace",
                    "Bancroft"
                ]
            },
            {
                "countyName": "Cassia",
                "cities": [
                    "Burley †",
                    "Oakley",
                    "Declo",
                    "Albion",
                    "Malta"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Dubois †",
                    "Spencer"
                ]
            },
            {
                "countyName": "Clearwater",
                "cities": [
                    "Orofino †",
                    "Pierce",
                    "Weippe",
                    "Elk River"
                ]
            },
            {
                "countyName": "Custer",
                "cities": [
                    "Challis †",
                    "Mackay",
                    "Stanley",
                    "Clayton",
                    "Lost River"
                ]
            },
            {
                "countyName": "Elmore",
                "cities": [
                    "Mountain Home †",
                    "Glenns Ferry"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Preston †",
                    "Franklin",
                    "Weston",
                    "Dayton",
                    "Clifton",
                    "Oxford"
                ]
            },
            {
                "countyName": "Fremont",
                "cities": [
                    "St. Anthony †",
                    "Ashton",
                    "Teton",
                    "Newdale",
                    "Parker",
                    "Island Park",
                    "Drummond",
                    "Warm River"
                ]
            },
            {
                "countyName": "Gem",
                "cities": [
                    "Emmett †"
                ]
            },
            {
                "countyName": "Gooding",
                "cities": [
                    "Gooding †",
                    "Wendell",
                    "Hagerman",
                    "Bliss"
                ]
            },
            {
                "countyName": "Idaho",
                "cities": [
                    "Grangeville †",
                    "Cottonwood",
                    "Kooskia",
                    "Riggins",
                    "Stites",
                    "Ferdinand",
                    "White Bird"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Rigby †",
                    "Menan",
                    "Ririe",
                    "Roberts",
                    "Lewisville",
                    "Mud Lake",
                    "Hamer"
                ]
            },
            {
                "countyName": "Jerome",
                "cities": [
                    "Jerome †",
                    "Hazelton",
                    "Eden"
                ]
            },
            {
                "countyName": "Kootenai",
                "cities": [
                    "Coeur d'Alene †",
                    "Post Falls",
                    "Hayden",
                    "Rathdrum",
                    "Dalton Gardens",
                    "Spirit Lake",
                    "Hauser",
                    "Athol",
                    "Hayden Lake",
                    "Worley",
                    "Harrison",
                    "Fernan Lake Village",
                    "Huetter",
                    "Stateline"
                ]
            },
            {
                "countyName": "Latah",
                "cities": [
                    "Moscow †",
                    "Genesee",
                    "Troy",
                    "Potlatch",
                    "Juliaetta",
                    "Deary",
                    "Kendrick",
                    "Onaway",
                    "Bovill"
                ]
            },
            {
                "countyName": "Lemhi",
                "cities": [
                    "Salmon †",
                    "Leadore"
                ]
            },
            {
                "countyName": "Lewis",
                "cities": [
                    "Kamiah",
                    "Craigmont",
                    "Nezperce †",
                    "Winchester",
                    "Reubens"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Shoshone †",
                    "Richfield",
                    "Dietrich"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Rexburg †",
                    "Sugar City"
                ]
            },
            {
                "countyName": "Minidoka",
                "cities": [
                    "Rupert †",
                    "Heyburn",
                    "Paul",
                    "Acequia",
                    "Minidoka"
                ]
            },
            {
                "countyName": "Nez Perce",
                "cities": [
                    "Lewiston †",
                    "Lapwai",
                    "Culdesac",
                    "Peck"
                ]
            },
            {
                "countyName": "Oneida",
                "cities": [
                    "Malad City †"
                ]
            },
            {
                "countyName": "Owyhee",
                "cities": [
                    "Homedale",
                    "Marsing",
                    "Grand View"
                ]
            },
            {
                "countyName": "Payette",
                "cities": [
                    "Payette †",
                    "Fruitland",
                    "New Plymouth"
                ]
            },
            {
                "countyName": "Power",
                "cities": [
                    "American Falls †",
                    "Rockland"
                ]
            },
            {
                "countyName": "Shoshone",
                "cities": [
                    "Kellogg",
                    "Pinehurst",
                    "Osburn",
                    "Wallace †",
                    "Smelterville",
                    "Mullan",
                    "Wardner"
                ]
            },
            {
                "countyName": "Teton",
                "cities": [
                    "Driggs †",
                    "Victor",
                    "Tetonia"
                ]
            },
            {
                "countyName": "Twin Falls",
                "cities": [
                    "Twin Falls †",
                    "Kimberly",
                    "Buhl",
                    "Filer",
                    "Hansen",
                    "Hollister",
                    "Castleford",
                    "Murtaugh"
                ]
            },
            {
                "countyName": "Valley",
                "cities": [
                    "McCall",
                    "Cascade †",
                    "Donnelly"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Weiser †",
                    "Cambridge",
                    "Midvale"
                ]
            }
        ]
    },
    {
        "stateId": "IL",
        "stateName": "Illinois",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Camp Point",
                    "Clayton",
                    "Coatsburg",
                    "Columbus",
                    "Golden",
                    "La Prairie",
                    "Liberty",
                    "Lima",
                    "Loraine",
                    "Mendon",
                    "Payson",
                    "Plainville",
                    "Quincy†",
                    "Ursa"
                ]
            },
            {
                "countyName": "Alexander",
                "cities": [
                    "Cairo†",
                    "East Cape Girardeau",
                    "McClure",
                    "Tamms",
                    "Thebes"
                ]
            },
            {
                "countyName": "Bond",
                "cities": [
                    "Greenville†",
                    "Mulberry Grove",
                    "Old Ripley",
                    "Pocahontas",
                    "Smithboro",
                    "Sorento"
                ]
            },
            {
                "countyName": "Bond\nMadison\nBond",
                "cities": [
                    "Pierron"
                ]
            },
            {
                "countyName": "Bond\nMontgomery",
                "cities": [
                    "Panama"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Belvidere†",
                    "Caledonia",
                    "Capron",
                    "Poplar Grove",
                    "Timberlane"
                ]
            },
            {
                "countyName": "Brown",
                "cities": [
                    "Mound Station",
                    "Mount Sterling†",
                    "Ripley",
                    "Versailles"
                ]
            },
            {
                "countyName": "Bureau",
                "cities": [
                    "Arlington",
                    "Buda",
                    "Bureau Junction",
                    "Cherry",
                    "Dalzell",
                    "De Pue",
                    "Dover",
                    "Hollowayville",
                    "Ladd",
                    "La Moille",
                    "Malden",
                    "Manlius",
                    "Mineral",
                    "Neponset",
                    "New Bedford",
                    "Ohio",
                    "Princeton†",
                    "Seatonville",
                    "Sheffield",
                    "Spring Valley",
                    "Tiskilwa",
                    "Walnut",
                    "Wyanet"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Batchtown",
                    "Brussels",
                    "Hamburg",
                    "Hardin†",
                    "Kampsville"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Chadwick",
                    "Lanark",
                    "Milledgeville",
                    "Mount Carroll†",
                    "Savanna",
                    "Shannon",
                    "Thomson"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Arenzville",
                    "Ashland",
                    "Beardstown",
                    "Chandlerville",
                    "Virginia†"
                ]
            },
            {
                "countyName": "Champaign",
                "cities": [
                    "Allerton",
                    "Bondville",
                    "Broadlands",
                    "Champaign",
                    "Fisher",
                    "Foosland",
                    "Gifford",
                    "Homer",
                    "Ivesdale",
                    "Longview",
                    "Ludlow",
                    "Mahomet",
                    "Ogden",
                    "Pesotum",
                    "Philo",
                    "Rantoul",
                    "Royal",
                    "Sadorus",
                    "St. Joseph",
                    "Savoy",
                    "Sidney",
                    "Thomasboro",
                    "Tolono",
                    "Urbana†"
                ]
            },
            {
                "countyName": "Christian",
                "cities": [
                    "Assumption",
                    "Bulpitt",
                    "Edinburg",
                    "Jeisyville",
                    "Kincaid",
                    "Morrisonville",
                    "Mount Auburn",
                    "Owaneco",
                    "Palmer",
                    "Stonington",
                    "Taylorville†",
                    "Tovey"
                ]
            },
            {
                "countyName": "Christian\nShelby",
                "cities": [
                    "Pana"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Marshall†",
                    "Martinsville",
                    "Westfield"
                ]
            },
            {
                "countyName": "Clark\nCumberland",
                "cities": [
                    "Casey"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Clay City",
                    "Flora",
                    "Iola",
                    "Louisville†",
                    "Sailor Springs",
                    "Xenia"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Albers",
                    "Aviston",
                    "Bartelso",
                    "Beckemeyer",
                    "Breese",
                    "Carlyle†",
                    "Damiansville",
                    "Germantown",
                    "Hoffman",
                    "Huey",
                    "St. Rose[c]",
                    "Trenton"
                ]
            },
            {
                "countyName": "Clinton\nBond",
                "cities": [
                    "Keyesport"
                ]
            },
            {
                "countyName": "Clinton\nMarion\nWashington",
                "cities": [
                    "Wamac"
                ]
            },
            {
                "countyName": "Clinton\nSt. Clair",
                "cities": [
                    "New Baden"
                ]
            },
            {
                "countyName": "Coles",
                "cities": [
                    "Ashmore",
                    "Charleston†",
                    "Humboldt",
                    "Lerna",
                    "Mattoon",
                    "Oakland"
                ]
            },
            {
                "countyName": "Cook",
                "cities": [
                    "Alsip",
                    "Arlington Heights",
                    "Bedford Park",
                    "Bellwood",
                    "Berkeley",
                    "Berwyn",
                    "Blue Island",
                    "Bridgeview",
                    "Broadview",
                    "Brookfield",
                    "Burbank",
                    "Burnham",
                    "Calumet City",
                    "Calumet Park",
                    "Chicago†",
                    "Chicago Heights",
                    "Chicago Ridge",
                    "Cicero",
                    "Country Club Hills",
                    "Countryside",
                    "Crestwood",
                    "Des Plaines",
                    "Dixmoor",
                    "Dolton",
                    "East Hazel Crest",
                    "Elk Grove Village",
                    "Elmwood Park",
                    "Evanston",
                    "Evergreen Park",
                    "Flossmoor",
                    "Ford Heights",
                    "Forest Park",
                    "Forest View",
                    "Franklin Park",
                    "Glencoe",
                    "Glenview",
                    "Glenwood",
                    "Golf",
                    "Harvey",
                    "Harwood Heights",
                    "Hazel Crest",
                    "Hickory Hills",
                    "Hillside",
                    "Hodgkins",
                    "Hoffman Estates",
                    "Hometown",
                    "Homewood",
                    "Indian Head Park",
                    "Inverness",
                    "Justice",
                    "Kenilworth",
                    "La Grange",
                    "La Grange Park",
                    "Lansing",
                    "Lincolnwood",
                    "Lynwood",
                    "Lyons",
                    "Markham",
                    "Matteson",
                    "Maywood",
                    "McCook",
                    "Melrose Park",
                    "Merrionette Park",
                    "Midlothian",
                    "Morton Grove",
                    "Mount Prospect",
                    "Niles",
                    "Norridge",
                    "Northbrook",
                    "Northfield",
                    "Northlake",
                    "North Riverside",
                    "Oak Forest",
                    "Oak Lawn",
                    "Oak Park",
                    "Olympia Fields",
                    "Orland Hills",
                    "Palatine",
                    "Palos Heights",
                    "Palos Hills",
                    "Palos Park",
                    "Park Ridge",
                    "Phoenix",
                    "Posen",
                    "Prospect Heights",
                    "Richton Park",
                    "Riverdale",
                    "River Forest",
                    "River Grove",
                    "Riverside",
                    "Robbins",
                    "Rolling Meadows",
                    "Rosemont",
                    "Sauk Village",
                    "Schaumburg",
                    "Schiller Park",
                    "Skokie",
                    "South Barrington",
                    "South Chicago Heights",
                    "South Holland",
                    "Stickney",
                    "Stone Park",
                    "Streamwood",
                    "Summit",
                    "Thornton",
                    "University Park",
                    "Westchester",
                    "Western Springs",
                    "Wheeling",
                    "Willow Springs",
                    "Wilmette",
                    "Winnetka",
                    "Worth"
                ]
            },
            {
                "countyName": "Cook\nDuPage",
                "cities": [
                    "Lemont"
                ]
            },
            {
                "countyName": "Cook\nDuPage\nKane",
                "cities": [
                    "Bartlett"
                ]
            },
            {
                "countyName": "Cook\nLake",
                "cities": [
                    "Barrington",
                    "Buffalo Grove"
                ]
            },
            {
                "countyName": "Cook\nMcHenry\nLake\nKane",
                "cities": [
                    "Barrington Hills"
                ]
            },
            {
                "countyName": "Cook\nWill",
                "cities": [
                    "Orland Park",
                    "Park Forest",
                    "Steger",
                    "Tinley Park"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Flat Rock",
                    "Hutsonville",
                    "Oblong",
                    "Palestine",
                    "Robinson†",
                    "Stoy"
                ]
            },
            {
                "countyName": "Cumberland",
                "cities": [
                    "Greenup",
                    "Jewett",
                    "Neoga",
                    "Toledo†"
                ]
            },
            {
                "countyName": "De Witt",
                "cities": [
                    "Clinton†",
                    "De Witt",
                    "Farmer City",
                    "Kenney",
                    "Wapella",
                    "Waynesville",
                    "Weldon"
                ]
            },
            {
                "countyName": "DeKalb",
                "cities": [
                    "Cortland",
                    "DeKalb",
                    "Genoa",
                    "Hinckley",
                    "Kingston",
                    "Kirkland",
                    "Malta",
                    "Shabbona",
                    "Sycamore†",
                    "Waterman"
                ]
            },
            {
                "countyName": "DeKalb\nKendall",
                "cities": [
                    "Sandwich"
                ]
            },
            {
                "countyName": "DeKalb\nLaSalle",
                "cities": [
                    "Somonauk"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Arcola",
                    "Camargo",
                    "Garrett",
                    "Hindsboro",
                    "Newman",
                    "Tuscola†",
                    "Villa Grove"
                ]
            },
            {
                "countyName": "Douglas\nMoultrie",
                "cities": [
                    "Arthur"
                ]
            },
            {
                "countyName": "Douglas\nPiatt",
                "cities": [
                    "Atwood"
                ]
            },
            {
                "countyName": "DuPage",
                "cities": [
                    "Addison",
                    "Bensenville",
                    "Bloomingdale",
                    "Carol Stream",
                    "Clarendon Hills",
                    "Darien",
                    "Downers Grove",
                    "Elmhurst",
                    "Glendale Heights",
                    "Glen Ellyn",
                    "Hanover Park",
                    "Itasca",
                    "Lisle",
                    "Lombard",
                    "Oak Brook",
                    "Oakbrook Terrace",
                    "Villa Park",
                    "Warrenville",
                    "West Chicago",
                    "Westmont",
                    "Wheaton†",
                    "Willowbrook",
                    "Winfield",
                    "Wood Dale",
                    "Woodridge"
                ]
            },
            {
                "countyName": "DuPage\nCook",
                "cities": [
                    "Burr Ridge",
                    "Hinsdale",
                    "Roselle"
                ]
            },
            {
                "countyName": "DuPage\nKane",
                "cities": [
                    "Wayne"
                ]
            },
            {
                "countyName": "DuPage\nWill",
                "cities": [
                    "Naperville"
                ]
            },
            {
                "countyName": "Edgar",
                "cities": [
                    "Brocton",
                    "Chrisman",
                    "Hume",
                    "Kansas",
                    "Metcalf",
                    "Paris†",
                    "Redmon",
                    "Vermilion"
                ]
            },
            {
                "countyName": "Edwards",
                "cities": [
                    "Albion†",
                    "Bone Gap",
                    "Browns",
                    "West Salem"
                ]
            },
            {
                "countyName": "Edwards\nWhite",
                "cities": [
                    "Grayville"
                ]
            },
            {
                "countyName": "Effingham",
                "cities": [
                    "Altamont",
                    "Beecher City",
                    "Dieterich",
                    "Edgewood",
                    "Effingham†",
                    "Mason",
                    "Montrose",
                    "Shumway",
                    "Teutopolis",
                    "Watson"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "Bingham",
                    "Brownstown",
                    "Farina",
                    "Ramsey",
                    "St. Elmo",
                    "St. Peter",
                    "Vandalia†"
                ]
            },
            {
                "countyName": "Ford",
                "cities": [
                    "Elliott",
                    "Gibson City",
                    "Kempton",
                    "Melvin",
                    "Paxton†",
                    "Piper City",
                    "Roberts",
                    "Sibley"
                ]
            },
            {
                "countyName": "Ford\nKankakee",
                "cities": [
                    "Cabery"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Benton†",
                    "Buckner",
                    "Christopher",
                    "Ewing",
                    "Hanaford",
                    "North City",
                    "Orient",
                    "Royalton",
                    "Sesser",
                    "Thompsonville",
                    "Valier",
                    "West City",
                    "West Frankfort",
                    "Zeigler"
                ]
            },
            {
                "countyName": "Franklin\nHamilton",
                "cities": [
                    "Macedonia"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Astoria",
                    "Avon",
                    "Banner",
                    "Bryant",
                    "Canton",
                    "Cuba",
                    "Dunfermline",
                    "Ellisville",
                    "Fairview",
                    "Farmington",
                    "Ipava",
                    "Lewistown†",
                    "Liverpool",
                    "Marietta",
                    "Norris",
                    "St. David",
                    "Smithfield",
                    "Table Grove",
                    "Vermont"
                ]
            },
            {
                "countyName": "Fulton\nKnox",
                "cities": [
                    "London Mills"
                ]
            },
            {
                "countyName": "Gallatin",
                "cities": [
                    "Equality",
                    "Junction",
                    "New Haven",
                    "Old Shawneetown",
                    "Omaha",
                    "Ridgway",
                    "Shawneetown†"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Carrollton†",
                    "Eldred",
                    "Greenfield",
                    "Hillview",
                    "Kane",
                    "Rockbridge",
                    "Roodhouse",
                    "White Hall",
                    "Wilmington"
                ]
            },
            {
                "countyName": "Grundy",
                "cities": [
                    "Braceville",
                    "Carbon Hill",
                    "Coal City",
                    "East Brooklyn",
                    "Gardner",
                    "Kinsman",
                    "Mazon",
                    "Morris†",
                    "South Wilmington",
                    "Verona"
                ]
            },
            {
                "countyName": "Grundy\nWill",
                "cities": [
                    "Diamond",
                    "Minooka"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Belle Prairie City",
                    "Broughton",
                    "Dahlgren",
                    "McLeansboro†"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Augusta",
                    "Basco",
                    "Bentley",
                    "Bowen",
                    "Carthage†",
                    "Elvaston",
                    "Ferris",
                    "Hamilton",
                    "La Harpe",
                    "Nauvoo",
                    "Plymouth",
                    "Pontoosuc",
                    "Warsaw",
                    "West Point"
                ]
            },
            {
                "countyName": "Hancock\nHenderson",
                "cities": [
                    "Dallas City"
                ]
            },
            {
                "countyName": "Hardin",
                "cities": [
                    "Cave-In-Rock",
                    "Elizabethtown†",
                    "Rosiclare"
                ]
            },
            {
                "countyName": "Henderson",
                "cities": [
                    "Biggsville",
                    "Gladstone",
                    "Gulfport",
                    "Lomax",
                    "Media",
                    "Oquawka†",
                    "Raritan",
                    "Stronghurst"
                ]
            },
            {
                "countyName": "Henry",
                "cities": [
                    "Alpha",
                    "Andover",
                    "Annawan",
                    "Atkinson",
                    "Bishop Hill",
                    "Cambridge†",
                    "Cleveland",
                    "Colona",
                    "Galva",
                    "Geneseo",
                    "Hooppole",
                    "Kewanee",
                    "Orion",
                    "Woodhull"
                ]
            },
            {
                "countyName": "Iroquois",
                "cities": [
                    "Ashkum",
                    "Beaverville",
                    "Buckley",
                    "Cissna Park",
                    "Clifton",
                    "Crescent City",
                    "Danforth",
                    "Donovan",
                    "Gilman",
                    "Iroquois",
                    "Loda",
                    "Martinton",
                    "Milford",
                    "Onarga",
                    "Papineau",
                    "Sheldon",
                    "Thawville",
                    "Watseka†",
                    "Wellington",
                    "Woodland"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Ava",
                    "Campbell Hill",
                    "Carbondale",
                    "De Soto",
                    "Dowell",
                    "Elkville",
                    "Gorham",
                    "Grand Tower",
                    "Makanda",
                    "Murphysboro†",
                    "Vergennes"
                ]
            },
            {
                "countyName": "Jasper",
                "cities": [
                    "Hidalgo",
                    "Newton†",
                    "Rose Hill",
                    "Ste. Marie",
                    "Wheeler",
                    "Willow Hill",
                    "Yale"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Belle Rive",
                    "Bluford",
                    "Bonnie",
                    "Dix",
                    "Ina",
                    "Mount Vernon†",
                    "Nason",
                    "Waltonville",
                    "Woodlawn"
                ]
            },
            {
                "countyName": "Jersey",
                "cities": [
                    "Elsah",
                    "Fidelity",
                    "Fieldon",
                    "Grafton",
                    "Jerseyville†",
                    "Otterville"
                ]
            },
            {
                "countyName": "Jo Daviess",
                "cities": [
                    "Apple River",
                    "East Dubuque",
                    "Elizabeth",
                    "Galena†",
                    "Hanover",
                    "Menominee",
                    "Nora",
                    "Scales Mound",
                    "Stockton",
                    "Warren"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Belknap",
                    "Buncombe",
                    "Cypress",
                    "Goreville",
                    "New Burnside",
                    "Simpson",
                    "Vienna†"
                ]
            },
            {
                "countyName": "Kane",
                "cities": [
                    "Batavia",
                    "Big Rock",
                    "Burlington",
                    "Campton Hills",
                    "Carpentersville",
                    "Elburn",
                    "Geneva†",
                    "Gilberts",
                    "Hampshire",
                    "Kaneville",
                    "Lily Lake",
                    "North Aurora",
                    "Pingree Grove",
                    "Sleepy Hollow",
                    "South Elgin",
                    "Sugar Grove",
                    "Virgil",
                    "West Dundee"
                ]
            },
            {
                "countyName": "Kane\nCook",
                "cities": [
                    "East Dundee",
                    "Elgin"
                ]
            },
            {
                "countyName": "Kane\nDeKalb",
                "cities": [
                    "Maple Park"
                ]
            },
            {
                "countyName": "Kane\nDuPage",
                "cities": [
                    "St. Charles"
                ]
            },
            {
                "countyName": "Kane\nDuPage\nWill\nKendall",
                "cities": [
                    "Aurora"
                ]
            },
            {
                "countyName": "Kane\nKendall",
                "cities": [
                    "Montgomery"
                ]
            },
            {
                "countyName": "Kankakee",
                "cities": [
                    "Aroma Park",
                    "Bonfield",
                    "Bourbonnais",
                    "Bradley",
                    "Buckingham",
                    "Essex",
                    "Grant Park",
                    "Herscher",
                    "Hopkins Park",
                    "Irwin",
                    "Kankakee†",
                    "Limestone",
                    "Manteno",
                    "Momence",
                    "St. Anne",
                    "Sammons Point",
                    "Sun River Terrace",
                    "Union Hill"
                ]
            },
            {
                "countyName": "Kankakee\nIroquois",
                "cities": [
                    "Chebanse"
                ]
            },
            {
                "countyName": "Kendall",
                "cities": [
                    "Lisbon",
                    "Millbrook",
                    "Newark",
                    "Oswego",
                    "Plano",
                    "Plattville",
                    "Yorkville†"
                ]
            },
            {
                "countyName": "Kendall\nLaSalle",
                "cities": [
                    "Millington"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Abingdon",
                    "Altona",
                    "East Galesburg",
                    "Galesburg†",
                    "Henderson",
                    "Knoxville",
                    "Maquon",
                    "Oneida",
                    "Rio",
                    "St. Augustine",
                    "Victoria",
                    "Wataga",
                    "Williamsfield",
                    "Yates City"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Antioch",
                    "Bannockburn",
                    "Beach Park",
                    "Grayslake",
                    "Green Oaks",
                    "Gurnee",
                    "Hainesville",
                    "Hawthorn Woods",
                    "Highland Park",
                    "Highwood",
                    "Indian Creek",
                    "Kildeer",
                    "Lake Barrington",
                    "Lake Bluff",
                    "Lake Forest",
                    "Lake Villa",
                    "Lake Zurich",
                    "Libertyville",
                    "Lincolnshire",
                    "Lindenhurst",
                    "Long Grove",
                    "Mettawa",
                    "Mundelein",
                    "North Barrington",
                    "North Chicago",
                    "Old Mill Creek",
                    "Park City",
                    "Riverwoods",
                    "Round Lake",
                    "Round Lake Beach",
                    "Round Lake Heights",
                    "Round Lake Park",
                    "Third Lake",
                    "Tower Lakes",
                    "Vernon Hills",
                    "Volo",
                    "Wadsworth",
                    "Wauconda",
                    "Waukegan†",
                    "Winthrop Harbor",
                    "Zion"
                ]
            },
            {
                "countyName": "Lake\nCook",
                "cities": [
                    "Deerfield",
                    "Deer Park"
                ]
            },
            {
                "countyName": "Lake\nMcHenry",
                "cities": [
                    "Fox Lake",
                    "Island Lake"
                ]
            },
            {
                "countyName": "LaSalle",
                "cities": [
                    "Cedar Point",
                    "Dana",
                    "Earlville",
                    "Grand Ridge",
                    "Kangley",
                    "LaSalle",
                    "Leland",
                    "Leonore",
                    "Lostant",
                    "Marseilles",
                    "Mendota",
                    "Naplate",
                    "North Utica",
                    "Oglesby",
                    "Ottawa†",
                    "Peru",
                    "Ransom",
                    "Rutland",
                    "Seneca",
                    "Sheridan",
                    "Tonica",
                    "Troy Grove"
                ]
            },
            {
                "countyName": "LaSalle\nLivingston",
                "cities": [
                    "Streator"
                ]
            },
            {
                "countyName": "Lawrence",
                "cities": [
                    "Bridgeport",
                    "Lawrenceville†",
                    "Russellville",
                    "St. Francisville",
                    "Sumner"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Amboy",
                    "Ashton",
                    "Compton",
                    "Dixon†",
                    "Franklin Grove",
                    "Harmon",
                    "Nelson",
                    "Paw Paw",
                    "Steward",
                    "Sublette",
                    "West Brooklyn"
                ]
            },
            {
                "countyName": "Lee\nDeKalb",
                "cities": [
                    "Lee"
                ]
            },
            {
                "countyName": "Livingston",
                "cities": [
                    "Campus",
                    "Chatsworth",
                    "Cornell",
                    "Cullom",
                    "Emington",
                    "Fairbury",
                    "Flanagan",
                    "Forrest",
                    "Long Point",
                    "Odell",
                    "Pontiac†",
                    "Reddick",
                    "Saunemin",
                    "Strawn"
                ]
            },
            {
                "countyName": "Livingston\nGrundy",
                "cities": [
                    "Dwight"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Atlanta",
                    "Broadwell",
                    "Elkhart",
                    "Emden",
                    "Hartsburg",
                    "Latham",
                    "Lincoln†",
                    "Middletown",
                    "Mount Pulaski",
                    "New Holland"
                ]
            },
            {
                "countyName": "Logan\nMason",
                "cities": [
                    "San Jose"
                ]
            },
            {
                "countyName": "Macon",
                "cities": [
                    "Argenta",
                    "Blue Mound",
                    "Decatur†",
                    "Forsyth",
                    "Harristown",
                    "Long Creek",
                    "Macon",
                    "Maroa",
                    "Mount Zion",
                    "Niantic",
                    "Oreana",
                    "Warrensburg"
                ]
            },
            {
                "countyName": "Macoupin",
                "cities": [
                    "Benld",
                    "Bunker Hill",
                    "Carlinville†",
                    "Chesterfield",
                    "Dorchester",
                    "Eagarville",
                    "East Gillespie",
                    "Gillespie",
                    "Girard",
                    "Hettick",
                    "Lake Ka-Ho",
                    "Medora",
                    "Modesto",
                    "Mount Clare",
                    "Mount Olive",
                    "Nilwood",
                    "Palmyra",
                    "Royal Lakes",
                    "Sawyerville",
                    "Scottville",
                    "Shipman",
                    "Standard City",
                    "Staunton",
                    "White City",
                    "Wilsonville"
                ]
            },
            {
                "countyName": "Macoupin\nJersey",
                "cities": [
                    "Brighton"
                ]
            },
            {
                "countyName": "Macoupin\nSangamon",
                "cities": [
                    "Virden"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Alhambra",
                    "Alton",
                    "Bethalto",
                    "East Alton",
                    "Edwardsville†",
                    "Glen Carbon",
                    "Godfrey",
                    "Granite City",
                    "Grantfork",
                    "Hamel",
                    "Hartford",
                    "Highland",
                    "Livingston",
                    "Marine",
                    "Maryville",
                    "New Douglas",
                    "Pontoon Beach",
                    "Roxana",
                    "St. Jacob",
                    "South Roxana",
                    "Troy",
                    "Venice",
                    "Williamson",
                    "Wood River",
                    "Worden"
                ]
            },
            {
                "countyName": "Madison\nSt. Clair",
                "cities": [
                    "Collinsville",
                    "Madison"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Alma",
                    "Central City",
                    "Iuka",
                    "Junction City",
                    "Kell",
                    "Kinmundy",
                    "Odin",
                    "Patoka",
                    "Salem†",
                    "Sandoval",
                    "Vernon",
                    "Walnut Hill"
                ]
            },
            {
                "countyName": "Marion\nClinton\nJefferson\nWashington",
                "cities": [
                    "Centralia"
                ]
            },
            {
                "countyName": "Marshall",
                "cities": [
                    "Henry",
                    "Hopewell",
                    "Lacon†",
                    "La Rose",
                    "Sparland",
                    "Toluca",
                    "Varna",
                    "Wenona"
                ]
            },
            {
                "countyName": "Mason",
                "cities": [
                    "Bath",
                    "Easton",
                    "Forest City",
                    "Havana†",
                    "Kilbourne",
                    "Manito",
                    "Mason City",
                    "Topeka"
                ]
            },
            {
                "countyName": "Massac",
                "cities": [
                    "Brookport",
                    "Joppa",
                    "Metropolis†"
                ]
            },
            {
                "countyName": "McDonough",
                "cities": [
                    "Bardolph",
                    "Blandinsville",
                    "Bushnell",
                    "Colchester",
                    "Good Hope",
                    "Industry",
                    "Macomb†",
                    "Prairie City",
                    "Sciota",
                    "Tennessee"
                ]
            },
            {
                "countyName": "McHenry",
                "cities": [
                    "Bull Valley",
                    "Cary",
                    "Crystal Lake",
                    "Greenwood",
                    "Harvard",
                    "Hebron",
                    "Holiday Hills",
                    "Johnsburg",
                    "Lake in the Hills",
                    "Lakewood",
                    "Marengo",
                    "McCullom Lake",
                    "McHenry",
                    "Oakwood Hills",
                    "Prairie Grove",
                    "Richmond",
                    "Ringwood",
                    "Spring Grove",
                    "Trout Valley",
                    "Union",
                    "Wonder Lake",
                    "Woodstock†"
                ]
            },
            {
                "countyName": "McHenry\nKane",
                "cities": [
                    "Algonquin",
                    "Huntley"
                ]
            },
            {
                "countyName": "McHenry\nLake",
                "cities": [
                    "Fox River Grove",
                    "Lakemoor",
                    "Port Barrington"
                ]
            },
            {
                "countyName": "McLean",
                "cities": [
                    "Anchor",
                    "Arrowsmith",
                    "Bellflower",
                    "Bloomington†",
                    "Carlock",
                    "Chenoa",
                    "Colfax",
                    "Cooksville",
                    "Danvers",
                    "Downs",
                    "Ellsworth",
                    "Gridley",
                    "Heyworth",
                    "Hudson",
                    "Le Roy",
                    "Lexington",
                    "McLean",
                    "Normal",
                    "Saybrook",
                    "Stanford",
                    "Towanda"
                ]
            },
            {
                "countyName": "Menard",
                "cities": [
                    "Athens",
                    "Greenview",
                    "Oakford",
                    "Petersburg†",
                    "Tallula"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Aledo†",
                    "Joy",
                    "Keithsburg",
                    "Matherville",
                    "New Boston",
                    "North Henderson",
                    "Seaton",
                    "Sherrard",
                    "Viola",
                    "Windsor"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Fults",
                    "Hecker",
                    "Maeystown",
                    "Valmeyer",
                    "Waterloo†"
                ]
            },
            {
                "countyName": "Monroe\nSt. Clair",
                "cities": [
                    "Columbia"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Butler",
                    "Coalton",
                    "Coffeen",
                    "Farmersville",
                    "Fillmore",
                    "Hillsboro†",
                    "Irving",
                    "Litchfield",
                    "Nokomis",
                    "Ohlman",
                    "Raymond",
                    "Schram City",
                    "Taylor Springs",
                    "Waggoner",
                    "Walshville",
                    "Wenonah",
                    "Witt"
                ]
            },
            {
                "countyName": "Montgomery\nBond",
                "cities": [
                    "Donnellson"
                ]
            },
            {
                "countyName": "Montgomery\nChristian",
                "cities": [
                    "Harvel"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Chapin",
                    "Concord",
                    "Franklin",
                    "Jacksonville†",
                    "Lynnville",
                    "Meredosia",
                    "Murrayville",
                    "South Jacksonville",
                    "Waverly",
                    "Woodson"
                ]
            },
            {
                "countyName": "Moultrie",
                "cities": [
                    "Allenville",
                    "Bethany",
                    "Dalton City",
                    "Gays",
                    "Lovington",
                    "Sullivan†"
                ]
            },
            {
                "countyName": "Ogle",
                "cities": [
                    "Adeline",
                    "Byron",
                    "Creston",
                    "Davis Junction",
                    "Forreston",
                    "Hillcrest",
                    "Leaf River",
                    "Monroe Center",
                    "Mount Morris",
                    "Oregon†",
                    "Polo",
                    "Rochelle",
                    "Stillman Valley"
                ]
            },
            {
                "countyName": "Peoria",
                "cities": [
                    "Bartonville",
                    "Bellevue",
                    "Brimfield",
                    "Chillicothe",
                    "Dunlap",
                    "Elmwood",
                    "Glasford",
                    "Hanna City",
                    "Kingston Mines",
                    "Mapleton",
                    "Norwood",
                    "Peoria†",
                    "Princeville",
                    "West Peoria"
                ]
            },
            {
                "countyName": "Peoria\nWoodford",
                "cities": [
                    "Peoria Heights"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Cutler",
                    "Du Quoin",
                    "Pinckneyville†",
                    "St. Johns",
                    "Tamaroa",
                    "Willisville"
                ]
            },
            {
                "countyName": "Piatt",
                "cities": [
                    "Bement",
                    "Cerro Gordo",
                    "Cisco",
                    "De Land",
                    "Hammond",
                    "Mansfield",
                    "Monticello†"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Barry",
                    "Baylis",
                    "Detroit",
                    "El Dara",
                    "Florence",
                    "Griggsville",
                    "Hull",
                    "Kinderhook",
                    "Milton",
                    "Nebo",
                    "New Canton",
                    "New Salem",
                    "Pearl",
                    "Perry",
                    "Pittsfield†",
                    "Pleasant Hill",
                    "Time",
                    "Valley City"
                ]
            },
            {
                "countyName": "Pope",
                "cities": [
                    "Eddyville",
                    "Golconda†"
                ]
            },
            {
                "countyName": "Pulaski",
                "cities": [
                    "Karnak",
                    "Mound City",
                    "Mounds",
                    "New Grand Chain",
                    "Olmsted",
                    "Pulaski",
                    "Ullin"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Granville",
                    "Hennepin†",
                    "Magnolia",
                    "Mark",
                    "McNabb",
                    "Standard"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Baldwin",
                    "Chester†",
                    "Coulterville",
                    "Ellis Grove",
                    "Evansville",
                    "Kaskaskia",
                    "Percy",
                    "Prairie du Rocher",
                    "Red Bud",
                    "Rockwood",
                    "Ruma",
                    "Sparta",
                    "Steeleville",
                    "Tilden"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Calhoun",
                    "Claremont",
                    "Noble",
                    "Olney†",
                    "Parkersburg"
                ]
            },
            {
                "countyName": "Rock Island",
                "cities": [
                    "Andalusia",
                    "Carbon Cliff",
                    "Cordova",
                    "East Moline",
                    "Hampton",
                    "Hillsdale",
                    "Milan",
                    "Moline",
                    "Oak Grove",
                    "Port Byron",
                    "Rapids City",
                    "Rock Island†",
                    "Silvis"
                ]
            },
            {
                "countyName": "Rock Island\nHenry",
                "cities": [
                    "Coal Valley"
                ]
            },
            {
                "countyName": "Rock Island\nMercer",
                "cities": [
                    "Reynolds"
                ]
            },
            {
                "countyName": "Saline",
                "cities": [
                    "Carrier Mills",
                    "Eldorado",
                    "Galatia",
                    "Harrisburg†",
                    "Muddy",
                    "Raleigh"
                ]
            },
            {
                "countyName": "Sangamon",
                "cities": [
                    "Auburn",
                    "Berlin",
                    "Buffalo",
                    "Cantrall",
                    "Chatham",
                    "Clear Lake[b]",
                    "Curran",
                    "Dawson",
                    "Divernon",
                    "Grandview",
                    "Illiopolis",
                    "Jerome",
                    "Leland Grove",
                    "Loami",
                    "Mechanicsburg",
                    "New Berlin",
                    "Pawnee",
                    "Pleasant Plains",
                    "Riverton",
                    "Rochester",
                    "Sherman",
                    "Southern View",
                    "Spaulding",
                    "Springfield‡†[d]",
                    "Thayer",
                    "Williamsville"
                ]
            },
            {
                "countyName": "Schuyler",
                "cities": [
                    "Browning",
                    "Camden",
                    "Littleton",
                    "Rushville†"
                ]
            },
            {
                "countyName": "Scott",
                "cities": [
                    "Alsey",
                    "Bluffs",
                    "Exeter",
                    "Glasgow",
                    "Manchester",
                    "Naples",
                    "Winchester†"
                ]
            },
            {
                "countyName": "Shelby",
                "cities": [
                    "Cowden",
                    "Findlay",
                    "Herrick",
                    "Oconee",
                    "Shelbyville†",
                    "Sigel",
                    "Stewardson",
                    "Strasburg",
                    "Tower Hill",
                    "Windsor, Shelby County, Illinois"
                ]
            },
            {
                "countyName": "Shelby\nChristian",
                "cities": [
                    "Moweaqua"
                ]
            },
            {
                "countyName": "St. Clair",
                "cities": [
                    "Alorton[a]",
                    "Belleville†",
                    "Brooklyn",
                    "Cahokia[a]",
                    "Caseyville",
                    "Centreville[a]",
                    "Dupo",
                    "East Carondelet",
                    "East St. Louis",
                    "Fairview Heights",
                    "Fayetteville",
                    "Freeburg",
                    "Lebanon",
                    "Lenzburg",
                    "Marissa",
                    "Mascoutah",
                    "Millstadt",
                    "New Athens",
                    "O'Fallon",
                    "St. Libory",
                    "Sauget",
                    "Shiloh",
                    "Smithton",
                    "Summerfield",
                    "Swansea",
                    "Washington Park"
                ]
            },
            {
                "countyName": "St. Clair\nMadison",
                "cities": [
                    "Fairmont City"
                ]
            },
            {
                "countyName": "Stark",
                "cities": [
                    "Bradford",
                    "La Fayette",
                    "Toulon†",
                    "Wyoming"
                ]
            },
            {
                "countyName": "Stephenson",
                "cities": [
                    "Cedarville",
                    "Dakota",
                    "Davis",
                    "Freeport†",
                    "German Valley",
                    "Lena",
                    "Orangeville",
                    "Pearl City",
                    "Ridott",
                    "Rock City",
                    "Winslow"
                ]
            },
            {
                "countyName": "Tazewell",
                "cities": [
                    "Armington",
                    "Creve Coeur",
                    "Deer Creek",
                    "Delavan",
                    "East Peoria",
                    "Green Valley",
                    "Hopedale",
                    "Mackinaw",
                    "Marquette Heights",
                    "Minier",
                    "Morton",
                    "North Pekin",
                    "Pekin†",
                    "South Pekin",
                    "Tremont",
                    "Washington"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Alto Pass",
                    "Anna",
                    "Cobden",
                    "Dongola",
                    "Jonesboro†",
                    "Mill Creek"
                ]
            },
            {
                "countyName": "Vermilion",
                "cities": [
                    "Alvin",
                    "Belgium",
                    "Bismarck",
                    "Catlin",
                    "Danville†",
                    "Fairmount",
                    "Fithian",
                    "Georgetown",
                    "Henning",
                    "Hoopeston",
                    "Indianola",
                    "Muncie",
                    "Oakwood",
                    "Potomac",
                    "Rankin",
                    "Ridge Farm",
                    "Rossville",
                    "Sidell",
                    "Tilton",
                    "Westville"
                ]
            },
            {
                "countyName": "Wabash",
                "cities": [
                    "Allendale",
                    "Bellmont",
                    "Keensburg",
                    "Mount Carmel†"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Kirkwood",
                    "Little York",
                    "Monmouth†",
                    "Roseville"
                ]
            },
            {
                "countyName": "Warren\nMercer",
                "cities": [
                    "Alexis"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Addieville",
                    "Ashley",
                    "Du Bois",
                    "Hoyleton",
                    "Irvington",
                    "Nashville†",
                    "New Minden",
                    "Oakdale",
                    "Okawville",
                    "Radom",
                    "Richview",
                    "Venedy"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Cisne",
                    "Fairfield†",
                    "Golden Gate",
                    "Jeffersonville",
                    "Johnsonville",
                    "Keenes",
                    "Mount Erie",
                    "Sims",
                    "Wayne City"
                ]
            },
            {
                "countyName": "White",
                "cities": [
                    "Burnt Prairie",
                    "Carmi†",
                    "Crossville",
                    "Enfield",
                    "Maunie",
                    "Norris City",
                    "Phillipstown",
                    "Springerton"
                ]
            },
            {
                "countyName": "White\nWayne",
                "cities": [
                    "Mill Shoals"
                ]
            },
            {
                "countyName": "Whiteside",
                "cities": [
                    "Albany",
                    "Coleta",
                    "Deer Grove",
                    "Erie",
                    "Fulton",
                    "Lyndon",
                    "Morrison†",
                    "Prophetstown",
                    "Rock Falls",
                    "Sterling",
                    "Tampico"
                ]
            },
            {
                "countyName": "Will",
                "cities": [
                    "Beecher",
                    "Braidwood",
                    "Crest Hill",
                    "Crete",
                    "Elwood",
                    "Frankfort",
                    "Homer Glen",
                    "Lockport",
                    "Manhattan",
                    "Mokena",
                    "Monee",
                    "New Lenox",
                    "Peotone",
                    "Plainfield",
                    "Rockdale",
                    "Romeoville",
                    "Shorewood",
                    "Symerton",
                    "Wilmington"
                ]
            },
            {
                "countyName": "Will\nDuPage",
                "cities": [
                    "Bolingbrook"
                ]
            },
            {
                "countyName": "Will\nGrundy",
                "cities": [
                    "Channahon",
                    "Godley"
                ]
            },
            {
                "countyName": "Will\nKendall",
                "cities": [
                    "Joliet†"
                ]
            },
            {
                "countyName": "Williamson",
                "cities": [
                    "Bush",
                    "Cambria",
                    "Carterville",
                    "Colp",
                    "Crainville",
                    "Creal Springs",
                    "Energy",
                    "Herrin",
                    "Hurst",
                    "Johnston City",
                    "Marion†",
                    "Pittsburg",
                    "Spillertown"
                ]
            },
            {
                "countyName": "Williamson\nFranklin",
                "cities": [
                    "Freeman Spur"
                ]
            },
            {
                "countyName": "Williamson\nSaline",
                "cities": [
                    "Stonefort"
                ]
            },
            {
                "countyName": "Winnebago",
                "cities": [
                    "Cherry Valley",
                    "Durand",
                    "Machesney Park",
                    "New Milford",
                    "Pecatonica",
                    "Rockford†",
                    "Rockton",
                    "Roscoe",
                    "South Beloit",
                    "Winnebago"
                ]
            },
            {
                "countyName": "Winnebago\nBoone",
                "cities": [
                    "Loves Park"
                ]
            },
            {
                "countyName": "Woodford",
                "cities": [
                    "Bay View Gardens",
                    "Benson",
                    "Congerville",
                    "El Paso",
                    "Eureka†",
                    "Germantown Hills",
                    "Kappa",
                    "Metamora",
                    "Minonk",
                    "Panola",
                    "Roanoke",
                    "Secor",
                    "Spring Bay"
                ]
            },
            {
                "countyName": "Woodford\nMarshall",
                "cities": [
                    "Washburn"
                ]
            },
            {
                "countyName": "Woodford\nTazewell",
                "cities": [
                    "Goodfield"
                ]
            }
        ]
    },
    {
        "stateId": "IN",
        "stateName": "Indiana",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Decatur",
                    "Berne"
                ]
            },
            {
                "countyName": "Allen",
                "cities": [
                    "Fort Wayne",
                    "New Haven",
                    "Huntertown",
                    "Leo-Cedarville",
                    "Woodburn"
                ]
            },
            {
                "countyName": "Bartholomew",
                "cities": [
                    "Columbus",
                    "Clifford"
                ]
            },
            {
                "countyName": "Benton",
                "cities": [
                    "Fowler",
                    "Boswell",
                    "Ambia"
                ]
            },
            {
                "countyName": "Blackford",
                "cities": [
                    "Hartford City",
                    "Montpelier"
                ]
            },
            {
                "countyName": "Blackford, Jay",
                "cities": [
                    "Dunkirk"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Zionsville",
                    "Lebanon",
                    "Whitestown",
                    "Advance"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Delphi",
                    "Camden",
                    "Burlington"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Logansport"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Jeffersonville",
                    "Clarksville",
                    "Sellersburg",
                    "Charlestown",
                    "Borden"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Brazil",
                    "Clay City",
                    "Carbon",
                    "Center Point"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Frankfort",
                    "Colfax"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Alton"
                ]
            },
            {
                "countyName": "Daviess",
                "cities": [
                    "Washington",
                    "Cannelburg",
                    "Alfordsville"
                ]
            },
            {
                "countyName": "Dearborn",
                "cities": [
                    "Lawrenceburg",
                    "Greendale",
                    "Aurora"
                ]
            },
            {
                "countyName": "Decatur",
                "cities": [
                    "Greensburg"
                ]
            },
            {
                "countyName": "DeKalb",
                "cities": [
                    "Auburn",
                    "Garrett",
                    "Butler",
                    "Waterloo",
                    "Corunna",
                    "Altona"
                ]
            },
            {
                "countyName": "DeKalb, Steuben",
                "cities": [
                    "Ashley"
                ]
            },
            {
                "countyName": "Delaware",
                "cities": [
                    "Muncie",
                    "Yorktown",
                    "Daleville"
                ]
            },
            {
                "countyName": "Delaware, Randolph",
                "cities": [
                    "Albany"
                ]
            },
            {
                "countyName": "Dubois",
                "cities": [
                    "Jasper",
                    "Huntingburg",
                    "Ferdinand",
                    "Birdseye"
                ]
            },
            {
                "countyName": "Elkhart",
                "cities": [
                    "Elkhart",
                    "Goshen",
                    "Middlebury",
                    "Bristol"
                ]
            },
            {
                "countyName": "Elkhart, Kosciusko",
                "cities": [
                    "Nappanee"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "Connersville"
                ]
            },
            {
                "countyName": "Floyd",
                "cities": [
                    "New Albany",
                    "Georgetown"
                ]
            },
            {
                "countyName": "Fountain",
                "cities": [
                    "Attica",
                    "Covington",
                    "Veedersburg"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Brookville",
                    "Cedar Grove"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Rochester",
                    "Akron"
                ]
            },
            {
                "countyName": "Gibson",
                "cities": [
                    "Princeton",
                    "Fort Branch",
                    "Oakland City"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Marion",
                    "Gas City",
                    "Upland",
                    "Fairmount",
                    "Jonesboro"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Linton",
                    "Bloomfield",
                    "Jasonville"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Carmel",
                    "Fishers",
                    "Noblesville",
                    "Westfield",
                    "Cicero",
                    "Sheridan",
                    "Arcadia"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Greenfield",
                    "McCordsville",
                    "Fortville"
                ]
            },
            {
                "countyName": "Hancock, Marion",
                "cities": [
                    "Cumberland"
                ]
            },
            {
                "countyName": "Harrison",
                "cities": [
                    "Corydon",
                    "Crandall"
                ]
            },
            {
                "countyName": "Hendricks",
                "cities": [
                    "Plainfield",
                    "Brownsburg",
                    "Avon",
                    "Danville",
                    "Pittsboro",
                    "Coatesville",
                    "Amo"
                ]
            },
            {
                "countyName": "Henry",
                "cities": [
                    "New Castle",
                    "Middletown",
                    "Knightstown",
                    "Cadiz",
                    "Blountsville"
                ]
            },
            {
                "countyName": "Howard",
                "cities": [
                    "Kokomo",
                    "Greentown"
                ]
            },
            {
                "countyName": "Huntington",
                "cities": [
                    "Huntington",
                    "Andrews"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Seymour",
                    "Brownstown",
                    "Crothersville"
                ]
            },
            {
                "countyName": "Jasper",
                "cities": [
                    "Rensselaer",
                    "DeMotte"
                ]
            },
            {
                "countyName": "Jay",
                "cities": [
                    "Portland",
                    "Bryant"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Madison",
                    "Hanover",
                    "Brooksburg"
                ]
            },
            {
                "countyName": "Jennings",
                "cities": [
                    "North Vernon"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Greenwood",
                    "Franklin",
                    "Bargersville",
                    "New Whiteland",
                    "Whiteland"
                ]
            },
            {
                "countyName": "Johnson, Shelby, Bartholomew",
                "cities": [
                    "Edinburgh"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Vincennes",
                    "Bicknell",
                    "Bruceville"
                ]
            },
            {
                "countyName": "Kosciusko",
                "cities": [
                    "Warsaw",
                    "Winona Lake",
                    "Syracuse",
                    "Claypool",
                    "Burket"
                ]
            },
            {
                "countyName": "LaGrange",
                "cities": [
                    "LaGrange"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Hammond",
                    "Gary",
                    "Merrillville",
                    "Crown Point",
                    "Schererville",
                    "Hobart",
                    "East Chicago",
                    "Highland",
                    "Munster",
                    "St. John",
                    "Griffith",
                    "Dyer",
                    "Cedar Lake",
                    "Lake Station",
                    "Lowell",
                    "Winfield",
                    "Whiting"
                ]
            },
            {
                "countyName": "LaPorte",
                "cities": [
                    "Michigan City",
                    "La Porte",
                    "Westville"
                ]
            },
            {
                "countyName": "Lawrence",
                "cities": [
                    "Bedford",
                    "Mitchell"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Anderson",
                    "Alexandria",
                    "Pendleton",
                    "Ingalls",
                    "Country Club Heights"
                ]
            },
            {
                "countyName": "Madison, Delaware",
                "cities": [
                    "Chesterfield"
                ]
            },
            {
                "countyName": "Madison, Tipton",
                "cities": [
                    "Elwood"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Indianapolis",
                    "Lawrence",
                    "Beech Grove",
                    "Speedway",
                    "Southport",
                    "Clermont",
                    "Crows Nest"
                ]
            },
            {
                "countyName": "Marshall",
                "cities": [
                    "Plymouth",
                    "Bremen",
                    "Argos",
                    "Bourbon",
                    "Culver"
                ]
            },
            {
                "countyName": "Martin",
                "cities": [
                    "Loogootee",
                    "Crane"
                ]
            },
            {
                "countyName": "Miami",
                "cities": [
                    "Peru",
                    "Bunker Hill",
                    "Amboy"
                ]
            },
            {
                "countyName": "Miami, Grant",
                "cities": [
                    "Converse"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Bloomington",
                    "Elletsville"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Crawfordsville",
                    "Darlington"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Martinsville",
                    "Mooresville",
                    "Brooklyn",
                    "Bethany"
                ]
            },
            {
                "countyName": "Newton",
                "cities": [
                    "Brook"
                ]
            },
            {
                "countyName": "Noble",
                "cities": [
                    "Kendallville",
                    "Ligonier",
                    "Avilla",
                    "Albion",
                    "Cromwell"
                ]
            },
            {
                "countyName": "Ohio",
                "cities": [
                    "Rising Sun"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Paoli",
                    "Orleans"
                ]
            },
            {
                "countyName": "Owen",
                "cities": [
                    "Spencer"
                ]
            },
            {
                "countyName": "Parke",
                "cities": [
                    "Rockville",
                    "Bloomingdale"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Tell City",
                    "Cannelton"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Petersburg"
                ]
            },
            {
                "countyName": "Porter",
                "cities": [
                    "Portage",
                    "Valparaiso",
                    "Chesterton",
                    "Porter",
                    "Hebron",
                    "Burns Harbor",
                    "Beverly Shores"
                ]
            },
            {
                "countyName": "Posey",
                "cities": [
                    "Mount Vernon",
                    "Cynthiana"
                ]
            },
            {
                "countyName": "Pulaski",
                "cities": [
                    "Winamac"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Greencastle",
                    "Cloverdale",
                    "Bainbridge"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Winchester",
                    "Union City"
                ]
            },
            {
                "countyName": "Ripley",
                "cities": [
                    "Versailles",
                    "Alamo"
                ]
            },
            {
                "countyName": "Ripley, Franklin",
                "cities": [
                    "Batesville"
                ]
            },
            {
                "countyName": "Rush",
                "cities": [
                    "Rushville",
                    "Carthage"
                ]
            },
            {
                "countyName": "Scott",
                "cities": [
                    "Scottsburg",
                    "Austin"
                ]
            },
            {
                "countyName": "Shelby",
                "cities": [
                    "Shelbyville"
                ]
            },
            {
                "countyName": "Spencer",
                "cities": [
                    "Santa Claus",
                    "Rockport",
                    "Dale",
                    "Chrisney"
                ]
            },
            {
                "countyName": "St. Joseph",
                "cities": [
                    "South Bend",
                    "Mishawaka",
                    "Osceola",
                    "Walkerton"
                ]
            },
            {
                "countyName": "Starke",
                "cities": [
                    "Knox"
                ]
            },
            {
                "countyName": "Steuben",
                "cities": [
                    "Angola",
                    "Fremont",
                    "Clear Lake"
                ]
            },
            {
                "countyName": "Sullivan",
                "cities": [
                    "Sullivan",
                    "Carlisle"
                ]
            },
            {
                "countyName": "Tippecanoe",
                "cities": [
                    "Lafayette",
                    "West Lafayette",
                    "Battle Ground",
                    "Clarks Hill"
                ]
            },
            {
                "countyName": "Tipton",
                "cities": [
                    "Tipton"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Liberty"
                ]
            },
            {
                "countyName": "Vanderburgh",
                "cities": [
                    "Evansville"
                ]
            },
            {
                "countyName": "Vermillion",
                "cities": [
                    "Clinton",
                    "Cayuga",
                    "Dana"
                ]
            },
            {
                "countyName": "Vigo",
                "cities": [
                    "Terre Haute",
                    "West Terre Haute"
                ]
            },
            {
                "countyName": "Wabash",
                "cities": [
                    "Wabash",
                    "North Manchester"
                ]
            },
            {
                "countyName": "Warrick",
                "cities": [
                    "Boonville",
                    "Chandler",
                    "Newburgh"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Salem",
                    "Campbellsburg"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Richmond",
                    "Centerville",
                    "Cambridge City",
                    "Boston"
                ]
            },
            {
                "countyName": "Wells",
                "cities": [
                    "Bluffton",
                    "Ossian"
                ]
            },
            {
                "countyName": "White",
                "cities": [
                    "Monticello",
                    "Brookston",
                    "Chalmers",
                    "Burnettsville"
                ]
            },
            {
                "countyName": "Whitley",
                "cities": [
                    "Columbia City",
                    "Churubusco"
                ]
            }
        ]
    },
    {
        "stateId": "KS",
        "stateName": "Kansas",
        "county": [
            {
                "countyName": "Allen",
                "cities": [
                    "Iola †"
                ]
            },
            {
                "countyName": "Atchison",
                "cities": [
                    "Atchison †"
                ]
            },
            {
                "countyName": "Barton",
                "cities": [
                    "Great Bend †"
                ]
            },
            {
                "countyName": "Bourbon",
                "cities": [
                    "Fort Scott †"
                ]
            },
            {
                "countyName": "Butler",
                "cities": [
                    "Andover",
                    "El Dorado †",
                    "Augusta"
                ]
            },
            {
                "countyName": "Cloud",
                "cities": [
                    "Concordia †"
                ]
            },
            {
                "countyName": "Cowley",
                "cities": [
                    "Arkansas City",
                    "Winfield †"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Pittsburg"
                ]
            },
            {
                "countyName": "Dickinson",
                "cities": [
                    "Abilene †"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Lawrence †",
                    "Eudora"
                ]
            },
            {
                "countyName": "Ellis",
                "cities": [
                    "Hays †"
                ]
            },
            {
                "countyName": "Finney",
                "cities": [
                    "Garden City †"
                ]
            },
            {
                "countyName": "Ford",
                "cities": [
                    "Dodge City †"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Ottawa †"
                ]
            },
            {
                "countyName": "Geary",
                "cities": [
                    "Junction City †"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Ulysses †"
                ]
            },
            {
                "countyName": "Harvey",
                "cities": [
                    "Newton †"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Overland Park",
                    "Olathe †",
                    "Shawnee",
                    "Lenexa",
                    "Leawood",
                    "Gardner",
                    "Prairie Village",
                    "Merriam",
                    "Mission",
                    "Spring Hill",
                    "Roeland Park",
                    "De Soto"
                ]
            },
            {
                "countyName": "Labette",
                "cities": [
                    "Parsons"
                ]
            },
            {
                "countyName": "Leavenworth",
                "cities": [
                    "Leavenworth †",
                    "Lansing",
                    "Basehor",
                    "Tonganoxie"
                ]
            },
            {
                "countyName": "Lyon",
                "cities": [
                    "Emporia †"
                ]
            },
            {
                "countyName": "McPherson",
                "cities": [
                    "McPherson †"
                ]
            },
            {
                "countyName": "Miami",
                "cities": [
                    "Paola †"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Coffeyville",
                    "Independence †"
                ]
            },
            {
                "countyName": "Neosho",
                "cities": [
                    "Chanute"
                ]
            },
            {
                "countyName": "Pratt",
                "cities": [
                    "Pratt †"
                ]
            },
            {
                "countyName": "Reno",
                "cities": [
                    "Hutchinson †"
                ]
            },
            {
                "countyName": "Riley",
                "cities": [
                    "Manhattan †"
                ]
            },
            {
                "countyName": "Saline",
                "cities": [
                    "Salina †"
                ]
            },
            {
                "countyName": "Sedgwick",
                "cities": [
                    "Wichita †",
                    "Derby",
                    "Haysville",
                    "Park City",
                    "Bel Aire",
                    "Valley Center",
                    "Mulvane",
                    "Maize",
                    "Goddard"
                ]
            },
            {
                "countyName": "Seward",
                "cities": [
                    "Liberal †"
                ]
            },
            {
                "countyName": "Shawnee",
                "cities": [
                    "Topeka †"
                ]
            },
            {
                "countyName": "Sumner",
                "cities": [
                    "Wellington †"
                ]
            },
            {
                "countyName": "Thomas",
                "cities": [
                    "Colby †"
                ]
            },
            {
                "countyName": "Wyandotte",
                "cities": [
                    "Kansas City †",
                    "Bonner Springs"
                ]
            }
        ]
    },
    {
        "stateId": "KY",
        "stateName": "Kentucky",
        "county": [
            {
                "countyName": "Adair",
                "cities": [
                    "Columbia"
                ]
            },
            {
                "countyName": "Allen",
                "cities": [
                    "Scottsville"
                ]
            },
            {
                "countyName": "Anderson",
                "cities": [
                    "Lawrenceburg[88]"
                ]
            },
            {
                "countyName": "Ballard",
                "cities": [
                    "Barlow[18]",
                    "Blandville",
                    "Kevil",
                    "LaCenter",
                    "Wickliffe"
                ]
            },
            {
                "countyName": "Barren",
                "cities": [
                    "Cave City",
                    "Glasgow",
                    "Park City"
                ]
            },
            {
                "countyName": "Bath",
                "cities": [
                    "Owingsville",
                    "Salt Lick",
                    "Sharpsburg"
                ]
            },
            {
                "countyName": "Bell",
                "cities": [
                    "Middlesboro",
                    "Pineville"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Florence",
                    "Union"
                ]
            },
            {
                "countyName": "Boone\nKenton",
                "cities": [
                    "Walton"
                ]
            },
            {
                "countyName": "Bourbon",
                "cities": [
                    "Millersburg",
                    "North Middletown",
                    "Paris[110]"
                ]
            },
            {
                "countyName": "Boyd",
                "cities": [
                    "Ashland",
                    "Catlettsburg"
                ]
            },
            {
                "countyName": "Boyle",
                "cities": [
                    "Danville",
                    "Junction City",
                    "Perryville"
                ]
            },
            {
                "countyName": "Bracken",
                "cities": [
                    "Augusta",
                    "Brooksville"
                ]
            },
            {
                "countyName": "Bracken\nMason",
                "cities": [
                    "Germantown"
                ]
            },
            {
                "countyName": "Breathitt",
                "cities": [
                    "Jackson"
                ]
            },
            {
                "countyName": "Breckinridge",
                "cities": [
                    "Cloverport",
                    "Hardinsburg",
                    "Irvington"
                ]
            },
            {
                "countyName": "Bullitt",
                "cities": [
                    "Fox Chase",
                    "Hebron Estates",
                    "Hillview",
                    "Hunters Hollow",
                    "Lebanon Junction",
                    "Mount Washington[105]",
                    "Pioneer Village",
                    "Shepherdsville"
                ]
            },
            {
                "countyName": "Butler",
                "cities": [
                    "Morgantown",
                    "Rochester",
                    "Woodbury"
                ]
            },
            {
                "countyName": "Caldwell",
                "cities": [
                    "Fredonia",
                    "Princeton"
                ]
            },
            {
                "countyName": "Calloway",
                "cities": [
                    "Hazel",
                    "Murray"
                ]
            },
            {
                "countyName": "Campbell",
                "cities": [
                    "Alexandria",
                    "Bellevue",
                    "California",
                    "Cold Spring",
                    "Crestview",
                    "Dayton[55]",
                    "Fort Thomas",
                    "Highland Heights[80]",
                    "Melbourne",
                    "Mentor",
                    "Newport",
                    "Silver Grove",
                    "Southgate",
                    "Wilder",
                    "Woodlawn"
                ]
            },
            {
                "countyName": "Carlisle",
                "cities": [
                    "Arlington",
                    "Bardwell"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Carrollton[37]",
                    "Ghent",
                    "Prestonville",
                    "Sanders",
                    "Worthville"
                ]
            },
            {
                "countyName": "Carter",
                "cities": [
                    "Grayson",
                    "Olive Hill"
                ]
            },
            {
                "countyName": "Casey",
                "cities": [
                    "Liberty"
                ]
            },
            {
                "countyName": "Christian",
                "cities": [
                    "Crofton",
                    "Hopkinsville",
                    "LaFayette",
                    "Oak Grove",
                    "Pembroke"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Winchester"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Manchester[94]"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Albany"
                ]
            },
            {
                "countyName": "Crittenden",
                "cities": [
                    "Marion"
                ]
            },
            {
                "countyName": "Cumberland",
                "cities": [
                    "Burkesville"
                ]
            },
            {
                "countyName": "Daviess",
                "cities": [
                    "Owensboro",
                    "Whitesville"
                ]
            },
            {
                "countyName": "Edmonson",
                "cities": [
                    "Brownsville"
                ]
            },
            {
                "countyName": "Elliott",
                "cities": [
                    "Sandy Hook"
                ]
            },
            {
                "countyName": "Estill",
                "cities": [
                    "Irvine",
                    "Ravenna"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "Lexington"
                ]
            },
            {
                "countyName": "Fleming",
                "cities": [
                    "Ewing",
                    "Flemingsburg"
                ]
            },
            {
                "countyName": "Floyd",
                "cities": [
                    "Allen",
                    "Martin",
                    "Prestonsburg[114]",
                    "Wayland",
                    "Wheelwright"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Frankfort"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Fulton",
                    "Hickman"
                ]
            },
            {
                "countyName": "Gallatin",
                "cities": [
                    "Glencoe",
                    "Warsaw[132]"
                ]
            },
            {
                "countyName": "Gallatin\nOwen",
                "cities": [
                    "Sparta"
                ]
            },
            {
                "countyName": "Garrard",
                "cities": [
                    "Lancaster"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Corinth",
                    "Crittenden",
                    "Dry Ridge"
                ]
            },
            {
                "countyName": "Grant\nPendleton",
                "cities": [
                    "Williamstown"
                ]
            },
            {
                "countyName": "Graves",
                "cities": [
                    "Mayfield",
                    "Wingo"
                ]
            },
            {
                "countyName": "Grayson",
                "cities": [
                    "Caneyville",
                    "Clarkson",
                    "Leitchfield"
                ]
            },
            {
                "countyName": "Green",
                "cities": [
                    "Greensburg"
                ]
            },
            {
                "countyName": "Greenup",
                "cities": [
                    "Bellefonte",
                    "Flatwoods",
                    "Greenup[75]",
                    "Raceland[115]",
                    "Russell[117]",
                    "South Shore",
                    "Worthington",
                    "Wurtland"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Hawesville",
                    "Lewisport"
                ]
            },
            {
                "countyName": "Hardin",
                "cities": [
                    "Elizabethtown",
                    "Radcliff",
                    "Sonora",
                    "Vine Grove",
                    "West Point"
                ]
            },
            {
                "countyName": "Hardin\nLaRue",
                "cities": [
                    "Upton"
                ]
            },
            {
                "countyName": "Harlan",
                "cities": [
                    "Benham",
                    "Cumberland",
                    "Evarts",
                    "Harlan[77]",
                    "Loyall",
                    "Lynch"
                ]
            },
            {
                "countyName": "Harrison",
                "cities": [
                    "Berry[23]",
                    "Cynthiana"
                ]
            },
            {
                "countyName": "Hart",
                "cities": [
                    "Bonnieville",
                    "Horse Cave",
                    "Munfordville"
                ]
            },
            {
                "countyName": "Henderson",
                "cities": [
                    "Corydon",
                    "Henderson",
                    "Robards"
                ]
            },
            {
                "countyName": "Henry",
                "cities": [
                    "Campbellsburg",
                    "Eminence",
                    "New Castle",
                    "Smithfield"
                ]
            },
            {
                "countyName": "Henry\nShelby",
                "cities": [
                    "Pleasureville"
                ]
            },
            {
                "countyName": "Hickman",
                "cities": [
                    "Clinton",
                    "Columbus"
                ]
            },
            {
                "countyName": "Hopkins",
                "cities": [
                    "Earlington",
                    "Hanson",
                    "Madisonville",
                    "Mortons Gap",
                    "Nebo",
                    "Nortonville[109]",
                    "St. Charles",
                    "White Plains"
                ]
            },
            {
                "countyName": "Hopkins\nCaldwell",
                "cities": [
                    "Dawson Springs[54]"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "McKee"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Anchorage",
                    "Audubon Park",
                    "Bancroft",
                    "Barbourmeade",
                    "Beechwood Village",
                    "Bellemeade",
                    "Bellewood",
                    "Blue Ridge Manor",
                    "Briarwood",
                    "Broeck Pointe",
                    "Brownsboro Farm",
                    "Brownsboro Village",
                    "Cambridge",
                    "Coldstream",
                    "Creekside",
                    "Crossgate",
                    "Douglass Hills",
                    "Druid Hills",
                    "Fincastle",
                    "Forest Hills",
                    "Glenview",
                    "Glenview Hills",
                    "Glenview Manor",
                    "Goose Creek",
                    "Graymoor-Devondale",
                    "Green Spring",
                    "Heritage Creek[79]",
                    "Hickory Hill",
                    "Hills and Dales",
                    "Hollow Creek",
                    "Hollyvilla",
                    "Houston Acres",
                    "Hurstbourne",
                    "Hurstbourne Acres",
                    "Indian Hills",
                    "Jeffersontown",
                    "Kingsley",
                    "Langdon Place",
                    "Lincolnshire",
                    "Louisville",
                    "Lyndon",
                    "Lynnview",
                    "Manor Creek",
                    "Maryhill Estates",
                    "Meadow Vale",
                    "Meadowbrook Farm",
                    "Meadowview Estates",
                    "Middletown",
                    "Mockingbird Valley",
                    "Moorland",
                    "Murray Hill",
                    "Norbourne Estates",
                    "Northfield",
                    "Norwood",
                    "Old Brownsboro Place",
                    "Parkway Village",
                    "Plantation",
                    "Poplar Hills",
                    "Richlawn",
                    "Riverwood",
                    "Rolling Fields",
                    "Rolling Hills",
                    "St. Matthews",
                    "St. Regis Park",
                    "Seneca Gardens",
                    "Shively",
                    "South Park View",
                    "Spring Mill",
                    "Spring Valley",
                    "Strathmoor Manor",
                    "Strathmoor Village",
                    "Sycamore",
                    "Ten Broeck",
                    "Thornhill",
                    "Watterson Park",
                    "Wellington",
                    "West Buechel",
                    "Westwood",
                    "Wildwood",
                    "Windy Hills",
                    "Woodland Hills",
                    "Woodlawn Park",
                    "Worthington Hills"
                ]
            },
            {
                "countyName": "Jefferson\nOldham",
                "cities": [
                    "Prospect"
                ]
            },
            {
                "countyName": "Jessamine",
                "cities": [
                    "Keene",
                    "Nicholasville",
                    "Wilmore"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Paintsville"
                ]
            },
            {
                "countyName": "Kenton",
                "cities": [
                    "Bromley",
                    "Covington",
                    "Crescent Springs",
                    "Crestview Hills",
                    "Edgewood",
                    "Elsmere",
                    "Erlanger",
                    "Fairview",
                    "Fort Mitchell",
                    "Fort Wright",
                    "Independence",
                    "Kenton Vale",
                    "Lakeside Park",
                    "Ludlow",
                    "Park Hills",
                    "Ryland Heights",
                    "Taylor Mill",
                    "Villa Hills"
                ]
            },
            {
                "countyName": "Knott",
                "cities": [
                    "Hindman",
                    "Pippa Passes"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Barbourville"
                ]
            },
            {
                "countyName": "LaRue",
                "cities": [
                    "Hodgenville"
                ]
            },
            {
                "countyName": "Laurel",
                "cities": [
                    "London"
                ]
            },
            {
                "countyName": "Lawrence",
                "cities": [
                    "Blaine",
                    "Louisa"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Beattyville[20]"
                ]
            },
            {
                "countyName": "Leslie",
                "cities": [
                    "Hyden"
                ]
            },
            {
                "countyName": "Letcher",
                "cities": [
                    "Blackey",
                    "Fleming-Neon",
                    "Jenkins",
                    "Whitesburg"
                ]
            },
            {
                "countyName": "Lewis",
                "cities": [
                    "Concord",
                    "Vanceburg"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Crab Orchard",
                    "Hustonville",
                    "Stanford"
                ]
            },
            {
                "countyName": "Livingston",
                "cities": [
                    "Carrsville",
                    "Grand Rivers",
                    "Salem",
                    "Smithland"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Adairville",
                    "Auburn",
                    "Lewisburg",
                    "Russellville"
                ]
            },
            {
                "countyName": "Lyon",
                "cities": [
                    "Eddyville",
                    "Kuttawa"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Berea",
                    "Richmond"
                ]
            },
            {
                "countyName": "Magoffin",
                "cities": [
                    "Salyersville"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Bradfordsville",
                    "Lebanon",
                    "Loretto",
                    "Raywick",
                    "St. Mary"
                ]
            },
            {
                "countyName": "Marshall",
                "cities": [
                    "Benton",
                    "Calvert City",
                    "Hardin"
                ]
            },
            {
                "countyName": "Martin",
                "cities": [
                    "Inez[84]",
                    "Warfield"
                ]
            },
            {
                "countyName": "Mason",
                "cities": [
                    "Dover",
                    "Maysville"
                ]
            },
            {
                "countyName": "Mason\nRobertson",
                "cities": [
                    "Sardis"
                ]
            },
            {
                "countyName": "McCracken",
                "cities": [
                    "Paducah"
                ]
            },
            {
                "countyName": "McLean",
                "cities": [
                    "Calhoun[31]",
                    "Island",
                    "Livermore",
                    "Sacramento"
                ]
            },
            {
                "countyName": "Meade",
                "cities": [
                    "Brandenburg",
                    "Ekron",
                    "Muldraugh"
                ]
            },
            {
                "countyName": "Menifee",
                "cities": [
                    "Frenchburg"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Burgin",
                    "Harrodsburg"
                ]
            },
            {
                "countyName": "Metcalfe",
                "cities": [
                    "Edmonton"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Fountain Run",
                    "Gamaliel",
                    "Tompkinsville"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Camargo",
                    "Jeffersonville",
                    "Mount Sterling"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "West Liberty"
                ]
            },
            {
                "countyName": "Muhlenberg",
                "cities": [
                    "Bremen[28]",
                    "Central City[41]",
                    "Drakesboro",
                    "Greenville",
                    "Powderly",
                    "South Carrollton"
                ]
            },
            {
                "countyName": "Nelson",
                "cities": [
                    "Bardstown[16]",
                    "Bloomfield[24]",
                    "Fairfield",
                    "New Haven"
                ]
            },
            {
                "countyName": "Nicholas",
                "cities": [
                    "Carlisle"
                ]
            },
            {
                "countyName": "Ohio",
                "cities": [
                    "Beaver Dam",
                    "Centertown[40]",
                    "Fordsville",
                    "Hartford",
                    "McHenry",
                    "Rockport"
                ]
            },
            {
                "countyName": "Oldham",
                "cities": [
                    "Crestwood",
                    "Goshen",
                    "La Grange",
                    "Orchard Grass Hills",
                    "Pewee Valley",
                    "River Bluff"
                ]
            },
            {
                "countyName": "Owen",
                "cities": [
                    "Gratz",
                    "Monterey",
                    "Owenton"
                ]
            },
            {
                "countyName": "Owsley",
                "cities": [
                    "Booneville"
                ]
            },
            {
                "countyName": "Pendleton",
                "cities": [
                    "Butler",
                    "Falmouth"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Buckhorn",
                    "Hazard",
                    "Vicco"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Coal Run Village[46]",
                    "Elkhorn City",
                    "Pikeville[112]"
                ]
            },
            {
                "countyName": "Powell",
                "cities": [
                    "Clay City",
                    "Stanton"
                ]
            },
            {
                "countyName": "Pulaski",
                "cities": [
                    "Burnside",
                    "Ferguson",
                    "Science Hill",
                    "Somerset"
                ]
            },
            {
                "countyName": "Pulaski\nLincoln",
                "cities": [
                    "Eubank[60]"
                ]
            },
            {
                "countyName": "Robertson",
                "cities": [
                    "Mount Olivet"
                ]
            },
            {
                "countyName": "Rockcastle",
                "cities": [
                    "Brodhead",
                    "Livingston",
                    "Mount Vernon"
                ]
            },
            {
                "countyName": "Rowan",
                "cities": [
                    "Lakeview Heights",
                    "Morehead"
                ]
            },
            {
                "countyName": "Russell",
                "cities": [
                    "Jamestown",
                    "Russell Springs"
                ]
            },
            {
                "countyName": "Scott",
                "cities": [
                    "Georgetown[67]",
                    "Sadieville",
                    "Stamping Ground"
                ]
            },
            {
                "countyName": "Shelby",
                "cities": [
                    "Shelbyville",
                    "Simpsonville"
                ]
            },
            {
                "countyName": "Simpson",
                "cities": [
                    "Franklin"
                ]
            },
            {
                "countyName": "Spencer",
                "cities": [
                    "Taylorsville"
                ]
            },
            {
                "countyName": "Taylor",
                "cities": [
                    "Campbellsville"
                ]
            },
            {
                "countyName": "Todd",
                "cities": [
                    "Elkton",
                    "Guthrie",
                    "Trenton"
                ]
            },
            {
                "countyName": "Trigg",
                "cities": [
                    "Cadiz"
                ]
            },
            {
                "countyName": "Trimble",
                "cities": [
                    "Bedford",
                    "Milton"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Morganfield",
                    "Sturgis",
                    "Uniontown",
                    "Waverly"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Bowling Green[26]",
                    "Oakland",
                    "Plum Springs",
                    "Smiths Grove",
                    "Woodburn"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Mackville",
                    "Springfield",
                    "Willisburg"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Monticello"
                ]
            },
            {
                "countyName": "Webster",
                "cities": [
                    "Clay",
                    "Dixon",
                    "Providence",
                    "Sebree",
                    "Slaughters[125]",
                    "Wheatcroft"
                ]
            },
            {
                "countyName": "Whitley",
                "cities": [
                    "Williamsburg"
                ]
            },
            {
                "countyName": "Whitley\nKnox\nLaurel",
                "cities": [
                    "Corbin"
                ]
            },
            {
                "countyName": "Wolfe",
                "cities": [
                    "Campton"
                ]
            },
            {
                "countyName": "Woodford",
                "cities": [
                    "Midway",
                    "Versailles"
                ]
            }
        ]
    },
    {
        "stateId": "LA",
        "stateName": "Louisiana",
        "county": [
            {
                "countyName": "Acadia",
                "cities": [
                    "Church Point",
                    "Crowley†",
                    "Estherwood",
                    "Iota",
                    "Mermentau",
                    "Morse",
                    "Rayne"
                ]
            },
            {
                "countyName": "Acadia, Evangeline",
                "cities": [
                    "Basile"
                ]
            },
            {
                "countyName": "Acadia, Lafayette",
                "cities": [
                    "Duson"
                ]
            },
            {
                "countyName": "Acadia, St. Landry",
                "cities": [
                    "Eunice"
                ]
            },
            {
                "countyName": "Allen",
                "cities": [
                    "Elizabeth",
                    "Kinder",
                    "Oakdale",
                    "Oberlin†",
                    "Reeves"
                ]
            },
            {
                "countyName": "Ascension",
                "cities": [
                    "Donaldsonville†",
                    "Gonzales",
                    "Sorrento"
                ]
            },
            {
                "countyName": "Assumption",
                "cities": [
                    "Napoleonville†"
                ]
            },
            {
                "countyName": "Avoyelles",
                "cities": [
                    "Bunkie",
                    "Cottonport",
                    "Evergreen",
                    "Hessmer",
                    "Mansura",
                    "Marksville†",
                    "Moreauville",
                    "Plaucheville",
                    "Simmesport"
                ]
            },
            {
                "countyName": "Beauregard",
                "cities": [
                    "Merryville"
                ]
            },
            {
                "countyName": "Beauregard, Vernon",
                "cities": [
                    "DeRidder†"
                ]
            },
            {
                "countyName": "Bienville",
                "cities": [
                    "Arcadia†",
                    "Bienville",
                    "Bryceland",
                    "Castor",
                    "Gibsland",
                    "Jamestown",
                    "Lucky",
                    "Mount Lebanon",
                    "Ringgold",
                    "Saline"
                ]
            },
            {
                "countyName": "Bossier",
                "cities": [
                    "Benton†",
                    "Bossier City",
                    "Haughton",
                    "Plain Dealing"
                ]
            },
            {
                "countyName": "Bossier, Caddo",
                "cities": [
                    "Shreveport†"
                ]
            },
            {
                "countyName": "Caddo",
                "cities": [
                    "Belcher",
                    "Blanchard",
                    "Gilliam",
                    "Greenwood",
                    "Hosston",
                    "Ida",
                    "Mooringsport",
                    "Oil City",
                    "Rodessa",
                    "Vivian"
                ]
            },
            {
                "countyName": "Calcasieu",
                "cities": [
                    "DeQuincy",
                    "Iowa",
                    "Lake Charles†",
                    "Sulphur",
                    "Vinton",
                    "Westlake"
                ]
            },
            {
                "countyName": "Caldwell",
                "cities": [
                    "Clarks",
                    "Columbia†",
                    "Grayson"
                ]
            },
            {
                "countyName": "Catahoula",
                "cities": [
                    "Harrisonburg†",
                    "Jonesville",
                    "Sicily Island"
                ]
            },
            {
                "countyName": "Claiborne",
                "cities": [
                    "Athens",
                    "Haynesville",
                    "Homer†",
                    "Lisbon"
                ]
            },
            {
                "countyName": "Claiborne, Union",
                "cities": [
                    "Junction City"
                ]
            },
            {
                "countyName": "Concordia",
                "cities": [
                    "Clayton",
                    "Ferriday",
                    "Ridgecrest",
                    "Vidalia†"
                ]
            },
            {
                "countyName": "De Soto",
                "cities": [
                    "Grand Cane",
                    "Keachi",
                    "Logansport",
                    "Longstreet",
                    "Mansfield†",
                    "South Mansfield",
                    "Stanley",
                    "Stonewall"
                ]
            },
            {
                "countyName": "East Baton Rouge",
                "cities": [
                    "Baker",
                    "Baton Rouge‡[a]",
                    "Central",
                    "Zachary"
                ]
            },
            {
                "countyName": "East Carroll",
                "cities": [
                    "Lake Providence†"
                ]
            },
            {
                "countyName": "East Feliciana",
                "cities": [
                    "Clinton†",
                    "Jackson",
                    "Norwood",
                    "Slaughter",
                    "Wilson"
                ]
            },
            {
                "countyName": "Evangeline",
                "cities": [
                    "Chataignier",
                    "Mamou",
                    "Pine Prairie",
                    "Turkey Creek",
                    "Ville Platte†"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Baskin",
                    "Gilbert",
                    "Winnsboro†",
                    "Wisner"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Colfax†",
                    "Creola",
                    "Dry Prong",
                    "Georgetown",
                    "Montgomery",
                    "Pollock"
                ]
            },
            {
                "countyName": "Iberia",
                "cities": [
                    "Jeanerette",
                    "Loreauville",
                    "New Iberia†"
                ]
            },
            {
                "countyName": "Iberia, Vermilion",
                "cities": [
                    "Delcambre"
                ]
            },
            {
                "countyName": "Iberville",
                "cities": [
                    "Grosse Tete",
                    "Maringouin",
                    "Plaquemine†",
                    "Rosedale",
                    "St. Gabriel",
                    "White Castle"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Chatham",
                    "East Hodge",
                    "Eros",
                    "Hodge",
                    "Jonesboro†",
                    "North Hodge",
                    "Quitman"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Grand Isle",
                    "Gretna†",
                    "Harahan",
                    "Jean Lafitte",
                    "Kenner",
                    "Westwego"
                ]
            },
            {
                "countyName": "Jefferson Davis",
                "cities": [
                    "Elton",
                    "Fenton",
                    "Jennings†",
                    "Lake Arthur",
                    "Welsh"
                ]
            },
            {
                "countyName": "La Salle",
                "cities": [
                    "Jena†",
                    "Olla",
                    "Urania"
                ]
            },
            {
                "countyName": "La Salle, Winn",
                "cities": [
                    "Tullos"
                ]
            },
            {
                "countyName": "Lafayette",
                "cities": [
                    "Carencro",
                    "Lafayette†[c]",
                    "Scott",
                    "Youngsville"
                ]
            },
            {
                "countyName": "Lafayette, St. Martin",
                "cities": [
                    "Broussard"
                ]
            },
            {
                "countyName": "Lafourche",
                "cities": [
                    "Golden Meadow",
                    "Lockport",
                    "Thibodaux†"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Choudrant",
                    "Dubach",
                    "Grambling",
                    "Ruston†",
                    "Simsboro",
                    "Vienna"
                ]
            },
            {
                "countyName": "Lincoln, Union",
                "cities": [
                    "Downsville"
                ]
            },
            {
                "countyName": "Livingston",
                "cities": [
                    "Albany",
                    "Denham Springs",
                    "French Settlement",
                    "Killian",
                    "Livingston†",
                    "Port Vincent",
                    "Springfield",
                    "Walker"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Delta",
                    "Mound",
                    "Richmond",
                    "Tallulah†"
                ]
            },
            {
                "countyName": "Morehouse",
                "cities": [
                    "Bastrop†",
                    "Bonita",
                    "Collinston",
                    "Mer Rouge",
                    "Oak Ridge"
                ]
            },
            {
                "countyName": "Natchitoches",
                "cities": [
                    "Ashland",
                    "Campti",
                    "Clarence",
                    "Goldonna",
                    "Natchez",
                    "Natchitoches†",
                    "Powhatan",
                    "Provencal",
                    "Robeline"
                ]
            },
            {
                "countyName": "Orleans",
                "cities": [
                    "New Orleans†"
                ]
            },
            {
                "countyName": "Ouachita",
                "cities": [
                    "Monroe†",
                    "Richwood",
                    "Sterlington",
                    "West Monroe"
                ]
            },
            {
                "countyName": "Pointe Coupee",
                "cities": [
                    "Fordoche",
                    "Livonia",
                    "Morganza",
                    "New Roads†"
                ]
            },
            {
                "countyName": "Rapides",
                "cities": [
                    "Alexandria†",
                    "Ball",
                    "Boyce",
                    "Cheneyville",
                    "Forest Hill",
                    "Glenmora",
                    "Lecompte",
                    "McNary",
                    "Pineville",
                    "Woodworth"
                ]
            },
            {
                "countyName": "Red River",
                "cities": [
                    "Coushatta†",
                    "Edgefield",
                    "Hall Summit",
                    "Martin"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Delhi",
                    "Mangham",
                    "Rayville†"
                ]
            },
            {
                "countyName": "Sabine",
                "cities": [
                    "Converse",
                    "Fisher",
                    "Florien",
                    "Many†",
                    "Noble",
                    "Pleasant Hill",
                    "Zwolle"
                ]
            },
            {
                "countyName": "St. Helena",
                "cities": [
                    "Greensburg†",
                    "Montpelier"
                ]
            },
            {
                "countyName": "St. James",
                "cities": [
                    "Gramercy",
                    "Lutcher"
                ]
            },
            {
                "countyName": "St. Landry",
                "cities": [
                    "Cankton",
                    "Grand Coteau",
                    "Krotz Springs",
                    "Leonville",
                    "Melville",
                    "Opelousas†",
                    "Palmetto",
                    "Port Barre",
                    "Sunset",
                    "Washington"
                ]
            },
            {
                "countyName": "St. Landry, St. Martin",
                "cities": [
                    "Arnaudville"
                ]
            },
            {
                "countyName": "St. Martin",
                "cities": [
                    "Breaux Bridge",
                    "Henderson",
                    "Parks",
                    "St. Martinville†"
                ]
            },
            {
                "countyName": "St. Martin, St. Mary",
                "cities": [
                    "Morgan City"
                ]
            },
            {
                "countyName": "St. Mary",
                "cities": [
                    "Baldwin",
                    "Berwick",
                    "Franklin†",
                    "Patterson"
                ]
            },
            {
                "countyName": "St. Tammany",
                "cities": [
                    "Abita Springs",
                    "Covington†",
                    "Folsom",
                    "Madisonville",
                    "Mandeville",
                    "Pearl River",
                    "Slidell",
                    "Sun"
                ]
            },
            {
                "countyName": "Tangipahoa",
                "cities": [
                    "Amite City†",
                    "Hammond",
                    "Independence",
                    "Kentwood",
                    "Ponchatoula",
                    "Roseland",
                    "Tangipahoa",
                    "Tickfaw"
                ]
            },
            {
                "countyName": "Tensas",
                "cities": [
                    "Newellton",
                    "St. Joseph†",
                    "Waterproof"
                ]
            },
            {
                "countyName": "Terrebonne",
                "cities": [
                    "Houma†[b]"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Bernice",
                    "Farmerville†",
                    "Lillie",
                    "Marion",
                    "Spearsville"
                ]
            },
            {
                "countyName": "Vermilion",
                "cities": [
                    "Abbeville†",
                    "Erath",
                    "Gueydan",
                    "Kaplan",
                    "Maurice"
                ]
            },
            {
                "countyName": "Vernon",
                "cities": [
                    "Anacoco",
                    "Hornbeck",
                    "Leesville†",
                    "New Llano",
                    "Rosepine",
                    "Simpson"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Angie",
                    "Bogalusa",
                    "Franklinton†",
                    "Varnado"
                ]
            },
            {
                "countyName": "Webster",
                "cities": [
                    "Cotton Valley",
                    "Cullen",
                    "Dixie Inn",
                    "Doyline",
                    "Dubberly",
                    "Heflin",
                    "Minden†",
                    "Sarepta",
                    "Shongaloo",
                    "Sibley",
                    "Springhill"
                ]
            },
            {
                "countyName": "West Baton Rouge",
                "cities": [
                    "Addis",
                    "Brusly",
                    "Port Allen†"
                ]
            },
            {
                "countyName": "West Carroll",
                "cities": [
                    "Epps",
                    "Forest",
                    "Kilbourne",
                    "Oak Grove†",
                    "Pioneer"
                ]
            },
            {
                "countyName": "West Feliciana",
                "cities": [
                    "St. Francisville†"
                ]
            },
            {
                "countyName": "Winn",
                "cities": [
                    "Atlanta",
                    "Calvin",
                    "Dodson",
                    "Sikes",
                    "Winnfield†"
                ]
            }
        ]
    },
    {
        "stateId": "MA",
        "stateName": "Massachusetts",
        "county": [
            {
                "countyName": "Barnstable",
                "cities": [
                    "Bourne",
                    "Brewster",
                    "Chatham",
                    "Dennis",
                    "Eastham",
                    "Falmouth",
                    "Harwich",
                    "Mashpee",
                    "Orleans",
                    "Provincetown",
                    "Sandwich",
                    "Truro",
                    "Wellfleet",
                    "Yarmouth"
                ]
            },
            {
                "countyName": "Barnstable (seat)",
                "cities": [
                    "Barnstable"
                ]
            },
            {
                "countyName": "Berkshire",
                "cities": [
                    "Adams",
                    "Alford",
                    "Becket",
                    "Cheshire",
                    "Clarksburg",
                    "Dalton",
                    "Egremont",
                    "Florida",
                    "Great Barrington",
                    "Hancock",
                    "Hinsdale",
                    "Lanesborough",
                    "Lee",
                    "Lenox",
                    "Monterey",
                    "Mount Washington",
                    "New Ashford",
                    "New Marlborough",
                    "North Adams",
                    "Otis",
                    "Peru",
                    "Richmond",
                    "Sandisfield",
                    "Savoy",
                    "Sheffield",
                    "Stockbridge",
                    "Tyringham",
                    "Washington",
                    "West Stockbridge",
                    "Williamstown",
                    "Windsor"
                ]
            },
            {
                "countyName": "Berkshire (seat)",
                "cities": [
                    "Pittsfield"
                ]
            },
            {
                "countyName": "Bristol",
                "cities": [
                    "Acushnet",
                    "Attleboro",
                    "Berkley",
                    "Dartmouth",
                    "Dighton",
                    "Easton",
                    "Fairhaven",
                    "Fall River",
                    "Freetown",
                    "Mansfield",
                    "New Bedford",
                    "North Attleborough",
                    "Norton",
                    "Raynham",
                    "Rehoboth",
                    "Seekonk",
                    "Somerset",
                    "Swansea",
                    "Westport"
                ]
            },
            {
                "countyName": "Bristol (seat)",
                "cities": [
                    "Taunton"
                ]
            },
            {
                "countyName": "Dukes",
                "cities": [
                    "Aquinnah",
                    "Chilmark",
                    "Gosnold",
                    "Oak Bluffs",
                    "Tisbury",
                    "West Tisbury"
                ]
            },
            {
                "countyName": "Dukes (seat)",
                "cities": [
                    "Edgartown"
                ]
            },
            {
                "countyName": "Essex",
                "cities": [
                    "Amesbury",
                    "Andover",
                    "Beverly",
                    "Boxford",
                    "Danvers",
                    "Essex",
                    "Georgetown",
                    "Gloucester",
                    "Groveland",
                    "Hamilton",
                    "Haverhill",
                    "Ipswich",
                    "Lynn",
                    "Lynnfield",
                    "Manchester-by-the-Sea",
                    "Marblehead",
                    "Merrimac",
                    "Methuen",
                    "Middleton",
                    "Nahant",
                    "Newbury",
                    "Newburyport",
                    "North Andover",
                    "Peabody",
                    "Rockport",
                    "Rowley",
                    "Salisbury",
                    "Saugus",
                    "Swampscott",
                    "Topsfield",
                    "Wenham",
                    "West Newbury"
                ]
            },
            {
                "countyName": "Essex (seat)",
                "cities": [
                    "Lawrence",
                    "Salem"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Ashfield",
                    "Bernardston",
                    "Buckland",
                    "Charlemont",
                    "Colrain",
                    "Conway",
                    "Deerfield",
                    "Erving",
                    "Gill",
                    "Hawley",
                    "Heath",
                    "Leverett",
                    "Leyden",
                    "Monroe",
                    "Montague",
                    "New Salem",
                    "Northfield",
                    "Orange",
                    "Rowe",
                    "Shelburne",
                    "Shutesbury",
                    "Sunderland",
                    "Warwick",
                    "Wendell",
                    "Whately"
                ]
            },
            {
                "countyName": "Franklin (seat)",
                "cities": [
                    "Greenfield"
                ]
            },
            {
                "countyName": "Hampden",
                "cities": [
                    "Agawam",
                    "Blandford",
                    "Brimfield",
                    "Chester",
                    "Chicopee",
                    "East Longmeadow",
                    "Granville",
                    "Hampden",
                    "Holland",
                    "Holyoke",
                    "Longmeadow",
                    "Ludlow",
                    "Monson",
                    "Montgomery",
                    "Palmer",
                    "Russell",
                    "Southwick",
                    "Tolland",
                    "Wales",
                    "West Springfield",
                    "Westfield",
                    "Wilbraham"
                ]
            },
            {
                "countyName": "Hampden (seat)",
                "cities": [
                    "Springfield"
                ]
            },
            {
                "countyName": "Hampshire",
                "cities": [
                    "Amherst",
                    "Belchertown",
                    "Chesterfield",
                    "Cummington",
                    "Easthampton",
                    "Goshen",
                    "Granby",
                    "Hadley",
                    "Hatfield",
                    "Huntington",
                    "Middlefield",
                    "Pelham",
                    "Plainfield",
                    "South Hadley",
                    "Southampton",
                    "Ware",
                    "Westhampton",
                    "Williamsburg",
                    "Worthington"
                ]
            },
            {
                "countyName": "Hampshire (seat)",
                "cities": [
                    "Northampton"
                ]
            },
            {
                "countyName": "Middlesex",
                "cities": [
                    "Acton",
                    "Arlington",
                    "Ashby",
                    "Ashland",
                    "Ayer",
                    "Bedford",
                    "Belmont",
                    "Billerica",
                    "Boxborough",
                    "Burlington",
                    "Carlisle",
                    "Chelmsford",
                    "Concord",
                    "Dracut",
                    "Dunstable",
                    "Everett",
                    "Framingham",
                    "Groton",
                    "Holliston",
                    "Hopkinton",
                    "Hudson",
                    "Lexington",
                    "Lincoln",
                    "Littleton",
                    "Malden",
                    "Marlborough",
                    "Maynard",
                    "Medford",
                    "Melrose",
                    "Natick",
                    "Newton",
                    "North Reading",
                    "Pepperell",
                    "Reading",
                    "Sherborn",
                    "Shirley",
                    "Somerville",
                    "Stoneham",
                    "Stow",
                    "Sudbury",
                    "Tewksbury",
                    "Townsend",
                    "Tyngsborough",
                    "Wakefield",
                    "Waltham",
                    "Watertown",
                    "Wayland",
                    "Westford",
                    "Weston",
                    "Wilmington",
                    "Winchester",
                    "Woburn"
                ]
            },
            {
                "countyName": "Middlesex (seat)",
                "cities": [
                    "Cambridge",
                    "Lowell"
                ]
            },
            {
                "countyName": "Nantucket (seat)",
                "cities": [
                    "Nantucket"
                ]
            },
            {
                "countyName": "Norfolk",
                "cities": [
                    "Avon",
                    "Bellingham",
                    "Braintree",
                    "Brookline",
                    "Canton",
                    "Cohasset",
                    "Dover",
                    "Foxborough",
                    "Franklin",
                    "Holbrook",
                    "Medfield",
                    "Medway",
                    "Millis",
                    "Milton",
                    "Needham",
                    "Norfolk",
                    "Norwood",
                    "Plainville",
                    "Quincy",
                    "Randolph",
                    "Sharon",
                    "Stoughton",
                    "Walpole",
                    "Wellesley",
                    "Westwood",
                    "Weymouth",
                    "Wrentham"
                ]
            },
            {
                "countyName": "Norfolk (seat)",
                "cities": [
                    "Dedham"
                ]
            },
            {
                "countyName": "Plymouth",
                "cities": [
                    "Abington",
                    "Bridgewater",
                    "Carver",
                    "Duxbury",
                    "East Bridgewater",
                    "Halifax",
                    "Hanover",
                    "Hanson",
                    "Hingham",
                    "Hull",
                    "Kingston",
                    "Lakeville",
                    "Marion",
                    "Marshfield",
                    "Mattapoisett",
                    "Middleborough",
                    "Norwell",
                    "Pembroke",
                    "Plympton",
                    "Rochester",
                    "Rockland",
                    "Scituate",
                    "Wareham",
                    "West Bridgewater",
                    "Whitman"
                ]
            },
            {
                "countyName": "Plymouth (seat)",
                "cities": [
                    "Brockton",
                    "Plymouth"
                ]
            },
            {
                "countyName": "Suffolk",
                "cities": [
                    "Chelsea",
                    "Revere",
                    "Winthrop"
                ]
            },
            {
                "countyName": "Suffolk (seat)",
                "cities": [
                    "Boston"
                ]
            },
            {
                "countyName": "Worcester",
                "cities": [
                    "Ashburnham",
                    "Athol",
                    "Auburn",
                    "Barre",
                    "Berlin",
                    "Blackstone",
                    "Bolton",
                    "Boylston",
                    "Brookfield",
                    "Charlton",
                    "Clinton",
                    "Douglas",
                    "Dudley",
                    "East Brookfield",
                    "Fitchburg",
                    "Gardner",
                    "Grafton",
                    "Hardwick",
                    "Harvard",
                    "Holden",
                    "Hopedale",
                    "Hubbardston",
                    "Lancaster",
                    "Leicester",
                    "Leominster",
                    "Lunenburg",
                    "Mendon",
                    "Milford",
                    "Millbury",
                    "Millville",
                    "New Braintree",
                    "North Brookfield",
                    "Northborough",
                    "Northbridge",
                    "Oakham",
                    "Oxford",
                    "Paxton",
                    "Petersham",
                    "Phillipston",
                    "Princeton",
                    "Royalston",
                    "Rutland",
                    "Shrewsbury",
                    "Southborough",
                    "Southbridge",
                    "Spencer",
                    "Sterling",
                    "Sturbridge",
                    "Sutton",
                    "Templeton",
                    "Upton",
                    "Uxbridge",
                    "Warren",
                    "Webster",
                    "West Boylston",
                    "West Brookfield",
                    "Westborough",
                    "Westminster",
                    "Winchendon"
                ]
            },
            {
                "countyName": "Worcester (seat)",
                "cities": [
                    "Worcester"
                ]
            }
        ]
    },
    {
        "stateId": "MD",
        "stateName": "Maryland",
        "county": [
            {
                "countyName": "Allegany",
                "cities": [
                    "Barton",
                    "Cumberland†",
                    "Frostburg",
                    "Lonaconing",
                    "Luke",
                    "Midland",
                    "Westernport"
                ]
            },
            {
                "countyName": "Anne Arundel",
                "cities": [
                    "Annapolis‡",
                    "Highland Beach"
                ]
            },
            {
                "countyName": "Calvert",
                "cities": [
                    "Chesapeake Beach",
                    "North Beach"
                ]
            },
            {
                "countyName": "Caroline",
                "cities": [
                    "Denton†",
                    "Federalsburg",
                    "Goldsboro",
                    "Greensboro",
                    "Henderson",
                    "Hillsboro",
                    "Marydel",
                    "Preston",
                    "Ridgely"
                ]
            },
            {
                "countyName": "Caroline, Queen Anne's",
                "cities": [
                    "Templeville"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Hampstead",
                    "Manchester",
                    "New Windsor",
                    "Sykesville",
                    "Taneytown",
                    "Union Bridge",
                    "Westminster†"
                ]
            },
            {
                "countyName": "Carroll, Frederick",
                "cities": [
                    "Mount Airy"
                ]
            },
            {
                "countyName": "Cecil",
                "cities": [
                    "Cecilton",
                    "Charlestown",
                    "Chesapeake City",
                    "Elkton†",
                    "North East",
                    "Perryville",
                    "Port Deposit",
                    "Rising Sun"
                ]
            },
            {
                "countyName": "Charles",
                "cities": [
                    "Indian Head",
                    "La Plata†",
                    "Port Tobacco Village"
                ]
            },
            {
                "countyName": "Dorchester",
                "cities": [
                    "Brookview",
                    "Cambridge†",
                    "Church Creek",
                    "East New Market",
                    "Eldorado",
                    "Galestown",
                    "Hurlock",
                    "Secretary",
                    "Vienna"
                ]
            },
            {
                "countyName": "Frederick",
                "cities": [
                    "Brunswick",
                    "Burkittsville",
                    "Emmitsburg",
                    "Frederick†",
                    "Middletown",
                    "Myersville",
                    "New Market",
                    "Rosemont",
                    "Thurmont",
                    "Walkersville",
                    "Woodsboro"
                ]
            },
            {
                "countyName": "Garrett",
                "cities": [
                    "Accident",
                    "Deer Park",
                    "Friendsville",
                    "Grantsville",
                    "Kitzmiller",
                    "Loch Lynn Heights",
                    "Mountain Lake Park",
                    "Oakland†"
                ]
            },
            {
                "countyName": "Harford",
                "cities": [
                    "Aberdeen",
                    "Bel Air†",
                    "Havre de Grace"
                ]
            },
            {
                "countyName": "Kent",
                "cities": [
                    "Betterton",
                    "Chestertown†",
                    "Galena",
                    "Rock Hall"
                ]
            },
            {
                "countyName": "Kent, Queen Anne's",
                "cities": [
                    "Millington"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Barnesville",
                    "Brookeville",
                    "Chevy Chase",
                    "Chevy Chase Section Five",
                    "Chevy Chase Section Three",
                    "Chevy Chase View",
                    "Chevy Chase Village",
                    "Gaithersburg",
                    "Garrett Park",
                    "Glen Echo",
                    "Kensington",
                    "Laytonsville",
                    "Martin's Additions",
                    "North Chevy Chase",
                    "Poolesville",
                    "Rockville†",
                    "Somerset",
                    "Takoma Park",
                    "Washington Grove"
                ]
            },
            {
                "countyName": "Prince George's",
                "cities": [
                    "Berwyn Heights",
                    "Bladensburg",
                    "Bowie",
                    "Brentwood",
                    "Capitol Heights",
                    "Cheverly",
                    "College Park",
                    "Colmar Manor",
                    "Cottage City",
                    "District Heights",
                    "Eagle Harbor",
                    "Edmonston",
                    "Fairmount Heights",
                    "Forest Heights",
                    "Glenarden",
                    "Greenbelt",
                    "Hyattsville",
                    "Landover Hills",
                    "Laurel",
                    "Morningside",
                    "Mount Rainier",
                    "New Carrollton",
                    "North Brentwood",
                    "Riverdale Park",
                    "Seat Pleasant",
                    "University Park",
                    "Upper Marlboro†"
                ]
            },
            {
                "countyName": "Queen Anne's",
                "cities": [
                    "Barclay",
                    "Centreville†",
                    "Church Hill",
                    "Queenstown",
                    "Sudlersville"
                ]
            },
            {
                "countyName": "Queen Anne's, Talbot",
                "cities": [
                    "Queen Anne"
                ]
            },
            {
                "countyName": "Somerset",
                "cities": [
                    "Crisfield",
                    "Princess Anne†"
                ]
            },
            {
                "countyName": "St. Mary's",
                "cities": [
                    "Leonardtown†"
                ]
            },
            {
                "countyName": "Talbot",
                "cities": [
                    "Easton†",
                    "Oxford",
                    "St. Michaels",
                    "Trappe"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Boonsboro",
                    "Clear Spring",
                    "Funkstown",
                    "Hagerstown†",
                    "Hancock",
                    "Keedysville",
                    "Sharpsburg",
                    "Smithsburg",
                    "Williamsport"
                ]
            },
            {
                "countyName": "Wicomico",
                "cities": [
                    "Delmar",
                    "Fruitland",
                    "Hebron",
                    "Mardela Springs",
                    "Pittsville",
                    "Salisbury†",
                    "Sharptown",
                    "Willards"
                ]
            },
            {
                "countyName": "Worcester",
                "cities": [
                    "Berlin",
                    "Ocean City",
                    "Pocomoke City",
                    "Snow Hill†"
                ]
            }
        ]
    },
    {
        "stateId": "ME",
        "stateName": "Maine",
        "county": [
            {
                "countyName": "Androscoggin",
                "cities": [
                    "Durham",
                    "Greene",
                    "Leeds",
                    "Lewiston",
                    "Lisbon",
                    "Livermore",
                    "Livermore Falls",
                    "Mechanic Falls",
                    "Minot",
                    "Poland",
                    "Sabattus",
                    "Turner",
                    "Wales"
                ]
            },
            {
                "countyName": "Androscoggin (seat)",
                "cities": [
                    "Auburn"
                ]
            },
            {
                "countyName": "Aroostook",
                "cities": [
                    "Allagash",
                    "Amity",
                    "Ashland",
                    "Blaine",
                    "Bridgewater",
                    "Caribou",
                    "Castle Hill",
                    "Caswell",
                    "Chapman",
                    "Crystal",
                    "Cyr Plantation",
                    "Dyer Brook",
                    "Eagle Lake",
                    "Easton",
                    "Fort Fairfield",
                    "Fort Kent",
                    "Frenchville",
                    "Garfield Plantation",
                    "Glenwood Plantation",
                    "Grand Isle",
                    "Hamlin",
                    "Hammond",
                    "Haynesville",
                    "Hersey",
                    "Hodgdon",
                    "Island Falls",
                    "Limestone",
                    "Linneus",
                    "Littleton",
                    "Ludlow",
                    "Macwahoc Plantation",
                    "Madawaska",
                    "Mapleton",
                    "Mars Hill",
                    "Masardis",
                    "Merrill",
                    "Monticello",
                    "Moro Plantation",
                    "Nashville Plantation",
                    "New Canada",
                    "New Limerick",
                    "New Sweden",
                    "Oakfield",
                    "Orient",
                    "Perham",
                    "Portage Lake",
                    "Presque Isle",
                    "Reed Plantation",
                    "Saint John Plantation",
                    "Sherman",
                    "Smyrna",
                    "St. Agatha",
                    "St. Francis",
                    "Stockholm",
                    "Van Buren",
                    "Wade",
                    "Wallagrass",
                    "Washburn",
                    "Westfield",
                    "Westmanland",
                    "Weston",
                    "Winterville Plantation",
                    "Woodland"
                ]
            },
            {
                "countyName": "Aroostook (seat)",
                "cities": [
                    "Houlton"
                ]
            },
            {
                "countyName": "Cumberland",
                "cities": [
                    "Baldwin",
                    "Bridgton",
                    "Brunswick",
                    "Cape Elizabeth",
                    "Casco",
                    "Chebeague Island",
                    "Cumberland",
                    "Falmouth",
                    "Freeport",
                    "Frye Island",
                    "Gorham",
                    "Gray",
                    "Harpswell",
                    "Harrison",
                    "Long Island",
                    "Naples",
                    "New Gloucester",
                    "North Yarmouth",
                    "Pownal",
                    "Raymond",
                    "Scarborough",
                    "Sebago",
                    "South Portland",
                    "Standish",
                    "Westbrook",
                    "Windham",
                    "Yarmouth"
                ]
            },
            {
                "countyName": "Cumberland (seat)",
                "cities": [
                    "Portland"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Avon",
                    "Carrabassett Valley",
                    "Carthage",
                    "Chesterville",
                    "Coplin Plantation",
                    "Dallas Plantation",
                    "Eustis",
                    "Industry",
                    "Jay",
                    "Kingfield",
                    "New Sharon",
                    "New Vineyard",
                    "Phillips",
                    "Rangeley",
                    "Rangeley Plantation",
                    "Sandy River Plantation",
                    "Strong",
                    "Temple",
                    "Weld",
                    "Wilton"
                ]
            },
            {
                "countyName": "Franklin (seat)",
                "cities": [
                    "Farmington"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Amherst",
                    "Aurora",
                    "Bar Harbor",
                    "Blue Hill",
                    "Brooklin",
                    "Brooksville",
                    "Bucksport",
                    "Castine",
                    "Cranberry Isles",
                    "Dedham",
                    "Deer Isle",
                    "Eastbrook",
                    "Franklin",
                    "Frenchboro",
                    "Gouldsboro",
                    "Great Pond",
                    "Hancock",
                    "Lamoine",
                    "Mariaville",
                    "Mount Desert",
                    "Orland",
                    "Osborn",
                    "Otis",
                    "Penobscot",
                    "Sedgwick",
                    "Sorrento",
                    "Southwest Harbor",
                    "Stonington",
                    "Sullivan",
                    "Surry",
                    "Swan's Island",
                    "Tremont",
                    "Trenton",
                    "Verona Island",
                    "Waltham",
                    "Winter Harbor"
                ]
            },
            {
                "countyName": "Hancock (seat)",
                "cities": [
                    "Ellsworth"
                ]
            },
            {
                "countyName": "Kennebec",
                "cities": [
                    "Albion",
                    "Belgrade",
                    "Benton",
                    "Chelsea",
                    "China",
                    "Clinton",
                    "Farmingdale",
                    "Fayette",
                    "Gardiner",
                    "Hallowell",
                    "Litchfield",
                    "Manchester",
                    "Monmouth",
                    "Mount Vernon",
                    "Oakland",
                    "Pittston",
                    "Randolph",
                    "Readfield",
                    "Rome",
                    "Sidney",
                    "Vassalboro",
                    "Vienna",
                    "Waterville",
                    "Wayne",
                    "West Gardiner",
                    "Windsor",
                    "Winslow",
                    "Winthrop"
                ]
            },
            {
                "countyName": "Kennebec (seat)",
                "cities": [
                    "Augusta"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Appleton",
                    "Camden",
                    "Cushing",
                    "Friendship",
                    "Hope",
                    "Isle au Haut",
                    "Matinicus Isle",
                    "North Haven",
                    "Owls Head",
                    "Rockport",
                    "South Thomaston",
                    "St. George",
                    "Thomaston",
                    "Union",
                    "Vinalhaven",
                    "Warren",
                    "Washington"
                ]
            },
            {
                "countyName": "Knox (seat)",
                "cities": [
                    "Rockland"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Alna",
                    "Boothbay",
                    "Boothbay Harbor",
                    "Bremen",
                    "Bristol",
                    "Damariscotta",
                    "Dresden",
                    "Edgecomb",
                    "Jefferson",
                    "Monhegan",
                    "Newcastle",
                    "Nobleboro",
                    "Somerville",
                    "South Bristol",
                    "Southport",
                    "Waldoboro",
                    "Westport Island",
                    "Whitefield"
                ]
            },
            {
                "countyName": "Lincoln (seat)",
                "cities": [
                    "Wiscasset"
                ]
            },
            {
                "countyName": "Oxford",
                "cities": [
                    "Andover",
                    "Bethel",
                    "Brownfield",
                    "Buckfield",
                    "Byron",
                    "Canton",
                    "Denmark",
                    "Dixfield",
                    "Fryeburg",
                    "Gilead",
                    "Greenwood",
                    "Hanover",
                    "Hartford",
                    "Hebron",
                    "Hiram",
                    "Lincoln Plantation",
                    "Lovell",
                    "Mexico",
                    "Newry",
                    "Norway",
                    "Otisfield",
                    "Oxford",
                    "Peru",
                    "Porter",
                    "Roxbury",
                    "Rumford",
                    "Stoneham",
                    "Stow",
                    "Sumner",
                    "Sweden",
                    "Upton",
                    "Waterford",
                    "West Paris",
                    "Woodstock"
                ]
            },
            {
                "countyName": "Oxford (seat)",
                "cities": [
                    "Paris"
                ]
            },
            {
                "countyName": "Penobscot",
                "cities": [
                    "Alton",
                    "Bradford",
                    "Bradley",
                    "Brewer",
                    "Burlington",
                    "Carmel",
                    "Carroll Plantation",
                    "Charleston",
                    "Chester",
                    "Clifton",
                    "Corinna",
                    "Corinth",
                    "Dexter",
                    "Dixmont",
                    "East Millinocket",
                    "Eddington",
                    "Edinburg",
                    "Enfield",
                    "Etna",
                    "Exeter",
                    "Garland",
                    "Glenburn",
                    "Greenbush",
                    "Hampden",
                    "Hermon",
                    "Holden",
                    "Howland",
                    "Hudson",
                    "Kenduskeag",
                    "Lagrange",
                    "Lakeville",
                    "Lee",
                    "Levant",
                    "Lincoln",
                    "Lowell",
                    "Mattawamkeag",
                    "Maxfield",
                    "Medway",
                    "Milford",
                    "Millinocket",
                    "Mount Chase",
                    "Newburgh",
                    "Newport",
                    "Old Town",
                    "Orono",
                    "Orrington",
                    "Passadumkeag",
                    "Patten",
                    "Plymouth",
                    "Seboeis Plantation",
                    "Springfield",
                    "Stacyville",
                    "Stetson",
                    "Veazie",
                    "Webster Plantation",
                    "Winn",
                    "Woodville"
                ]
            },
            {
                "countyName": "Penobscot (seat)",
                "cities": [
                    "Bangor"
                ]
            },
            {
                "countyName": "Piscataquis",
                "cities": [
                    "Abbot",
                    "Beaver Cove",
                    "Bowerbank",
                    "Brownville",
                    "Greenville",
                    "Guilford",
                    "Kingsbury Plantation",
                    "Lake View Plantation",
                    "Medford",
                    "Milo",
                    "Monson",
                    "Parkman",
                    "Sangerville",
                    "Sebec",
                    "Shirley",
                    "Wellington",
                    "Willimantic"
                ]
            },
            {
                "countyName": "Piscataquis (seat)",
                "cities": [
                    "Dover-Foxcroft"
                ]
            },
            {
                "countyName": "Sagadahoc",
                "cities": [
                    "Arrowsic",
                    "Bowdoin",
                    "Bowdoinham",
                    "Georgetown",
                    "Phippsburg",
                    "Richmond",
                    "Topsham",
                    "West Bath",
                    "Woolwich"
                ]
            },
            {
                "countyName": "Sagadahoc (seat)",
                "cities": [
                    "Bath"
                ]
            },
            {
                "countyName": "Somerset",
                "cities": [
                    "Anson",
                    "Athens",
                    "Bingham",
                    "Brighton Plantation",
                    "Cambridge",
                    "Canaan",
                    "Caratunk",
                    "Cornville",
                    "Dennistown",
                    "Detroit",
                    "Embden",
                    "Fairfield",
                    "Harmony",
                    "Hartland",
                    "Highland Plantation",
                    "Jackman",
                    "Madison",
                    "Mercer",
                    "Moose River",
                    "Moscow",
                    "New Portland",
                    "Norridgewock",
                    "Palmyra",
                    "Pittsfield",
                    "Pleasant Ridge Plantation",
                    "Ripley",
                    "Smithfield",
                    "Solon",
                    "St. Albans",
                    "Starks",
                    "The Forks",
                    "West Forks"
                ]
            },
            {
                "countyName": "Somerset (seat)",
                "cities": [
                    "Skowhegan"
                ]
            },
            {
                "countyName": "Waldo",
                "cities": [
                    "Belmont",
                    "Brooks",
                    "Burnham",
                    "Frankfort",
                    "Freedom",
                    "Islesboro",
                    "Jackson",
                    "Knox",
                    "Liberty",
                    "Lincolnville",
                    "Monroe",
                    "Montville",
                    "Morrill",
                    "Northport",
                    "Palermo",
                    "Prospect",
                    "Searsmont",
                    "Searsport",
                    "Stockton Springs",
                    "Swanville",
                    "Thorndike",
                    "Troy",
                    "Unity",
                    "Waldo",
                    "Winterport"
                ]
            },
            {
                "countyName": "Waldo (seat)",
                "cities": [
                    "Belfast"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Addison",
                    "Alexander",
                    "Baileyville",
                    "Baring Plantation",
                    "Beals",
                    "Beddington",
                    "Calais",
                    "Charlotte",
                    "Cherryfield",
                    "Columbia",
                    "Columbia Falls",
                    "Cooper",
                    "Crawford",
                    "Cutler",
                    "Danforth",
                    "Deblois",
                    "Dennysville",
                    "East Machias",
                    "Eastport",
                    "Grand Lake Stream",
                    "Harrington",
                    "Jonesboro",
                    "Jonesport",
                    "Lubec",
                    "Machiasport",
                    "Marshfield",
                    "Meddybemps",
                    "Milbridge",
                    "Northfield",
                    "Pembroke",
                    "Perry",
                    "Princeton",
                    "Robbinston",
                    "Roque Bluffs",
                    "Steuben",
                    "Talmadge",
                    "Topsfield",
                    "Vanceboro",
                    "Waite",
                    "Wesley",
                    "Whiting",
                    "Whitneyville"
                ]
            },
            {
                "countyName": "Washington (seat)",
                "cities": [
                    "Machias"
                ]
            },
            {
                "countyName": "York",
                "cities": [
                    "Acton",
                    "Arundel",
                    "Berwick",
                    "Biddeford",
                    "Buxton",
                    "Cornish",
                    "Dayton",
                    "Eliot",
                    "Hollis",
                    "Kennebunk",
                    "Kennebunkport",
                    "Kittery",
                    "Lebanon",
                    "Limerick",
                    "Limington",
                    "Lyman",
                    "Newfield",
                    "North Berwick",
                    "Ogunquit",
                    "Old Orchard Beach",
                    "Parsonsfield",
                    "Saco",
                    "Sanford",
                    "Shapleigh",
                    "South Berwick",
                    "Waterboro",
                    "Wells",
                    "York",
                    "Alfred"
                ]
            }
        ]
    },
    {
        "stateId": "MI",
        "stateName": "Michigan",
        "county": [
            {
                "countyName": "Alcona",
                "cities": [
                    "Alcona",
                    "Caledonia",
                    "Curtis",
                    "Greenbush",
                    "Gustin",
                    "Harrisville†",
                    "Harrisville",
                    "Hawes",
                    "Haynes",
                    "Lincoln",
                    "Mikado",
                    "Millen",
                    "Mitchell"
                ]
            },
            {
                "countyName": "Alger",
                "cities": [
                    "Au Train",
                    "Burt",
                    "Chatham",
                    "Grand Island",
                    "Limestone",
                    "Mathias",
                    "Munising†",
                    "Munising",
                    "Onota",
                    "Rock River"
                ]
            },
            {
                "countyName": "Allegan",
                "cities": [
                    "Allegan†",
                    "Allegan",
                    "Casco",
                    "Cheshire",
                    "Clyde",
                    "Dorr",
                    "Douglas",
                    "Fennville",
                    "Fillmore",
                    "Ganges",
                    "Gun Plain",
                    "Heath",
                    "Hopkins",
                    "Hopkins",
                    "Laketown",
                    "Lee",
                    "Leighton",
                    "Manlius",
                    "Martin",
                    "Martin",
                    "Monterey",
                    "Otsego",
                    "Otsego",
                    "Overisel",
                    "Plainwell",
                    "Salem",
                    "Saugatuck",
                    "Saugatuck",
                    "Trowbridge",
                    "Valley",
                    "Watson",
                    "Wayland",
                    "Wayland"
                ]
            },
            {
                "countyName": "Alpena",
                "cities": [
                    "Alpena†",
                    "Alpena",
                    "Green",
                    "Long Rapids",
                    "Maple Ridge",
                    "Ossineke",
                    "Sanborn",
                    "Wellington",
                    "Wilson"
                ]
            },
            {
                "countyName": "Antrim",
                "cities": [
                    "Banks",
                    "Bellaire†",
                    "Central Lake",
                    "Central Lake",
                    "Chestonia",
                    "Custer",
                    "Echo",
                    "Elk Rapids",
                    "Elk Rapids",
                    "Ellsworth",
                    "Forest Home",
                    "Helena",
                    "Jordan",
                    "Kearney",
                    "Mancelona",
                    "Mancelona",
                    "Milton",
                    "Star",
                    "Torch Lake",
                    "Warner"
                ]
            },
            {
                "countyName": "Arenac",
                "cities": [
                    "Adams",
                    "Arenac",
                    "Au Gres",
                    "Au Gres",
                    "Clayton",
                    "Deep River",
                    "Lincoln",
                    "Mason",
                    "Moffatt",
                    "Omer",
                    "Sims",
                    "Standish†",
                    "Standish",
                    "Sterling",
                    "Turner",
                    "Turner",
                    "Twining",
                    "Whitney"
                ]
            },
            {
                "countyName": "Baraga",
                "cities": [
                    "Arvon",
                    "Baraga",
                    "Baraga",
                    "Covington",
                    "L'Anse†",
                    "L'Anse",
                    "Spurr"
                ]
            },
            {
                "countyName": "Barry",
                "cities": [
                    "Assyria",
                    "Baltimore",
                    "Barry",
                    "Carlton",
                    "Castleton",
                    "Freeport",
                    "Hastings†",
                    "Hastings",
                    "Hope",
                    "Irving",
                    "Johnstown",
                    "Maple Grove",
                    "Middleville",
                    "Nashville",
                    "Orangeville",
                    "Prairieville",
                    "Rutland",
                    "Thornapple",
                    "Woodland",
                    "Woodland",
                    "Yankee Springs"
                ]
            },
            {
                "countyName": "Bay",
                "cities": [
                    "Auburn",
                    "Bangor",
                    "Bay City†",
                    "Beaver",
                    "Essexville",
                    "Frankenlust",
                    "Fraser",
                    "Garfield",
                    "Gibson",
                    "Hampton",
                    "Kawkawlin",
                    "Merritt",
                    "Monitor",
                    "Mount Forest",
                    "Pinconning",
                    "Pinconning",
                    "Portsmouth",
                    "Williams"
                ]
            },
            {
                "countyName": "Benzie",
                "cities": [
                    "Almira",
                    "Benzonia",
                    "Benzonia",
                    "Beulah†",
                    "Blaine",
                    "Colfax",
                    "Crystal Lake",
                    "Elberta",
                    "Frankfort",
                    "Gilmore",
                    "Homestead",
                    "Honor",
                    "Inland",
                    "Joyfield",
                    "Lake",
                    "Lake Ann",
                    "Platte",
                    "Thompsonville",
                    "Weldon"
                ]
            },
            {
                "countyName": "Berrien",
                "cities": [
                    "Bainbridge",
                    "Baroda",
                    "Baroda",
                    "Benton",
                    "Benton Harbor",
                    "Berrien",
                    "Berrien Springs",
                    "Bertrand",
                    "Bridgman",
                    "Buchanan",
                    "Buchanan",
                    "Chikaming",
                    "Coloma",
                    "Coloma",
                    "Eau Claire",
                    "Galien",
                    "Galien",
                    "Grand Beach",
                    "Hagar",
                    "Lake",
                    "Lincoln",
                    "Michiana",
                    "New Buffalo",
                    "New Buffalo",
                    "Niles",
                    "Oronoko",
                    "Pipestone",
                    "Royalton",
                    "St. Joseph†",
                    "St. Joseph",
                    "Shoreham",
                    "Sodus",
                    "Stevensville",
                    "Three Oaks",
                    "Three Oaks",
                    "Watervliet",
                    "Watervliet",
                    "Weesaw"
                ]
            },
            {
                "countyName": "Berrien, Cass",
                "cities": [
                    "Niles"
                ]
            },
            {
                "countyName": "Branch",
                "cities": [
                    "Algansee",
                    "Batavia",
                    "Bethel",
                    "Bronson",
                    "Bronson",
                    "Butler",
                    "California",
                    "Coldwater†",
                    "Coldwater",
                    "Gilead",
                    "Girard",
                    "Kinderhook",
                    "Matteson",
                    "Noble",
                    "Ovid",
                    "Quincy",
                    "Quincy",
                    "Sherwood",
                    "Sherwood",
                    "Union"
                ]
            },
            {
                "countyName": "Branch, Calhoun",
                "cities": [
                    "Union City"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Albion",
                    "Albion",
                    "Athens",
                    "Athens",
                    "Battle Creek",
                    "Bedford",
                    "Burlington",
                    "Burlington",
                    "Clarence",
                    "Clarendon",
                    "Convis",
                    "Eckford",
                    "Emmett",
                    "Fredonia",
                    "Homer",
                    "Homer",
                    "Lee",
                    "Leroy",
                    "Marengo",
                    "Marshall†",
                    "Marshall",
                    "Newton",
                    "Pennfield",
                    "Sheridan",
                    "Springfield",
                    "Tekonsha",
                    "Tekonsha"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Calvin",
                    "Cassopolis†",
                    "Dowagiac",
                    "Edwardsburg",
                    "Howard",
                    "Jefferson",
                    "LaGrange",
                    "Marcellus",
                    "Marcellus",
                    "Mason",
                    "Milton",
                    "Newberg",
                    "Ontwa",
                    "Penn",
                    "Pokagon",
                    "Porter",
                    "Silver Creek",
                    "Vandalia",
                    "Volinia",
                    "Wayne"
                ]
            },
            {
                "countyName": "Charlevoix",
                "cities": [
                    "Bay",
                    "Boyne City",
                    "Boyne Falls",
                    "Boyne Valley",
                    "Chandler",
                    "Charlevoix†",
                    "Charlevoix",
                    "East Jordan",
                    "Evangeline",
                    "Eveline",
                    "Hayes",
                    "Hudson",
                    "Marion",
                    "Melrose",
                    "Norwood",
                    "Peaine",
                    "St. James",
                    "South Arm",
                    "Wilson"
                ]
            },
            {
                "countyName": "Cheboygan",
                "cities": [
                    "Aloha",
                    "Beaugrand",
                    "Benton",
                    "Burt",
                    "Cheboygan†",
                    "Ellis",
                    "Forest",
                    "Grant",
                    "Hebron",
                    "Inverness",
                    "Koehler",
                    "Mackinaw",
                    "Mentor",
                    "Mullett",
                    "Munro",
                    "Nunda",
                    "Tuscarora",
                    "Walker",
                    "Waverly",
                    "Wilmot",
                    "Wolverine"
                ]
            },
            {
                "countyName": "Cheboygan, Emmet",
                "cities": [
                    "Mackinaw City"
                ]
            },
            {
                "countyName": "Chippewa",
                "cities": [
                    "Bay Mills",
                    "Bruce",
                    "Chippewa",
                    "Dafter",
                    "Detour",
                    "DeTour Village",
                    "Drummond",
                    "Hulbert",
                    "Kinross",
                    "Pickford",
                    "Raber",
                    "Rudyard",
                    "Sault Ste. Marie†",
                    "Soo",
                    "Sugar Island",
                    "Superior",
                    "Trout Lake",
                    "Whitefish"
                ]
            },
            {
                "countyName": "Clare",
                "cities": [
                    "Arthur",
                    "Farwell",
                    "Franklin",
                    "Freeman",
                    "Frost",
                    "Garfield",
                    "Grant",
                    "Greenwood",
                    "Hamilton",
                    "Harrison†",
                    "Hatton",
                    "Hayes",
                    "Lincoln",
                    "Redding",
                    "Sheridan",
                    "Summerfield",
                    "Surrey",
                    "Winterfield"
                ]
            },
            {
                "countyName": "Clare\nIsabella",
                "cities": [
                    "Clare"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Bath",
                    "Bengal",
                    "Bingham",
                    "Dallas",
                    "DeWitt",
                    "DeWitt",
                    "Duplain",
                    "Eagle",
                    "Eagle",
                    "Elsie",
                    "Essex",
                    "Fowler",
                    "Greenbush",
                    "Lebanon",
                    "Maple Rapids",
                    "Olive",
                    "Ovid",
                    "Riley",
                    "St. Johns†",
                    "Victor",
                    "Watertown",
                    "Westphalia",
                    "Westphalia"
                ]
            },
            {
                "countyName": "Clinton, Shiawassee",
                "cities": [
                    "Ovid"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Beaver Creek",
                    "Frederic",
                    "Grayling†",
                    "Grayling",
                    "Lovells",
                    "Maple Forest",
                    "South Branch"
                ]
            },
            {
                "countyName": "Delta",
                "cities": [
                    "Baldwin",
                    "Bark River",
                    "Bay de Noc",
                    "Brampton",
                    "Cornell",
                    "Ensign",
                    "Escanaba†",
                    "Escanaba",
                    "Fairbanks",
                    "Ford River",
                    "Garden",
                    "Garden",
                    "Gladstone",
                    "Maple Ridge",
                    "Masonville",
                    "Nahma",
                    "Wells"
                ]
            },
            {
                "countyName": "Dickinson",
                "cities": [
                    "Breen",
                    "Breitung",
                    "Felch",
                    "Iron Mountain†",
                    "Kingsford",
                    "Norway",
                    "Norway",
                    "Sagola",
                    "Waucedah",
                    "West Branch"
                ]
            },
            {
                "countyName": "Eaton",
                "cities": [
                    "Bellevue",
                    "Bellevue",
                    "Benton",
                    "Brookfield",
                    "Carmel",
                    "Charlotte†",
                    "Chester",
                    "Delta",
                    "Dimondale",
                    "Eaton",
                    "Eaton Rapids",
                    "Eaton Rapids",
                    "Grand Ledge",
                    "Hamlin",
                    "Kalamo",
                    "Mulliken",
                    "Olivet",
                    "Oneida",
                    "Potterville",
                    "Roxand",
                    "Sunfield",
                    "Sunfield",
                    "Vermontville",
                    "Vermontville",
                    "Walton",
                    "Windsor"
                ]
            },
            {
                "countyName": "Emmet",
                "cities": [
                    "Alanson",
                    "Bear Creek",
                    "Bliss",
                    "Carp Lake",
                    "Center",
                    "Cross Village",
                    "Friendship",
                    "Harbor Springs",
                    "Littlefield",
                    "Little Traverse",
                    "McKinley",
                    "Maple River",
                    "Pellston",
                    "Petoskey†",
                    "Pleasantview",
                    "Readmond",
                    "Resort",
                    "Springvale",
                    "Wawatam",
                    "West Traverse"
                ]
            },
            {
                "countyName": "Genesee",
                "cities": [
                    "Argentine",
                    "Atlas",
                    "Burton",
                    "Clayton",
                    "Clio",
                    "Davison",
                    "Davison",
                    "Fenton",
                    "Flint†",
                    "Flint",
                    "Flushing",
                    "Flushing",
                    "Forest",
                    "Gaines",
                    "Gaines",
                    "Genesee",
                    "Goodrich",
                    "Grand Blanc",
                    "Grand Blanc",
                    "Linden",
                    "Montrose",
                    "Montrose",
                    "Mount Morris",
                    "Mount Morris",
                    "Mundy",
                    "Otisville",
                    "Richfield",
                    "Swartz Creek",
                    "Thetford",
                    "Vienna"
                ]
            },
            {
                "countyName": "Genesee, Livingston, Oakland",
                "cities": [
                    "Fenton"
                ]
            },
            {
                "countyName": "Genesee, Shiawassee",
                "cities": [
                    "Lennon"
                ]
            },
            {
                "countyName": "Gladwin",
                "cities": [
                    "Beaverton",
                    "Beaverton",
                    "Bentley",
                    "Billings",
                    "Bourret",
                    "Buckeye",
                    "Butman",
                    "Clement",
                    "Gladwin†",
                    "Gladwin",
                    "Grim",
                    "Grout",
                    "Hay",
                    "Sage",
                    "Secord",
                    "Sherman",
                    "Tobacco"
                ]
            },
            {
                "countyName": "Gogebic",
                "cities": [
                    "Bessemer†",
                    "Bessemer",
                    "Erwin",
                    "Ironwood",
                    "Ironwood",
                    "Marenisco",
                    "Wakefield",
                    "Wakefield",
                    "Watersmeet"
                ]
            },
            {
                "countyName": "Grand Traverse",
                "cities": [
                    "Acme",
                    "Blair",
                    "East Bay",
                    "Fife Lake",
                    "Fife Lake",
                    "Garfield",
                    "Grant",
                    "Green Lake",
                    "Kingsley",
                    "Long Lake",
                    "Mayfield",
                    "Paradise",
                    "Peninsula",
                    "Union",
                    "Whitewater"
                ]
            },
            {
                "countyName": "Grand Traverse,\nLeelanau",
                "cities": [
                    "Traverse City†"
                ]
            },
            {
                "countyName": "Gratiot",
                "cities": [
                    "Alma",
                    "Arcada",
                    "Ashley",
                    "Bethany",
                    "Breckenridge",
                    "Elba",
                    "Emerson",
                    "Fulton",
                    "Hamilton",
                    "Ithaca†",
                    "Lafayette",
                    "Newark",
                    "New Haven",
                    "North Shade",
                    "North Star",
                    "Perrinton",
                    "Pine River",
                    "St. Louis",
                    "Seville",
                    "Sumner",
                    "Washington",
                    "Wheeler"
                ]
            },
            {
                "countyName": "Hillsdale",
                "cities": [
                    "Adams",
                    "Allen",
                    "Allen",
                    "Amboy",
                    "Cambria",
                    "Camden",
                    "Camden",
                    "Fayette",
                    "Hillsdale†",
                    "Hillsdale",
                    "Jefferson",
                    "Jonesville",
                    "Litchfield",
                    "Litchfield",
                    "Montgomery",
                    "Moscow",
                    "North Adams",
                    "Pittsford",
                    "Ransom",
                    "Reading",
                    "Reading",
                    "Scipio",
                    "Somerset",
                    "Waldron",
                    "Wheatland",
                    "Woodbridge",
                    "Wright"
                ]
            },
            {
                "countyName": "Houghton",
                "cities": [
                    "Adams",
                    "Calumet",
                    "Calumet",
                    "Chassell",
                    "Copper City",
                    "Duncan",
                    "Elm River",
                    "Franklin",
                    "Hancock",
                    "Hancock",
                    "Houghton†",
                    "Laird",
                    "Lake Linden",
                    "Laurium",
                    "Osceola",
                    "Portage",
                    "Quincy",
                    "Schoolcraft",
                    "South Range",
                    "Stanton",
                    "Torch Lake"
                ]
            },
            {
                "countyName": "Huron",
                "cities": [
                    "Bad Axe†",
                    "Bingham",
                    "Bloomfield",
                    "Brookfield",
                    "Caseville",
                    "Caseville",
                    "Chandler",
                    "Colfax",
                    "Dwight",
                    "Elkton",
                    "Fairhaven",
                    "Gore",
                    "Grant",
                    "Harbor Beach",
                    "Hume",
                    "Huron",
                    "Kinde",
                    "Lake",
                    "Lincoln",
                    "McKinley",
                    "Meade",
                    "Oliver",
                    "Owendale",
                    "Paris",
                    "Pigeon",
                    "Pointe Aux Barques",
                    "Port Austin",
                    "Port Austin",
                    "Port Hope",
                    "Rubicon",
                    "Sand Beach",
                    "Sebewaing",
                    "Sebewaing",
                    "Sheridan",
                    "Sherman",
                    "Sigel",
                    "Ubly",
                    "Verona",
                    "Winsor"
                ]
            },
            {
                "countyName": "Ingham",
                "cities": [
                    "Alaiedon",
                    "Aurelius",
                    "Bunker Hill",
                    "Dansville",
                    "Delhi",
                    "Ingham",
                    "Lansing",
                    "Leroy",
                    "Leslie",
                    "Leslie",
                    "Locke",
                    "Mason†",
                    "Meridian",
                    "Onondaga",
                    "Stockbridge",
                    "Stockbridge",
                    "Vevay",
                    "Webberville",
                    "Wheatfield",
                    "White Oak",
                    "Williamston",
                    "Williamstown"
                ]
            },
            {
                "countyName": "Ingham, Clinton",
                "cities": [
                    "East Lansing"
                ]
            },
            {
                "countyName": "Ingham, Clinton, Eaton",
                "cities": [
                    "Lansing‡"
                ]
            },
            {
                "countyName": "Ionia",
                "cities": [
                    "Belding",
                    "Berlin",
                    "Boston",
                    "Campbell",
                    "Clarksville",
                    "Danby",
                    "Easton",
                    "Ionia†",
                    "Ionia",
                    "Keene",
                    "Lake Odessa",
                    "Lyons",
                    "Lyons",
                    "Muir",
                    "North Plains",
                    "Odessa",
                    "Orange",
                    "Orleans",
                    "Otisco",
                    "Pewamo",
                    "Portland",
                    "Portland",
                    "Ronald",
                    "Saranac",
                    "Sebewa"
                ]
            },
            {
                "countyName": "Ionia, Clinton",
                "cities": [
                    "Hubbardston"
                ]
            },
            {
                "countyName": "Iosco",
                "cities": [
                    "Alabaster",
                    "Au Sable",
                    "Baldwin",
                    "Burleigh",
                    "East Tawas",
                    "Grant",
                    "Oscoda",
                    "Plainfield",
                    "Reno",
                    "Sherman",
                    "Tawas",
                    "Tawas City†",
                    "Whittemore",
                    "Wilber"
                ]
            },
            {
                "countyName": "Iron",
                "cities": [
                    "Alpha",
                    "Bates",
                    "Caspian",
                    "Crystal Falls†",
                    "Crystal Falls",
                    "Gaastra",
                    "Hematite",
                    "Iron River",
                    "Iron River",
                    "Mansfield",
                    "Mastodon",
                    "Stambaugh"
                ]
            },
            {
                "countyName": "Isabella",
                "cities": [
                    "Broomfield",
                    "Chippewa",
                    "Coe",
                    "Coldwater",
                    "Deerfield",
                    "Denver",
                    "Fremont",
                    "Gilmore",
                    "Isabella",
                    "Lake Isabella",
                    "Lincoln",
                    "Mount Pleasant†",
                    "Nottawa",
                    "Rolland",
                    "Rosebush",
                    "Shepherd",
                    "Sherman",
                    "Union",
                    "Vernon",
                    "Wise"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Blackman",
                    "Brooklyn",
                    "Columbia",
                    "Concord",
                    "Concord",
                    "Grass Lake",
                    "Grass Lake",
                    "Hanover",
                    "Hanover",
                    "Henrietta",
                    "Jackson†",
                    "Leoni",
                    "Liberty",
                    "Napoleon",
                    "Norvell",
                    "Parma",
                    "Parma",
                    "Pulaski",
                    "Rives",
                    "Sandstone",
                    "Spring Arbor",
                    "Springport",
                    "Springport",
                    "Summit",
                    "Tompkins",
                    "Waterloo"
                ]
            },
            {
                "countyName": "Jackson\nLenawee",
                "cities": [
                    "Cement City"
                ]
            },
            {
                "countyName": "Kalamazoo",
                "cities": [
                    "Alamo",
                    "Augusta",
                    "Brady",
                    "Charleston",
                    "Climax",
                    "Climax",
                    "Comstock",
                    "Cooper",
                    "Galesburg",
                    "Kalamazoo†",
                    "Kalamazoo",
                    "Oshtemo",
                    "Parchment",
                    "Pavilion",
                    "Portage",
                    "Prairie Ronde",
                    "Richland",
                    "Richland",
                    "Ross",
                    "Schoolcraft",
                    "Schoolcraft",
                    "Texas",
                    "Vicksburg",
                    "Wakeshma"
                ]
            },
            {
                "countyName": "Kalkaska",
                "cities": [
                    "Bear Lake",
                    "Blue Lake",
                    "Boardman",
                    "Clearwater",
                    "Coldsprings",
                    "Excelsior",
                    "Garfield",
                    "Kalkaska†",
                    "Kalkaska",
                    "Oliver",
                    "Orange",
                    "Rapid River",
                    "Springfield"
                ]
            },
            {
                "countyName": "Kent",
                "cities": [
                    "Ada",
                    "Algoma",
                    "Alpine",
                    "Bowne",
                    "Byron",
                    "Caledonia",
                    "Caledonia",
                    "Cannon",
                    "Cascade",
                    "Cedar Springs",
                    "Courtland",
                    "East Grand Rapids",
                    "Gaines",
                    "Grand Rapids†",
                    "Grand Rapids",
                    "Grandville",
                    "Grattan",
                    "Kent City",
                    "Kentwood",
                    "Lowell",
                    "Lowell",
                    "Nelson",
                    "Oakfield",
                    "Plainfield",
                    "Rockford",
                    "Sand Lake",
                    "Solon",
                    "Sparta",
                    "Sparta",
                    "Spencer",
                    "Tyrone",
                    "Vergennes",
                    "Walker",
                    "Wyoming"
                ]
            },
            {
                "countyName": "Kent\nMuskegon",
                "cities": [
                    "Casnovia"
                ]
            },
            {
                "countyName": "Keweenaw",
                "cities": [
                    "Ahmeek",
                    "Allouez",
                    "Eagle Harbor",
                    "Grant",
                    "Houghton",
                    "Sherman"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Baldwin†",
                    "Chase",
                    "Cherry Valley",
                    "Dover",
                    "Eden",
                    "Elk",
                    "Ellsworth",
                    "Lake",
                    "Luther",
                    "Newkirk",
                    "Peacock",
                    "Pinora",
                    "Pleasant Plains",
                    "Sauble",
                    "Sweetwater",
                    "Webber",
                    "Yates"
                ]
            },
            {
                "countyName": "Lapeer",
                "cities": [
                    "Almont",
                    "Almont",
                    "Arcadia",
                    "Attica",
                    "Burlington",
                    "Burnside",
                    "Clifford",
                    "Columbiaville",
                    "Deerfield",
                    "Dryden",
                    "Dryden",
                    "Elba",
                    "Goodland",
                    "Hadley",
                    "Imlay",
                    "Imlay City",
                    "Lapeer†",
                    "Lapeer",
                    "Marathon",
                    "Mayfield",
                    "Metamora",
                    "Metamora",
                    "North Branch",
                    "North Branch",
                    "Oregon",
                    "Rich"
                ]
            },
            {
                "countyName": "Lapeer, Genesee",
                "cities": [
                    "Otter Lake"
                ]
            },
            {
                "countyName": "Leelanau",
                "cities": [
                    "Bingham",
                    "Centerville",
                    "Cleveland",
                    "Elmwood",
                    "Empire",
                    "Empire",
                    "Glen Arbor",
                    "Kasson",
                    "Leelanau",
                    "Leland",
                    "Northport",
                    "Solon",
                    "Suttons Bay",
                    "Suttons Bay†"
                ]
            },
            {
                "countyName": "Lenawee",
                "cities": [
                    "Addison",
                    "Adrian†",
                    "Adrian",
                    "Blissfield",
                    "Blissfield",
                    "Britton",
                    "Cambridge",
                    "Clayton",
                    "Clinton",
                    "Clinton",
                    "Deerfield",
                    "Deerfield",
                    "Dover",
                    "Fairfield",
                    "Franklin",
                    "Hudson",
                    "Hudson",
                    "Macon",
                    "Madison",
                    "Medina",
                    "Morenci",
                    "Ogden",
                    "Onsted",
                    "Palmyra",
                    "Raisin",
                    "Ridgeway",
                    "Riga",
                    "Rollin",
                    "Rome",
                    "Seneca",
                    "Tecumseh",
                    "Tecumseh",
                    "Woodstock"
                ]
            },
            {
                "countyName": "Livingston",
                "cities": [
                    "Brighton",
                    "Brighton",
                    "Cohoctah",
                    "Conway",
                    "Deerfield",
                    "Fowlerville",
                    "Genoa",
                    "Green Oak",
                    "Hamburg",
                    "Handy",
                    "Hartland",
                    "Howell†",
                    "Howell",
                    "Iosco",
                    "Marion",
                    "Oceola",
                    "Pinckney",
                    "Putnam",
                    "Tyrone",
                    "Unadilla"
                ]
            },
            {
                "countyName": "Luce",
                "cities": [
                    "Columbus",
                    "Lakefield",
                    "McMillan",
                    "Newberry†",
                    "Pentland"
                ]
            },
            {
                "countyName": "Mackinac",
                "cities": [
                    "Bois Blanc",
                    "Brevort",
                    "Clark",
                    "Garfield",
                    "Hendricks",
                    "Hudson",
                    "Mackinac Island",
                    "Marquette",
                    "Moran",
                    "Newton",
                    "Portage",
                    "St. Ignace†",
                    "St. Ignace"
                ]
            },
            {
                "countyName": "Macomb",
                "cities": [
                    "Armada",
                    "Armada",
                    "Bruce",
                    "Center Line",
                    "Chesterfield",
                    "Clinton",
                    "Eastpointe",
                    "Fraser",
                    "Harrison",
                    "Lenox",
                    "Macomb",
                    "Mount Clemens†",
                    "New Baltimore",
                    "New Haven",
                    "Ray",
                    "Richmond",
                    "Romeo",
                    "Roseville",
                    "St. Clair Shores",
                    "Shelby",
                    "Sterling Heights",
                    "Utica",
                    "Warren",
                    "Washington"
                ]
            },
            {
                "countyName": "Macomb, St. Clair",
                "cities": [
                    "Memphis",
                    "Richmond"
                ]
            },
            {
                "countyName": "Manistee",
                "cities": [
                    "Arcadia",
                    "Bear Lake",
                    "Bear Lake",
                    "Brown",
                    "Cleon",
                    "Copemish",
                    "Dickson",
                    "Eastlake",
                    "Filer",
                    "Kaleva",
                    "Manistee†",
                    "Manistee",
                    "Maple Grove",
                    "Marilla",
                    "Norman",
                    "Onekama",
                    "Onekama",
                    "Pleasanton",
                    "Springdale",
                    "Stronach"
                ]
            },
            {
                "countyName": "Marquette",
                "cities": [
                    "Champion",
                    "Chocolay",
                    "Ely",
                    "Ewing",
                    "Forsyth",
                    "Humboldt",
                    "Ishpeming",
                    "Ishpeming",
                    "Marquette†",
                    "Marquette",
                    "Michigamme",
                    "Negaunee",
                    "Negaunee",
                    "Powell",
                    "Republic",
                    "Richmond",
                    "Sands",
                    "Skandia",
                    "Tilden",
                    "Turin",
                    "Wells",
                    "West Branch"
                ]
            },
            {
                "countyName": "Mason",
                "cities": [
                    "Amber",
                    "Branch",
                    "Custer",
                    "Custer",
                    "Eden",
                    "Fountain",
                    "Free Soil",
                    "Free Soil",
                    "Grant",
                    "Hamlin",
                    "Logan",
                    "Ludington†",
                    "Meade",
                    "Pere Marquette",
                    "Riverton",
                    "Scottville",
                    "Sheridan",
                    "Sherman",
                    "Summit",
                    "Victory"
                ]
            },
            {
                "countyName": "Mecosta",
                "cities": [
                    "Aetna",
                    "Austin",
                    "Barryton",
                    "Big Rapids†",
                    "Big Rapids",
                    "Chippewa",
                    "Colfax",
                    "Deerfield",
                    "Fork",
                    "Grant",
                    "Green",
                    "Hinton",
                    "Martiny",
                    "Mecosta",
                    "Mecosta",
                    "Millbrook",
                    "Morley",
                    "Morton",
                    "Sheridan",
                    "Stanwood",
                    "Wheatland"
                ]
            },
            {
                "countyName": "Menominee",
                "cities": [
                    "Carney",
                    "Cedarville",
                    "Daggett",
                    "Daggett",
                    "Faithorn",
                    "Gourley",
                    "Harris",
                    "Holmes",
                    "Ingallston",
                    "Lake",
                    "Mellen",
                    "Menominee†",
                    "Menominee",
                    "Meyer",
                    "Nadeau",
                    "Powers",
                    "Spalding",
                    "Stephenson",
                    "Stephenson"
                ]
            },
            {
                "countyName": "Midland",
                "cities": [
                    "Coleman",
                    "Edenville",
                    "Geneva",
                    "Greendale",
                    "Homer",
                    "Hope",
                    "Ingersoll",
                    "Jasper",
                    "Jerome",
                    "Larkin",
                    "Lee",
                    "Lincoln",
                    "Midland",
                    "Mills",
                    "Mount Haley",
                    "Porter",
                    "Sanford",
                    "Warren"
                ]
            },
            {
                "countyName": "Midland, Bay",
                "cities": [
                    "Midland†"
                ]
            },
            {
                "countyName": "Missaukee",
                "cities": [
                    "Aetna",
                    "Bloomfield",
                    "Butterfield",
                    "Caldwell",
                    "Clam Union",
                    "Enterprise",
                    "Forest",
                    "Holland",
                    "Lake",
                    "Lake City†",
                    "McBain",
                    "Norwich",
                    "Pioneer",
                    "Reeder",
                    "Richland",
                    "Riverside",
                    "West Branch"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Ash",
                    "Bedford",
                    "Berlin",
                    "Carleton",
                    "Dundee",
                    "Dundee",
                    "Erie",
                    "Estral Beach",
                    "Exeter",
                    "Frenchtown",
                    "Ida",
                    "La Salle",
                    "London",
                    "Luna Pier",
                    "Maybee",
                    "Milan",
                    "Monroe†",
                    "Monroe",
                    "Petersburg",
                    "Raisinville",
                    "South Rockwood",
                    "Summerfield",
                    "Whiteford"
                ]
            },
            {
                "countyName": "Montcalm",
                "cities": [
                    "Belvidere",
                    "Bloomer",
                    "Bushnell",
                    "Carson City",
                    "Cato",
                    "Crystal",
                    "Day",
                    "Douglass",
                    "Edmore",
                    "Eureka",
                    "Evergreen",
                    "Fairplain",
                    "Ferris",
                    "Greenville",
                    "Home",
                    "Howard City",
                    "Lakeview",
                    "McBride",
                    "Maple Valley",
                    "Montcalm",
                    "Pierson",
                    "Pierson",
                    "Pine",
                    "Reynolds",
                    "Richland",
                    "Sheridan",
                    "Sidney",
                    "Stanton†",
                    "Winfield"
                ]
            },
            {
                "countyName": "Montmorency",
                "cities": [
                    "Albert",
                    "Avery",
                    "Briley†",
                    "Hillman",
                    "Loud",
                    "Montmorency",
                    "Rust",
                    "Vienna"
                ]
            },
            {
                "countyName": "Montmorency, Alpena",
                "cities": [
                    "Hillman"
                ]
            },
            {
                "countyName": "Muskegon",
                "cities": [
                    "Blue Lake",
                    "Casnovia",
                    "Cedar Creek",
                    "Dalton",
                    "Egelston",
                    "Fruitland",
                    "Fruitport",
                    "Fruitport",
                    "Holton",
                    "Laketon",
                    "Lakewood Club",
                    "Montague",
                    "Montague",
                    "Moorland",
                    "Muskegon†",
                    "Muskegon",
                    "Muskegon Heights",
                    "North Muskegon",
                    "Norton Shores",
                    "Ravenna",
                    "Ravenna",
                    "Roosevelt Park",
                    "Sullivan",
                    "Whitehall",
                    "Whitehall",
                    "White River"
                ]
            },
            {
                "countyName": "Newaygo",
                "cities": [
                    "Ashland",
                    "Barton",
                    "Beaver",
                    "Big Prairie",
                    "Bridgeton",
                    "Brooks",
                    "Croton",
                    "Dayton",
                    "Denver",
                    "Ensley",
                    "Everett",
                    "Fremont",
                    "Garfield",
                    "Goodwell",
                    "Grant",
                    "Grant",
                    "Home",
                    "Lilley",
                    "Lincoln",
                    "Merrill",
                    "Monroe",
                    "Newaygo",
                    "Norwich",
                    "Sheridan",
                    "Sherman",
                    "Troy",
                    "White Cloud†",
                    "Wilcox"
                ]
            },
            {
                "countyName": "Newaygo, Oceana",
                "cities": [
                    "Hesperia"
                ]
            },
            {
                "countyName": "Oakland",
                "cities": [
                    "Addison",
                    "Auburn Hills",
                    "Berkley",
                    "Beverly Hills",
                    "Bingham Farms",
                    "Birmingham",
                    "Bloomfield",
                    "Bloomfield Hills",
                    "Brandon",
                    "Clawson",
                    "Commerce",
                    "Farmington",
                    "Farmington Hills",
                    "Ferndale",
                    "Franklin",
                    "Groveland",
                    "Hazel Park",
                    "Highland",
                    "Holly",
                    "Holly",
                    "Huntington Woods",
                    "Independence",
                    "Keego Harbor",
                    "Lake Angelus",
                    "Lake Orion",
                    "Lathrup Village",
                    "Leonard",
                    "Lyon",
                    "Madison Heights",
                    "Milford",
                    "Milford",
                    "Novi",
                    "Novi",
                    "Oakland",
                    "Oak Park",
                    "Orchard Lake Village",
                    "Orion",
                    "Ortonville",
                    "Oxford",
                    "Oxford",
                    "Pleasant Ridge",
                    "Pontiac†",
                    "Rochester",
                    "Rochester Hills",
                    "Rose",
                    "Royal Oak",
                    "Royal Oak",
                    "Southfield",
                    "Southfield",
                    "South Lyon",
                    "Springfield",
                    "Sylvan Lake",
                    "Troy",
                    "Village of Clarkston",
                    "Walled Lake",
                    "Waterford",
                    "West Bloomfield",
                    "White Lake",
                    "Wixom",
                    "Wolverine Lake"
                ]
            },
            {
                "countyName": "Oakland, Wayne",
                "cities": [
                    "Northville"
                ]
            },
            {
                "countyName": "Oceana",
                "cities": [
                    "Benona",
                    "Claybanks",
                    "Colfax",
                    "Crystal",
                    "Elbridge",
                    "Ferry",
                    "Golden",
                    "Grant",
                    "Greenwood",
                    "Hart†",
                    "Hart",
                    "Leavitt",
                    "New Era",
                    "Newfield",
                    "Otto",
                    "Pentwater",
                    "Pentwater",
                    "Rothbury",
                    "Shelby",
                    "Shelby",
                    "Walkerville",
                    "Weare"
                ]
            },
            {
                "countyName": "Ogemaw",
                "cities": [
                    "Churchill",
                    "Cumming",
                    "Edwards",
                    "Foster",
                    "Goodar",
                    "Hill",
                    "Horton",
                    "Klacking",
                    "Logan",
                    "Mills",
                    "Ogemaw",
                    "Prescott",
                    "Richland",
                    "Rose",
                    "Rose City",
                    "West Branch†",
                    "West Branch"
                ]
            },
            {
                "countyName": "Ontonagon",
                "cities": [
                    "Bergland",
                    "Bohemia",
                    "Carp Lake",
                    "Greenland",
                    "Haight",
                    "Interior",
                    "McMillan",
                    "Matchwood",
                    "Ontonagon†",
                    "Ontonagon",
                    "Rockland",
                    "Stannard"
                ]
            },
            {
                "countyName": "Osceola",
                "cities": [
                    "Burdell",
                    "Cedar",
                    "Evart",
                    "Evart",
                    "Hartwick",
                    "Hersey",
                    "Hersey",
                    "Highland",
                    "LeRoy",
                    "LeRoy",
                    "Lincoln",
                    "Marion",
                    "Marion",
                    "Middle Branch",
                    "Orient",
                    "Osceola",
                    "Reed City†",
                    "Richmond",
                    "Rose Lake",
                    "Sherman",
                    "Sylvan",
                    "Tustin"
                ]
            },
            {
                "countyName": "Oscoda",
                "cities": [
                    "Big Creek",
                    "Clinton",
                    "Comins",
                    "Elmer",
                    "Greenwood",
                    "Mentor"
                ]
            },
            {
                "countyName": "Otsego",
                "cities": [
                    "Bagley",
                    "Charlton",
                    "Chester",
                    "Corwith",
                    "Dover",
                    "Elmira",
                    "Gaylord†",
                    "Hayes",
                    "Livingston",
                    "Otsego Lake",
                    "Vanderbilt"
                ]
            },
            {
                "countyName": "Ottawa",
                "cities": [
                    "Allendale",
                    "Blendon",
                    "Chester",
                    "Coopersville",
                    "Crockery",
                    "Ferrysburg",
                    "Georgetown",
                    "Grand Haven†",
                    "Grand Haven",
                    "Holland",
                    "Hudsonville",
                    "Jamestown",
                    "Olive",
                    "Park",
                    "Polkton",
                    "Port Sheldon",
                    "Robinson",
                    "Spring Lake",
                    "Spring Lake",
                    "Tallmadge",
                    "Wright",
                    "Zeeland",
                    "Zeeland"
                ]
            },
            {
                "countyName": "Ottawa, Allegan",
                "cities": [
                    "Holland"
                ]
            },
            {
                "countyName": "Presque Isle",
                "cities": [
                    "Allis",
                    "Bearinger",
                    "Belknap",
                    "Bismarck",
                    "Case",
                    "Krakow",
                    "Metz",
                    "Millersburg",
                    "Moltke",
                    "North Allis",
                    "Ocqueoc",
                    "Onaway",
                    "Posen",
                    "Posen",
                    "Presque Isle",
                    "Pulawski",
                    "Rogers",
                    "Rogers City†"
                ]
            },
            {
                "countyName": "Roscommon",
                "cities": [
                    "Au Sable",
                    "Backus",
                    "Denton",
                    "Gerrish",
                    "Higgins",
                    "Lake",
                    "Lyon",
                    "Markey",
                    "Nester",
                    "Richfield",
                    "Roscommon†",
                    "Roscommon"
                ]
            },
            {
                "countyName": "Saginaw",
                "cities": [
                    "Albee",
                    "Birch Run",
                    "Birch Run",
                    "Blumfield",
                    "Brady",
                    "Brant",
                    "Bridgeport",
                    "Buena Vista",
                    "Carrollton",
                    "Chapin",
                    "Chesaning",
                    "Chesaning",
                    "Frankenmuth",
                    "Frankenmuth",
                    "Fremont",
                    "James",
                    "Jonesfield",
                    "Kochville",
                    "Lakefield",
                    "Maple Grove",
                    "Marion",
                    "Merrill",
                    "Oakley",
                    "Richland",
                    "Saginaw†",
                    "Saginaw",
                    "St. Charles",
                    "St. Charles",
                    "Spaulding",
                    "Swan Creek",
                    "Taymouth",
                    "Thomas",
                    "Tittabawassee",
                    "Zilwaukee",
                    "Zilwaukee"
                ]
            },
            {
                "countyName": "Sanilac",
                "cities": [
                    "Applegate",
                    "Argyle",
                    "Austin",
                    "Bridgehampton",
                    "Buel",
                    "Carsonville",
                    "Croswell",
                    "Custer",
                    "Deckerville",
                    "Delaware",
                    "Elk",
                    "Elmer",
                    "Evergreen",
                    "Flynn",
                    "Forester",
                    "Forestville",
                    "Fremont",
                    "Greenleaf",
                    "Lamotte",
                    "Lexington",
                    "Lexington",
                    "Maple Valley",
                    "Marion",
                    "Marlette",
                    "Marlette",
                    "Melvin",
                    "Minden",
                    "Minden City",
                    "Moore",
                    "Peck",
                    "Port Sanilac",
                    "Sandusky†",
                    "Sanilac",
                    "Speaker",
                    "Washington",
                    "Watertown",
                    "Wheatland",
                    "Worth"
                ]
            },
            {
                "countyName": "Sanilac, Lapeer",
                "cities": [
                    "Brown City"
                ]
            },
            {
                "countyName": "Schoolcraft",
                "cities": [
                    "Doyle",
                    "Germfask",
                    "Hiawatha",
                    "Inwood",
                    "Manistique†",
                    "Manistique",
                    "Mueller",
                    "Seney",
                    "Thompson"
                ]
            },
            {
                "countyName": "Shiawassee",
                "cities": [
                    "Antrim",
                    "Bancroft",
                    "Bennington",
                    "Burns",
                    "Byron",
                    "Caledonia",
                    "Corunna†",
                    "Durand",
                    "Fairfield",
                    "Hazelton",
                    "Laingsburg",
                    "Middlebury",
                    "Morrice",
                    "New Haven",
                    "New Lothrop",
                    "Owosso",
                    "Owosso",
                    "Perry",
                    "Perry",
                    "Rush",
                    "Sciota",
                    "Shiawassee",
                    "Venice",
                    "Vernon",
                    "Vernon",
                    "Woodhull"
                ]
            },
            {
                "countyName": "St. Clair",
                "cities": [
                    "Algonac",
                    "Berlin",
                    "Brockway",
                    "Burtchville",
                    "Capac",
                    "Casco",
                    "China",
                    "Clay",
                    "Clyde",
                    "Columbus",
                    "Cottrellville",
                    "East China",
                    "Emmett",
                    "Emmett",
                    "Fort Gratiot",
                    "Grant",
                    "Greenwood",
                    "Ira",
                    "Kenockee",
                    "Kimball",
                    "Lynn",
                    "Marine City",
                    "Marysville",
                    "Mussey",
                    "Port Huron†",
                    "Port Huron",
                    "Riley",
                    "St. Clair",
                    "St. Clair",
                    "Wales",
                    "Yale"
                ]
            },
            {
                "countyName": "St. Joseph",
                "cities": [
                    "Burr Oak",
                    "Burr Oak",
                    "Centreville†",
                    "Colon",
                    "Colon",
                    "Constantine",
                    "Constantine",
                    "Fabius",
                    "Fawn River",
                    "Florence",
                    "Flowerfield",
                    "Leonidas",
                    "Lockport",
                    "Mendon",
                    "Mendon",
                    "Mottville",
                    "Nottawa",
                    "Park",
                    "Sherman",
                    "Sturgis",
                    "Sturgis",
                    "Three Rivers",
                    "White Pigeon",
                    "White Pigeon"
                ]
            },
            {
                "countyName": "Tuscola",
                "cities": [
                    "Akron",
                    "Akron",
                    "Almer",
                    "Arbela",
                    "Caro†",
                    "Cass City",
                    "Columbia",
                    "Dayton",
                    "Denmark",
                    "Elkland",
                    "Ellington",
                    "Elmwood",
                    "Fairgrove",
                    "Fairgrove",
                    "Fremont",
                    "Gagetown",
                    "Gilford",
                    "Indianfields",
                    "Juniata",
                    "Kingston",
                    "Kingston",
                    "Koylton",
                    "Mayville",
                    "Millington",
                    "Millington",
                    "Novesta",
                    "Tuscola",
                    "Unionville",
                    "Vassar",
                    "Vassar",
                    "Watertown",
                    "Wells",
                    "Wisner"
                ]
            },
            {
                "countyName": "Tuscola, Saginaw",
                "cities": [
                    "Reese"
                ]
            },
            {
                "countyName": "Van Buren",
                "cities": [
                    "Almena",
                    "Antwerp",
                    "Arlington",
                    "Bangor",
                    "Bangor",
                    "Bloomingdale",
                    "Bloomingdale",
                    "Breedsville",
                    "Columbia",
                    "Covert",
                    "Decatur",
                    "Decatur",
                    "Geneva",
                    "Gobles",
                    "Hamilton",
                    "Hartford",
                    "Hartford",
                    "Keeler",
                    "Lawrence",
                    "Lawrence",
                    "Lawton",
                    "Mattawan",
                    "Paw Paw†",
                    "Paw Paw",
                    "Pine Grove",
                    "Porter",
                    "South Haven",
                    "Waverly"
                ]
            },
            {
                "countyName": "Van Buren, Allegan",
                "cities": [
                    "South Haven"
                ]
            },
            {
                "countyName": "Washtenaw",
                "cities": [
                    "Ann Arbor†",
                    "Ann Arbor",
                    "Augusta",
                    "Barton Hills",
                    "Bridgewater",
                    "Chelsea",
                    "Dexter",
                    "Dexter",
                    "Freedom",
                    "Lima",
                    "Lodi",
                    "Lyndon",
                    "Manchester",
                    "Manchester",
                    "Northfield",
                    "Pittsfield",
                    "Salem",
                    "Saline",
                    "Saline",
                    "Scio",
                    "Sharon",
                    "Superior",
                    "Sylvan",
                    "Webster",
                    "York",
                    "Ypsilanti",
                    "Ypsilanti"
                ]
            },
            {
                "countyName": "Washtenaw, Monroe",
                "cities": [
                    "Milan"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Allen Park",
                    "Belleville",
                    "Brownstown",
                    "Canton",
                    "Dearborn",
                    "Dearborn Heights",
                    "Detroit†",
                    "Ecorse",
                    "Garden City",
                    "Gibraltar",
                    "Grosse Ile",
                    "Grosse Pointe",
                    "Grosse Pointe Farms",
                    "Grosse Pointe Park",
                    "Grosse Pointe Woods",
                    "Hamtramck",
                    "Harper Woods",
                    "Highland Park",
                    "Huron",
                    "Inkster",
                    "Lincoln Park",
                    "Livonia",
                    "Melvindale",
                    "Northville",
                    "Plymouth",
                    "Plymouth",
                    "Redford",
                    "River Rouge",
                    "Riverview",
                    "Rockwood",
                    "Romulus",
                    "Southgate",
                    "Sumpter",
                    "Taylor",
                    "Trenton",
                    "Van Buren",
                    "Wayne",
                    "Westland",
                    "Woodhaven",
                    "Wyandotte"
                ]
            },
            {
                "countyName": "Wayne, Macomb",
                "cities": [
                    "Village of Grosse Pointe Shores"
                ]
            },
            {
                "countyName": "Wayne, Monroe",
                "cities": [
                    "Flat Rock"
                ]
            },
            {
                "countyName": "Wexford",
                "cities": [
                    "Antioch",
                    "Boon",
                    "Buckley",
                    "Cadillac†",
                    "Cedar Creek",
                    "Cherry Grove",
                    "Clam Lake",
                    "Colfax",
                    "Greenwood",
                    "Hanover",
                    "Haring",
                    "Harrietta",
                    "Henderson",
                    "Liberty",
                    "Manton",
                    "Mesick",
                    "Selma",
                    "Slagle",
                    "South Branch",
                    "Springville",
                    "Wexford"
                ]
            }
        ]
    },
    {
        "stateId": "MN",
        "stateName": "Minnesota",
        "county": [
            {
                "countyName": "Hennepin",
                "cities": [
                    "Minneapolis †",
                    "Bloomington",
                    "Brooklyn Park",
                    "Plymouth",
                    "Maple Grove",
                    "Eden Prairie",
                    "Minnetonka",
                    "Edina",
                    "St. Louis Park",
                    "Richfield",
                    "Brooklyn Center",
                    "Champlin",
                    "Crystal",
                    "Golden Valley",
                    "New Hope",
                    "Hopkins",
                    "Robbinsdale",
                    "Rogers",
                    "Mound",
                    "Minnetrista",
                    "Orono",
                    "Shorewood",
                    "Corcoran",
                    "Medina",
                    "Wayzata",
                    "Deephaven",
                    "Independence",
                    "Greenfield",
                    "Osseo",
                    "Excelsior",
                    "St. Bonifacius",
                    "Maple Plain",
                    "Long Lake",
                    "Spring Park",
                    "Tonka Bay",
                    "Greenwood",
                    "Loretto",
                    "Minnetonka Beach",
                    "Woodland",
                    "Medicine Lake"
                ]
            },
            {
                "countyName": "Ramsey",
                "cities": [
                    "Saint Paul ††",
                    "Maplewood",
                    "Roseville",
                    "Shoreview",
                    "New Brighton",
                    "Vadnais Heights",
                    "North St. Paul",
                    "Mounds View",
                    "Little Canada",
                    "Arden Hills",
                    "North Oaks",
                    "Falcon Heights",
                    "Lauderdale",
                    "Gem Lake"
                ]
            },
            {
                "countyName": "Olmsted",
                "cities": [
                    "Rochester †",
                    "Stewartville",
                    "Byron",
                    "Eyota",
                    "Oronoco",
                    "Dover"
                ]
            },
            {
                "countyName": "St. Louis",
                "cities": [
                    "Duluth †",
                    "Hibbing",
                    "Hermantown",
                    "Virginia",
                    "Chisholm",
                    "Rice Lake",
                    "Eveleth",
                    "Ely",
                    "Proctor",
                    "Mountain Iron",
                    "Hoyt Lakes",
                    "Gilbert",
                    "Aurora",
                    "Babbitt",
                    "Biwabik",
                    "Buhl",
                    "Cook",
                    "Floodwood",
                    "Tower",
                    "Orr",
                    "Winton",
                    "Kinney",
                    "Meadowlands",
                    "Brookston",
                    "Iron Junction",
                    "McKinley",
                    "Leonidas"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Woodbury",
                    "Cottage Grove",
                    "Oakdale",
                    "Forest Lake",
                    "Stillwater †",
                    "Hugo",
                    "Lake Elmo",
                    "Mahtomedi",
                    "St. Paul Park",
                    "Newport",
                    "Oak Park Heights",
                    "Grant",
                    "Scandia",
                    "Bayport",
                    "Afton",
                    "Lakeland",
                    "Dellwood",
                    "Lake St. Croix Beach",
                    "Birchwood Village",
                    "Landfall",
                    "Marine on St. Croix",
                    "Willernie",
                    "Pine Springs",
                    "St. Marys Point",
                    "Lakeland Shores"
                ]
            },
            {
                "countyName": "Dakota",
                "cities": [
                    "Lakeville",
                    "Eagan",
                    "Burnsville",
                    "Apple Valley",
                    "Inver Grove Heights",
                    "Rosemount",
                    "Farmington",
                    "West St. Paul",
                    "South St. Paul",
                    "Mendota Heights",
                    "Lilydale",
                    "Hampton",
                    "Sunfish Lake",
                    "Randolph",
                    "Vermillion",
                    "Mendota",
                    "Coates",
                    "Miesville",
                    "New Trier"
                ]
            },
            {
                "countyName": "Anoka\nRamsey",
                "cities": [
                    "Blaine",
                    "Spring Lake Park"
                ]
            },
            {
                "countyName": "Stearns\nBenton\nSherburne",
                "cities": [
                    "St. Cloud †"
                ]
            },
            {
                "countyName": "Anoka",
                "cities": [
                    "Coon Rapids",
                    "Andover",
                    "Fridley",
                    "Ramsey",
                    "Columbia Heights",
                    "Lino Lakes",
                    "Anoka †",
                    "Ham Lake",
                    "East Bethel",
                    "Oak Grove",
                    "Circle Pines",
                    "Nowthen",
                    "Columbus",
                    "Centerville",
                    "Lexington",
                    "Hilltop",
                    "Bethel"
                ]
            },
            {
                "countyName": "Scott",
                "cities": [
                    "Shakopee †",
                    "Savage",
                    "Prior Lake",
                    "Belle Plaine",
                    "Jordan",
                    "Credit River",
                    "Elko New Market"
                ]
            },
            {
                "countyName": "Blue Earth\nNicollet\nLe Sueur",
                "cities": [
                    "Mankato †"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Moorhead †",
                    "Dilworth",
                    "Barnesville",
                    "Hawley",
                    "Glyndon",
                    "Sabin",
                    "Ulen",
                    "Hitterdal",
                    "Felton",
                    "Comstock",
                    "Georgetown"
                ]
            },
            {
                "countyName": "Carver",
                "cities": [
                    "Chaska †",
                    "Waconia",
                    "Victoria",
                    "Carver",
                    "Watertown",
                    "Norwood Young America",
                    "Mayer",
                    "Cologne",
                    "Hamburg",
                    "New Germany"
                ]
            },
            {
                "countyName": "Sherburne",
                "cities": [
                    "Elk River †",
                    "Big Lake",
                    "Zimmerman",
                    "Becker",
                    "Clear Lake"
                ]
            },
            {
                "countyName": "Steele",
                "cities": [
                    "Owatonna †",
                    "Medford",
                    "Ellendale"
                ]
            },
            {
                "countyName": "Carver\nHennepin",
                "cities": [
                    "Chanhassen"
                ]
            },
            {
                "countyName": "Mower",
                "cities": [
                    "Austin †",
                    "Grand Meadow",
                    "Le Roy",
                    "Adams",
                    "Brownsdale",
                    "Lyle",
                    "Racine",
                    "Rose Creek",
                    "Dexter",
                    "Waltham",
                    "Mapleview",
                    "Elkton",
                    "Sargeant",
                    "Taopi"
                ]
            },
            {
                "countyName": "Winona",
                "cities": [
                    "Winona †",
                    "Goodview",
                    "St. Charles",
                    "Lewiston",
                    "Stockton",
                    "Rollingstone",
                    "Altura",
                    "Dakota",
                    "Utica",
                    "Minnesota City",
                    "Elba"
                ]
            },
            {
                "countyName": "Rice",
                "cities": [
                    "Faribault †",
                    "Lonsdale",
                    "Dundas",
                    "Morristown",
                    "Nerstrand"
                ]
            },
            {
                "countyName": "Ramsey\nWashington",
                "cities": [
                    "White Bear Lake"
                ]
            },
            {
                "countyName": "Wright",
                "cities": [
                    "Otsego",
                    "St. Michael",
                    "Buffalo †",
                    "Monticello",
                    "Albertville",
                    "Delano",
                    "Montrose",
                    "Annandale",
                    "Cokato",
                    "Maple Lake",
                    "Howard Lake",
                    "Waverly",
                    "South Haven"
                ]
            },
            {
                "countyName": "Dakota\nWashington",
                "cities": [
                    "Hastings †"
                ]
            },
            {
                "countyName": "Kandiyohi",
                "cities": [
                    "Willmar †",
                    "New London",
                    "Atwater",
                    "Spicer",
                    "Kandiyohi",
                    "Prinsburg",
                    "Pennock",
                    "Lake Lillian",
                    "Blomkest",
                    "Sunburg",
                    "Regal"
                ]
            },
            {
                "countyName": "Rice\nDakota",
                "cities": [
                    "Northfield"
                ]
            },
            {
                "countyName": "Stearns\nBenton",
                "cities": [
                    "Sartell"
                ]
            },
            {
                "countyName": "Freeborn",
                "cities": [
                    "Albert Lea †",
                    "Clarks Grove",
                    "Alden",
                    "Glenville",
                    "Geneva",
                    "Emmons",
                    "Hartland",
                    "Hollandale",
                    "Freeborn",
                    "Hayward",
                    "Conger",
                    "Twin Lakes",
                    "Manchester",
                    "Myrtle"
                ]
            },
            {
                "countyName": "Goodhue",
                "cities": [
                    "Red Wing †",
                    "Cannon Falls",
                    "Zumbrota",
                    "Kenyon",
                    "Goodhue",
                    "Wanamingo"
                ]
            },
            {
                "countyName": "Beltrami",
                "cities": [
                    "Bemidji †",
                    "Blackduck",
                    "Wilton",
                    "Kelliher",
                    "Tenstrike",
                    "Turtle River",
                    "Solway",
                    "Funkley"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Alexandria †",
                    "Evansville",
                    "Brandon",
                    "Carlos",
                    "Miltona",
                    "Garfield",
                    "Kensington",
                    "Nelson",
                    "Forada",
                    "Millerville"
                ]
            },
            {
                "countyName": "McLeod",
                "cities": [
                    "Hutchinson",
                    "Glencoe †",
                    "Winsted",
                    "Lester Prairie",
                    "Silver Lake",
                    "Brownton",
                    "Stewart",
                    "Plato",
                    "Biscay"
                ]
            },
            {
                "countyName": "Crow Wing",
                "cities": [
                    "Brainerd †",
                    "Baxter",
                    "Breezy Point",
                    "Pequot Lakes",
                    "Crosslake",
                    "Crosby",
                    "Nisswa",
                    "Emily",
                    "Deerwood",
                    "Ironton",
                    "Jenkins",
                    "Fifty Lakes",
                    "Cuyuna",
                    "Garrison",
                    "Riverton",
                    "Trommald",
                    "Fort Ripley",
                    "Manhattan Beach"
                ]
            },
            {
                "countyName": "Nicollet\nBlue Earth",
                "cities": [
                    "North Mankato"
                ]
            },
            {
                "countyName": "Otter Tail",
                "cities": [
                    "Fergus Falls †",
                    "Perham",
                    "Pelican Rapids",
                    "New York Mills",
                    "Parkers Prairie",
                    "Battle Lake",
                    "Henning",
                    "Ottertail",
                    "Underwood",
                    "Vergas",
                    "Deer Creek",
                    "Dalton",
                    "Bluffton",
                    "Dent",
                    "Elizabeth",
                    "Erhard",
                    "Richville",
                    "Clitherall",
                    "Vining",
                    "Urbank"
                ]
            },
            {
                "countyName": "Brown",
                "cities": [
                    "New Ulm †",
                    "Sleepy Eye",
                    "Springfield",
                    "Hanska",
                    "Evan",
                    "Cobden"
                ]
            },
            {
                "countyName": "Benton",
                "cities": [
                    "Sauk Rapids",
                    "Foley †",
                    "Rice",
                    "Gilman"
                ]
            },
            {
                "countyName": "Nobles",
                "cities": [
                    "Worthington †",
                    "Adrian",
                    "Brewster",
                    "Ellsworth",
                    "Round Lake",
                    "Rushmore",
                    "Wilmont",
                    "Bigelow",
                    "Lismore",
                    "Dundee",
                    "Kinbrae"
                ]
            },
            {
                "countyName": "Lyon",
                "cities": [
                    "Marshall †",
                    "Tracy",
                    "Minneota",
                    "Cottonwood",
                    "Balaton",
                    "Lynd",
                    "Ghent",
                    "Russell",
                    "Taunton",
                    "Garvin",
                    "Florence"
                ]
            },
            {
                "countyName": "Carlton",
                "cities": [
                    "Cloquet",
                    "Moose Lake",
                    "Scanlon",
                    "Carlton †",
                    "Barnum",
                    "Wrenshall",
                    "Cromwell",
                    "Kettle River",
                    "Wright"
                ]
            },
            {
                "countyName": "Nicollet",
                "cities": [
                    "St. Peter †",
                    "Nicollet",
                    "Courtland",
                    "Lafayette"
                ]
            },
            {
                "countyName": "Chisago",
                "cities": [
                    "North Branch",
                    "Wyoming",
                    "Chisago City",
                    "Lindstrom",
                    "Rush City",
                    "Stacy",
                    "Shafer",
                    "Harris",
                    "Taylors Falls",
                    "Center City †"
                ]
            },
            {
                "countyName": "Itasca",
                "cities": [
                    "Grand Rapids †",
                    "Cohasset",
                    "Coleraine",
                    "Keewatin",
                    "Nashwauk",
                    "Deer River",
                    "Bovey",
                    "La Prairie",
                    "Taconite",
                    "Marble",
                    "Bigfork",
                    "Calumet",
                    "Warba",
                    "Effie",
                    "Squaw Lake",
                    "Zemple"
                ]
            },
            {
                "countyName": "Isanti",
                "cities": [
                    "Cambridge †",
                    "Isanti"
                ]
            },
            {
                "countyName": "Martin",
                "cities": [
                    "Fairmont †",
                    "Truman",
                    "Sherburn",
                    "Welcome",
                    "Trimont",
                    "Ceylon",
                    "Granada",
                    "Northrop",
                    "Dunnell"
                ]
            },
            {
                "countyName": "Hennepin\nRamsey",
                "cities": [
                    "St. Anthony Village"
                ]
            },
            {
                "countyName": "Becker",
                "cities": [
                    "Detroit Lakes †",
                    "Frazee",
                    "Lake Park",
                    "Audubon",
                    "Ogema",
                    "Callaway",
                    "Wolf Lake"
                ]
            },
            {
                "countyName": "Hennepin\nWright",
                "cities": [
                    "Dayton"
                ]
            },
            {
                "countyName": "Waseca",
                "cities": [
                    "Waseca †",
                    "Janesville",
                    "New Richland",
                    "Waldorf"
                ]
            },
            {
                "countyName": "Morrison",
                "cities": [
                    "Little Falls †",
                    "Pierz",
                    "Randall",
                    "Upsala",
                    "Buckman",
                    "Bowlus",
                    "Flensburg",
                    "Sobieski",
                    "Harding",
                    "Lastrup",
                    "Elmdale",
                    "Genola",
                    "Hillman"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "East Grand Forks",
                    "Crookston †",
                    "Fosston",
                    "Fertile",
                    "McIntosh",
                    "Fisher",
                    "Erskine",
                    "Climax",
                    "Winger",
                    "Mentor",
                    "Lengby",
                    "Beltrami",
                    "Nielsville",
                    "Gully",
                    "Trail"
                ]
            },
            {
                "countyName": "Pennington",
                "cities": [
                    "Thief River Falls †",
                    "St. Hilaire",
                    "Goodridge"
                ]
            },
            {
                "countyName": "Anoka\nIsanti",
                "cities": [
                    "St. Francis"
                ]
            },
            {
                "countyName": "Stearns",
                "cities": [
                    "Waite Park",
                    "St. Joseph",
                    "Sauk Centre",
                    "Cold Spring",
                    "St. Augusta",
                    "Melrose",
                    "Albany",
                    "Paynesville",
                    "Rockville",
                    "Avon",
                    "Richmond",
                    "Kimball",
                    "St. Stephen",
                    "Holdingford",
                    "Belgrade",
                    "Freeport",
                    "New Munich",
                    "St. Martin",
                    "Elrosa",
                    "Greenwald",
                    "Meire Grove",
                    "Roscoe",
                    "St. Anthony",
                    "Lake Henry",
                    "Spring Hill",
                    "St. Rosa"
                ]
            },
            {
                "countyName": "Scott\nLe Sueur",
                "cities": [
                    "New Prague"
                ]
            },
            {
                "countyName": "Dodge",
                "cities": [
                    "Kasson",
                    "Dodge Center",
                    "Hayfield",
                    "Mantorville †",
                    "West Concord",
                    "Claremont"
                ]
            },
            {
                "countyName": "Meeker",
                "cities": [
                    "Litchfield †",
                    "Dassel",
                    "Watkins",
                    "Grove City",
                    "Cosmos",
                    "Darwin",
                    "Kingston",
                    "Cedar Mills"
                ]
            },
            {
                "countyName": "Koochiching",
                "cities": [
                    "International Falls †",
                    "Ranier",
                    "Littlefork",
                    "Big Falls",
                    "Northome",
                    "Mizpah"
                ]
            },
            {
                "countyName": "Houston\nWinona",
                "cities": [
                    "La Crescent"
                ]
            },
            {
                "countyName": "Mille Lacs\nSherburne",
                "cities": [
                    "Princeton"
                ]
            },
            {
                "countyName": "Wabasha\nGoodhue",
                "cities": [
                    "Lake City"
                ]
            },
            {
                "countyName": "Chippewa",
                "cities": [
                    "Montevideo †",
                    "Clara City",
                    "Milan",
                    "Maynard",
                    "Watson"
                ]
            },
            {
                "countyName": "Stevens",
                "cities": [
                    "Morris †",
                    "Hancock",
                    "Chokio",
                    "Donnelly",
                    "Alberta"
                ]
            },
            {
                "countyName": "Redwood\nRenville",
                "cities": [
                    "Redwood Falls †"
                ]
            },
            {
                "countyName": "Rock",
                "cities": [
                    "Luverne †",
                    "Hills",
                    "Beaver Creek",
                    "Magnolia",
                    "Hardwick",
                    "Steen",
                    "Kenneth"
                ]
            },
            {
                "countyName": "Cottonwood",
                "cities": [
                    "Windom †",
                    "Mountain Lake",
                    "Westbrook",
                    "Jeffers",
                    "Storden",
                    "Bingham Lake"
                ]
            },
            {
                "countyName": "Watonwan",
                "cities": [
                    "St. James †",
                    "Madelia",
                    "Butterfield",
                    "Lewisville",
                    "Odin",
                    "Darfur",
                    "La Salle"
                ]
            },
            {
                "countyName": "Wright\nHennepin",
                "cities": [
                    "Rockford",
                    "Hanover"
                ]
            },
            {
                "countyName": "Wadena\nOtter Tail",
                "cities": [
                    "Wadena †"
                ]
            },
            {
                "countyName": "Hubbard",
                "cities": [
                    "Park Rapids †",
                    "Akeley",
                    "Nevis",
                    "Laporte"
                ]
            },
            {
                "countyName": "Le Sueur\nNicollet\nSibley",
                "cities": [
                    "Le Sueur"
                ]
            },
            {
                "countyName": "Pipestone",
                "cities": [
                    "Pipestone †",
                    "Edgerton",
                    "Ruthton",
                    "Holland",
                    "Woodstock",
                    "Trosky",
                    "Ihlen",
                    "Hatfield"
                ]
            },
            {
                "countyName": "Goodhue\nOlmsted",
                "cities": [
                    "Pine Island"
                ]
            },
            {
                "countyName": "Kanabec",
                "cities": [
                    "Mora †",
                    "Ogilvie",
                    "Grasston",
                    "Quamba"
                ]
            },
            {
                "countyName": "Todd",
                "cities": [
                    "Long Prairie †",
                    "Browerville",
                    "Clarissa",
                    "Bertha",
                    "Eagle Bend",
                    "Grey Eagle",
                    "Hewitt",
                    "Burtrum",
                    "West Union"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Two Harbors †",
                    "Silver Bay",
                    "Beaver Bay"
                ]
            },
            {
                "countyName": "Wabasha",
                "cities": [
                    "Plainview",
                    "Wabasha †",
                    "Elgin",
                    "Mazeppa",
                    "Kellogg",
                    "Zumbro Falls",
                    "Millville",
                    "Hammond"
                ]
            },
            {
                "countyName": "Le Sueur",
                "cities": [
                    "Montgomery",
                    "Le Center †",
                    "Waterville",
                    "Cleveland",
                    "Kasota",
                    "Kilkenny",
                    "Heidelberg"
                ]
            },
            {
                "countyName": "Swift",
                "cities": [
                    "Benson †",
                    "Appleton",
                    "Kerkhoven",
                    "Murdock",
                    "Clontarf",
                    "De Graff",
                    "Danvers",
                    "Holloway"
                ]
            },
            {
                "countyName": "Pine",
                "cities": [
                    "Pine City †",
                    "Sandstone",
                    "Hinckley",
                    "Rock Creek",
                    "Sturgeon Lake",
                    "Willow River",
                    "Askov",
                    "Finlayson",
                    "Rutledge",
                    "Brook Park",
                    "Bruno",
                    "Kerrick",
                    "Henriette",
                    "Denham"
                ]
            },
            {
                "countyName": "Wilkin",
                "cities": [
                    "Breckenridge †",
                    "Campbell",
                    "Foxhome",
                    "Wolverton",
                    "Nashua",
                    "Kent",
                    "Doran"
                ]
            },
            {
                "countyName": "Blue Earth",
                "cities": [
                    "Eagle Lake",
                    "Lake Crystal",
                    "Mapleton",
                    "Madison Lake",
                    "St. Clair",
                    "Good Thunder",
                    "Amboy",
                    "Vernon Center",
                    "Skyline",
                    "Pemberton"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Jackson †",
                    "Lakefield",
                    "Heron Lake",
                    "Okabena",
                    "Alpha",
                    "Wilder"
                ]
            },
            {
                "countyName": "Faribault",
                "cities": [
                    "Blue Earth †",
                    "Wells",
                    "Winnebago",
                    "Elmore",
                    "Kiester",
                    "Bricelyn",
                    "Frost",
                    "Easton",
                    "Delavan",
                    "Walters"
                ]
            },
            {
                "countyName": "Mille Lacs",
                "cities": [
                    "Milaca †",
                    "Isle",
                    "Onamia",
                    "Foreston",
                    "Wahkon",
                    "Pease",
                    "Bock"
                ]
            },
            {
                "countyName": "Todd\nWadena",
                "cities": [
                    "Staples"
                ]
            },
            {
                "countyName": "Fillmore\nOlmsted",
                "cities": [
                    "Chatfield"
                ]
            },
            {
                "countyName": "Houston",
                "cities": [
                    "Caledonia †",
                    "Spring Grove",
                    "Houston",
                    "Brownsville",
                    "Hokah",
                    "Eitzen"
                ]
            },
            {
                "countyName": "Roseau",
                "cities": [
                    "Roseau †",
                    "Warroad",
                    "Greenbush",
                    "Badger",
                    "Strathcona"
                ]
            },
            {
                "countyName": "Yellow Medicine\nChippewa",
                "cities": [
                    "Granite Falls †"
                ]
            },
            {
                "countyName": "Pope",
                "cities": [
                    "Glenwood †",
                    "Starbuck",
                    "Long Beach",
                    "Lowry",
                    "Cyrus",
                    "Villard",
                    "Farwell",
                    "Sedan",
                    "Westport"
                ]
            },
            {
                "countyName": "Fillmore",
                "cities": [
                    "Spring Valley",
                    "Rushford",
                    "Preston †",
                    "Harmony",
                    "Rushford Village",
                    "Lanesboro",
                    "Mabel",
                    "Wykoff",
                    "Fountain",
                    "Canton",
                    "Peterson",
                    "Ostrander",
                    "Whalan"
                ]
            },
            {
                "countyName": "Renville",
                "cities": [
                    "Olivia †",
                    "Renville",
                    "Fairfax",
                    "Hector",
                    "Bird Island",
                    "Buffalo Lake",
                    "Franklin",
                    "Sacred Heart",
                    "Danube",
                    "Morton"
                ]
            },
            {
                "countyName": "Sibley",
                "cities": [
                    "Gaylord †",
                    "Arlington",
                    "Winthrop",
                    "Henderson",
                    "Gibbon",
                    "Green Isle",
                    "New Auburn"
                ]
            },
            {
                "countyName": "Aitkin",
                "cities": [
                    "Aitkin †",
                    "Hill City",
                    "McGregor",
                    "Palisade",
                    "Tamarack",
                    "McGrath"
                ]
            },
            {
                "countyName": "Wright\nStearns",
                "cities": [
                    "Clearwater"
                ]
            },
            {
                "countyName": "Big Stone",
                "cities": [
                    "Ortonville †",
                    "Graceville",
                    "Clinton",
                    "Beardsley",
                    "Odessa",
                    "Correll",
                    "Johnson",
                    "Barry"
                ]
            },
            {
                "countyName": "Murray",
                "cities": [
                    "Slayton †",
                    "Fulda",
                    "Chandler",
                    "Lake Wilson",
                    "Currie",
                    "Iona",
                    "Avoca",
                    "Dovray",
                    "Hadley"
                ]
            },
            {
                "countyName": "Steele\nDodge",
                "cities": [
                    "Blooming Prairie"
                ]
            },
            {
                "countyName": "Isanti\nKanabec",
                "cities": [
                    "Braham"
                ]
            },
            {
                "countyName": "Douglas\nTodd",
                "cities": [
                    "Osakis"
                ]
            },
            {
                "countyName": "Norman",
                "cities": [
                    "Ada †",
                    "Twin Valley",
                    "Halstad",
                    "Hendrum",
                    "Gary",
                    "Shelly",
                    "Perley",
                    "Borup"
                ]
            },
            {
                "countyName": "Yellow Medicine",
                "cities": [
                    "Canby",
                    "Clarkfield",
                    "Wood Lake",
                    "Hanley Falls",
                    "Echo",
                    "Porter",
                    "St. Leo",
                    "Hazel Run"
                ]
            },
            {
                "countyName": "Marshall",
                "cities": [
                    "Warren †",
                    "Stephen",
                    "Argyle",
                    "Alvarado",
                    "Newfolden",
                    "Middle River",
                    "Oslo",
                    "Grygla",
                    "Holt",
                    "Viking",
                    "Strandquist"
                ]
            },
            {
                "countyName": "Lac qui Parle",
                "cities": [
                    "Madison †",
                    "Dawson",
                    "Bellingham",
                    "Boyd",
                    "Marietta",
                    "Nassau",
                    "Louisburg"
                ]
            },
            {
                "countyName": "Traverse",
                "cities": [
                    "Wheaton †",
                    "Browns Valley",
                    "Dumont",
                    "Tintah"
                ]
            },
            {
                "countyName": "Cook",
                "cities": [
                    "Grand Marais †"
                ]
            },
            {
                "countyName": "Wadena",
                "cities": [
                    "Menahga",
                    "Sebeka",
                    "Verndale",
                    "Nimrod",
                    "Aldrich"
                ]
            },
            {
                "countyName": "Red Lake",
                "cities": [
                    "Red Lake Falls †",
                    "Oklee",
                    "Plummer",
                    "Brooks"
                ]
            },
            {
                "countyName": "Clearwater",
                "cities": [
                    "Bagley †",
                    "Clearbrook",
                    "Gonvick",
                    "Shevlin",
                    "Leonard"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Elbow Lake †",
                    "Hoffman",
                    "Ashby",
                    "Herman",
                    "Barrett",
                    "Wendell",
                    "Norcross"
                ]
            },
            {
                "countyName": "Morrison\nBenton",
                "cities": [
                    "Royalton"
                ]
            },
            {
                "countyName": "Mahnomen",
                "cities": [
                    "Mahnomen †",
                    "Waubun",
                    "Bejou"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Tyler",
                    "Lake Benton",
                    "Hendricks",
                    "Ivanhoe †",
                    "Arco"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Lake Shore",
                    "East Gull Lake",
                    "Walker †",
                    "Pine River",
                    "Cass Lake",
                    "Pillager",
                    "Remer",
                    "Hackensack",
                    "Backus",
                    "Longville",
                    "Bena",
                    "Chickamaw Beach",
                    "Federal Dam",
                    "Boy River"
                ]
            },
            {
                "countyName": "Meeker\nStearns",
                "cities": [
                    "Eden Valley"
                ]
            },
            {
                "countyName": "Lake of the Woods",
                "cities": [
                    "Baudette †",
                    "Williams"
                ]
            },
            {
                "countyName": "Redwood",
                "cities": [
                    "Morgan",
                    "Lamberton",
                    "Walnut Grove",
                    "Wabasso",
                    "Sanborn",
                    "Belview",
                    "Vesta",
                    "Milroy",
                    "Lucan",
                    "Clements",
                    "Revere",
                    "Seaforth",
                    "Wanda",
                    "Delhi"
                ]
            },
            {
                "countyName": "Kittson",
                "cities": [
                    "Hallock †",
                    "Karlstad",
                    "Lancaster",
                    "Lake Bronson",
                    "Kennedy",
                    "Halma",
                    "St. Vincent",
                    "Humboldt",
                    "Donaldson"
                ]
            },
            {
                "countyName": "Kandiyohi\nChippewa",
                "cities": [
                    "Raymond"
                ]
            },
            {
                "countyName": "Le Sueur\nWaseca",
                "cities": [
                    "Elysian"
                ]
            },
            {
                "countyName": "Morrison\nCass",
                "cities": [
                    "Motley"
                ]
            },
            {
                "countyName": "Faribault\nBlue Earth",
                "cities": [
                    "Minnesota Lake"
                ]
            },
            {
                "countyName": "Stearns\nPope",
                "cities": [
                    "Brooten"
                ]
            },
            {
                "countyName": "Pipestone\nRock",
                "cities": [
                    "Jasper"
                ]
            },
            {
                "countyName": "Wilkin\nOtter Tail",
                "cities": [
                    "Rothsay"
                ]
            },
            {
                "countyName": "Brown\nCottonwood",
                "cities": [
                    "Comfrey"
                ]
            },
            {
                "countyName": "Morrison\nTodd",
                "cities": [
                    "Swanville"
                ]
            },
            {
                "countyName": "Goodhue\nRice",
                "cities": [
                    "Dennison"
                ]
            },
            {
                "countyName": "Goodhue\nWabasha",
                "cities": [
                    "Bellechester"
                ]
            },
            {
                "countyName": "Roseau\nLake of the Woods",
                "cities": [
                    "Roosevelt"
                ]
            },
            {
                "countyName": "Watonwan\nMartin",
                "cities": [
                    "Ormsby"
                ]
            },
            {
                "countyName": "Wabasha\nWinona",
                "cities": [
                    "Minneiska"
                ]
            }
        ]
    },
    {
        "stateId": "MO",
        "stateName": "Missouri",
        "county": [
            {
                "countyName": "Adair",
                "cities": [
                    "Kirksville †"
                ]
            },
            {
                "countyName": "Audrain",
                "cities": [
                    "Mexico †"
                ]
            },
            {
                "countyName": "Barry, Lawrence",
                "cities": [
                    "Monett"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Columbia †"
                ]
            },
            {
                "countyName": "Buchanan",
                "cities": [
                    "St. Joseph †"
                ]
            },
            {
                "countyName": "Butler",
                "cities": [
                    "Poplar Bluff †"
                ]
            },
            {
                "countyName": "Callaway",
                "cities": [
                    "Fulton †"
                ]
            },
            {
                "countyName": "Cape Girardeau",
                "cities": [
                    "Cape Girardeau",
                    "Jackson †"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Belton",
                    "Raymore",
                    "Harrisonville †",
                    "Pleasant Hill"
                ]
            },
            {
                "countyName": "Christian",
                "cities": [
                    "Nixa",
                    "Ozark †"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Liberty †",
                    "Gladstone",
                    "Excelsior Springs",
                    "Kearney",
                    "Smithville"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Cameron"
                ]
            },
            {
                "countyName": "Cole",
                "cities": [
                    "Jefferson City ††"
                ]
            },
            {
                "countyName": "Cooper",
                "cities": [
                    "Boonville †"
                ]
            },
            {
                "countyName": "Dunklin",
                "cities": [
                    "Kennett †"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Washington",
                    "Union †"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Springfield †",
                    "Republic"
                ]
            },
            {
                "countyName": "Henry",
                "cities": [
                    "Clinton †"
                ]
            },
            {
                "countyName": "Howell",
                "cities": [
                    "West Plains †"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Independence †",
                    "Lee's Summit",
                    "Blue Springs",
                    "Raytown",
                    "Grandview",
                    "Grain Valley",
                    "Oak Grove"
                ]
            },
            {
                "countyName": "Jackson, Platte, Cass, Clay",
                "cities": [
                    "Kansas City †"
                ]
            },
            {
                "countyName": "Jasper",
                "cities": [
                    "Carthage †",
                    "Webb City",
                    "Carl Junction"
                ]
            },
            {
                "countyName": "Jasper, Newton",
                "cities": [
                    "Joplin"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Arnold",
                    "Festus"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Warrensburg †"
                ]
            },
            {
                "countyName": "Laclede",
                "cities": [
                    "Lebanon †"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Troy †"
                ]
            },
            {
                "countyName": "Livingston",
                "cities": [
                    "Chillicothe †"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Hannibal"
                ]
            },
            {
                "countyName": "Newton",
                "cities": [
                    "Neosho †"
                ]
            },
            {
                "countyName": "Nodaway",
                "cities": [
                    "Maryville †"
                ]
            },
            {
                "countyName": "none (independent city)",
                "cities": [
                    "St. Louis"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Perryville †"
                ]
            },
            {
                "countyName": "Pettis",
                "cities": [
                    "Sedalia †"
                ]
            },
            {
                "countyName": "Phelps",
                "cities": [
                    "Rolla †"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Bolivar †"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Moberly"
                ]
            },
            {
                "countyName": "Saline",
                "cities": [
                    "Marshall †"
                ]
            },
            {
                "countyName": "Scott",
                "cities": [
                    "Sikeston"
                ]
            },
            {
                "countyName": "St. Charles",
                "cities": [
                    "O'Fallon",
                    "St. Charles †",
                    "St. Peters",
                    "Wentzville",
                    "Lake St. Louis",
                    "Dardenne Prairie"
                ]
            },
            {
                "countyName": "St. Francois",
                "cities": [
                    "Farmington †",
                    "Park Hills"
                ]
            },
            {
                "countyName": "St. Louis",
                "cities": [
                    "Florissant",
                    "Chesterfield",
                    "Wildwood",
                    "University City",
                    "Ballwin",
                    "Kirkwood",
                    "Maryland Heights",
                    "Hazelwood",
                    "Webster Groves",
                    "Ferguson",
                    "Creve Coeur",
                    "Manchester",
                    "Clayton †",
                    "Overland",
                    "Jennings",
                    "St. Ann",
                    "Crestwood",
                    "Bridgeton",
                    "Town and Country",
                    "Eureka",
                    "Bellefontaine Neighbors",
                    "Ellisville",
                    "Berkeley",
                    "Richmond Heights",
                    "Des Peres",
                    "Ladue",
                    "Sunset Hills",
                    "Maplewood",
                    "Brentwood",
                    "Olivette"
                ]
            },
            {
                "countyName": "Taney",
                "cities": [
                    "Branson"
                ]
            },
            {
                "countyName": "Vernon",
                "cities": [
                    "Nevada †"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Warrenton †"
                ]
            }
        ]
    },
    {
        "stateId": "MT",
        "stateName": "Montana",
        "county": [
            {
                "countyName": "Beaverhead",
                "cities": [
                    "Dillon†",
                    "Lima"
                ]
            },
            {
                "countyName": "Big Horn",
                "cities": [
                    "Hardin†",
                    "Lodge Grass"
                ]
            },
            {
                "countyName": "Blaine",
                "cities": [
                    "Chinook†",
                    "Harlem"
                ]
            },
            {
                "countyName": "Broadwater",
                "cities": [
                    "Townsend†"
                ]
            },
            {
                "countyName": "Carbon",
                "cities": [
                    "Bearcreek",
                    "Bridger",
                    "Fromberg",
                    "Joliet",
                    "Red Lodge†"
                ]
            },
            {
                "countyName": "Carter",
                "cities": [
                    "Ekalaka†"
                ]
            },
            {
                "countyName": "Cascade",
                "cities": [
                    "Belt",
                    "Cascade",
                    "Great Falls†",
                    "Neihart"
                ]
            },
            {
                "countyName": "Chouteau",
                "cities": [
                    "Big Sandy",
                    "Fort Benton†",
                    "Geraldine"
                ]
            },
            {
                "countyName": "Custer",
                "cities": [
                    "Ismay",
                    "Miles City†"
                ]
            },
            {
                "countyName": "Daniels",
                "cities": [
                    "Flaxville",
                    "Scobey†"
                ]
            },
            {
                "countyName": "Dawson",
                "cities": [
                    "Glendive†",
                    "Richey"
                ]
            },
            {
                "countyName": "Deer Lodge",
                "cities": [
                    "Anaconda†"
                ]
            },
            {
                "countyName": "Fallon",
                "cities": [
                    "Baker†",
                    "Plevna"
                ]
            },
            {
                "countyName": "Fergus",
                "cities": [
                    "Denton",
                    "Grass Range",
                    "Lewistown†",
                    "Moore",
                    "Winifred"
                ]
            },
            {
                "countyName": "Flathead",
                "cities": [
                    "Columbia Falls",
                    "Kalispell†",
                    "Whitefish"
                ]
            },
            {
                "countyName": "Gallatin",
                "cities": [
                    "Belgrade",
                    "Bozeman†",
                    "Manhattan",
                    "Three Forks",
                    "West Yellowstone"
                ]
            },
            {
                "countyName": "Garfield",
                "cities": [
                    "Jordan†"
                ]
            },
            {
                "countyName": "Glacier",
                "cities": [
                    "Browning",
                    "Cut Bank†"
                ]
            },
            {
                "countyName": "Golden Valley",
                "cities": [
                    "Lavina",
                    "Ryegate†"
                ]
            },
            {
                "countyName": "Granite",
                "cities": [
                    "Drummond",
                    "Philipsburg†"
                ]
            },
            {
                "countyName": "Hill",
                "cities": [
                    "Havre†",
                    "Hingham"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Boulder†",
                    "Whitehall"
                ]
            },
            {
                "countyName": "Judith Basin",
                "cities": [
                    "Hobson",
                    "Stanford†"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Polson†",
                    "Ronan",
                    "St. Ignatius"
                ]
            },
            {
                "countyName": "Lewis and Clark",
                "cities": [
                    "East Helena",
                    "Helena‡"
                ]
            },
            {
                "countyName": "Liberty",
                "cities": [
                    "Chester†"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Eureka",
                    "Libby†",
                    "Rexford",
                    "Troy"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Ennis",
                    "Sheridan",
                    "Twin Bridges",
                    "Virginia City†"
                ]
            },
            {
                "countyName": "McCone",
                "cities": [
                    "Circle†"
                ]
            },
            {
                "countyName": "Meagher",
                "cities": [
                    "White Sulphur Springs†"
                ]
            },
            {
                "countyName": "Mineral",
                "cities": [
                    "Alberton",
                    "Superior†"
                ]
            },
            {
                "countyName": "Missoula",
                "cities": [
                    "Missoula†"
                ]
            },
            {
                "countyName": "Musselshell",
                "cities": [
                    "Melstone",
                    "Roundup†"
                ]
            },
            {
                "countyName": "Park",
                "cities": [
                    "Clyde Park",
                    "Livingston†"
                ]
            },
            {
                "countyName": "Petroleum",
                "cities": [
                    "Winnett†"
                ]
            },
            {
                "countyName": "Phillips",
                "cities": [
                    "Dodson",
                    "Malta†",
                    "Saco"
                ]
            },
            {
                "countyName": "Pondera",
                "cities": [
                    "Conrad†",
                    "Valier"
                ]
            },
            {
                "countyName": "Powder River",
                "cities": [
                    "Broadus†"
                ]
            },
            {
                "countyName": "Powell",
                "cities": [
                    "Deer Lodge†"
                ]
            },
            {
                "countyName": "Prairie",
                "cities": [
                    "Terry†"
                ]
            },
            {
                "countyName": "Ravalli",
                "cities": [
                    "Darby",
                    "Hamilton†",
                    "Pinesdale",
                    "Stevensville"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Fairview",
                    "Sidney†"
                ]
            },
            {
                "countyName": "Roosevelt",
                "cities": [
                    "Bainville",
                    "Culbertson",
                    "Froid",
                    "Poplar",
                    "Wolf Point†"
                ]
            },
            {
                "countyName": "Rosebud",
                "cities": [
                    "Colstrip",
                    "Forsyth†"
                ]
            },
            {
                "countyName": "Sanders",
                "cities": [
                    "Hot Springs",
                    "Plains",
                    "Thompson Falls†"
                ]
            },
            {
                "countyName": "Sheridan",
                "cities": [
                    "Medicine Lake",
                    "Outlook",
                    "Plentywood†",
                    "Westby"
                ]
            },
            {
                "countyName": "Silver Bow",
                "cities": [
                    "Butte†",
                    "Walkerville"
                ]
            },
            {
                "countyName": "Stillwater",
                "cities": [
                    "Columbus†"
                ]
            },
            {
                "countyName": "Sweet Grass",
                "cities": [
                    "Big Timber†"
                ]
            },
            {
                "countyName": "Teton",
                "cities": [
                    "Choteau†",
                    "Dutton",
                    "Fairfield"
                ]
            },
            {
                "countyName": "Toole",
                "cities": [
                    "Kevin",
                    "Shelby†",
                    "Sunburst"
                ]
            },
            {
                "countyName": "Treasure",
                "cities": [
                    "Hysham†"
                ]
            },
            {
                "countyName": "Valley",
                "cities": [
                    "Fort Peck",
                    "Glasgow†",
                    "Nashua",
                    "Opheim"
                ]
            },
            {
                "countyName": "Wheatland",
                "cities": [
                    "Harlowton†",
                    "Judith Gap"
                ]
            },
            {
                "countyName": "Wibaux",
                "cities": [
                    "Wibaux†"
                ]
            },
            {
                "countyName": "Yellowstone",
                "cities": [
                    "Billings†",
                    "Broadview",
                    "Laurel"
                ]
            }
        ]
    },
    {
        "stateId": "NC",
        "stateName": "North Carolina",
        "county": [
            {
                "countyName": "Alamance",
                "cities": [
                    "Burlington"
                ]
            },
            {
                "countyName": "Brunswick",
                "cities": [
                    "Leland"
                ]
            },
            {
                "countyName": "Buncombe",
                "cities": [
                    "Asheville †"
                ]
            },
            {
                "countyName": "Cabarrus",
                "cities": [
                    "Concord †"
                ]
            },
            {
                "countyName": "Cabarrus, Rowan",
                "cities": [
                    "Kannapolis"
                ]
            },
            {
                "countyName": "Catawba, Burke, Caldwell",
                "cities": [
                    "Hickory"
                ]
            },
            {
                "countyName": "Cleveland",
                "cities": [
                    "Shelby †"
                ]
            },
            {
                "countyName": "Craven",
                "cities": [
                    "New Bern †"
                ]
            },
            {
                "countyName": "Cumberland",
                "cities": [
                    "Fayetteville †"
                ]
            },
            {
                "countyName": "Davidson",
                "cities": [
                    "Lexington †"
                ]
            },
            {
                "countyName": "Davidson, Randolph",
                "cities": [
                    "Thomasville"
                ]
            },
            {
                "countyName": "Durham (seat), Wake, Orange",
                "cities": [
                    "Durham †"
                ]
            },
            {
                "countyName": "Edgecombe, Nash",
                "cities": [
                    "Rocky Mount"
                ]
            },
            {
                "countyName": "Forsyth",
                "cities": [
                    "Winston-Salem †",
                    "Clemmons"
                ]
            },
            {
                "countyName": "Forsyth, Guilford",
                "cities": [
                    "Kernersville"
                ]
            },
            {
                "countyName": "Gaston",
                "cities": [
                    "Gastonia †"
                ]
            },
            {
                "countyName": "Guilford",
                "cities": [
                    "Greensboro †"
                ]
            },
            {
                "countyName": "Guilford, Randolph, Davidson, Forsyth",
                "cities": [
                    "High Point"
                ]
            },
            {
                "countyName": "Iredell",
                "cities": [
                    "Mooresville",
                    "Statesville †"
                ]
            },
            {
                "countyName": "Johnston, Wake",
                "cities": [
                    "Clayton"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Sanford †"
                ]
            },
            {
                "countyName": "Lenoir",
                "cities": [
                    "Kinston †"
                ]
            },
            {
                "countyName": "Mecklenburg",
                "cities": [
                    "Charlotte †",
                    "Huntersville",
                    "Cornelius",
                    "Matthews"
                ]
            },
            {
                "countyName": "Mecklenburg, Union",
                "cities": [
                    "Mint Hill"
                ]
            },
            {
                "countyName": "New Hanover",
                "cities": [
                    "Wilmington †"
                ]
            },
            {
                "countyName": "Onslow",
                "cities": [
                    "Jacksonville †"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Carrboro"
                ]
            },
            {
                "countyName": "Orange, Durham",
                "cities": [
                    "Chapel Hill"
                ]
            },
            {
                "countyName": "Pitt",
                "cities": [
                    "Greenville †"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Asheboro †"
                ]
            },
            {
                "countyName": "Rowan",
                "cities": [
                    "Salisbury †"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Indian Trail",
                    "Monroe †",
                    "Waxhaw"
                ]
            },
            {
                "countyName": "Wake",
                "cities": [
                    "Apex",
                    "Holly Springs",
                    "Fuquay-Varina",
                    "Garner",
                    "Knightdale"
                ]
            },
            {
                "countyName": "Wake (seat), Durham",
                "cities": [
                    "Raleigh ††"
                ]
            },
            {
                "countyName": "Wake, Chatham",
                "cities": [
                    "Cary"
                ]
            },
            {
                "countyName": "Wake, Durham",
                "cities": [
                    "Morrisville"
                ]
            },
            {
                "countyName": "Wake, Franklin",
                "cities": [
                    "Wake Forest"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Goldsboro †"
                ]
            },
            {
                "countyName": "Wilson",
                "cities": [
                    "Wilson †"
                ]
            }
        ]
    },
    {
        "stateId": "ND",
        "stateName": "North Dakota",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Hettinger",
                    "Reeder",
                    "Bucyrus",
                    "Haynes"
                ]
            },
            {
                "countyName": "Barnes",
                "cities": [
                    "Valley City",
                    "Wimbledon",
                    "Litchville",
                    "Sanborn",
                    "Oriska",
                    "Fingal",
                    "Dazey",
                    "Kathryn",
                    "Nome",
                    "Rogers",
                    "Leal",
                    "Sibley",
                    "Pillsbury"
                ]
            },
            {
                "countyName": "Benson",
                "cities": [
                    "Leeds",
                    "Maddock",
                    "Minnewaukan",
                    "Oberon",
                    "Esmond",
                    "Warwick",
                    "Brinsmade",
                    "Knox",
                    "York"
                ]
            },
            {
                "countyName": "Billings",
                "cities": [
                    "Medora"
                ]
            },
            {
                "countyName": "Bottineau",
                "cities": [
                    "Bottineau",
                    "Westhope",
                    "Lansford",
                    "Willow City",
                    "Newburg",
                    "Maxbass",
                    "Souris",
                    "Landa",
                    "Kramer",
                    "Gardena",
                    "Antler",
                    "Overly"
                ]
            },
            {
                "countyName": "Bowman",
                "cities": [
                    "Bowman",
                    "Scranton",
                    "Rhame",
                    "Gascoyne"
                ]
            },
            {
                "countyName": "Burke",
                "cities": [
                    "Powers Lake",
                    "Bowbells",
                    "Lignite",
                    "Columbus",
                    "Portal",
                    "Flaxton"
                ]
            },
            {
                "countyName": "Burleigh",
                "cities": [
                    "Bismarck",
                    "Lincoln",
                    "Wing",
                    "Regan"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Fargo",
                    "West Fargo",
                    "Horace",
                    "Casselton",
                    "Mapleton",
                    "Kindred",
                    "Reile's Acres",
                    "Harwood",
                    "Argusville",
                    "Oxbow",
                    "Hunter",
                    "Arthur",
                    "Tower City",
                    "Davenport",
                    "Leonard",
                    "Frontier",
                    "Grandin",
                    "Buffalo",
                    "Page",
                    "Gardner",
                    "Amenia",
                    "North River",
                    "Prairie Rose",
                    "Briarwood",
                    "Alice",
                    "Ayr"
                ]
            },
            {
                "countyName": "Cavalier",
                "cities": [
                    "Langdon",
                    "Munich",
                    "Osnabrock",
                    "Milton",
                    "Alsen",
                    "Nekoma",
                    "Sarles",
                    "Calvin",
                    "Loma",
                    "Wales",
                    "Hannah",
                    "Calio"
                ]
            },
            {
                "countyName": "Dickey",
                "cities": [
                    "Oakes",
                    "Ellendale",
                    "Fullerton",
                    "Forbes",
                    "Monango",
                    "Ludden"
                ]
            },
            {
                "countyName": "Divide",
                "cities": [
                    "Crosby",
                    "Noonan",
                    "Fortuna",
                    "Ambrose"
                ]
            },
            {
                "countyName": "Dunn",
                "cities": [
                    "Killdeer",
                    "Dunn Center",
                    "Halliday",
                    "Dodge"
                ]
            },
            {
                "countyName": "Eddy",
                "cities": [
                    "New Rockford",
                    "Sheyenne"
                ]
            },
            {
                "countyName": "Emmons",
                "cities": [
                    "Linton",
                    "Strasburg",
                    "Hazelton",
                    "Hague",
                    "Braddock"
                ]
            },
            {
                "countyName": "Foster",
                "cities": [
                    "Carrington",
                    "Glenfield",
                    "McHenry",
                    "Grace City"
                ]
            },
            {
                "countyName": "Golden Valley",
                "cities": [
                    "Beach",
                    "Golva",
                    "Sentinel Butte"
                ]
            },
            {
                "countyName": "Grand Forks",
                "cities": [
                    "Grand Forks",
                    "Larimore",
                    "Thompson",
                    "Northwood",
                    "Emerado",
                    "Manvel",
                    "Gilby",
                    "Niagara",
                    "Inkster"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Elgin",
                    "Carson",
                    "New Leipzig",
                    "Leith"
                ]
            },
            {
                "countyName": "Griggs",
                "cities": [
                    "Cooperstown",
                    "Binford",
                    "Hannaford"
                ]
            },
            {
                "countyName": "Hettinger",
                "cities": [
                    "New England",
                    "Mott",
                    "Regent"
                ]
            },
            {
                "countyName": "Kidder",
                "cities": [
                    "Steele",
                    "Tappen",
                    "Dawson",
                    "Tuttle",
                    "Pettibone",
                    "Robinson"
                ]
            },
            {
                "countyName": "LaMoure",
                "cities": [
                    "LaMoure",
                    "Edgeley",
                    "Kulm",
                    "Marion",
                    "Jud",
                    "Verona",
                    "Dickey",
                    "Berlin"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Napoleon",
                    "Gackle",
                    "Lehr",
                    "Fredonia"
                ]
            },
            {
                "countyName": "McHenry",
                "cities": [
                    "Velva",
                    "Towner",
                    "Drake",
                    "Granville",
                    "Anamoose",
                    "Upham",
                    "Deering",
                    "Karlsruhe",
                    "Voltaire",
                    "Balfour",
                    "Bergen",
                    "Kief"
                ]
            },
            {
                "countyName": "McIntosh",
                "cities": [
                    "Wishek",
                    "Ashley",
                    "Zeeland",
                    "Venturia"
                ]
            },
            {
                "countyName": "McKenzie",
                "cities": [
                    "Watford City",
                    "Alexander",
                    "Arnegard"
                ]
            },
            {
                "countyName": "McLean",
                "cities": [
                    "Garrison",
                    "Washburn",
                    "Underwood",
                    "Wilton",
                    "Turtle Lake",
                    "Max",
                    "Riverdale",
                    "Mercer",
                    "Butte",
                    "Benedict",
                    "Coleharbor",
                    "Ruso"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Beulah",
                    "Hazen",
                    "Stanton",
                    "Zap",
                    "Golden Valley",
                    "Pick City"
                ]
            },
            {
                "countyName": "Morton",
                "cities": [
                    "Mandan",
                    "New Salem",
                    "Hebron",
                    "Glen Ullin",
                    "Flasher",
                    "Almont"
                ]
            },
            {
                "countyName": "Mountrail",
                "cities": [
                    "New Town",
                    "Stanley",
                    "Parshall",
                    "Plaza",
                    "Palermo",
                    "White Earth",
                    "Ross"
                ]
            },
            {
                "countyName": "Nelson",
                "cities": [
                    "Lakota",
                    "McVille",
                    "Michigan City",
                    "Aneta",
                    "Petersburg",
                    "Tolna",
                    "Pekin"
                ]
            },
            {
                "countyName": "Oliver",
                "cities": [
                    "Center"
                ]
            },
            {
                "countyName": "Pembina",
                "cities": [
                    "Cavalier",
                    "Walhalla",
                    "Drayton",
                    "Pembina",
                    "Neche",
                    "St. Thomas",
                    "Crystal",
                    "Mountain",
                    "Bathgate",
                    "Hamilton",
                    "Canton City"
                ]
            },
            {
                "countyName": "Pierce",
                "cities": [
                    "Rugby",
                    "Balta",
                    "Wolford"
                ]
            },
            {
                "countyName": "Ramsey",
                "cities": [
                    "Devils Lake",
                    "Edmore",
                    "Crary",
                    "Starkweather",
                    "Brocket",
                    "Hampden",
                    "Lawton",
                    "Churchs Ferry"
                ]
            },
            {
                "countyName": "Ransom",
                "cities": [
                    "Lisbon",
                    "Enderlin",
                    "Fort Ransom",
                    "Sheldon",
                    "Elliott"
                ]
            },
            {
                "countyName": "Renville",
                "cities": [
                    "Mohall",
                    "Glenburn",
                    "Sherwood",
                    "Tolley",
                    "Grano",
                    "Loraine"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Wahpeton",
                    "Hankinson",
                    "Lidgerwood",
                    "Wyndmere",
                    "Fairmount",
                    "Walcott",
                    "Abercrombie",
                    "Colfax",
                    "Mooreton",
                    "Christine",
                    "Dwight",
                    "Mantador",
                    "Great Bend",
                    "Barney"
                ]
            },
            {
                "countyName": "Rolette",
                "cities": [
                    "Rolla",
                    "Dunseith",
                    "Rolette",
                    "St. John",
                    "Mylo"
                ]
            },
            {
                "countyName": "Sargent",
                "cities": [
                    "Gwinner",
                    "Milnor",
                    "Forman",
                    "Rutland",
                    "Cogswell",
                    "Havana",
                    "Cayuga"
                ]
            },
            {
                "countyName": "Sheridan",
                "cities": [
                    "McClusky",
                    "Goodrich",
                    "Martin"
                ]
            },
            {
                "countyName": "Sioux",
                "cities": [
                    "Fort Yates",
                    "Selfridge",
                    "Solen"
                ]
            },
            {
                "countyName": "Slope",
                "cities": [
                    "Marmarth",
                    "Amidon"
                ]
            },
            {
                "countyName": "Stark",
                "cities": [
                    "Dickinson",
                    "Belfield",
                    "Richardton",
                    "South Heart",
                    "Gladstone",
                    "Taylor"
                ]
            },
            {
                "countyName": "Steele",
                "cities": [
                    "Finley",
                    "Hope",
                    "Sharon",
                    "Luverne"
                ]
            },
            {
                "countyName": "Stutsman",
                "cities": [
                    "Jamestown",
                    "Medina",
                    "Streeter",
                    "Kensal",
                    "Spiritwood Lake",
                    "Buchanan",
                    "Montpelier",
                    "Cleveland",
                    "Woodworth",
                    "Pingree",
                    "Courtenay"
                ]
            },
            {
                "countyName": "Towner",
                "cities": [
                    "Cando",
                    "Bisbee",
                    "Rocklake",
                    "Egeland",
                    "Hansboro",
                    "Perth"
                ]
            },
            {
                "countyName": "Traill",
                "cities": [
                    "Mayville",
                    "Hillsboro",
                    "Hatton",
                    "Portland",
                    "Buxton",
                    "Reynolds",
                    "Galesburg",
                    "Clifford"
                ]
            },
            {
                "countyName": "Walsh",
                "cities": [
                    "Grafton",
                    "Park River",
                    "Minto",
                    "Hoople",
                    "Fordville",
                    "Edinburg",
                    "Adams",
                    "Forest River",
                    "Lankin",
                    "Pisek",
                    "Ardoch",
                    "Fairdale",
                    "Conway"
                ]
            },
            {
                "countyName": "Ward",
                "cities": [
                    "Minot",
                    "Surrey",
                    "Burlington",
                    "Kenmare",
                    "Berthold",
                    "Sawyer",
                    "Des Lacs",
                    "Makoti",
                    "Carpio",
                    "Ryder",
                    "Douglas",
                    "Donnybrook"
                ]
            },
            {
                "countyName": "Wells",
                "cities": [
                    "Harvey",
                    "Fessenden",
                    "Bowdon",
                    "Sykeston",
                    "Hurdsfield",
                    "Cathay",
                    "Hamberg"
                ]
            },
            {
                "countyName": "Williams",
                "cities": [
                    "Williston",
                    "Tioga",
                    "Ray",
                    "Grenora",
                    "Wildrose",
                    "Epping",
                    "Alamo",
                    "Springbrook"
                ]
            }
        ]
    },
    {
        "stateId": "NE",
        "stateName": "Nebraska",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Hastings",
                    "Kenesaw",
                    "Juniata",
                    "Roseland",
                    "Holstein",
                    "Ayr",
                    "Prosser"
                ]
            },
            {
                "countyName": "Antelope",
                "cities": [
                    "Neligh",
                    "Elgin",
                    "Orchard",
                    "Clearwater",
                    "Oakdale",
                    "Brunswick",
                    "Royal"
                ]
            },
            {
                "countyName": "Arthur",
                "cities": [
                    "Arthur"
                ]
            },
            {
                "countyName": "Blaine",
                "cities": [
                    "Dunning",
                    "Brewster"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Albion",
                    "St. Edward",
                    "Cedar Rapids",
                    "Petersburg",
                    "Primrose"
                ]
            },
            {
                "countyName": "Box Butte",
                "cities": [
                    "Alliance",
                    "Hemingford"
                ]
            },
            {
                "countyName": "Boyd",
                "cities": [
                    "Spencer",
                    "Butte",
                    "Lynch",
                    "Naper",
                    "Bristow",
                    "Anoka",
                    "Gross",
                    "Monowi"
                ]
            },
            {
                "countyName": "Brown",
                "cities": [
                    "Ainsworth",
                    "Long Pine",
                    "Johnstown"
                ]
            },
            {
                "countyName": "Buffalo",
                "cities": [
                    "Kearney",
                    "Gibbon",
                    "Ravenna",
                    "Elm Creek",
                    "Pleasanton",
                    "Riverdale",
                    "Amherst",
                    "Miller"
                ]
            },
            {
                "countyName": "Buffalo\nHall",
                "cities": [
                    "Shelton"
                ]
            },
            {
                "countyName": "Burt",
                "cities": [
                    "Tekamah",
                    "Oakland",
                    "Lyons",
                    "Decatur",
                    "Craig"
                ]
            },
            {
                "countyName": "Butler",
                "cities": [
                    "David City",
                    "Bellwood",
                    "Rising City",
                    "Brainard",
                    "Dwight",
                    "Ulysses",
                    "Octavia",
                    "Bruno",
                    "Linwood",
                    "Abie",
                    "Garrison",
                    "Surprise"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Plattsmouth",
                    "Louisville",
                    "Eagle",
                    "Weeping Water",
                    "Elmwood",
                    "Greenwood",
                    "Murray",
                    "Cedar Creek",
                    "Murdock",
                    "Union",
                    "Avoca",
                    "Nehawka",
                    "Manley",
                    "Alvo",
                    "South Bend"
                ]
            },
            {
                "countyName": "Cedar",
                "cities": [
                    "Hartington",
                    "Laurel",
                    "Randolph",
                    "Coleridge",
                    "Wynot",
                    "Fordyce",
                    "Belden",
                    "St. Helena",
                    "Magnet",
                    "Obert"
                ]
            },
            {
                "countyName": "Chase",
                "cities": [
                    "Imperial",
                    "Wauneta",
                    "Lamar"
                ]
            },
            {
                "countyName": "Cherry",
                "cities": [
                    "Valentine",
                    "Cody",
                    "Merriman",
                    "Crookston",
                    "Kilgore",
                    "Wood Lake",
                    "Nenzel"
                ]
            },
            {
                "countyName": "Cheyenne",
                "cities": [
                    "Sidney",
                    "Potter",
                    "Lodgepole",
                    "Dalton",
                    "Gurley"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Sutton",
                    "Harvard",
                    "Clay Center",
                    "Edgar",
                    "Fairfield",
                    "Glenvil",
                    "Ong",
                    "Deweese",
                    "Saronville"
                ]
            },
            {
                "countyName": "Clay\nAdams",
                "cities": [
                    "Trumbull"
                ]
            },
            {
                "countyName": "Colfax",
                "cities": [
                    "Schuyler",
                    "Clarkson",
                    "Howells",
                    "Leigh",
                    "Rogers",
                    "Richland"
                ]
            },
            {
                "countyName": "Cuming",
                "cities": [
                    "West Point",
                    "Wisner",
                    "Beemer",
                    "Bancroft"
                ]
            },
            {
                "countyName": "Custer",
                "cities": [
                    "Broken Bow",
                    "Arnold",
                    "Callaway",
                    "Sargent",
                    "Ansley",
                    "Merna",
                    "Mason City",
                    "Oconto",
                    "Anselmo",
                    "Berwyn",
                    "Comstock"
                ]
            },
            {
                "countyName": "Dakota",
                "cities": [
                    "South Sioux City",
                    "Dakota City",
                    "Homer",
                    "Jackson",
                    "Hubbard"
                ]
            },
            {
                "countyName": "Dawes",
                "cities": [
                    "Chadron",
                    "Crawford",
                    "Whitney"
                ]
            },
            {
                "countyName": "Dawson",
                "cities": [
                    "Lexington",
                    "Cozad",
                    "Gothenburg",
                    "Overton",
                    "Sumner",
                    "Farnam",
                    "Eddyville"
                ]
            },
            {
                "countyName": "Deuel",
                "cities": [
                    "Chappell",
                    "Big Springs"
                ]
            },
            {
                "countyName": "Dixon",
                "cities": [
                    "Ponca",
                    "Allen",
                    "Newcastle",
                    "Concord",
                    "Dixon",
                    "Martinsburg",
                    "Waterbury",
                    "Maskell"
                ]
            },
            {
                "countyName": "Dixon\nDakota\nThurston",
                "cities": [
                    "Emerson"
                ]
            },
            {
                "countyName": "Dixon\nWayne",
                "cities": [
                    "Wakefield"
                ]
            },
            {
                "countyName": "Dodge",
                "cities": [
                    "Fremont",
                    "North Bend",
                    "Scribner",
                    "Hooper",
                    "Dodge",
                    "Inglewood",
                    "Nickerson",
                    "Snyder",
                    "Uehling",
                    "Winslow"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Omaha",
                    "Ralston",
                    "Valley",
                    "Bennington",
                    "Waterloo",
                    "Boys Town"
                ]
            },
            {
                "countyName": "Dundy",
                "cities": [
                    "Benkelman",
                    "Haigler"
                ]
            },
            {
                "countyName": "Fillmore",
                "cities": [
                    "Geneva",
                    "Fairmont",
                    "Exeter",
                    "Shickley",
                    "Milligan",
                    "Ohiowa",
                    "Grafton",
                    "Strang"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Franklin",
                    "Hildreth",
                    "Campbell",
                    "Upland",
                    "Bloomington",
                    "Naponee",
                    "Riverton"
                ]
            },
            {
                "countyName": "Frontier",
                "cities": [
                    "Curtis",
                    "Eustis",
                    "Maywood",
                    "Moorefield",
                    "Stockville"
                ]
            },
            {
                "countyName": "Furnas",
                "cities": [
                    "Cambridge",
                    "Arapahoe",
                    "Beaver City",
                    "Holbrook",
                    "Edison",
                    "Wilsonville",
                    "Hendley"
                ]
            },
            {
                "countyName": "Furnas\nHarlan",
                "cities": [
                    "Oxford"
                ]
            },
            {
                "countyName": "Gage",
                "cities": [
                    "Beatrice",
                    "Wymore",
                    "Adams",
                    "Cortland",
                    "Blue Springs",
                    "Clatonia",
                    "Odell",
                    "Pickrell",
                    "Filley",
                    "Barneston",
                    "Virginia",
                    "Liberty"
                ]
            },
            {
                "countyName": "Garden",
                "cities": [
                    "Oshkosh",
                    "Lewellen"
                ]
            },
            {
                "countyName": "Garfield",
                "cities": [
                    "Burwell"
                ]
            },
            {
                "countyName": "Gosper",
                "cities": [
                    "Elwood",
                    "Smithfield"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Hyannis"
                ]
            },
            {
                "countyName": "Greeley",
                "cities": [
                    "Spalding",
                    "Greeley Center",
                    "Scotia",
                    "Wolbach"
                ]
            },
            {
                "countyName": "Hall",
                "cities": [
                    "Grand Island",
                    "Wood River",
                    "Doniphan",
                    "Cairo",
                    "Alda"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Aurora",
                    "Hampton",
                    "Giltner",
                    "Phillips",
                    "Marquette",
                    "Hordville",
                    "Stockham"
                ]
            },
            {
                "countyName": "Harlan",
                "cities": [
                    "Alma",
                    "Orleans",
                    "Stamford",
                    "Republican City",
                    "Huntley",
                    "Ragan"
                ]
            },
            {
                "countyName": "Hayes",
                "cities": [
                    "Hayes Center",
                    "Hamlet"
                ]
            },
            {
                "countyName": "Hitchcock",
                "cities": [
                    "Culbertson",
                    "Trenton",
                    "Stratton"
                ]
            },
            {
                "countyName": "Hitchcock\nHayes",
                "cities": [
                    "Palisade"
                ]
            },
            {
                "countyName": "Holt",
                "cities": [
                    "O'Neill",
                    "Atkinson",
                    "Stuart",
                    "Ewing",
                    "Chambers",
                    "Page",
                    "Inman",
                    "Emmet"
                ]
            },
            {
                "countyName": "Hooker",
                "cities": [
                    "Mullen"
                ]
            },
            {
                "countyName": "Howard",
                "cities": [
                    "St. Paul",
                    "Dannebrog",
                    "Elba",
                    "Howard City (Boelus)",
                    "Farwell",
                    "Cushing",
                    "Cotesfield"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Fairbury",
                    "Plymouth",
                    "Diller",
                    "Daykin",
                    "Endicott",
                    "Jansen",
                    "Reynolds",
                    "Harbine",
                    "Steele City"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Tecumseh",
                    "Sterling",
                    "Cook",
                    "Elk Creek",
                    "Crab Orchard"
                ]
            },
            {
                "countyName": "Kearney",
                "cities": [
                    "Minden",
                    "Axtell",
                    "Wilcox",
                    "Heartwell",
                    "Norman"
                ]
            },
            {
                "countyName": "Keith",
                "cities": [
                    "Ogallala",
                    "Paxton",
                    "Brule"
                ]
            },
            {
                "countyName": "Keya Paha",
                "cities": [
                    "Springview",
                    "Burton"
                ]
            },
            {
                "countyName": "Kimball",
                "cities": [
                    "Kimball",
                    "Dix",
                    "Bushnell"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Creighton",
                    "Bloomfield",
                    "Crofton",
                    "Wausa",
                    "Verdigre",
                    "Santee",
                    "Niobrara",
                    "Center",
                    "Winnetoon",
                    "Verdel",
                    "Bazile Mills"
                ]
            },
            {
                "countyName": "Lancaster",
                "cities": [
                    "Lincoln",
                    "Waverly",
                    "Hickman",
                    "Bennet",
                    "Firth",
                    "Malcolm",
                    "Hallam",
                    "Panama",
                    "Roca",
                    "Denton",
                    "Raymond",
                    "Sprague",
                    "Davey"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "North Platte",
                    "Sutherland",
                    "Hershey",
                    "Brady",
                    "Wallace",
                    "Maxwell",
                    "Wellfleet"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Stapleton",
                    "Gandy"
                ]
            },
            {
                "countyName": "Loup",
                "cities": [
                    "Taylor"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Norfolk",
                    "Madison",
                    "Battle Creek",
                    "Meadow Grove"
                ]
            },
            {
                "countyName": "Madison\nAntelope",
                "cities": [
                    "Tilden"
                ]
            },
            {
                "countyName": "Madison\nPlatte",
                "cities": [
                    "Newman Grove"
                ]
            },
            {
                "countyName": "Merrick",
                "cities": [
                    "Central City",
                    "Palmer",
                    "Clarks",
                    "Silver Creek",
                    "Chapman"
                ]
            },
            {
                "countyName": "Morrill",
                "cities": [
                    "Bridgeport",
                    "Bayard",
                    "Broadwater"
                ]
            },
            {
                "countyName": "Nance",
                "cities": [
                    "Fullerton",
                    "Genoa",
                    "Belgrade"
                ]
            },
            {
                "countyName": "Nemaha",
                "cities": [
                    "Auburn",
                    "Peru",
                    "Johnson",
                    "Brownville",
                    "Brock",
                    "Nemaha",
                    "Julian"
                ]
            },
            {
                "countyName": "Nuckolls",
                "cities": [
                    "Superior",
                    "Nelson",
                    "Lawrence",
                    "Ruskin",
                    "Hardy",
                    "Oak",
                    "Nora"
                ]
            },
            {
                "countyName": "Otoe",
                "cities": [
                    "Nebraska City",
                    "Syracuse",
                    "Palmyra",
                    "Unadilla",
                    "Talmage",
                    "Douglas",
                    "Dunbar",
                    "Otoe",
                    "Burr",
                    "Lorton"
                ]
            },
            {
                "countyName": "Pawnee",
                "cities": [
                    "Pawnee City",
                    "Table Rock",
                    "Du Bois",
                    "Burchard",
                    "Steinauer",
                    "Lewiston"
                ]
            },
            {
                "countyName": "Perkins",
                "cities": [
                    "Grant",
                    "Madrid",
                    "Venango",
                    "Elsie"
                ]
            },
            {
                "countyName": "Phelps",
                "cities": [
                    "Holdrege",
                    "Bertrand",
                    "Loomis",
                    "Funk",
                    "Atlanta"
                ]
            },
            {
                "countyName": "Pierce",
                "cities": [
                    "Pierce",
                    "Plainview",
                    "Osmond",
                    "Hadar",
                    "Foster",
                    "McLean"
                ]
            },
            {
                "countyName": "Platte",
                "cities": [
                    "Columbus",
                    "Humphrey",
                    "Duncan",
                    "Platte Center",
                    "Lindsay",
                    "Monroe",
                    "Creston",
                    "Tarnov",
                    "Cornlea"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Stromsburg",
                    "Osceola",
                    "Shelby",
                    "Polk"
                ]
            },
            {
                "countyName": "Red Willow",
                "cities": [
                    "McCook",
                    "Indianola",
                    "Bartley",
                    "Danbury",
                    "Lebanon"
                ]
            },
            {
                "countyName": "Richardson",
                "cities": [
                    "Falls City",
                    "Humboldt",
                    "Verdon",
                    "Shubert",
                    "Stella",
                    "Dawson",
                    "Rulo",
                    "Salem",
                    "Barada",
                    "Preston"
                ]
            },
            {
                "countyName": "Rock",
                "cities": [
                    "Bassett",
                    "Newport"
                ]
            },
            {
                "countyName": "Saline",
                "cities": [
                    "Crete",
                    "Wilber",
                    "Friend",
                    "Dorchester",
                    "De Witt",
                    "Western",
                    "Tobias",
                    "Swanton"
                ]
            },
            {
                "countyName": "Sarpy",
                "cities": [
                    "Bellevue",
                    "Papillion",
                    "La Vista",
                    "Gretna",
                    "Springfield"
                ]
            },
            {
                "countyName": "Saunders",
                "cities": [
                    "Wahoo",
                    "Ashland",
                    "Yutan",
                    "Ceresco",
                    "Mead",
                    "Cedar Bluffs",
                    "Valparaiso",
                    "Prague",
                    "Weston",
                    "Ithaca",
                    "Morse Bluff",
                    "Colon",
                    "Leshara",
                    "Memphis",
                    "Malmo"
                ]
            },
            {
                "countyName": "Scotts Bluff",
                "cities": [
                    "Scottsbluff",
                    "Gering",
                    "Mitchell",
                    "Terrytown",
                    "Morrill",
                    "Minatare",
                    "Lyman",
                    "Henry",
                    "Melbeta",
                    "McGrew"
                ]
            },
            {
                "countyName": "Seward",
                "cities": [
                    "Seward",
                    "Milford",
                    "Utica",
                    "Beaver Crossing",
                    "Staplehurst",
                    "Pleasant Dale",
                    "Garland",
                    "Goehner",
                    "Bee",
                    "Cordova"
                ]
            },
            {
                "countyName": "Sheridan",
                "cities": [
                    "Gordon",
                    "Rushville",
                    "Hay Springs",
                    "Clinton"
                ]
            },
            {
                "countyName": "Sherman",
                "cities": [
                    "Loup City",
                    "Litchfield",
                    "Ashton",
                    "Rockville",
                    "Hazard"
                ]
            },
            {
                "countyName": "Sioux",
                "cities": [
                    "Harrison"
                ]
            },
            {
                "countyName": "Stanton",
                "cities": [
                    "Stanton",
                    "Pilger"
                ]
            },
            {
                "countyName": "Thayer",
                "cities": [
                    "Hebron",
                    "Deshler",
                    "Davenport",
                    "Bruning",
                    "Chester",
                    "Alexandria",
                    "Byron",
                    "Carleton",
                    "Hubbell",
                    "Belvidere",
                    "Gilead"
                ]
            },
            {
                "countyName": "Thomas",
                "cities": [
                    "Thedford"
                ]
            },
            {
                "countyName": "Thomas\nBlaine",
                "cities": [
                    "Halsey"
                ]
            },
            {
                "countyName": "Thurston",
                "cities": [
                    "Pender",
                    "Winnebago",
                    "Walthill",
                    "Rosalie",
                    "Thurston"
                ]
            },
            {
                "countyName": "Valley",
                "cities": [
                    "Ord",
                    "Arcadia",
                    "North Loup",
                    "Elyria"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Blair",
                    "Arlington",
                    "Fort Calhoun",
                    "Kennard",
                    "Herman",
                    "Washington"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Wayne",
                    "Winside",
                    "Hoskins",
                    "Carroll",
                    "Sholes"
                ]
            },
            {
                "countyName": "Webster",
                "cities": [
                    "Red Cloud",
                    "Blue Hill",
                    "Bladen",
                    "Guide Rock",
                    "Cowles"
                ]
            },
            {
                "countyName": "Wheeler",
                "cities": [
                    "Bartlett",
                    "Ericson"
                ]
            },
            {
                "countyName": "York",
                "cities": [
                    "York",
                    "Henderson",
                    "McCool Junction",
                    "Waco",
                    "Bradshaw",
                    "Gresham",
                    "Benedict",
                    "Thayer",
                    "Lushton"
                ]
            }
        ]
    },
    {
        "stateId": "NH",
        "stateName": "New Hampshire",
        "county": [
            {
                "countyName": "Belknap",
                "cities": [
                    "Alton",
                    "Barnstead",
                    "Belmont",
                    "Center Harbor",
                    "Gilford",
                    "Gilmanton",
                    "Meredith",
                    "New Hampton",
                    "Sanbornton",
                    "Tilton"
                ]
            },
            {
                "countyName": "Belknap (seat)",
                "cities": [
                    "Laconia"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Albany",
                    "Bartlett",
                    "Brookfield",
                    "Chatham",
                    "Conway",
                    "Eaton",
                    "Effingham",
                    "Freedom",
                    "Hart's Location",
                    "Jackson",
                    "Madison",
                    "Moultonborough",
                    "Sandwich",
                    "Tamworth",
                    "Tuftonboro",
                    "Wakefield",
                    "Wolfeboro"
                ]
            },
            {
                "countyName": "Carroll (seat)",
                "cities": [
                    "Ossipee"
                ]
            },
            {
                "countyName": "Cheshire",
                "cities": [
                    "Alstead",
                    "Chesterfield",
                    "Dublin",
                    "Fitzwilliam",
                    "Gilsum",
                    "Harrisville",
                    "Hinsdale",
                    "Jaffrey",
                    "Marlborough",
                    "Marlow",
                    "Nelson",
                    "Richmond",
                    "Rindge",
                    "Roxbury",
                    "Stoddard",
                    "Sullivan",
                    "Surry",
                    "Swanzey",
                    "Troy",
                    "Walpole",
                    "Westmoreland",
                    "Winchester"
                ]
            },
            {
                "countyName": "Cheshire (seat)",
                "cities": [
                    "Keene"
                ]
            },
            {
                "countyName": "Coös",
                "cities": [
                    "Berlin",
                    "Carroll",
                    "Clarksville",
                    "Colebrook",
                    "Columbia",
                    "Dalton",
                    "Dummer",
                    "Errol",
                    "Gorham",
                    "Jefferson",
                    "Milan",
                    "Northumberland",
                    "Pittsburg",
                    "Randolph",
                    "Shelburne",
                    "Stark",
                    "Stewartstown",
                    "Stratford",
                    "Whitefield"
                ]
            },
            {
                "countyName": "Coös (seat)",
                "cities": [
                    "Lancaster"
                ]
            },
            {
                "countyName": "Grafton",
                "cities": [
                    "Alexandria",
                    "Ashland",
                    "Bath",
                    "Benton",
                    "Bethlehem",
                    "Bridgewater",
                    "Bristol",
                    "Campton",
                    "Canaan",
                    "Dorchester",
                    "Easton",
                    "Ellsworth",
                    "Enfield",
                    "Franconia",
                    "Grafton",
                    "Groton",
                    "Hanover",
                    "Hebron",
                    "Holderness",
                    "Landaff",
                    "Lebanon",
                    "Lincoln",
                    "Lisbon",
                    "Littleton",
                    "Lyman",
                    "Lyme",
                    "Monroe",
                    "Orange",
                    "Orford",
                    "Piermont",
                    "Plymouth",
                    "Rumney",
                    "Sugar Hill",
                    "Thornton",
                    "Warren",
                    "Waterville Valley",
                    "Wentworth",
                    "Woodstock"
                ]
            },
            {
                "countyName": "Grafton (seat)",
                "cities": [
                    "Haverhill"
                ]
            },
            {
                "countyName": "Hillsborough",
                "cities": [
                    "Amherst",
                    "Antrim",
                    "Bedford",
                    "Bennington",
                    "Brookline",
                    "Deering",
                    "Francestown",
                    "Goffstown",
                    "Greenfield",
                    "Greenville",
                    "Hancock",
                    "Hillsborough",
                    "Hollis",
                    "Hudson",
                    "Litchfield",
                    "Lyndeborough",
                    "Mason",
                    "Merrimack",
                    "Milford",
                    "Mont Vernon",
                    "New Boston",
                    "New Ipswich",
                    "Pelham",
                    "Peterborough",
                    "Sharon",
                    "Temple",
                    "Weare",
                    "Wilton",
                    "Windsor"
                ]
            },
            {
                "countyName": "Hillsborough (seat)",
                "cities": [
                    "Manchester",
                    "Nashua"
                ]
            },
            {
                "countyName": "Merrimack",
                "cities": [
                    "Allenstown",
                    "Andover",
                    "Boscawen",
                    "Bow",
                    "Bradford",
                    "Canterbury",
                    "Chichester",
                    "Danbury",
                    "Dunbarton",
                    "Epsom",
                    "Franklin",
                    "Henniker",
                    "Hill",
                    "Hooksett",
                    "Hopkinton",
                    "Loudon",
                    "New London",
                    "Newbury",
                    "Northfield",
                    "Pembroke",
                    "Pittsfield",
                    "Salisbury",
                    "Sutton",
                    "Warner",
                    "Webster",
                    "Wilmot"
                ]
            },
            {
                "countyName": "Merrimack (seat)",
                "cities": [
                    "Concord"
                ]
            },
            {
                "countyName": "Rockingham",
                "cities": [
                    "Atkinson",
                    "Auburn",
                    "Candia",
                    "Chester",
                    "Danville",
                    "Deerfield",
                    "Derry",
                    "East Kingston",
                    "Epping",
                    "Exeter",
                    "Fremont",
                    "Greenland",
                    "Hampstead",
                    "Hampton",
                    "Hampton Falls",
                    "Kensington",
                    "Kingston",
                    "Londonderry",
                    "New Castle",
                    "Newfields",
                    "Newington",
                    "Newmarket",
                    "Newton",
                    "North Hampton",
                    "Northwood",
                    "Nottingham",
                    "Plaistow",
                    "Portsmouth",
                    "Raymond",
                    "Rye",
                    "Salem",
                    "Sandown",
                    "Seabrook",
                    "South Hampton",
                    "Stratham",
                    "Windham"
                ]
            },
            {
                "countyName": "Rockingham (seat)",
                "cities": [
                    "Brentwood"
                ]
            },
            {
                "countyName": "Strafford",
                "cities": [
                    "Barrington",
                    "Durham",
                    "Farmington",
                    "Lee",
                    "Madbury",
                    "Middleton",
                    "Milton",
                    "New Durham",
                    "Rochester",
                    "Rollinsford",
                    "Somersworth",
                    "Strafford"
                ]
            },
            {
                "countyName": "Strafford (seat)",
                "cities": [
                    "Dover"
                ]
            },
            {
                "countyName": "Sullivan",
                "cities": [
                    "Acworth",
                    "Charlestown",
                    "Claremont",
                    "Cornish",
                    "Croydon",
                    "Goshen",
                    "Grantham",
                    "Langdon",
                    "Lempster",
                    "Plainfield",
                    "Springfield",
                    "Sunapee",
                    "Unity",
                    "Washington"
                ]
            },
            {
                "countyName": "Sullivan (seat)",
                "cities": [
                    "Newport"
                ]
            }
        ]
    },
    {
        "stateId": "NJ",
        "stateName": "New Jersey",
        "county": [
            {
                "countyName": "Atlantic",
                "cities": [
                    "Egg Harbor Township",
                    "Atlantic City",
                    "Galloway Township",
                    "Hamilton Township",
                    "Pleasantville",
                    "Hammonton",
                    "Somers Point",
                    "Ventnor City",
                    "Absecon",
                    "Northfield",
                    "Brigantine",
                    "Buena Vista Township",
                    "Linwood",
                    "Mullica Township",
                    "Margate City",
                    "Buena",
                    "Egg Harbor City",
                    "Weymouth Township",
                    "Folsom",
                    "Estell Manor",
                    "Port Republic",
                    "Longport",
                    "Corbin City"
                ]
            },
            {
                "countyName": "Bergen",
                "cities": [
                    "Hackensack",
                    "Teaneck",
                    "Fort Lee",
                    "Fair Lawn",
                    "Garfield",
                    "Englewood",
                    "Bergenfield",
                    "Paramus",
                    "Lodi",
                    "Ridgewood",
                    "Cliffside Park",
                    "Mahwah",
                    "Lyndhurst",
                    "Elmwood Park",
                    "Palisades Park",
                    "Rutherford",
                    "Dumont",
                    "New Milford",
                    "Wyckoff",
                    "North Arlington",
                    "Tenafly",
                    "Fairview",
                    "Ramsey",
                    "Edgewater",
                    "Saddle Brook",
                    "Ridgefield Park",
                    "Oakland",
                    "Glen Rock",
                    "Hasbrouck Heights",
                    "River Edge",
                    "Wallington",
                    "Ridgefield",
                    "Westwood",
                    "Franklin Lakes",
                    "Little Ferry",
                    "Hillsdale",
                    "Wood-Ridge",
                    "Maywood",
                    "Waldwick",
                    "East Rutherford",
                    "River Vale",
                    "Leonia",
                    "Washington Township",
                    "Cresskill",
                    "Park Ridge",
                    "Bogota",
                    "Closter",
                    "Montvale",
                    "Upper Saddle River",
                    "Oradell",
                    "Emerson",
                    "Midland Park",
                    "Allendale",
                    "Carlstadt",
                    "Woodcliff Lake",
                    "Old Tappan",
                    "Rochelle Park",
                    "Norwood",
                    "Englewood Cliffs",
                    "Demarest",
                    "Northvale",
                    "Harrington Park",
                    "Ho-Ho-Kus",
                    "Saddle River",
                    "Haworth",
                    "Moonachie",
                    "South Hackensack",
                    "Alpine",
                    "Rockleigh",
                    "Teterboro"
                ]
            },
            {
                "countyName": "Burlington",
                "cities": [
                    "Evesham Township",
                    "Mount Laurel",
                    "Willingboro Township",
                    "Pemberton Township",
                    "Medford",
                    "Burlington Township",
                    "Moorestown",
                    "Maple Shade Township",
                    "Delran Township",
                    "Cinnaminson Township",
                    "Florence Township",
                    "Lumberton",
                    "Bordentown Township",
                    "Southampton Township",
                    "Mount Holly",
                    "Burlington City",
                    "Chesterfield Township",
                    "Westampton",
                    "Edgewater Park",
                    "Mansfield Township",
                    "Riverside Township",
                    "North Hanover Township",
                    "Palmyra",
                    "Tabernacle Township",
                    "Shamong Township",
                    "New Hanover Township",
                    "Eastampton Township",
                    "Hainesport Township",
                    "Delanco Township",
                    "Medford Lakes",
                    "Bordentown City",
                    "Springfield Township",
                    "Riverton",
                    "Beverly",
                    "Woodland Township",
                    "Pemberton Borough",
                    "Bass River Township",
                    "Wrightstown",
                    "Washington Township",
                    "Fieldsboro"
                ]
            },
            {
                "countyName": "Camden",
                "cities": [
                    "Cherry Hill Township",
                    "Camden",
                    "Gloucester Township",
                    "Winslow Township",
                    "Pennsauken Township",
                    "Voorhees Township",
                    "Lindenwold",
                    "Haddon Township",
                    "Collingswood",
                    "Haddonfield",
                    "Bellmawr",
                    "Gloucester City",
                    "Pine Hill",
                    "Waterford Township",
                    "Audubon",
                    "Runnemede",
                    "Haddon Heights",
                    "Berlin Borough",
                    "Barrington",
                    "Stratford",
                    "Berlin Township",
                    "Somerdale",
                    "Clementon",
                    "Mount Ephraim",
                    "Magnolia",
                    "Oaklyn",
                    "Merchantville",
                    "Lawnside",
                    "Woodlynne",
                    "Gibbsboro",
                    "Laurel Springs",
                    "Brooklawn",
                    "Chesilhurst",
                    "Audubon Park",
                    "Hi-Nella",
                    "Tavistock"
                ]
            },
            {
                "countyName": "Cape May",
                "cities": [
                    "Lower Township",
                    "Middle Township",
                    "Upper Township",
                    "Ocean City",
                    "Dennis Township",
                    "Wildwood",
                    "North Wildwood",
                    "Wildwood Crest",
                    "Cape May",
                    "Woodbine",
                    "Sea Isle City",
                    "Avalon",
                    "West Cape May",
                    "Stone Harbor",
                    "West Wildwood",
                    "Cape May Point"
                ]
            },
            {
                "countyName": "Cumberland",
                "cities": [
                    "Vineland",
                    "Millville",
                    "Bridgeton",
                    "Upper Deerfield Township",
                    "Maurice River Township",
                    "Fairfield Township",
                    "Commercial Township",
                    "Hopewell Township",
                    "Deerfield Township",
                    "Lawrence Township",
                    "Downe Township",
                    "Stow Creek Township",
                    "Greenwich Township",
                    "Shiloh"
                ]
            },
            {
                "countyName": "Essex",
                "cities": [
                    "Newark",
                    "East Orange",
                    "Irvington",
                    "Bloomfield",
                    "West Orange",
                    "Montclair",
                    "Belleville",
                    "Orange",
                    "Livingston",
                    "Nutley",
                    "Maplewood",
                    "Millburn",
                    "South Orange",
                    "Verona",
                    "Cedar Grove",
                    "West Caldwell",
                    "Caldwell",
                    "Fairfield",
                    "Glen Ridge",
                    "North Caldwell",
                    "Roseland",
                    "Essex Fells"
                ]
            },
            {
                "countyName": "Gloucester",
                "cities": [
                    "Washington Township",
                    "Monroe Township",
                    "Deptford Township",
                    "Glassboro",
                    "West Deptford Township",
                    "Franklin Township",
                    "Mantua Township",
                    "Harrison Township",
                    "Woolwich Township",
                    "East Greenwich Township",
                    "Woodbury",
                    "Clayton",
                    "Pitman",
                    "Paulsboro",
                    "Logan Township",
                    "Greenwich Township",
                    "Elk Township",
                    "Westville",
                    "South Harrison Township",
                    "Woodbury Heights",
                    "National Park",
                    "Swedesboro",
                    "Wenonah",
                    "Newfield"
                ]
            },
            {
                "countyName": "Hudson",
                "cities": [
                    "Jersey City",
                    "Bayonne",
                    "Union City",
                    "North Bergen",
                    "Hoboken",
                    "West New York",
                    "Kearny",
                    "Secaucus",
                    "Harrison",
                    "Weehawken",
                    "Guttenberg",
                    "East Newark"
                ]
            },
            {
                "countyName": "Hunterdon",
                "cities": [
                    "Raritan Township",
                    "Readington Township",
                    "Clinton Township",
                    "Union Township",
                    "Lebanon Township",
                    "Tewksbury Township",
                    "Holland Township",
                    "Flemington",
                    "Alexandria Township",
                    "Delaware Township",
                    "Lambertville",
                    "East Amwell Township",
                    "Kingwood Township",
                    "Bethlehem Township",
                    "High Bridge",
                    "Franklin Township",
                    "West Amwell Township",
                    "Clinton Town",
                    "Glen Gardner",
                    "Lebanon Borough",
                    "Hampton Borough",
                    "Frenchtown",
                    "Milford",
                    "Califon",
                    "Bloomsbury",
                    "Stockton"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Hamilton Township",
                    "Trenton",
                    "Ewing Township",
                    "Lawrence Township",
                    "Princeton",
                    "East Windsor Township",
                    "West Windsor",
                    "Hopewell Township",
                    "Robbinsville Township",
                    "Hightstown",
                    "Pennington",
                    "Hopewell Borough"
                ]
            },
            {
                "countyName": "Middlesex",
                "cities": [
                    "Edison",
                    "Woodbridge Township",
                    "Old Bridge Township",
                    "Piscataway",
                    "Perth Amboy",
                    "New Brunswick",
                    "East Brunswick",
                    "Monroe Township",
                    "South Brunswick",
                    "Sayreville",
                    "North Brunswick",
                    "Carteret",
                    "South Plainfield",
                    "Plainsboro Township",
                    "South River",
                    "Highland Park",
                    "Metuchen",
                    "Middlesex",
                    "South Amboy",
                    "Spotswood",
                    "Dunellen",
                    "Milltown",
                    "Jamesburg",
                    "Cranbury",
                    "Helmetta"
                ]
            },
            {
                "countyName": "Monmouth",
                "cities": [
                    "Middletown Township",
                    "Howell Township",
                    "Marlboro Township",
                    "Manalapan Township",
                    "Freehold Township",
                    "Long Branch",
                    "Neptune Township",
                    "Ocean Township",
                    "Wall Township",
                    "Hazlet",
                    "Aberdeen Township",
                    "Tinton Falls",
                    "Holmdel Township",
                    "Asbury Park",
                    "Eatontown",
                    "Red Bank",
                    "Freehold Borough",
                    "Millstone Township",
                    "Colts Neck Township",
                    "Keansburg",
                    "Matawan",
                    "West Long Branch",
                    "Rumson",
                    "Upper Freehold Township",
                    "Keyport",
                    "Fair Haven",
                    "Oceanport",
                    "Little Silver",
                    "Manasquan",
                    "Belmar",
                    "Union Beach",
                    "Brielle",
                    "Spring Lake Heights",
                    "Neptune City",
                    "Highlands",
                    "Atlantic Highlands",
                    "Bradley Beach",
                    "Shrewsbury Borough",
                    "Monmouth Beach",
                    "Spring Lake",
                    "Englishtown",
                    "Avon-by-the-Sea",
                    "Sea Girt",
                    "Allentown",
                    "Lake Como",
                    "Farmingdale",
                    "Sea Bright",
                    "Shrewsbury Township",
                    "Deal",
                    "Interlaken",
                    "Roosevelt",
                    "Allenhurst",
                    "Loch Arbour"
                ]
            },
            {
                "countyName": "Morris",
                "cities": [
                    "Parsippany-Troy Hills",
                    "Mount Olive Township",
                    "Randolph",
                    "Rockaway Township",
                    "Morris Township",
                    "Roxbury",
                    "Montville",
                    "Jefferson Township",
                    "Morristown",
                    "Dover",
                    "Washington Township",
                    "Denville Township",
                    "Madison",
                    "Pequannock Township",
                    "Hanover Township",
                    "Florham Park",
                    "East Hanover Township",
                    "Chatham Township",
                    "Lincoln Park",
                    "Kinnelon",
                    "Chatham Borough",
                    "Boonton Town",
                    "Long Hill Township",
                    "Butler",
                    "Chester Township",
                    "Wharton",
                    "Rockaway Borough",
                    "Morris Plains",
                    "Mendham Township",
                    "Mount Arlington",
                    "Mendham Borough",
                    "Mountain Lakes",
                    "Boonton Township",
                    "Riverdale",
                    "Mine Hill Township",
                    "Harding Township",
                    "Netcong",
                    "Chester Borough",
                    "Victory Gardens"
                ]
            },
            {
                "countyName": "Ocean",
                "cities": [
                    "Lakewood Township",
                    "Toms River",
                    "Brick Township",
                    "Jackson Township",
                    "Manchester Township",
                    "Berkeley Township",
                    "Lacey Township",
                    "Stafford Township",
                    "Barnegat Township",
                    "Little Egg Harbor Township",
                    "Point Pleasant",
                    "Beachwood",
                    "Ocean Township",
                    "Plumsted Township",
                    "Point Pleasant Beach",
                    "South Toms River",
                    "Tuckerton",
                    "Long Beach Township",
                    "Lakehurst",
                    "Seaside Heights",
                    "Pine Beach",
                    "Ocean Gate",
                    "Lavallette",
                    "Eagleswood Township",
                    "Island Heights",
                    "Seaside Park",
                    "Surf City",
                    "Ship Bottom",
                    "Beach Haven",
                    "Bay Head",
                    "Barnegat Light",
                    "Harvey Cedars",
                    "Mantoloking"
                ]
            },
            {
                "countyName": "Passaic",
                "cities": [
                    "Paterson",
                    "Clifton",
                    "Passaic",
                    "Wayne",
                    "West Milford",
                    "Hawthorne",
                    "Woodland Park",
                    "Little Falls",
                    "Ringwood",
                    "Wanaque",
                    "Pompton Lakes",
                    "Totowa",
                    "Haledon",
                    "North Haledon",
                    "Bloomingdale",
                    "Prospect Park"
                ]
            },
            {
                "countyName": "Salem",
                "cities": [
                    "Pennsville Township",
                    "Pittsgrove Township",
                    "Carneys Point Township",
                    "Salem",
                    "Penns Grove",
                    "Pilesgrove Township",
                    "Woodstown",
                    "Upper Pittsgrove Township",
                    "Alloway Township",
                    "Quinton Township",
                    "Oldmans Township",
                    "Lower Alloways Creek Township",
                    "Mannington Township",
                    "Elmer",
                    "Elsinboro Township"
                ]
            },
            {
                "countyName": "Somerset",
                "cities": [
                    "Franklin Township",
                    "Bridgewater Township",
                    "Hillsborough Township",
                    "Bernards Township",
                    "Montgomery Township",
                    "North Plainfield",
                    "Warren Township",
                    "Branchburg",
                    "Somerville",
                    "Bound Brook",
                    "Manville",
                    "Bedminster",
                    "Bernardsville",
                    "Raritan",
                    "Green Brook Township",
                    "Watchung",
                    "South Bound Brook",
                    "Peapack and Gladstone",
                    "Far Hills",
                    "Rocky Hill",
                    "Millstone Borough"
                ]
            },
            {
                "countyName": "Sussex",
                "cities": [
                    "Vernon Township",
                    "Sparta",
                    "Hopatcong",
                    "Wantage Township",
                    "Newton",
                    "Hardyston Township",
                    "Byram Township",
                    "Andover Township",
                    "Frankford Township",
                    "Franklin Borough",
                    "Hampton Township",
                    "Stillwater Township",
                    "Montague Township",
                    "Green Township",
                    "Stanhope",
                    "Hamburg",
                    "Fredon Township",
                    "Lafayette Township",
                    "Ogdensburg",
                    "Sussex",
                    "Sandyston Township",
                    "Branchville",
                    "Andover Borough",
                    "Walpack Township"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Elizabeth",
                    "Union Township",
                    "Plainfield",
                    "Linden",
                    "Westfield",
                    "Rahway",
                    "Scotch Plains",
                    "Cranford",
                    "Summit",
                    "Roselle",
                    "Hillside",
                    "Springfield Township",
                    "Clark",
                    "Roselle Park",
                    "New Providence",
                    "Berkeley Heights",
                    "Kenilworth",
                    "Fanwood",
                    "Mountainside",
                    "Garwood",
                    "Winfield Township"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Phillipsburg",
                    "Hackettstown",
                    "Lopatcong Township",
                    "Mansfield Township",
                    "Washington Borough",
                    "Washington Township",
                    "Blairstown",
                    "Greenwich Township",
                    "Independence Township",
                    "Allamuchy Township",
                    "White Township",
                    "Pohatcong Township",
                    "Franklin Township",
                    "Knowlton Township",
                    "Liberty Township",
                    "Belvidere",
                    "Harmony Township",
                    "Oxford Township",
                    "Alpha",
                    "Frelinghuysen Township",
                    "Hope Township",
                    "Hardwick Township"
                ]
            }
        ]
    },
    {
        "stateId": "NM",
        "stateName": "New Mexico",
        "county": [
            {
                "countyName": "Bernalillo",
                "cities": [
                    "Albuquerque†",
                    "Los Ranchos de Albuquerque",
                    "Tijeras"
                ]
            },
            {
                "countyName": "Bernalillo\nSandoval",
                "cities": [
                    "Rio Rancho"
                ]
            },
            {
                "countyName": "Catron",
                "cities": [
                    "Reserve†"
                ]
            },
            {
                "countyName": "Chaves",
                "cities": [
                    "Dexter",
                    "Hagerman",
                    "Lake Arthur",
                    "Roswell†"
                ]
            },
            {
                "countyName": "Cibola",
                "cities": [
                    "Grants†",
                    "Milan"
                ]
            },
            {
                "countyName": "Colfax",
                "cities": [
                    "Angel Fire",
                    "Cimarron",
                    "Eagle Nest",
                    "Maxwell",
                    "Raton†",
                    "Springer"
                ]
            },
            {
                "countyName": "Curry",
                "cities": [
                    "Clovis†",
                    "Grady",
                    "Melrose",
                    "Texico"
                ]
            },
            {
                "countyName": "De Baca",
                "cities": [
                    "Fort Sumner†"
                ]
            },
            {
                "countyName": "Doña Ana",
                "cities": [
                    "Anthony[a]",
                    "Hatch",
                    "Las Cruces†",
                    "Mesilla",
                    "Sunland Park"
                ]
            },
            {
                "countyName": "Eddy",
                "cities": [
                    "Artesia",
                    "Carlsbad†",
                    "Hope",
                    "Loving"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Bayard",
                    "Hurley",
                    "Santa Clara",
                    "Silver City†"
                ]
            },
            {
                "countyName": "Guadalupe",
                "cities": [
                    "Santa Rosa†",
                    "Vaughn"
                ]
            },
            {
                "countyName": "Harding",
                "cities": [
                    "Roy"
                ]
            },
            {
                "countyName": "Harding\nSan Miguel",
                "cities": [
                    "Mosquero†"
                ]
            },
            {
                "countyName": "Hidalgo",
                "cities": [
                    "Lordsburg†",
                    "Virden"
                ]
            },
            {
                "countyName": "Lea",
                "cities": [
                    "Eunice",
                    "Hobbs",
                    "Jal",
                    "Lovington†",
                    "Tatum"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Capitan",
                    "Carrizozo†",
                    "Corona",
                    "Ruidoso",
                    "Ruidoso Downs"
                ]
            },
            {
                "countyName": "Los Alamos",
                "cities": [
                    "Los Alamos"
                ]
            },
            {
                "countyName": "Luna",
                "cities": [
                    "Columbus",
                    "Deming†"
                ]
            },
            {
                "countyName": "McKinley",
                "cities": [
                    "Gallup†"
                ]
            },
            {
                "countyName": "Mora",
                "cities": [
                    "Wagon Mound"
                ]
            },
            {
                "countyName": "Otero",
                "cities": [
                    "Alamogordo†",
                    "Cloudcroft",
                    "Tularosa"
                ]
            },
            {
                "countyName": "Quay",
                "cities": [
                    "House",
                    "Logan",
                    "San Jon",
                    "Tucumcari†"
                ]
            },
            {
                "countyName": "Rio Arriba",
                "cities": [
                    "Chama"
                ]
            },
            {
                "countyName": "Rio Arriba\nSanta Fe",
                "cities": [
                    "Espanola"
                ]
            },
            {
                "countyName": "Roosevelt",
                "cities": [
                    "Causey",
                    "Dora",
                    "Elida",
                    "Floyd",
                    "Portales†"
                ]
            },
            {
                "countyName": "San Juan",
                "cities": [
                    "Aztec†",
                    "Bloomfield",
                    "Farmington",
                    "Kirtland[b]"
                ]
            },
            {
                "countyName": "San Miguel",
                "cities": [
                    "Las Vegas†",
                    "Pecos"
                ]
            },
            {
                "countyName": "Sandoval",
                "cities": [
                    "Bernalillo†",
                    "Corrales",
                    "Cuba",
                    "Jemez Springs",
                    "San Ysidro"
                ]
            },
            {
                "countyName": "Santa Fe",
                "cities": [
                    "Edgewood",
                    "Santa Fe‡"
                ]
            },
            {
                "countyName": "Sierra",
                "cities": [
                    "Elephant Butte",
                    "Truth or Consequences†",
                    "Williamsburg"
                ]
            },
            {
                "countyName": "Socorro",
                "cities": [
                    "Magdalena",
                    "Socorro†"
                ]
            },
            {
                "countyName": "Taos",
                "cities": [
                    "Questa",
                    "Red River",
                    "Taos†",
                    "Taos Ski Valley"
                ]
            },
            {
                "countyName": "Torrance",
                "cities": [
                    "Encino",
                    "Estancia†",
                    "Moriarty",
                    "Mountainair",
                    "Willard"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Clayton†",
                    "Des Moines",
                    "Folsom",
                    "Grenville"
                ]
            },
            {
                "countyName": "Valencia",
                "cities": [
                    "Belen",
                    "Bosque Farms",
                    "Los Lunas†",
                    "Peralta[c]",
                    "Rio Communities[d][15]"
                ]
            }
        ]
    },
    {
        "stateId": "NV",
        "stateName": "Nevada",
        "county": [
            {
                "countyName": "Churchill",
                "cities": [
                    "Fallon†"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Boulder City",
                    "Henderson",
                    "Las Vegas†",
                    "Mesquite",
                    "North Las Vegas"
                ]
            },
            {
                "countyName": "Elko",
                "cities": [
                    "Carlin",
                    "Elko†",
                    "Wells",
                    "West Wendover"
                ]
            },
            {
                "countyName": "Humboldt",
                "cities": [
                    "Winnemucca†"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Caliente"
                ]
            },
            {
                "countyName": "Lyon",
                "cities": [
                    "Fernley",
                    "Yerington†"
                ]
            },
            {
                "countyName": "None",
                "cities": [
                    "Carson City‡"
                ]
            },
            {
                "countyName": "Pershing",
                "cities": [
                    "Lovelock†"
                ]
            },
            {
                "countyName": "Washoe",
                "cities": [
                    "Reno†",
                    "Sparks"
                ]
            },
            {
                "countyName": "White Pine",
                "cities": [
                    "Ely†"
                ]
            }
        ]
    },
    {
        "stateId": "NY",
        "stateName": "New York",
        "county": [
            {
                "countyName": "Albany",
                "cities": [
                    "Albany",
                    "Altamont",
                    "Cohoes",
                    "Colonie",
                    "Green Island",
                    "Menands",
                    "Ravena",
                    "Voorheesville",
                    "Watervliet"
                ]
            },
            {
                "countyName": "Allegany",
                "cities": [
                    "Alfred",
                    "Andover",
                    "Angelica",
                    "Belmont",
                    "Bolivar",
                    "Canaseraga",
                    "Cuba",
                    "Richburg",
                    "Wellsville",
                    "Fillmore",
                    "Friendship",
                    "Oramel"
                ]
            },
            {
                "countyName": "Allegany, Steuben",
                "cities": [
                    "Almond"
                ]
            },
            {
                "countyName": "Bronx, Kings, New York, Queens, Richmond",
                "cities": [
                    "New York City"
                ]
            },
            {
                "countyName": "Broome",
                "cities": [
                    "Binghamton",
                    "Endicott",
                    "Johnson City",
                    "Lisle",
                    "Port Dickinson",
                    "Whitney Point",
                    "Windsor",
                    "Union"
                ]
            },
            {
                "countyName": "Broome, Delaware",
                "cities": [
                    "Deposit"
                ]
            },
            {
                "countyName": "Cattaraugus",
                "cities": [
                    "Allegany",
                    "Cattaraugus",
                    "Delevan",
                    "Ellicottville",
                    "Franklinville",
                    "Little Valley",
                    "Olean",
                    "Portville",
                    "Salamanca",
                    "South Dayton",
                    "East Randolph",
                    "Limestone",
                    "Perrysburg",
                    "Randolph"
                ]
            },
            {
                "countyName": "Cayuga",
                "cities": [
                    "Auburn",
                    "Aurora",
                    "Cato",
                    "Cayuga",
                    "Fair Haven",
                    "Meridian",
                    "Moravia",
                    "Port Byron",
                    "Union Springs",
                    "Weedsport"
                ]
            },
            {
                "countyName": "Chautauqua",
                "cities": [
                    "Bemus Point",
                    "Brocton",
                    "Cassadaga",
                    "Celoron",
                    "Dunkirk",
                    "Falconer",
                    "Fredonia",
                    "Jamestown",
                    "Lakewood",
                    "Mayville",
                    "Panama",
                    "Sherman",
                    "Silver Creek",
                    "Sinclairville",
                    "Westfield",
                    "Cherry Creek",
                    "Forestville"
                ]
            },
            {
                "countyName": "Chemung",
                "cities": [
                    "Elmira",
                    "Elmira Heights",
                    "Horseheads",
                    "Millport",
                    "Wellsburg",
                    "Van Etten"
                ]
            },
            {
                "countyName": "Chenango",
                "cities": [
                    "Afton",
                    "Bainbridge",
                    "Greene",
                    "New Berlin",
                    "Norwich",
                    "Oxford",
                    "Sherburne",
                    "Smyrna"
                ]
            },
            {
                "countyName": "Chenango, Madison",
                "cities": [
                    "Earlville"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Champlain",
                    "Dannemora",
                    "Plattsburgh",
                    "Rouses Point",
                    "Mooers"
                ]
            },
            {
                "countyName": "Clinton & Essex",
                "cities": [
                    "Keeseville"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Chatham",
                    "Hudson",
                    "Kinderhook",
                    "Philmont",
                    "Valatie"
                ]
            },
            {
                "countyName": "Cortland",
                "cities": [
                    "Cortland",
                    "Homer",
                    "Marathon",
                    "McGraw"
                ]
            },
            {
                "countyName": "County",
                "cities": [
                    "Village"
                ]
            },
            {
                "countyName": "Delaware",
                "cities": [
                    "Delhi",
                    "Fleischmanns",
                    "Franklin",
                    "Hancock",
                    "Hobart",
                    "Margaretville",
                    "Sidney",
                    "Stamford",
                    "Walton",
                    "Andes",
                    "Downsville",
                    "Roxbury"
                ]
            },
            {
                "countyName": "Dutchess",
                "cities": [
                    "Beacon",
                    "Fishkill",
                    "Millbrook",
                    "Millerton",
                    "Pawling",
                    "Poughkeepsie",
                    "Red Hook",
                    "Rhinebeck",
                    "Tivoli",
                    "Wappingers Falls",
                    "Pleasant Valley"
                ]
            },
            {
                "countyName": "Erie",
                "cities": [
                    "Akron",
                    "Alden",
                    "Angola",
                    "Blasdell",
                    "Buffalo",
                    "Depew",
                    "East Aurora",
                    "Farnham",
                    "Hamburg",
                    "Kenmore",
                    "Lackawanna",
                    "Lancaster",
                    "North Collins",
                    "Orchard Park",
                    "Sloan",
                    "Springville",
                    "Tonawanda",
                    "Williamsville"
                ]
            },
            {
                "countyName": "Erie, Cattaraugus",
                "cities": [
                    "Gowanda"
                ]
            },
            {
                "countyName": "Essex",
                "cities": [
                    "Lake Placid",
                    "Bloomingdale",
                    "Elizabethtown",
                    "Port Henry",
                    "Ticonderoga",
                    "Westport"
                ]
            },
            {
                "countyName": "Essex, Franklin",
                "cities": [
                    "Saranac Lake"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Brushton",
                    "Burke",
                    "Chateaugay",
                    "Malone",
                    "Tupper Lake",
                    "Fort Covington",
                    "North Bangor"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Broadalbin",
                    "Gloversville",
                    "Johnstown",
                    "Mayfield",
                    "Northville"
                ]
            },
            {
                "countyName": "Genesee",
                "cities": [
                    "Alexander",
                    "Batavia",
                    "Bergen",
                    "Corfu",
                    "Elba",
                    "Le Roy",
                    "Oakfield"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Athens",
                    "Catskill",
                    "Coxsackie",
                    "Hunter",
                    "Tannersville",
                    "Prattsville"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Speculator"
                ]
            },
            {
                "countyName": "Herkimer",
                "cities": [
                    "Cold Brook",
                    "Frankfort",
                    "Herkimer",
                    "Ilion",
                    "Little Falls",
                    "Middleville",
                    "Mohawk",
                    "Newport",
                    "Poland",
                    "West Winfield",
                    "Old Forge"
                ]
            },
            {
                "countyName": "Herkimer, Fulton",
                "cities": [
                    "Dolgeville"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Adams",
                    "Alexandria Bay",
                    "Antwerp",
                    "Black River",
                    "Brownville",
                    "Cape Vincent",
                    "Carthage",
                    "Chaumont",
                    "Clayton",
                    "Deferiet",
                    "Dexter",
                    "Ellisburg",
                    "Evans Mills",
                    "Glen Park",
                    "Mannsville",
                    "Philadelphia",
                    "Sackets Harbor",
                    "Theresa",
                    "Watertown",
                    "West Carthage",
                    "Belleville",
                    "Henderson",
                    "Herrings",
                    "LaFargeville"
                ]
            },
            {
                "countyName": "Lewis",
                "cities": [
                    "Castorland",
                    "Constableville",
                    "Copenhagen",
                    "Croghan",
                    "Lowville",
                    "Lyons Falls",
                    "Port Leyden",
                    "Turin",
                    "Harrisville"
                ]
            },
            {
                "countyName": "Livingston",
                "cities": [
                    "Avon",
                    "Caledonia",
                    "Dansville",
                    "Geneseo",
                    "Leicester",
                    "Lima",
                    "Livonia",
                    "Mount Morris",
                    "Nunda"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Canastota",
                    "Cazenovia",
                    "Chittenango",
                    "DeRuyter",
                    "Hamilton",
                    "Madison",
                    "Morrisville",
                    "Munnsville",
                    "Oneida",
                    "Wampsville",
                    "Brookfield"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Brockport",
                    "Churchville",
                    "East Rochester",
                    "Fairport",
                    "Hilton",
                    "Honeoye Falls",
                    "Pittsford",
                    "Rochester",
                    "Scottsville",
                    "Spencerport",
                    "Webster",
                    "Charlotte"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Ames",
                    "Amsterdam",
                    "Canajoharie",
                    "Fonda",
                    "Fort Johnson",
                    "Fort Plain",
                    "Fultonville",
                    "Hagaman",
                    "Nelliston",
                    "Palatine Bridge",
                    "St. Johnsville"
                ]
            },
            {
                "countyName": "Nassau",
                "cities": [
                    "Atlantic Beach",
                    "Baxter Estates",
                    "Bayville",
                    "Bellerose",
                    "Brookville",
                    "Cedarhurst",
                    "Centre Island",
                    "Cove Neck",
                    "East Hills",
                    "East Rockaway",
                    "East Williston",
                    "Farmingdale",
                    "Floral Park",
                    "Flower Hill",
                    "Freeport",
                    "Garden City",
                    "Glen Cove",
                    "Great Neck",
                    "Great Neck Estates",
                    "Great Neck Plaza",
                    "Hempstead",
                    "Hewlett Bay Park",
                    "Hewlett Harbor",
                    "Hewlett Neck",
                    "Island Park",
                    "Kensington",
                    "Kings Point",
                    "Lake Success",
                    "Lattingtown",
                    "Laurel Hollow",
                    "Lawrence",
                    "Long Beach",
                    "Lynbrook",
                    "Malverne",
                    "Manorhaven",
                    "Massapequa Park",
                    "Matinecock",
                    "Mill Neck",
                    "Mineola",
                    "Munsey Park",
                    "Muttontown",
                    "New Hyde Park",
                    "North Hills",
                    "Old Brookville",
                    "Old Westbury",
                    "Oyster Bay Cove",
                    "Plandome",
                    "Plandome Heights",
                    "Plandome Manor",
                    "Port Washington North",
                    "Rockville Centre",
                    "Roslyn",
                    "Roslyn Estates",
                    "Roslyn Harbor",
                    "Russell Gardens",
                    "Saddle Rock",
                    "Sands Point",
                    "Sea Cliff",
                    "South Floral Park",
                    "Stewart Manor",
                    "Thomaston",
                    "Upper Brookville",
                    "Valley Stream",
                    "Westbury",
                    "Williston Park",
                    "Woodsburgh"
                ]
            },
            {
                "countyName": "Niagara",
                "cities": [
                    "Barker",
                    "Lewiston",
                    "Lockport",
                    "Middleport",
                    "Niagara Falls",
                    "North Tonawanda",
                    "Wilson",
                    "Youngstown"
                ]
            },
            {
                "countyName": "Oneida",
                "cities": [
                    "Boonville",
                    "Camden",
                    "Clayville",
                    "Clinton",
                    "Holland Patent",
                    "New Hartford",
                    "New York Mills",
                    "Oneida Castle",
                    "Oriskany",
                    "Oriskany Falls",
                    "Remsen",
                    "Rome",
                    "Sherrill",
                    "Sylvan Beach",
                    "Utica",
                    "Vernon",
                    "Waterville",
                    "Whitesboro",
                    "Yorkville",
                    "Barneveld",
                    "Bridgewater",
                    "Forestport",
                    "Prospect"
                ]
            },
            {
                "countyName": "Onondaga",
                "cities": [
                    "Baldwinsville",
                    "Camillus",
                    "East Syracuse",
                    "Elbridge",
                    "Fabius",
                    "Fayetteville",
                    "Jordan",
                    "Liverpool",
                    "Manlius",
                    "Marcellus",
                    "Minoa",
                    "North Syracuse",
                    "Skaneateles",
                    "Solvay",
                    "Syracuse",
                    "Tully",
                    "Eastwood"
                ]
            },
            {
                "countyName": "Ontario",
                "cities": [
                    "Bloomfield",
                    "Canandaigua",
                    "Clifton Springs",
                    "Manchester",
                    "Naples",
                    "Phelps",
                    "Shortsville",
                    "Victor",
                    "East Bloomfield",
                    "Holcomb"
                ]
            },
            {
                "countyName": "Ontario, Seneca",
                "cities": [
                    "Geneva"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Chester",
                    "Cornwall-on-Hudson",
                    "Florida",
                    "Goshen",
                    "Greenwood Lake",
                    "Harriman",
                    "Highland Falls",
                    "Kiryas Joel",
                    "Maybrook",
                    "Middletown",
                    "Monroe",
                    "Montgomery",
                    "Newburgh",
                    "Otisville",
                    "Port Jervis",
                    "South Blooming Grove",
                    "Tuxedo Park",
                    "Unionville",
                    "Walden",
                    "Warwick",
                    "Washingtonville",
                    "Woodbury",
                    "Amchir"
                ]
            },
            {
                "countyName": "Orleans",
                "cities": [
                    "Albion",
                    "Holley",
                    "Lyndonville",
                    "Medina"
                ]
            },
            {
                "countyName": "Oswego",
                "cities": [
                    "Central Square",
                    "Cleveland",
                    "Fulton",
                    "Hannibal",
                    "Lacona",
                    "Mexico",
                    "Oswego",
                    "Parish",
                    "Phoenix",
                    "Pulaski",
                    "Sandy Creek",
                    "Altmar"
                ]
            },
            {
                "countyName": "Otsego",
                "cities": [
                    "Cherry Valley",
                    "Cooperstown",
                    "Gilbertsville",
                    "Laurens",
                    "Milford",
                    "Morris",
                    "Oneonta",
                    "Otego",
                    "Richfield Springs",
                    "Unadilla",
                    "Schenevus"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Brewster",
                    "Cold Spring",
                    "Nelsonville"
                ]
            },
            {
                "countyName": "Rensselaer",
                "cities": [
                    "Castleton-on-Hudson",
                    "East Nassau",
                    "Hoosick Falls",
                    "Nassau",
                    "Rensselaer",
                    "Schaghticoke",
                    "Troy",
                    "Valley Falls",
                    "Lansingburgh"
                ]
            },
            {
                "countyName": "Rockland",
                "cities": [
                    "Airmont",
                    "Chestnut Ridge",
                    "Grand View-on-Hudson",
                    "Haverstraw",
                    "Hillburn",
                    "Kaser",
                    "Montebello",
                    "New Hempstead",
                    "New Square",
                    "Nyack",
                    "Piermont",
                    "Pomona",
                    "Sloatsburg",
                    "South Nyack",
                    "Spring Valley",
                    "Suffern",
                    "Upper Nyack",
                    "Wesley Hills",
                    "West Haverstraw",
                    "South Nyack"
                ]
            },
            {
                "countyName": "Saratoga",
                "cities": [
                    "Ballston Spa",
                    "Corinth",
                    "Galway",
                    "Mechanicville",
                    "Round Lake",
                    "Saratoga Springs",
                    "Schuylerville",
                    "South Glens Falls",
                    "Stillwater",
                    "Victory",
                    "Waterford"
                ]
            },
            {
                "countyName": "Schenectady",
                "cities": [
                    "Delanson",
                    "Schenectady",
                    "Scotia"
                ]
            },
            {
                "countyName": "Schoharie",
                "cities": [
                    "Cobleskill",
                    "Esperance",
                    "Middleburgh",
                    "Richmondville",
                    "Schoharie",
                    "Sharon Springs"
                ]
            },
            {
                "countyName": "Schuyler",
                "cities": [
                    "Burdett",
                    "Montour Falls",
                    "Odessa",
                    "Watkins Glen"
                ]
            },
            {
                "countyName": "Seneca",
                "cities": [
                    "Interlaken",
                    "Lodi",
                    "Ovid",
                    "Waterloo",
                    "Seneca Falls"
                ]
            },
            {
                "countyName": "St. Lawrence",
                "cities": [
                    "Canton",
                    "Gouverneur",
                    "Hammond",
                    "Heuvelton",
                    "Massena",
                    "Norwood",
                    "Ogdensburg",
                    "Potsdam",
                    "Rensselaer Falls",
                    "Richville",
                    "Waddington",
                    "Edwards",
                    "Hermon",
                    "Morristown"
                ]
            },
            {
                "countyName": "Steuben",
                "cities": [
                    "Addison",
                    "Arkport",
                    "Avoca",
                    "Bath",
                    "Canisteo",
                    "Cohocton",
                    "Corning",
                    "Hammondsport",
                    "Hornell",
                    "North Hornell",
                    "Painted Post",
                    "Riverside",
                    "Savona",
                    "South Corning",
                    "Wayland",
                    "Prattsburgh",
                    "Woodhull"
                ]
            },
            {
                "countyName": "Suffolk",
                "cities": [
                    "Amityville",
                    "Asharoken",
                    "Babylon",
                    "Belle Terre",
                    "Bellport",
                    "Brightwaters",
                    "Dering Harbor",
                    "East Hampton",
                    "Greenport",
                    "Head of the Harbor",
                    "Huntington Bay",
                    "Islandia",
                    "Lake Grove",
                    "Lindenhurst",
                    "Lloyd Harbor",
                    "Nissequogue",
                    "North Haven",
                    "Northport",
                    "Ocean Beach",
                    "Old Field",
                    "Patchogue",
                    "Poquott",
                    "Port Jefferson",
                    "Quogue",
                    "Sag Harbor",
                    "Sagaponack",
                    "Saltaire",
                    "Shoreham",
                    "Southampton",
                    "Village of the Branch",
                    "West Hampton Dunes",
                    "Westhampton Beach",
                    "Mastic Beach",
                    "Northville",
                    "Pine Valley",
                    "Village of The Landing"
                ]
            },
            {
                "countyName": "Sullivan",
                "cities": [
                    "Bloomingburg",
                    "Jeffersonville",
                    "Liberty",
                    "Monticello",
                    "Woodridge",
                    "Wurtsboro"
                ]
            },
            {
                "countyName": "Tioga",
                "cities": [
                    "Candor",
                    "Newark Valley",
                    "Nichols",
                    "Owego",
                    "Spencer",
                    "Waverly"
                ]
            },
            {
                "countyName": "Tompkins",
                "cities": [
                    "Cayuga Heights",
                    "Dryden",
                    "Freeville",
                    "Groton",
                    "Ithaca",
                    "Lansing",
                    "Trumansburg",
                    "Newfield"
                ]
            },
            {
                "countyName": "Ulster",
                "cities": [
                    "Ellenville",
                    "Kingston",
                    "New Paltz",
                    "Saugerties",
                    "Marlboro",
                    "Pine Hill",
                    "Rifton",
                    "Rosendale"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Glens Falls",
                    "Lake George"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Argyle",
                    "Cambridge",
                    "Fort Ann",
                    "Fort Edward",
                    "Granville",
                    "Greenwich",
                    "Hudson Falls",
                    "Whitehall",
                    "Salem"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Clyde",
                    "Newark",
                    "Palmyra",
                    "Red Creek",
                    "Sodus",
                    "Sodus Point",
                    "Wolcott",
                    "Lyons",
                    "Macedon",
                    "Savannah"
                ]
            },
            {
                "countyName": "Westchester",
                "cities": [
                    "Ardsley",
                    "Briarcliff Manor",
                    "Bronxville",
                    "Buchanan",
                    "Croton-on-Hudson",
                    "Dobbs Ferry",
                    "Elmsford",
                    "Harrison",
                    "Hastings-on-Hudson",
                    "Irvington",
                    "Larchmont",
                    "Mamaroneck",
                    "Mount Kisco",
                    "Mount Vernon",
                    "New Rochelle",
                    "Ossining",
                    "Peekskill",
                    "Pelham",
                    "Pelham Manor",
                    "Pleasantville",
                    "Port Chester",
                    "Rye",
                    "Rye Brook",
                    "Scarsdale",
                    "Sleepy Hollow",
                    "Tarrytown",
                    "Tuckahoe",
                    "White Plains",
                    "Yonkers",
                    "North Pelham"
                ]
            },
            {
                "countyName": "Wyoming",
                "cities": [
                    "Arcade",
                    "Castile",
                    "Gainesville",
                    "Perry",
                    "Silver Springs",
                    "Warsaw",
                    "Wyoming",
                    "Pike"
                ]
            },
            {
                "countyName": "Wyoming, Genesse",
                "cities": [
                    "Attica"
                ]
            },
            {
                "countyName": "Yates",
                "cities": [
                    "Dresden",
                    "Dundee",
                    "Penn Yan"
                ]
            },
            {
                "countyName": "Yates, Ontario",
                "cities": [
                    "Rushville"
                ]
            }
        ]
    },
    {
        "stateId": "OH",
        "stateName": "Ohio",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Manchester",
                    "Peebles",
                    "Rome",
                    "Seaman",
                    "West Union",
                    "Winchester"
                ]
            },
            {
                "countyName": "Allen",
                "cities": [
                    "Lima",
                    "Beaverdam",
                    "Cairo",
                    "Elida",
                    "Harrod",
                    "Lafayette",
                    "Spencerville"
                ]
            },
            {
                "countyName": "Allen\nHancock",
                "cities": [
                    "Bluffton"
                ]
            },
            {
                "countyName": "Allen\nVan Wert",
                "cities": [
                    "Delphos"
                ]
            },
            {
                "countyName": "Ashland",
                "cities": [
                    "Ashland",
                    "Bailey Lakes",
                    "Hayesville",
                    "Jeromesville",
                    "Mifflin",
                    "Perrysville",
                    "Polk",
                    "Savannah"
                ]
            },
            {
                "countyName": "Ashland\nHolmes",
                "cities": [
                    "Loudonville"
                ]
            },
            {
                "countyName": "Ashtabula",
                "cities": [
                    "Ashtabula",
                    "Conneaut",
                    "Geneva",
                    "Andover",
                    "Geneva-on-the-Lake",
                    "Jefferson",
                    "North Kingsville",
                    "Orwell",
                    "Roaming Shores",
                    "Rock Creek"
                ]
            },
            {
                "countyName": "Athens",
                "cities": [
                    "Athens",
                    "Nelsonville",
                    "Albany",
                    "Amesville",
                    "Chauncey",
                    "Coolville",
                    "Glouster",
                    "Jacksonville",
                    "Trimble"
                ]
            },
            {
                "countyName": "Athens\nHocking",
                "cities": [
                    "Buchtel"
                ]
            },
            {
                "countyName": "Auglaize",
                "cities": [
                    "Saint Marys",
                    "Wapakoneta",
                    "Buckland",
                    "Cridersville",
                    "New Bremen",
                    "New Knoxville",
                    "Waynesfield"
                ]
            },
            {
                "countyName": "Auglaize\nShelby",
                "cities": [
                    "Minster"
                ]
            },
            {
                "countyName": "Belmont",
                "cities": [
                    "Martins Ferry",
                    "Saint Clairsville",
                    "Barnesville",
                    "Bellaire",
                    "Belmont",
                    "Bethesda",
                    "Bridgeport",
                    "Brookside",
                    "Flushing",
                    "Holloway",
                    "Morristown",
                    "Powhatan Point",
                    "Shadyside"
                ]
            },
            {
                "countyName": "Brown",
                "cities": [
                    "Aberdeen",
                    "Fayetteville",
                    "Georgetown",
                    "Hamersville",
                    "Higginsport",
                    "Mount Orab",
                    "Ripley",
                    "Russellville"
                ]
            },
            {
                "countyName": "Brown\nHighland",
                "cities": [
                    "Sardinia"
                ]
            },
            {
                "countyName": "Butler",
                "cities": [
                    "Hamilton",
                    "Oxford",
                    "Trenton",
                    "Jacksonburg",
                    "Millville",
                    "New Miami",
                    "Seven Mile"
                ]
            },
            {
                "countyName": "Butler\nHamilton",
                "cities": [
                    "Fairfield"
                ]
            },
            {
                "countyName": "Butler\nWarren",
                "cities": [
                    "Middletown",
                    "Monroe"
                ]
            },
            {
                "countyName": "Carroll",
                "cities": [
                    "Carrollton",
                    "Dellroy",
                    "Leesville",
                    "Malvern",
                    "Sherrodsville"
                ]
            },
            {
                "countyName": "Champaign",
                "cities": [
                    "Urbana",
                    "Christiansburg",
                    "Mechanicsburg",
                    "Mutual",
                    "North Lewisburg",
                    "St. Paris",
                    "Woodstock"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "New Carlisle",
                    "Springfield",
                    "Catawba",
                    "Donnelsville",
                    "Enon",
                    "North Hampton",
                    "South Charleston",
                    "South Vienna",
                    "Tremont City"
                ]
            },
            {
                "countyName": "Clermont",
                "cities": [
                    "Batavia",
                    "Bethel",
                    "Chilo",
                    "Felicity",
                    "Moscow",
                    "Neville",
                    "New Richmond",
                    "Owensville",
                    "Williamsburg"
                ]
            },
            {
                "countyName": "Clermont\nHamilton",
                "cities": [
                    "Milford"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Wilmington",
                    "Clarksville",
                    "Martinsville",
                    "Midland",
                    "New Vienna",
                    "Port William",
                    "Sabina"
                ]
            },
            {
                "countyName": "Clinton\nWarren",
                "cities": [
                    "Blanchester"
                ]
            },
            {
                "countyName": "Columbiana",
                "cities": [
                    "East Liverpool",
                    "East Palestine",
                    "Hanoverton",
                    "Leetonia",
                    "Lisbon",
                    "New Waterford",
                    "Rogers",
                    "Salineville",
                    "Summitville",
                    "Wellsville"
                ]
            },
            {
                "countyName": "Columbiana\nMahoning",
                "cities": [
                    "Columbiana",
                    "Salem",
                    "Washingtonville"
                ]
            },
            {
                "countyName": "Coshocton",
                "cities": [
                    "Coshocton",
                    "Conesville",
                    "Nellie",
                    "Plainfield",
                    "Warsaw",
                    "West Lafayette"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Bucyrus",
                    "Chatfield",
                    "New Washington",
                    "North Robinson",
                    "Tiro"
                ]
            },
            {
                "countyName": "Crawford\nRichland",
                "cities": [
                    "Crestline"
                ]
            },
            {
                "countyName": "Crawford\nRichland\nMorrow",
                "cities": [
                    "Galion"
                ]
            },
            {
                "countyName": "Cuyahoga",
                "cities": [
                    "Bay Village",
                    "Beachwood",
                    "Bedford",
                    "Bedford Heights",
                    "Berea",
                    "Brecksville",
                    "Broadview Heights",
                    "Brooklyn",
                    "Brook Park",
                    "Cleveland",
                    "Cleveland Heights",
                    "East Cleveland",
                    "Euclid",
                    "Fairview Park",
                    "Garfield Heights",
                    "Highland Heights",
                    "Independence",
                    "Lakewood",
                    "Lyndhurst",
                    "Maple Heights",
                    "Mayfield Heights",
                    "Middleburg Heights",
                    "North Olmsted",
                    "North Royalton",
                    "Olmsted Falls",
                    "Parma",
                    "Parma Heights",
                    "Pepper Pike",
                    "Richmond Heights",
                    "Rocky River",
                    "Seven Hills",
                    "Shaker Heights",
                    "Solon",
                    "South Euclid",
                    "Strongsville",
                    "University Heights",
                    "Warrensville Heights",
                    "Westlake",
                    "Bentleyville",
                    "Bratenahl",
                    "Brooklyn Heights",
                    "Chagrin Falls",
                    "Cuyahoga Heights",
                    "Gates Mills",
                    "Glenwillow",
                    "Highland Hills",
                    "Linndale",
                    "Mayfield",
                    "Moreland Hills",
                    "Newburgh Heights",
                    "North Randall",
                    "Oakwood",
                    "Orange",
                    "Valley View",
                    "Walton Hills",
                    "Woodmere"
                ]
            },
            {
                "countyName": "Cuyahoga\nGeauga",
                "cities": [
                    "Hunting Valley"
                ]
            },
            {
                "countyName": "Darke",
                "cities": [
                    "Greenville",
                    "Ansonia",
                    "Arcanum",
                    "Castine",
                    "Gettysburg",
                    "Gordon",
                    "Hollansburg",
                    "Ithaca",
                    "New Madison",
                    "New Weston",
                    "North Star",
                    "Osgood",
                    "Palestine",
                    "Pitsburg",
                    "Rossburg",
                    "Union City",
                    "Versailles",
                    "Wayne Lakes",
                    "Yorkshire"
                ]
            },
            {
                "countyName": "Defiance",
                "cities": [
                    "Defiance",
                    "Hicksville",
                    "Ney",
                    "Sherwood"
                ]
            },
            {
                "countyName": "Delaware",
                "cities": [
                    "Delaware",
                    "Powell",
                    "Sunbury",
                    "Ashley",
                    "Galena",
                    "Ostrander",
                    "Shawnee Hills"
                ]
            },
            {
                "countyName": "Erie",
                "cities": [
                    "Huron",
                    "Sandusky",
                    "Bay View",
                    "Berlin Heights",
                    "Castalia",
                    "Kelleys Island"
                ]
            },
            {
                "countyName": "Erie\nHuron",
                "cities": [
                    "Milan"
                ]
            },
            {
                "countyName": "Fairfield",
                "cities": [
                    "Lancaster",
                    "Amanda",
                    "Baltimore",
                    "Bremen",
                    "Carroll",
                    "Millersport",
                    "Pleasantville",
                    "Rushville",
                    "Stoutsville",
                    "Sugar Grove",
                    "Thurston",
                    "West Rushville"
                ]
            },
            {
                "countyName": "Fairfield\nFranklin",
                "cities": [
                    "Pickerington",
                    "Lithopolis"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "Washington Court House",
                    "Bloomingburg",
                    "Jeffersonville",
                    "Milledgeville",
                    "Octa"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Bexley",
                    "Gahanna",
                    "Grandview Heights",
                    "Grove City",
                    "Groveport",
                    "Hilliard",
                    "Obetz",
                    "Upper Arlington",
                    "Whitehall",
                    "Worthington",
                    "Brice",
                    "Marble Cliff",
                    "Minerva Park",
                    "Riverlea",
                    "Urbancrest",
                    "Valleyview"
                ]
            },
            {
                "countyName": "Franklin\nDelaware",
                "cities": [
                    "Westerville"
                ]
            },
            {
                "countyName": "Franklin\nDelaware\nFairfield",
                "cities": [
                    "Columbus"
                ]
            },
            {
                "countyName": "Franklin\nFairfield",
                "cities": [
                    "Canal Winchester"
                ]
            },
            {
                "countyName": "Franklin\nLicking",
                "cities": [
                    "New Albany"
                ]
            },
            {
                "countyName": "Franklin\nLicking\nFairfield",
                "cities": [
                    "Reynoldsburg"
                ]
            },
            {
                "countyName": "Franklin\nPickaway",
                "cities": [
                    "Harrisburg",
                    "Lockbourne"
                ]
            },
            {
                "countyName": "Franklin\nUnion\nDelaware",
                "cities": [
                    "Dublin"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Wauseon",
                    "Archbold",
                    "Delta",
                    "Fayette",
                    "Lyons",
                    "Metamora"
                ]
            },
            {
                "countyName": "Fulton\nLucas",
                "cities": [
                    "Swanton"
                ]
            },
            {
                "countyName": "Gallia",
                "cities": [
                    "Centerville",
                    "Cheshire",
                    "Crown City",
                    "Gallipolis",
                    "Rio Grande",
                    "Vinton"
                ]
            },
            {
                "countyName": "Geauga",
                "cities": [
                    "Chardon",
                    "Aquilla",
                    "Burton",
                    "Middlefield",
                    "South Russell"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Beavercreek",
                    "Bellbrook",
                    "Fairborn",
                    "Xenia",
                    "Bowersville",
                    "Cedarville",
                    "Jamestown",
                    "Spring Valley",
                    "Yellow Springs"
                ]
            },
            {
                "countyName": "Greene\nClark",
                "cities": [
                    "Clifton"
                ]
            },
            {
                "countyName": "Guernsey",
                "cities": [
                    "Cambridge",
                    "Byesville",
                    "Cumberland",
                    "Lore City",
                    "Old Washington",
                    "Pleasant City",
                    "Quaker City",
                    "Senecaville"
                ]
            },
            {
                "countyName": "Guernsey\nBelmont",
                "cities": [
                    "Fairview"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Blue Ash",
                    "Cheviot",
                    "Cincinnati",
                    "Deer Park",
                    "Forest Park",
                    "Harrison",
                    "Madeira",
                    "Montgomery",
                    "Mount Healthy",
                    "North College Hill",
                    "Norwood",
                    "Reading",
                    "Springdale",
                    "The Village of Indian Hill",
                    "Wyoming",
                    "Addyston",
                    "Amberley",
                    "Arlington Heights",
                    "Cleves",
                    "Elmwood Place",
                    "Evendale",
                    "Fairfax",
                    "Glendale",
                    "Golf Manor",
                    "Greenhills",
                    "Lincoln Heights",
                    "Lockland",
                    "Mariemont",
                    "Newtown",
                    "North Bend",
                    "St. Bernard",
                    "Silverton",
                    "Terrace Park",
                    "Woodlawn"
                ]
            },
            {
                "countyName": "Hamilton\nButler",
                "cities": [
                    "Sharonville"
                ]
            },
            {
                "countyName": "Hamilton\nClermont\nWarren",
                "cities": [
                    "Loveland"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Findlay",
                    "Arcadia",
                    "Arlington",
                    "Benton Ridge",
                    "Jenera",
                    "McComb",
                    "Mount Blanchard",
                    "Mount Cory",
                    "Rawson",
                    "Van Buren",
                    "Vanlue"
                ]
            },
            {
                "countyName": "Hardin",
                "cities": [
                    "Kenton",
                    "Ada",
                    "Alger",
                    "Dunkirk",
                    "Forest",
                    "McGuffey",
                    "Mount Victory",
                    "Patterson"
                ]
            },
            {
                "countyName": "Hardin\nLogan",
                "cities": [
                    "Ridgeway"
                ]
            },
            {
                "countyName": "Harrison",
                "cities": [
                    "Bowerston",
                    "Cadiz",
                    "Deersville",
                    "Freeport",
                    "Harrisville",
                    "Hopedale",
                    "Jewett",
                    "New Athens",
                    "Scio"
                ]
            },
            {
                "countyName": "Henry",
                "cities": [
                    "Napoleon",
                    "Deshler",
                    "Florida",
                    "Hamler",
                    "Holgate",
                    "Liberty Center",
                    "Malinta",
                    "McClure",
                    "New Bavaria"
                ]
            },
            {
                "countyName": "Highland",
                "cities": [
                    "Hillsboro",
                    "Highland",
                    "Leesburg",
                    "Mowrystown",
                    "Sinking Spring"
                ]
            },
            {
                "countyName": "Highland\nClinton",
                "cities": [
                    "Lynchburg"
                ]
            },
            {
                "countyName": "Highland\nRoss",
                "cities": [
                    "Greenfield"
                ]
            },
            {
                "countyName": "Hocking",
                "cities": [
                    "Logan",
                    "Laurelville",
                    "Murray City"
                ]
            },
            {
                "countyName": "Holmes",
                "cities": [
                    "Glenmont",
                    "Holmesville",
                    "Killbuck",
                    "Millersburg",
                    "Nashville"
                ]
            },
            {
                "countyName": "Huron",
                "cities": [
                    "Norwalk",
                    "Willard",
                    "Greenwich",
                    "Monroeville",
                    "New London",
                    "North Fairfield",
                    "Wakeman"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Jackson",
                    "Wellston",
                    "Coalton",
                    "Oak Hill"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Steubenville",
                    "Toronto",
                    "Amsterdam",
                    "Bergholz",
                    "Bloomingdale",
                    "Dillonvale",
                    "Empire",
                    "Irondale",
                    "Mingo Junction",
                    "Mount Pleasant",
                    "New Alexandria",
                    "Rayland",
                    "Richmond",
                    "Stratton",
                    "Tiltonsville",
                    "Wintersville"
                ]
            },
            {
                "countyName": "Jefferson\nBelmont",
                "cities": [
                    "Yorkville"
                ]
            },
            {
                "countyName": "Jefferson\nHarrison",
                "cities": [
                    "Adena"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Mount Vernon",
                    "Centerburg",
                    "Danville",
                    "Fredericktown",
                    "Gambier",
                    "Gann",
                    "Martinsburg"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Eastlake",
                    "Kirtland",
                    "Mentor",
                    "Mentor-on-the-Lake",
                    "Painesville",
                    "Wickliffe",
                    "Willoughby",
                    "Willoughby Hills",
                    "Willowick",
                    "Fairport Harbor",
                    "Grand River",
                    "Kirtland Hills",
                    "Lakeline",
                    "Madison",
                    "North Perry",
                    "Perry",
                    "Timberlake",
                    "Waite Hill"
                ]
            },
            {
                "countyName": "Lawrence",
                "cities": [
                    "Ironton",
                    "Athalia",
                    "Chesapeake",
                    "Coal Grove",
                    "Hanging Rock",
                    "Proctorville",
                    "South Point"
                ]
            },
            {
                "countyName": "Licking",
                "cities": [
                    "Heath",
                    "Johnstown",
                    "Newark",
                    "Pataskala",
                    "Alexandria",
                    "Granville",
                    "Hanover",
                    "Hartford",
                    "Hebron",
                    "Kirkersville",
                    "St. Louisville"
                ]
            },
            {
                "countyName": "Licking\nFairfield",
                "cities": [
                    "Buckeye Lake"
                ]
            },
            {
                "countyName": "Licking\nKnox",
                "cities": [
                    "Utica"
                ]
            },
            {
                "countyName": "Licking\nMuskingum",
                "cities": [
                    "Gratiot"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Bellefontaine",
                    "Belle Center",
                    "De Graff",
                    "Huntsville",
                    "Lakeview",
                    "Quincy",
                    "Rushsylvania",
                    "Russells Point",
                    "Valley Hi",
                    "West Liberty",
                    "West Mansfield",
                    "Zanesfield"
                ]
            },
            {
                "countyName": "Lorain",
                "cities": [
                    "Amherst",
                    "Avon",
                    "Avon Lake",
                    "Elyria",
                    "Lorain",
                    "North Ridgeville",
                    "Oberlin",
                    "Sheffield Lake",
                    "Grafton",
                    "Kipton",
                    "LaGrange",
                    "Rochester",
                    "Sheffield",
                    "South Amherst",
                    "Wellington"
                ]
            },
            {
                "countyName": "Lorain\nErie",
                "cities": [
                    "Vermilion"
                ]
            },
            {
                "countyName": "Lucas",
                "cities": [
                    "Maumee",
                    "Oregon",
                    "Sylvania",
                    "Toledo",
                    "Waterville",
                    "Berkey",
                    "Harbor View",
                    "Holland",
                    "Ottawa Hills",
                    "Whitehouse"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "London",
                    "Midway",
                    "Mount Sterling",
                    "South Solon",
                    "West Jefferson"
                ]
            },
            {
                "countyName": "Madison\nUnion",
                "cities": [
                    "Plain City"
                ]
            },
            {
                "countyName": "Mahoning",
                "cities": [
                    "Campbell",
                    "Canfield",
                    "Struthers",
                    "Beloit",
                    "Craig Beach",
                    "Lowellville",
                    "New Middletown",
                    "Poland",
                    "Sebring"
                ]
            },
            {
                "countyName": "Mahoning\nTrumbull",
                "cities": [
                    "Youngstown"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Marion",
                    "Caledonia",
                    "Green Camp",
                    "LaRue",
                    "Morral",
                    "New Bloomington",
                    "Prospect",
                    "Waldo"
                ]
            },
            {
                "countyName": "Medina",
                "cities": [
                    "Brunswick",
                    "Medina",
                    "Wadsworth",
                    "Chippewa Lake",
                    "Gloria Glens Park",
                    "Lodi",
                    "Seville",
                    "Spencer",
                    "Westfield Center"
                ]
            },
            {
                "countyName": "Meigs",
                "cities": [
                    "Middleport",
                    "Pomeroy",
                    "Racine",
                    "Rutland",
                    "Syracuse"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Celina",
                    "Chickasaw",
                    "Coldwater",
                    "Fort Recovery",
                    "Mendon",
                    "Montezuma",
                    "Rockford",
                    "St. Henry"
                ]
            },
            {
                "countyName": "Mercer\nDarke",
                "cities": [
                    "Burkettsville"
                ]
            },
            {
                "countyName": "Miami",
                "cities": [
                    "Piqua",
                    "Tipp City",
                    "Troy",
                    "Casstown",
                    "Covington",
                    "Fletcher",
                    "Laura",
                    "Ludlow Falls",
                    "Pleasant Hill",
                    "Potsdam",
                    "West Milton"
                ]
            },
            {
                "countyName": "Miami\nDarke",
                "cities": [
                    "Bradford"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Antioch",
                    "Beallsville",
                    "Clarington",
                    "Graysville",
                    "Jerusalem",
                    "Lewisville",
                    "Miltonsburg",
                    "Stafford",
                    "Woodsfield"
                ]
            },
            {
                "countyName": "Monroe\nBelmont",
                "cities": [
                    "Wilson"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Brookville",
                    "Clayton",
                    "Dayton",
                    "Englewood",
                    "Germantown",
                    "Miamisburg",
                    "Moraine",
                    "Oakwood",
                    "Riverside",
                    "Trotwood",
                    "Vandalia",
                    "West Carrollton",
                    "Farmersville",
                    "New Lebanon",
                    "Phillipsburg"
                ]
            },
            {
                "countyName": "Montgomery\nGreene",
                "cities": [
                    "Centerville",
                    "Kettering"
                ]
            },
            {
                "countyName": "Montgomery\nMiami",
                "cities": [
                    "Huber Heights",
                    "Union"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Chesterhill",
                    "Malta",
                    "McConnelsville",
                    "Stockport"
                ]
            },
            {
                "countyName": "Morrow",
                "cities": [
                    "Cardington",
                    "Chesterville",
                    "Edison",
                    "Fulton",
                    "Marengo",
                    "Mount Gilead",
                    "Sparta"
                ]
            },
            {
                "countyName": "Muskingum",
                "cities": [
                    "Zanesville",
                    "Adamsville",
                    "Dresden",
                    "Frazeysburg",
                    "Fultonham",
                    "New Concord",
                    "Norwich",
                    "Philo",
                    "South Zanesville"
                ]
            },
            {
                "countyName": "Noble",
                "cities": [
                    "Batesville",
                    "Belle Valley",
                    "Caldwell",
                    "Dexter City",
                    "Sarahsville",
                    "Summerfield"
                ]
            },
            {
                "countyName": "Ottawa",
                "cities": [
                    "Port Clinton",
                    "Clay Center",
                    "Genoa",
                    "Marblehead",
                    "Oak Harbor",
                    "Put-in-Bay",
                    "Rocky Ridge"
                ]
            },
            {
                "countyName": "Ottawa\nSandusky",
                "cities": [
                    "Elmore"
                ]
            },
            {
                "countyName": "Paulding",
                "cities": [
                    "Antwerp",
                    "Broughton",
                    "Cecil",
                    "Grover Hill",
                    "Haviland",
                    "Latty",
                    "Melrose",
                    "Oakwood",
                    "Paulding",
                    "Payne"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Corning",
                    "Crooksville",
                    "Glenford",
                    "Hemlock",
                    "Junction City",
                    "New Lexington",
                    "New Straitsville",
                    "Rendville",
                    "Shawnee",
                    "Somerset",
                    "Thornville"
                ]
            },
            {
                "countyName": "Perry\nMuskingum",
                "cities": [
                    "Roseville"
                ]
            },
            {
                "countyName": "Pickaway",
                "cities": [
                    "Circleville",
                    "Ashville",
                    "Commercial Point",
                    "Darbyville",
                    "South Bloomfield",
                    "Williamsport"
                ]
            },
            {
                "countyName": "Pickaway\nFairfield",
                "cities": [
                    "Tarlton"
                ]
            },
            {
                "countyName": "Pickaway\nFayette",
                "cities": [
                    "New Holland"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Beaver",
                    "Piketon",
                    "Waverly"
                ]
            },
            {
                "countyName": "Portage",
                "cities": [
                    "Aurora",
                    "Kent",
                    "Ravenna",
                    "Streetsboro",
                    "Garrettsville",
                    "Hiram",
                    "Mantua",
                    "Sugar Bush Knolls",
                    "Windham"
                ]
            },
            {
                "countyName": "Preble",
                "cities": [
                    "Eaton",
                    "Camden",
                    "Eldorado",
                    "Gratis",
                    "Lewisburg",
                    "New Paris",
                    "West Alexandria",
                    "West Elkton",
                    "West Manchester"
                ]
            },
            {
                "countyName": "Preble\nButler",
                "cities": [
                    "College Corner"
                ]
            },
            {
                "countyName": "Preble\nMontgomery",
                "cities": [
                    "Verona"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Belmore",
                    "Cloverdale",
                    "Columbus Grove",
                    "Continental",
                    "Dupont",
                    "Fort Jennings",
                    "Gilboa",
                    "Glandorf",
                    "Kalida",
                    "Leipsic",
                    "Miller City",
                    "Ottawa",
                    "Ottoville",
                    "Pandora",
                    "West Leipsic"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Mansfield",
                    "Ontario",
                    "Shelby",
                    "Bellville",
                    "Butler",
                    "Lexington",
                    "Lucas",
                    "Shiloh"
                ]
            },
            {
                "countyName": "Richland\nHuron",
                "cities": [
                    "Plymouth"
                ]
            },
            {
                "countyName": "Ross",
                "cities": [
                    "Chillicothe",
                    "Adelphi",
                    "Bainbridge",
                    "Clarksburg",
                    "Frankfort",
                    "Kingston",
                    "South Salem"
                ]
            },
            {
                "countyName": "Sandusky",
                "cities": [
                    "Clyde",
                    "Fremont",
                    "Burgoon",
                    "Gibsonburg",
                    "Helena",
                    "Lindsey",
                    "Woodville"
                ]
            },
            {
                "countyName": "Sandusky\nHuron\nErie",
                "cities": [
                    "Bellevue"
                ]
            },
            {
                "countyName": "Scioto",
                "cities": [
                    "Portsmouth",
                    "New Boston",
                    "Otway",
                    "Rarden",
                    "South Webster"
                ]
            },
            {
                "countyName": "Seneca",
                "cities": [
                    "Tiffin",
                    "Attica",
                    "Bettsville",
                    "Bloomville",
                    "New Riegel",
                    "Republic"
                ]
            },
            {
                "countyName": "Seneca\nHancock\nWood",
                "cities": [
                    "Fostoria"
                ]
            },
            {
                "countyName": "Seneca\nSandusky",
                "cities": [
                    "Green Springs"
                ]
            },
            {
                "countyName": "Shelby",
                "cities": [
                    "Sidney",
                    "Anna",
                    "Botkins",
                    "Fort Loramie",
                    "Jackson Center",
                    "Kettlersville",
                    "Lockington",
                    "Port Jefferson",
                    "Russia"
                ]
            },
            {
                "countyName": "Stark",
                "cities": [
                    "Canal Fulton",
                    "Canton",
                    "Louisville",
                    "Massillon",
                    "North Canton",
                    "Beach City",
                    "Brewster",
                    "East Canton",
                    "East Sparta",
                    "Hartville",
                    "Hills and Dales",
                    "Meyers Lake",
                    "Navarre",
                    "Waynesburg",
                    "Wilmot"
                ]
            },
            {
                "countyName": "Stark\nCarroll",
                "cities": [
                    "Magnolia"
                ]
            },
            {
                "countyName": "Stark\nCarroll\nColumbiana",
                "cities": [
                    "Minerva"
                ]
            },
            {
                "countyName": "Stark\nMahoning",
                "cities": [
                    "Alliance"
                ]
            },
            {
                "countyName": "Summit",
                "cities": [
                    "Akron",
                    "Barberton",
                    "Cuyahoga Falls",
                    "Fairlawn",
                    "Green",
                    "Hudson",
                    "Macedonia",
                    "Munroe Falls",
                    "New Franklin",
                    "Norton",
                    "Reminderville",
                    "Stow",
                    "Twinsburg",
                    "Boston Heights",
                    "Clinton",
                    "Lakemore",
                    "Northfield",
                    "Peninsula",
                    "Richfield",
                    "Silver Lake"
                ]
            },
            {
                "countyName": "Summit\nPortage",
                "cities": [
                    "Tallmadge",
                    "Mogadore"
                ]
            },
            {
                "countyName": "Trumbull",
                "cities": [
                    "Cortland",
                    "Girard",
                    "Hubbard",
                    "Niles",
                    "Warren",
                    "Lordstown",
                    "McDonald",
                    "Newton Falls",
                    "Orangeville",
                    "West Farmington",
                    "Yankee Lake"
                ]
            },
            {
                "countyName": "Tuscarawas",
                "cities": [
                    "Dover",
                    "New Philadelphia",
                    "Uhrichsville",
                    "Barnhill",
                    "Bolivar",
                    "Dennison",
                    "Gnadenhutten",
                    "Midvale",
                    "Mineral City",
                    "Newcomerstown",
                    "Parral",
                    "Port Washington",
                    "Roswell",
                    "Stone Creek",
                    "Strasburg",
                    "Sugarcreek",
                    "Tuscarawas",
                    "Zoar"
                ]
            },
            {
                "countyName": "Tuscarawas\nHolmes\nCoshocton",
                "cities": [
                    "Baltic"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Marysville",
                    "Magnetic Springs",
                    "Milford Center",
                    "Richwood",
                    "Unionville Center"
                ]
            },
            {
                "countyName": "Van Wert",
                "cities": [
                    "Van Wert",
                    "Convoy",
                    "Elgin",
                    "Middle Point",
                    "Ohio City",
                    "Venedocia",
                    "Willshire",
                    "Wren"
                ]
            },
            {
                "countyName": "Van Wert\nPaulding",
                "cities": [
                    "Scott"
                ]
            },
            {
                "countyName": "Vinton",
                "cities": [
                    "Hamden",
                    "McArthur",
                    "Wilkesville",
                    "Zaleski"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Franklin",
                    "Lebanon",
                    "Mason",
                    "South Lebanon",
                    "Butlerville",
                    "Corwin",
                    "Harveysburg",
                    "Maineville",
                    "Morrow",
                    "Pleasant Plain",
                    "Waynesville"
                ]
            },
            {
                "countyName": "Warren\nMontgomery",
                "cities": [
                    "Carlisle",
                    "Springboro"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Belpre",
                    "Marietta",
                    "Beverly",
                    "Lowell",
                    "Lower Salem",
                    "Macksburg",
                    "Matamoras"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Orrville",
                    "Wooster",
                    "Apple Creek",
                    "Burbank",
                    "Congress",
                    "Dalton",
                    "Doylestown",
                    "Fredericksburg",
                    "Marshallville",
                    "Mount Eaton",
                    "Shreve",
                    "Smithville",
                    "West Salem"
                ]
            },
            {
                "countyName": "Wayne\nMedina",
                "cities": [
                    "Rittman",
                    "Creston"
                ]
            },
            {
                "countyName": "Williams",
                "cities": [
                    "Bryan",
                    "Blakeslee",
                    "Edgerton",
                    "Edon",
                    "Holiday City",
                    "Montpelier",
                    "Pioneer",
                    "Stryker",
                    "West Unity"
                ]
            },
            {
                "countyName": "Wood",
                "cities": [
                    "Bowling Green",
                    "Northwood",
                    "Perrysburg",
                    "Rossford",
                    "Bairdstown",
                    "Bloomdale",
                    "Bradner",
                    "Custar",
                    "Cygnet",
                    "Grand Rapids",
                    "Haskins",
                    "Hoytville",
                    "Jerry City",
                    "Luckey",
                    "Millbury",
                    "Milton Center",
                    "North Baltimore",
                    "Pemberville",
                    "Portage",
                    "Risingsun",
                    "Tontogany",
                    "Walbridge",
                    "Wayne",
                    "West Millgrove",
                    "Weston"
                ]
            },
            {
                "countyName": "Wyandot",
                "cities": [
                    "Upper Sandusky",
                    "Carey",
                    "Harpster",
                    "Kirby",
                    "Marseilles",
                    "Nevada",
                    "Sycamore",
                    "Wharton"
                ]
            }
        ]
    },
    {
        "stateId": "OK",
        "stateName": "Oklahoma",
        "county": [
            {
                "countyName": "Beckham",
                "cities": [
                    "Elk City"
                ]
            },
            {
                "countyName": "Bryan",
                "cities": [
                    "Durant †"
                ]
            },
            {
                "countyName": "Caddo",
                "cities": [
                    "Anadarko †"
                ]
            },
            {
                "countyName": "Canadian",
                "cities": [
                    "Yukon",
                    "Mustang",
                    "El Reno †"
                ]
            },
            {
                "countyName": "Canadian\nKingfisher",
                "cities": [
                    "Piedmont"
                ]
            },
            {
                "countyName": "Carter",
                "cities": [
                    "Ardmore †"
                ]
            },
            {
                "countyName": "Cherokee",
                "cities": [
                    "Tahlequah †"
                ]
            },
            {
                "countyName": "Choctaw",
                "cities": [
                    "Hugo †"
                ]
            },
            {
                "countyName": "Cleveland",
                "cities": [
                    "Norman †",
                    "Moore",
                    "Noble"
                ]
            },
            {
                "countyName": "Comanche",
                "cities": [
                    "Lawton †"
                ]
            },
            {
                "countyName": "Craig",
                "cities": [
                    "Vinita †"
                ]
            },
            {
                "countyName": "Creek\nTulsa",
                "cities": [
                    "Sapulpa †"
                ]
            },
            {
                "countyName": "Custer",
                "cities": [
                    "Weatherford"
                ]
            },
            {
                "countyName": "Custer\nWashita",
                "cities": [
                    "Clinton"
                ]
            },
            {
                "countyName": "Delaware",
                "cities": [
                    "Grove"
                ]
            },
            {
                "countyName": "Garfield",
                "cities": [
                    "Enid †"
                ]
            },
            {
                "countyName": "Garvin",
                "cities": [
                    "Pauls Valley †"
                ]
            },
            {
                "countyName": "Grady",
                "cities": [
                    "Chickasha †",
                    "Tuttle"
                ]
            },
            {
                "countyName": "Hughes",
                "cities": [
                    "Holdenville †"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Altus †"
                ]
            },
            {
                "countyName": "Kay",
                "cities": [
                    "Ponca City",
                    "Blackwell"
                ]
            },
            {
                "countyName": "Le Flore",
                "cities": [
                    "Poteau †"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Guthrie †"
                ]
            },
            {
                "countyName": "Mayes",
                "cities": [
                    "Pryor Creek †"
                ]
            },
            {
                "countyName": "McClain",
                "cities": [
                    "Newcastle"
                ]
            },
            {
                "countyName": "McClain\nCleveland",
                "cities": [
                    "Purcell †"
                ]
            },
            {
                "countyName": "McClain\nGrady",
                "cities": [
                    "Blanchard"
                ]
            },
            {
                "countyName": "McCurtain",
                "cities": [
                    "Idabel †"
                ]
            },
            {
                "countyName": "Murray",
                "cities": [
                    "Sulphur †"
                ]
            },
            {
                "countyName": "Muskogee",
                "cities": [
                    "Muskogee †"
                ]
            },
            {
                "countyName": "Oklahoma",
                "cities": [
                    "Edmond",
                    "Midwest City",
                    "Del City",
                    "Bethany",
                    "Choctaw",
                    "Warr Acres",
                    "The Village",
                    "Harrah"
                ]
            },
            {
                "countyName": "Oklahoma\nCanadian\nCleveland\nPottawatomie",
                "cities": [
                    "Oklahoma City ††"
                ]
            },
            {
                "countyName": "Okmulgee",
                "cities": [
                    "Okmulgee †",
                    "Henryetta"
                ]
            },
            {
                "countyName": "Osage\nTulsa",
                "cities": [
                    "Skiatook"
                ]
            },
            {
                "countyName": "Ottawa",
                "cities": [
                    "Miami †"
                ]
            },
            {
                "countyName": "Payne",
                "cities": [
                    "Stillwater †",
                    "Cushing"
                ]
            },
            {
                "countyName": "Pittsburg",
                "cities": [
                    "McAlester †"
                ]
            },
            {
                "countyName": "Pontotoc",
                "cities": [
                    "Ada †"
                ]
            },
            {
                "countyName": "Pottawatomie",
                "cities": [
                    "Shawnee †",
                    "Tecumseh"
                ]
            },
            {
                "countyName": "Rogers",
                "cities": [
                    "Claremore †",
                    "Verdigris"
                ]
            },
            {
                "countyName": "Rogers\nWagoner",
                "cities": [
                    "Catoosa"
                ]
            },
            {
                "countyName": "Seminole",
                "cities": [
                    "Seminole"
                ]
            },
            {
                "countyName": "Sequoyah",
                "cities": [
                    "Sallisaw †"
                ]
            },
            {
                "countyName": "Stephens",
                "cities": [
                    "Duncan †"
                ]
            },
            {
                "countyName": "Texas",
                "cities": [
                    "Guymon †"
                ]
            },
            {
                "countyName": "Tulsa",
                "cities": [
                    "Jenks",
                    "Glenpool"
                ]
            },
            {
                "countyName": "Tulsa\nCreek\nOsage",
                "cities": [
                    "Sand Springs"
                ]
            },
            {
                "countyName": "Tulsa\nOsage\nRogers\nWagoner",
                "cities": [
                    "Tulsa †"
                ]
            },
            {
                "countyName": "Tulsa\nRogers",
                "cities": [
                    "Owasso",
                    "Collinsville"
                ]
            },
            {
                "countyName": "Tulsa\nWagoner",
                "cities": [
                    "Broken Arrow",
                    "Bixby"
                ]
            },
            {
                "countyName": "Wagoner",
                "cities": [
                    "Coweta",
                    "Wagoner †"
                ]
            },
            {
                "countyName": "Washington\nOsage",
                "cities": [
                    "Bartlesville †"
                ]
            },
            {
                "countyName": "Woods",
                "cities": [
                    "Alva †"
                ]
            },
            {
                "countyName": "Woodward",
                "cities": [
                    "Woodward †"
                ]
            }
        ]
    },
    {
        "stateId": "OR",
        "stateName": "Oregon",
        "county": [
            {
                "countyName": "Baker",
                "cities": [
                    "Baker City †",
                    "Huntington",
                    "Haines",
                    "Halfway",
                    "Sumpter",
                    "Richland",
                    "Unity"
                ]
            },
            {
                "countyName": "Baker, Grant",
                "cities": [
                    "Greenhorn"
                ]
            },
            {
                "countyName": "Benton",
                "cities": [
                    "Corvallis †",
                    "Philomath",
                    "Adair Village",
                    "Monroe"
                ]
            },
            {
                "countyName": "Clackamas",
                "cities": [
                    "Oregon City †",
                    "West Linn",
                    "Happy Valley",
                    "Canby",
                    "Sandy",
                    "Gladstone",
                    "Molalla",
                    "Estacada",
                    "Johnson City",
                    "Barlow"
                ]
            },
            {
                "countyName": "Clackamas, Multnomah",
                "cities": [
                    "Milwaukie"
                ]
            },
            {
                "countyName": "Clackamas, Multnomah, Washington",
                "cities": [
                    "Lake Oswego"
                ]
            },
            {
                "countyName": "Clackamas, Washington",
                "cities": [
                    "Wilsonville",
                    "Rivergrove"
                ]
            },
            {
                "countyName": "Clatsop",
                "cities": [
                    "Astoria †",
                    "Seaside",
                    "Warrenton",
                    "Gearhart",
                    "Cannon Beach"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "St. Helens †",
                    "Scappoose",
                    "Vernonia",
                    "Columbia City",
                    "Rainier",
                    "Clatskanie",
                    "Prescott"
                ]
            },
            {
                "countyName": "Coos",
                "cities": [
                    "Coos Bay",
                    "North Bend",
                    "Coquille †",
                    "Bandon",
                    "Myrtle Point",
                    "Lakeside",
                    "Powers"
                ]
            },
            {
                "countyName": "Crook",
                "cities": [
                    "Prineville †"
                ]
            },
            {
                "countyName": "Curry",
                "cities": [
                    "Brookings",
                    "Gold Beach †",
                    "Port Orford"
                ]
            },
            {
                "countyName": "Deschutes",
                "cities": [
                    "Bend †",
                    "Redmond",
                    "Sisters",
                    "La Pine"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Roseburg †",
                    "Sutherlin",
                    "Winston",
                    "Reedsport",
                    "Myrtle Creek",
                    "Canyonville",
                    "Riddle",
                    "Drain",
                    "Yoncalla",
                    "Oakland",
                    "Glendale",
                    "Elkton"
                ]
            },
            {
                "countyName": "Gilliam",
                "cities": [
                    "Condon †",
                    "Arlington",
                    "Lonerock"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "John Day",
                    "Prairie City",
                    "Canyon City †",
                    "Mount Vernon",
                    "Long Creek",
                    "Seneca",
                    "Dayville",
                    "Monument",
                    "Granite"
                ]
            },
            {
                "countyName": "Harney",
                "cities": [
                    "Burns †",
                    "Hines"
                ]
            },
            {
                "countyName": "Hood River",
                "cities": [
                    "Hood River †",
                    "Cascade Locks"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Medford †",
                    "Ashland",
                    "Central Point",
                    "Eagle Point",
                    "Talent",
                    "Phoenix",
                    "Shady Cove",
                    "Jacksonville",
                    "Rogue River",
                    "Gold Hill",
                    "Butte Falls"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Madras †",
                    "Culver",
                    "Metolius"
                ]
            },
            {
                "countyName": "Josephine",
                "cities": [
                    "Grants Pass †",
                    "Cave Junction"
                ]
            },
            {
                "countyName": "Klamath",
                "cities": [
                    "Klamath Falls †",
                    "Merrill",
                    "Chiloquin",
                    "Malin",
                    "Bonanza"
                ]
            },
            {
                "countyName": "Lake",
                "cities": [
                    "Lakeview †",
                    "Paisley"
                ]
            },
            {
                "countyName": "Lane",
                "cities": [
                    "Eugene †",
                    "Springfield",
                    "Cottage Grove",
                    "Florence",
                    "Junction City",
                    "Creswell",
                    "Veneta",
                    "Oakridge",
                    "Dunes City",
                    "Coburg",
                    "Lowell",
                    "Westfir"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Newport †",
                    "Lincoln City",
                    "Toledo",
                    "Waldport",
                    "Depoe Bay",
                    "Siletz",
                    "Yachats"
                ]
            },
            {
                "countyName": "Linn",
                "cities": [
                    "Lebanon",
                    "Sweet Home",
                    "Harrisburg",
                    "Millersburg",
                    "Brownsville",
                    "Tangent",
                    "Lyons",
                    "Halsey",
                    "Scio",
                    "Sodaville",
                    "Waterloo"
                ]
            },
            {
                "countyName": "Linn, Benton",
                "cities": [
                    "Albany †"
                ]
            },
            {
                "countyName": "Malheur",
                "cities": [
                    "Ontario",
                    "Nyssa",
                    "Vale †",
                    "Adrian",
                    "Jordan Valley"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Keizer",
                    "Woodburn",
                    "Silverton",
                    "Stayton",
                    "Aumsville",
                    "Hubbard",
                    "Mt. Angel",
                    "Jefferson",
                    "Sublimity",
                    "Gervais",
                    "Turner",
                    "Aurora",
                    "Donald",
                    "St. Paul",
                    "Scotts Mills",
                    "Detroit"
                ]
            },
            {
                "countyName": "Marion, Linn",
                "cities": [
                    "Mill City",
                    "Gates",
                    "Idanha"
                ]
            },
            {
                "countyName": "Marion, Polk",
                "cities": [
                    "Salem ††"
                ]
            },
            {
                "countyName": "Morrow",
                "cities": [
                    "Boardman",
                    "Irrigon",
                    "Heppner †",
                    "Ione",
                    "Lexington"
                ]
            },
            {
                "countyName": "Multnomah",
                "cities": [
                    "Gresham",
                    "Troutdale",
                    "Fairview",
                    "Wood Village",
                    "Maywood Park"
                ]
            },
            {
                "countyName": "Multnomah, Washington, Clackamas",
                "cities": [
                    "Portland †"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Dallas †",
                    "Monmouth",
                    "Independence",
                    "Falls City"
                ]
            },
            {
                "countyName": "Sherman",
                "cities": [
                    "Wasco",
                    "Moro †",
                    "Rufus",
                    "Grass Valley"
                ]
            },
            {
                "countyName": "Tillamook",
                "cities": [
                    "Tillamook †",
                    "Rockaway Beach",
                    "Bay City",
                    "Garibaldi",
                    "Manzanita",
                    "Wheeler",
                    "Nehalem"
                ]
            },
            {
                "countyName": "Umatilla",
                "cities": [
                    "Hermiston",
                    "Pendleton †",
                    "Umatilla",
                    "Milton-Freewater",
                    "Stanfield",
                    "Pilot Rock",
                    "Athena",
                    "Weston",
                    "Echo",
                    "Adams",
                    "Helix",
                    "Ukiah"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "La Grande †",
                    "Union",
                    "Elgin",
                    "Island City",
                    "Cove",
                    "North Powder",
                    "Imbler",
                    "Summerville"
                ]
            },
            {
                "countyName": "Wallowa",
                "cities": [
                    "Enterprise †",
                    "Joseph",
                    "Wallowa",
                    "Lostine"
                ]
            },
            {
                "countyName": "Wasco",
                "cities": [
                    "The Dalles †",
                    "Dufur",
                    "Mosier",
                    "Maupin",
                    "Antelope",
                    "Shaniko"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Hillsboro †",
                    "Beaverton",
                    "Tigard",
                    "Forest Grove",
                    "Sherwood",
                    "Cornelius",
                    "King City",
                    "North Plains",
                    "Durham",
                    "Banks"
                ]
            },
            {
                "countyName": "Washington, Clackamas",
                "cities": [
                    "Tualatin"
                ]
            },
            {
                "countyName": "Washington, Yamhill",
                "cities": [
                    "Gaston"
                ]
            },
            {
                "countyName": "Wheeler",
                "cities": [
                    "Fossil †",
                    "Spray",
                    "Mitchell"
                ]
            },
            {
                "countyName": "Yamhill",
                "cities": [
                    "McMinnville †",
                    "Newberg",
                    "Sheridan",
                    "Lafayette",
                    "Dundee",
                    "Dayton",
                    "Carlton",
                    "Amity",
                    "Yamhill"
                ]
            },
            {
                "countyName": "Yamhill, Polk",
                "cities": [
                    "Willamina"
                ]
            }
        ]
    },
    {
        "stateId": "PA",
        "stateName": "Pennsylvania",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Gettysburg borough†",
                    "Conewago Township",
                    "Cumberland Township",
                    "Reading Township",
                    "Oxford Township",
                    "Straban Township",
                    "Franklin Township",
                    "Mount Pleasant Township",
                    "Littlestown borough",
                    "Carroll Valley borough",
                    "Mount Joy Township",
                    "Menallen Township",
                    "Union Township",
                    "McSherrystown borough",
                    "Germany Township",
                    "Latimore Township",
                    "Butler Township",
                    "Hamilton Township",
                    "Berwick Township",
                    "Hamiltonban Township",
                    "Huntington Township",
                    "Tyrone Township",
                    "Bonneauville borough",
                    "New Oxford borough",
                    "East Berlin borough",
                    "Liberty Township",
                    "Biglerville borough",
                    "Abbottstown Borough",
                    "Highland Township",
                    "Arendtsville borough",
                    "York Springs borough",
                    "Freedom Township",
                    "Bendersville borough",
                    "Fairfield borough"
                ]
            },
            {
                "countyName": "Allegheny",
                "cities": [
                    "Pittsburgh city†",
                    "Penn Hills Township",
                    "Mount Lebanon Township",
                    "Bethel Park borough",
                    "Ross Township",
                    "Shaler Township",
                    "McCandless Township",
                    "Monroeville borough",
                    "Plum borough",
                    "Moon Township",
                    "West Mifflin borough",
                    "Baldwin borough",
                    "McKeesport city",
                    "Upper St. Clair Township",
                    "Hampton Township",
                    "Scott Township",
                    "Wilkinsburg borough",
                    "South Fayette Township",
                    "North Fayette Township",
                    "Whitehall borough",
                    "Franklin Park borough",
                    "Robinson Township",
                    "South Park Township",
                    "Elizabeth Township",
                    "Pine Township",
                    "West Deer Township",
                    "Richland Township",
                    "Munhall borough",
                    "Jefferson Hills borough",
                    "Harrison Township",
                    "North Versailles Township",
                    "Brentwood borough",
                    "Swissvale borough",
                    "Dormont borough",
                    "O'Hara Township",
                    "Bellevue borough",
                    "Castle Shannon borough",
                    "Pleasant Hills borough",
                    "Carnegie borough",
                    "White Oak borough",
                    "Kennedy Township",
                    "Collier Township",
                    "Indiana Township",
                    "Marshall Township",
                    "Clairton city",
                    "West View borough",
                    "Forest Hills borough",
                    "Oakmont borough",
                    "Wilkins Township",
                    "Stowe Township",
                    "McKees Rocks borough",
                    "Crafton borough",
                    "Coraopolis borough",
                    "Ohio Township",
                    "Duquesne city",
                    "Fox Chapel borough",
                    "Turtle Creek borough",
                    "Findlay Township",
                    "Bridgeville borough",
                    "North Braddock borough",
                    "Avalon borough",
                    "Tarentum borough",
                    "Glassport borough",
                    "Green Tree borough",
                    "Sewickley borough",
                    "Port Vue borough",
                    "Millvale borough",
                    "Etna borough",
                    "Sharpsburg borough",
                    "Springdale borough",
                    "Mount Oliver borough",
                    "Forward Township",
                    "Reserve Township",
                    "Ingram borough",
                    "Pitcairn borough",
                    "Brackenridge borough",
                    "Homestead borough",
                    "Edgewood borough",
                    "Churchill borough",
                    "Harmar Township",
                    "Aspinwall borough",
                    "Crescent Township",
                    "Liberty borough",
                    "Verona borough",
                    "Emsworth borough",
                    "Fawn Township",
                    "Wilmerding borough",
                    "Braddock borough",
                    "East McKeesport borough",
                    "Rankin borough",
                    "Baldwin Township",
                    "West Homestead borough",
                    "Aleppo Township",
                    "Braddock Hills borough",
                    "East Pittsburgh borough",
                    "Dravosburg borough",
                    "Ben Avon borough",
                    "Cheswick borough",
                    "Edgeworth borough",
                    "Springdale Township",
                    "Leet Township",
                    "Versailles borough",
                    "East Deer Township",
                    "Elizabeth borough",
                    "Oakdale borough",
                    "Blawnox borough",
                    "Bell Acres borough",
                    "Whitaker borough",
                    "Heidelberg borough",
                    "Leetsdale borough",
                    "Bradford Woods borough",
                    "Frazer Township",
                    "Neville Township",
                    "Lincoln borough",
                    "Sewickley Heights borough",
                    "Chalfant borough",
                    "Kilbuck Township",
                    "Sewickley Hills borough",
                    "Pennsbury Village borough",
                    "Wall borough",
                    "Glen Osborne borough",
                    "West Elizabeth borough",
                    "Thornburg borough",
                    "Rosslyn Farms borough",
                    "Ben Avon Heights borough",
                    "South Versailles Township",
                    "Glenfield borough",
                    "Haysville borough"
                ]
            },
            {
                "countyName": "Armstrong",
                "cities": [
                    "Kiskiminetas Township",
                    "Manor Township",
                    "East Franklin Township",
                    "Kittanning borough†",
                    "North Buffalo Township",
                    "Ford City borough",
                    "Cowanshannock Township",
                    "Parks Township",
                    "South Buffalo Township",
                    "Gilpin Township",
                    "Plumcreek Township",
                    "Kittanning Township",
                    "Leechburg borough",
                    "Rayburn Township",
                    "West Franklin Township",
                    "Freeport borough",
                    "Apollo borough",
                    "Sugarcreek Township",
                    "Mahoning Township",
                    "North Apollo borough",
                    "Bethel Township",
                    "Wayne Township",
                    "South Bend Township",
                    "West Kittanning borough",
                    "Redbank Township",
                    "Boggs Township",
                    "Washington Township",
                    "Rural Valley borough",
                    "Parker city",
                    "Madison Township",
                    "Bradys Bend Township",
                    "Burrell Township",
                    "Valley Township",
                    "Worthington borough",
                    "Dayton borough",
                    "South Bethlehem borough",
                    "Pine Township",
                    "Manorville borough",
                    "Ford Cliff borough",
                    "Elderton borough",
                    "Perry Township",
                    "Cadogan Township",
                    "Applewold borough",
                    "Atwood borough",
                    "Hovey Township"
                ]
            },
            {
                "countyName": "Beaver",
                "cities": [
                    "Hopewell Township",
                    "Center Township",
                    "Aliquippa city",
                    "Economy borough",
                    "Beaver Falls city",
                    "Brighton Township",
                    "Chippewa Township",
                    "New Sewickley Township",
                    "Ambridge borough",
                    "New Brighton borough",
                    "Monaca borough",
                    "North Sewickley Township",
                    "Beaver borough†",
                    "Baden borough",
                    "Franklin Township",
                    "Hanover Township",
                    "Rochester borough",
                    "Ohioville borough",
                    "Daugherty Township",
                    "Harmony Township",
                    "Raccoon Township",
                    "Patterson Township",
                    "Rochester Township",
                    "South Beaver Township",
                    "Midland borough",
                    "Independence Township",
                    "Greene Township",
                    "Conway borough",
                    "Darlington Township",
                    "Big Beaver borough",
                    "Industry borough",
                    "Freedom borough",
                    "Pulaski Township",
                    "White Township",
                    "Vanport Township",
                    "West Mayfield borough",
                    "Marion Township",
                    "Koppel borough",
                    "Bridgewater borough",
                    "Patterson Heights borough",
                    "East Rochester borough",
                    "Potter Township",
                    "South Heights borough",
                    "New Galilee borough",
                    "Fallston borough",
                    "Darlington borough",
                    "Eastvale borough",
                    "Shippingport borough",
                    "Georgetown borough",
                    "Hookstown borough",
                    "Frankfort Springs borough",
                    "Homewood borough",
                    "Glasgow borough"
                ]
            },
            {
                "countyName": "Bedford",
                "cities": [
                    "Bedford Township",
                    "West Providence Township",
                    "East St. Clair Township",
                    "Bedford borough†",
                    "Napier Township",
                    "South Woodbury Township",
                    "Hopewell Township",
                    "East Providence Township",
                    "Londonderry Township",
                    "Everett borough",
                    "Snake Spring Township",
                    "West St. Clair Township",
                    "Broad Top Township",
                    "Kimmel Township",
                    "Cumberland Valley Township",
                    "Liberty Township",
                    "Monroe Township",
                    "Woodbury Township",
                    "King Township",
                    "Colerain Township",
                    "Bloomfield Township",
                    "Southampton Township",
                    "Harrison Township",
                    "Juniata Township",
                    "Hyndman borough",
                    "Saxton borough",
                    "Mann Township",
                    "Lincoln Township",
                    "Schellsburg borough",
                    "Manns Choice borough",
                    "Pavia Township",
                    "Woodbury Borough",
                    "Hopewell borough",
                    "Pleasantville borough",
                    "New Paris borough",
                    "Coaldale borough",
                    "Rainsburg borough",
                    "St. Clairsville borough"
                ]
            },
            {
                "countyName": "Berks",
                "cities": [
                    "Reading city†",
                    "Spring Township",
                    "Exeter Township",
                    "Muhlenberg Township",
                    "Cumru Township",
                    "Amity Township",
                    "Wyomissing borough",
                    "Maidencreek Township",
                    "Maxatawny Township",
                    "South Heidelberg Township",
                    "Robeson Township",
                    "Bern Township",
                    "Longswamp Township",
                    "Lower Heidelberg Township",
                    "Shillington borough",
                    "Birdsboro borough",
                    "Colebrookdale Township",
                    "Kutztown borough",
                    "Brecknock Township",
                    "Lower Alsace Township",
                    "Hamburg borough",
                    "West Reading borough",
                    "Ruscombmanor Township",
                    "Bethel Township",
                    "Fleetwood borough",
                    "Centre Township",
                    "Boyertown borough",
                    "Caernarvon Township",
                    "Sinking Spring borough",
                    "Laureldale borough",
                    "Washington Township",
                    "Rockland Township",
                    "Alsace Township",
                    "Greenwich Township",
                    "Oley Township",
                    "Tilden Township",
                    "Union Township",
                    "Richmond Township",
                    "Douglass Township",
                    "Tulpehocken Township",
                    "Earl Township",
                    "Mount Penn borough",
                    "Mohnton borough",
                    "Hereford Township",
                    "Kenhorst borough",
                    "Womelsdorf borough",
                    "Wernersville borough",
                    "Perry Township",
                    "Windsor Township",
                    "Robesonia borough",
                    "Topton borough",
                    "Jefferson Township",
                    "Penn Township",
                    "Leesport borough",
                    "Upper Tulpehocken Township",
                    "St. Lawrence borough",
                    "Marion Township",
                    "Upper Bern Township",
                    "Pike Township",
                    "Heidelberg Township",
                    "Albany Township",
                    "Ontelaunee Township",
                    "Shoemakersville borough",
                    "District Township",
                    "North Heidelberg Township",
                    "Bally borough",
                    "Bernville borough",
                    "Bechtelsville borough",
                    "Lyons borough",
                    "Centerport borough",
                    "Lenhartsville borough",
                    "New Morgan borough"
                ]
            },
            {
                "countyName": "Blair",
                "cities": [
                    "Altoona city",
                    "Logan Township",
                    "Frankstown Township",
                    "Allegheny Township",
                    "Antis Township",
                    "Hollidaysburg borough†",
                    "Tyrone borough",
                    "Blair Township",
                    "Greenfield Township",
                    "Freedom Township",
                    "Snyder Township",
                    "North Woodbury Township",
                    "Roaring Spring borough",
                    "Taylor Township",
                    "Martinsburg borough",
                    "Tyrone Township",
                    "Bellwood borough",
                    "Woodbury Township",
                    "Huston Township",
                    "Williamsburg borough",
                    "Duncansville borough",
                    "Juniata Township",
                    "Catharine Township",
                    "Newry borough"
                ]
            },
            {
                "countyName": "Bradford",
                "cities": [
                    "Sayre borough",
                    "Athens Township",
                    "Athens borough",
                    "Towanda borough†",
                    "Canton Township",
                    "Ridgebury Township",
                    "Canton borough",
                    "Wysox Township",
                    "Troy Township",
                    "Smithfield Township",
                    "Ulster Township",
                    "Sheshequin Township",
                    "Litchfield Township",
                    "Troy borough",
                    "Rome Township",
                    "Wyalusing Township",
                    "Monroe Township",
                    "Wilmot Township",
                    "Columbia Township",
                    "Orwell Township",
                    "Towanda Township",
                    "Tuscarora Township",
                    "North Towanda Township",
                    "South Creek Township",
                    "Springfield Township",
                    "Asylum Township",
                    "South Waverly borough",
                    "Terry Township",
                    "Warren Township",
                    "Granville Township",
                    "Windham Township",
                    "Albany Township",
                    "Wells Township",
                    "Burlington Township",
                    "Herrick Township",
                    "Franklin Township",
                    "Leroy Township",
                    "West Burlington Township",
                    "Pike Township",
                    "Standing Stone Township",
                    "Wyalusing borough",
                    "Monroe borough",
                    "Stevens Township",
                    "Rome borough",
                    "New Albany borough",
                    "Le Raysville borough",
                    "Overton Township",
                    "Sylvania borough",
                    "Armenia Township",
                    "Alba Borough",
                    "Burlington borough"
                ]
            },
            {
                "countyName": "Bucks",
                "cities": [
                    "Bensalem Township",
                    "Bristol Township",
                    "Middletown Township",
                    "Northampton Township",
                    "Falls Township",
                    "Warminster Township",
                    "Lower Makefield Township",
                    "Warrington Township",
                    "Buckingham Township",
                    "Newtown Township",
                    "Lower Southampton Township",
                    "Doylestown Township",
                    "Upper Southampton Township",
                    "Hilltown Township",
                    "Warwick Township",
                    "Richland Township",
                    "Plumstead Township",
                    "New Britain Township",
                    "Milford Township",
                    "Bristol borough",
                    "Quakertown borough",
                    "Morrisville borough",
                    "Solebury Township",
                    "Perkasie borough",
                    "Doylestown borough†",
                    "Upper Makefield Township",
                    "Bedminster Township",
                    "East Rockhill Township",
                    "West Rockhill Township",
                    "Springfield Township",
                    "Sellersville borough",
                    "Chalfont borough",
                    "Tinicum Township",
                    "Nockamixon Township",
                    "New Britain borough",
                    "Wrightstown Township",
                    "New Hope borough",
                    "Yardley borough",
                    "Penndel borough",
                    "Newtown borough",
                    "Haycock Township",
                    "Dublin borough",
                    "Tullytown borough",
                    "Langhorne borough",
                    "Langhorne Manor borough",
                    "Richlandtown borough",
                    "Bridgeton Township",
                    "Durham Township",
                    "Ivyland borough",
                    "Hulmeville borough",
                    "Trumbauersville borough",
                    "Silverdale borough",
                    "Riegelsville borough"
                ]
            },
            {
                "countyName": "Butler",
                "cities": [
                    "Cranberry Township",
                    "Butler Township",
                    "Butler City†",
                    "Adams Township",
                    "Center Township",
                    "Buffalo Township",
                    "Slippery Rock Township",
                    "Jefferson Township",
                    "Middlesex Township",
                    "Penn Township",
                    "Summit Township",
                    "Connoquenessing Township",
                    "Zelienople borough",
                    "Slippery Rock borough",
                    "Jackson Township",
                    "Winfield Township",
                    "Oakland Township",
                    "Seven Fields borough",
                    "Clinton Township",
                    "Clay Township",
                    "Franklin Township",
                    "Clearfield Township",
                    "Lancaster Township",
                    "Forward Township",
                    "Muddy Creek Township",
                    "Fairview Township",
                    "Donegal Township",
                    "Evans City borough",
                    "Mars borough",
                    "Saxonburg borough",
                    "Concord Township",
                    "Worth Township",
                    "Brady Township",
                    "Washington Township",
                    "Marion Township",
                    "Prospect borough",
                    "Cherry Township",
                    "Mercer Township",
                    "Chicora borough",
                    "Harrisville borough",
                    "Harmony borough",
                    "Venango Township",
                    "East Butler borough",
                    "Allegheny Township",
                    "Parker Township",
                    "Connoquenessing borough",
                    "Valencia borough",
                    "Bruin borough",
                    "Callery borough",
                    "West Liberty borough",
                    "Eau Claire borough",
                    "Portersville borough",
                    "Karns City borough",
                    "Petrolia borough",
                    "Fairview borough",
                    "West Sunbury borough",
                    "Cherry Valley borough"
                ]
            },
            {
                "countyName": "Cambria",
                "cities": [
                    "Johnstown city",
                    "Richland Township",
                    "Cambria Township",
                    "Adams Township",
                    "Upper Yoder Township",
                    "Westmont borough",
                    "Jackson Township",
                    "Cresson Township",
                    "Northern Cambria borough",
                    "Portage Township",
                    "Ebensburg borough†",
                    "Allegheny Township",
                    "Stonycreek Township",
                    "Nanty Glo borough",
                    "East Taylor Township",
                    "Lower Yoder Township",
                    "Portage borough",
                    "Summerhill Township",
                    "Geistown borough",
                    "Croyle Township",
                    "Southmont borough",
                    "Barr Township",
                    "Blacklick Township",
                    "Conemaugh Township",
                    "Susquehanna Township",
                    "Patton borough",
                    "Cresson borough",
                    "Gallitzin borough",
                    "East Carroll Township",
                    "Ferndale borough",
                    "Reade Township",
                    "Clearfield Township",
                    "Loretto borough",
                    "Gallitzin Township",
                    "West Carroll Township",
                    "Hastings borough",
                    "Dale borough",
                    "East Conemaugh borough",
                    "Elder Township",
                    "Lilly borough",
                    "South Fork borough",
                    "Washington Township",
                    "Carrolltown borough",
                    "White Township",
                    "West Taylor Township",
                    "Scalp Level borough",
                    "Lorain borough",
                    "Brownstown borough",
                    "Middle Taylor Township",
                    "Munster Township",
                    "Sankertown borough",
                    "Summerhill borough",
                    "Vintondale borough",
                    "Dean Township",
                    "Chest Township",
                    "Daisytown Borough",
                    "Franklin Borough",
                    "Ehrenfeld borough",
                    "Ashville borough",
                    "Wilmore borough",
                    "Chest Springs borough",
                    "Cassandra borough"
                ]
            },
            {
                "countyName": "Cambria, Blair",
                "cities": [
                    "Tunnelhill borough (comb.)"
                ]
            },
            {
                "countyName": "Cameron",
                "cities": [
                    "Shippen Township",
                    "Emporium borough†",
                    "Lumber Township",
                    "Grove Township",
                    "Portage Township",
                    "Gibson Township",
                    "Driftwood borough"
                ]
            },
            {
                "countyName": "Carbon",
                "cities": [
                    "Penn Forest Township",
                    "Lehighton borough",
                    "Palmerton borough",
                    "Jim Thorpe borough†",
                    "Towamensing Township",
                    "Mahoning Township",
                    "Franklin Township",
                    "Lansford borough",
                    "Nesquehoning borough",
                    "Lower Towamensing Township",
                    "Summit Hill borough",
                    "East Penn Township",
                    "Weatherly borough",
                    "Kidder Township",
                    "Banks Township",
                    "Packer Township",
                    "Bowmanstown borough",
                    "Beaver Meadows borough",
                    "Parryville borough",
                    "Lehigh Township",
                    "Weissport borough",
                    "East Side borough",
                    "Lausanne Township"
                ]
            },
            {
                "countyName": "Centre",
                "cities": [
                    "State College borough",
                    "Ferguson Township",
                    "Patton Township",
                    "College Township",
                    "Spring Township",
                    "Benner Township",
                    "Bellefonte borough†",
                    "Harris Township",
                    "Walker Township",
                    "Rush Township",
                    "Potter Township",
                    "Boggs Township",
                    "Philipsburg borough",
                    "Halfmoon Township",
                    "Gregg Township",
                    "Liberty Township",
                    "Miles Township",
                    "Snow Shoe Township",
                    "Haines Township",
                    "Union Township",
                    "Huston Township",
                    "Centre Hall borough",
                    "Marion Township",
                    "Penn Township",
                    "Milesburg borough",
                    "Howard Township",
                    "Millheim borough",
                    "Taylor Township",
                    "Worth Township",
                    "Snow Shoe borough",
                    "Howard borough",
                    "Curtin Township",
                    "Port Matilda borough",
                    "Burnside Township",
                    "Unionville borough"
                ]
            },
            {
                "countyName": "Chester",
                "cities": [
                    "Tredyffrin Township",
                    "West Goshen Township",
                    "West Chester borough†",
                    "West Whiteland Township",
                    "Uwchlan Township",
                    "East Goshen Township",
                    "Phoenixville borough",
                    "Caln Township",
                    "Coatesville City",
                    "West Bradford Township",
                    "New Garden Township",
                    "Upper Uwchlan Township",
                    "Westtown Township",
                    "East Whiteland Township",
                    "Willistown Township",
                    "Easttown Township",
                    "East Bradford Township",
                    "West Caln Township",
                    "East Nottingham Township",
                    "Schuylkill Township",
                    "London Grove Township",
                    "North Coventry Township",
                    "Downingtown borough",
                    "Honey Brook Township",
                    "Kennett Township",
                    "East Fallowfield Township",
                    "West Brandywine Township",
                    "East Brandywine Township",
                    "East Pikeland Township",
                    "Valley Township",
                    "East Marlborough Township",
                    "East Vincent Township",
                    "East Coventry Township",
                    "Kennett Square borough",
                    "New London Township",
                    "Charlestown Township",
                    "Penn Township",
                    "Lower Oxford Township",
                    "Oxford borough",
                    "East Caln Township",
                    "West Vincent Township",
                    "Pocopson Township",
                    "Franklin Township",
                    "Birmingham Township",
                    "West Pikeland Township",
                    "Sadsbury Township",
                    "Parkesburg borough",
                    "Pennsbury Township",
                    "Wallace Township",
                    "Spring City borough",
                    "London Britain Township",
                    "Thornbury Township",
                    "Malvern borough",
                    "West Grove borough",
                    "West Nottingham Township",
                    "South Coventry Township",
                    "West Fallowfield Township",
                    "Warwick Township",
                    "Upper Oxford Township",
                    "West Sadsbury Township",
                    "Londonderry Township",
                    "West Nantmeal Township",
                    "East Nantmeal Township",
                    "Honey Brook borough",
                    "Elk Township",
                    "Atglen borough",
                    "Avondale borough",
                    "South Coatesville borough",
                    "Newlin Township",
                    "Highland Township",
                    "Elverson borough",
                    "West Marlborough Township",
                    "Modena borough"
                ]
            },
            {
                "countyName": "Clarion",
                "cities": [
                    "Clarion borough†",
                    "Clarion Township",
                    "Farmington Township",
                    "Washington Township",
                    "Limestone Township",
                    "Beaver Township",
                    "Paint Township",
                    "Monroe Township",
                    "Elk Township",
                    "Redbank Township",
                    "Porter Township",
                    "Madison Township",
                    "Knox borough",
                    "Ashland Township",
                    "Knox Township",
                    "Toby Township",
                    "New Bethlehem borough",
                    "Perry Township",
                    "Rimersburg borough",
                    "East Brady borough",
                    "Salem Township",
                    "Sligo Borough",
                    "Strattanville borough",
                    "Licking Township",
                    "Highland Township",
                    "Richland Township",
                    "Hawthorn borough",
                    "Shippenville borough",
                    "Piney Township",
                    "St. Petersburg borough",
                    "Millcreek Township",
                    "Callensburg borough",
                    "Foxburg borough",
                    "Brady Township"
                ]
            },
            {
                "countyName": "Clearfield",
                "cities": [
                    "Sandy Township",
                    "DuBois city",
                    "Lawrence Township",
                    "Clearfield borough†",
                    "Decatur Township",
                    "Woodward Township",
                    "Bradford Township",
                    "Morris Township",
                    "Cooper Township",
                    "Curwensville borough",
                    "Pike Township",
                    "Brady Township",
                    "Beccaria Township",
                    "Boggs Township",
                    "Huston Township",
                    "Graham Township",
                    "Bigler Township",
                    "Penn Township",
                    "Gulich Township",
                    "Osceola Mills borough",
                    "Burnside Township",
                    "Karthaus Township",
                    "Union Township",
                    "Chester Hill borough",
                    "Houtzdale borough",
                    "Bell Township",
                    "Knox Township",
                    "Irvona borough",
                    "Girard Township",
                    "Covington Township",
                    "Coalport borough",
                    "Chest Township",
                    "Jordan Township",
                    "Ramey borough",
                    "Ferguson Township",
                    "Goshen Township",
                    "Bloom Township",
                    "Brisbin borough",
                    "Westover borough",
                    "Greenwood Township",
                    "Mahaffey borough",
                    "Grampian borough",
                    "Wallaceton borough",
                    "Troutville borough",
                    "Burnside Borough",
                    "Glen Hope borough",
                    "Newburg borough",
                    "Pine Township",
                    "New Washington borough"
                ]
            },
            {
                "countyName": "Clinton",
                "cities": [
                    "Lock Haven city†",
                    "Pine Creek Township",
                    "Lamar Township",
                    "Woodward Township",
                    "Bald Eagle Township",
                    "Greene Township",
                    "Wayne Township",
                    "Mill Hall borough",
                    "Avis borough",
                    "Porter Township",
                    "Flemington borough",
                    "Renovo borough",
                    "Castanea Township",
                    "Beech Creek Township",
                    "Dunnstable Township",
                    "Crawford Township",
                    "Chapman Township",
                    "Logan Township",
                    "Beech Creek borough",
                    "Loganton borough",
                    "South Renovo borough",
                    "Gallagher Township",
                    "Noyes Township",
                    "Colebrook Township",
                    "Allison Township",
                    "Leidy Township",
                    "Grugan Township",
                    "West Keating Township",
                    "East Keating Township"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Bloomsburg town†",
                    "Berwick borough",
                    "Scott Township",
                    "Briar Creek Township",
                    "Mifflin Township",
                    "Hemlock Township",
                    "North Centre Township",
                    "Greenwood Township",
                    "South Centre Township",
                    "Mount Pleasant Township",
                    "Madison Township",
                    "Catawissa borough",
                    "Fishing Creek Township",
                    "Locust Township",
                    "Montour Township",
                    "Main Township",
                    "Orange Township",
                    "Benton Township",
                    "Cleveland Township",
                    "Pine Township",
                    "Millville borough",
                    "Catawissa Township",
                    "Beaver Township",
                    "Sugarloaf Township",
                    "Benton borough",
                    "Conyngham Township",
                    "Briar Creek borough",
                    "Jackson Township",
                    "Franklin Township",
                    "Roaring Creek Township",
                    "Orangeville borough",
                    "Stillwater borough",
                    "Centralia borough"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Meadville City†",
                    "Vernon Township",
                    "Titusville city",
                    "West Mead Township",
                    "Hayfield Township",
                    "Sadsbury Township",
                    "Woodcock Township",
                    "Cambridge Springs borough",
                    "South Shenango Township",
                    "Summit Township",
                    "Bloomfield Township",
                    "Oil Creek Township",
                    "Rome Township",
                    "Sparta Township",
                    "Randolph Township",
                    "East Fallowfield Township",
                    "Cussewago Township",
                    "Cambridge Township",
                    "Wayne Township",
                    "Spring Township",
                    "Rockdale Township",
                    "East Mead Township",
                    "Richmond Township",
                    "Conneaut Township",
                    "Greenwood Township",
                    "North Shenango Township",
                    "Summerhill Township",
                    "Troy Township",
                    "Cochranton borough",
                    "Linesville borough",
                    "Fairfield Township",
                    "Union Township",
                    "Venango Township",
                    "Saegertown borough",
                    "East Fairfield Township",
                    "Beaver Township",
                    "Steuben Township",
                    "Conneautville borough",
                    "Athens Township",
                    "Conneaut Lake borough",
                    "West Fallowfield Township",
                    "Hydetown borough",
                    "West Shenango Township",
                    "Springboro borough",
                    "Pine Township",
                    "Blooming Valley borough",
                    "Townville borough",
                    "Spartansburg borough",
                    "Venango borough",
                    "Centerville borough",
                    "Woodcock borough"
                ]
            },
            {
                "countyName": "Cumberland",
                "cities": [
                    "Hampden Township",
                    "East Pennsboro Township",
                    "Carlisle borough†",
                    "Upper Allen Township",
                    "Lower Allen Township",
                    "South Middleton Township",
                    "Silver Spring Township",
                    "North Middleton Township",
                    "Mechanicsburg borough",
                    "Camp Hill borough",
                    "New Cumberland borough",
                    "Middlesex Township",
                    "Southampton Township",
                    "Monroe Township",
                    "West Pennsboro Township",
                    "Shippensburg Township",
                    "Dickinson Township",
                    "Lemoyne borough",
                    "Wormleysburg borough",
                    "Penn Township",
                    "North Newton Township",
                    "Hopewell Township",
                    "Mount Holly Springs borough",
                    "Upper Frankford Township",
                    "Lower Mifflin Township",
                    "Lower Frankford Township",
                    "Shiremanstown borough",
                    "South Newton Township",
                    "Newville borough",
                    "Upper Mifflin Township",
                    "Newburg borough",
                    "Cooke Township"
                ]
            },
            {
                "countyName": "Cumberland, Franklin",
                "cities": [
                    "Shippensburg borough (comb.)"
                ]
            },
            {
                "countyName": "Dauphin",
                "cities": [
                    "Harrisburg city†",
                    "Lower Paxton Township",
                    "Derry Township",
                    "Susquehanna Township",
                    "Swatara Township",
                    "West Hanover Township",
                    "Middletown borough",
                    "Lower Swatara Township",
                    "South Hanover Township",
                    "Steelton borough",
                    "East Hanover Township",
                    "Londonderry Township",
                    "Middle Paxton Township",
                    "Hummelstown borough",
                    "Upper Paxton Township",
                    "Halifax Township",
                    "Conewago Township",
                    "Penbrook borough",
                    "Millersburg borough",
                    "Highspire borough",
                    "Washington Township",
                    "Jackson Township",
                    "Lykens borough",
                    "Lykens Township",
                    "Paxtang borough",
                    "Elizabethville borough",
                    "Williamstown borough",
                    "Wayne Township",
                    "Wiconisco Township",
                    "Williams Township",
                    "Royalton borough",
                    "Halifax borough",
                    "Dauphin borough",
                    "Mifflin Township",
                    "Gratz borough",
                    "Berrysburg borough",
                    "Jefferson Township",
                    "Pillow borough",
                    "Reed Township",
                    "Rush Township"
                ]
            },
            {
                "countyName": "Delaware",
                "cities": [
                    "Upper Darby Township",
                    "Haverford Township",
                    "Chester city",
                    "Radnor Township",
                    "Ridley Township",
                    "Springfield Township",
                    "Marple Township",
                    "Concord Township",
                    "Upper Chichester Township",
                    "Aston Township",
                    "Middletown Township",
                    "Nether Providence Township",
                    "Newtown Township",
                    "Yeadon borough",
                    "Darby borough",
                    "Lansdowne borough",
                    "Upper Providence Township",
                    "Darby Township",
                    "Bethel Township",
                    "Collingdale borough",
                    "Thornbury Township",
                    "Brookhaven borough",
                    "Glenolden borough",
                    "Ridley Park borough",
                    "Clifton Heights borough",
                    "Folcroft borough",
                    "Prospect Park borough",
                    "Swarthmore borough",
                    "Norwood borough",
                    "Sharon Hill borough",
                    "Media borough†",
                    "Aldan borough",
                    "Tinicum Township",
                    "Chester Township",
                    "Edgmont Township",
                    "Chadds Ford Township",
                    "Lower Chichester Township",
                    "Upland borough",
                    "Morton borough",
                    "East Lansdowne borough",
                    "Colwyn borough",
                    "Chester Heights borough",
                    "Eddystone borough",
                    "Marcus Hook borough",
                    "Parkside borough",
                    "Trainer borough",
                    "Millbourne borough",
                    "Rose Valley borough",
                    "Rutledge borough"
                ]
            },
            {
                "countyName": "Elk",
                "cities": [
                    "St. Marys city",
                    "Ridgway borough†",
                    "Fox Township",
                    "Ridgway Township",
                    "Johnsonburg borough",
                    "Jay Township",
                    "Jones Township",
                    "Horton Township",
                    "Highland Township",
                    "Spring Creek Township",
                    "Benezette Township",
                    "Millstone Township"
                ]
            },
            {
                "countyName": "Erie",
                "cities": [
                    "Erie city†",
                    "Millcreek Township",
                    "Harborcreek Township",
                    "Fairview Township",
                    "Summit Township",
                    "Corry city",
                    "Edinboro borough",
                    "North East Township",
                    "Girard Township",
                    "Greene Township",
                    "Washington Township",
                    "McKean Township",
                    "Conneaut Township",
                    "North East borough",
                    "Waterford Township",
                    "Lawrence Park Township",
                    "Springfield Township",
                    "Wesleyville borough",
                    "Union City borough",
                    "Girard borough",
                    "Lake City borough",
                    "Venango Township",
                    "Greenfield Township",
                    "Elk Creek Township",
                    "LeBoeuf Township",
                    "Wayne Township",
                    "Union Township",
                    "Franklin Township",
                    "Waterford borough",
                    "Albion borough",
                    "Concord Township",
                    "Amity Township",
                    "Cranesville borough",
                    "Platea borough",
                    "Mill Village borough",
                    "Wattsburg borough",
                    "McKean borough",
                    "Elgin borough"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "North Union Township",
                    "South Union Township",
                    "Uniontown city†",
                    "Connellsville city",
                    "Dunbar Township",
                    "Bullskin Township",
                    "Georges Township",
                    "Luzerne Township",
                    "Redstone Township",
                    "German Township",
                    "Menallen Township",
                    "Washington Township",
                    "Wharton Township",
                    "Saltlick Township",
                    "Masontown borough",
                    "Springfield Township",
                    "Springhill Township",
                    "Perry Township",
                    "Franklin Township",
                    "Connellsville Township",
                    "Brownsville borough",
                    "Henry Clay Township",
                    "Upper Tyrone Township",
                    "Jefferson Township",
                    "Fairchance borough",
                    "South Connellsville borough",
                    "Nicholson Township",
                    "Perryopolis borough",
                    "Point Marion borough",
                    "Lower Tyrone Township",
                    "Belle Vernon borough",
                    "Dunbar borough",
                    "Smithfield borough",
                    "Everson borough",
                    "Stewart Township",
                    "Brownsville Township",
                    "Fayette City borough",
                    "Newell borough",
                    "Vanderbilt borough",
                    "Dawson borough",
                    "Markleysburg borough",
                    "Ohiopyle borough"
                ]
            },
            {
                "countyName": "Fayette, Somerset",
                "cities": [
                    "Seven Springs borough (comb.)"
                ]
            },
            {
                "countyName": "Forest",
                "cities": [
                    "Jenks Township",
                    "Tionesta Township",
                    "Harmony Township",
                    "Hickory Township",
                    "Green Township",
                    "Tionesta borough†",
                    "Howe Township",
                    "Barnett Township",
                    "Kingsley Township"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Chambersburg borough†",
                    "Greene Township",
                    "Antrim Township",
                    "Guilford Township",
                    "Washington Township",
                    "Hamilton Township",
                    "Waynesboro borough",
                    "Southampton Township",
                    "Montgomery Township",
                    "St. Thomas Township",
                    "Quincy Township",
                    "Peters Township",
                    "Greencastle borough",
                    "Fannett Township",
                    "Letterkenny Township",
                    "Lurgan Township",
                    "Metal Township",
                    "Mont Alto borough",
                    "Mercersburg borough",
                    "Warren Township",
                    "Orrstown borough"
                ]
            },
            {
                "countyName": "Fulton",
                "cities": [
                    "Ayr Township",
                    "Licking Creek Township",
                    "Todd Township",
                    "Bethel Township",
                    "Belfast Township",
                    "Dublin Township",
                    "McConnellsburg borough†",
                    "Taylor Township",
                    "Thompson Township",
                    "Brush Creek Township",
                    "Union Township",
                    "Wells Township",
                    "Valley-Hi borough"
                ]
            },
            {
                "countyName": "Greene",
                "cities": [
                    "Franklin Township",
                    "Cumberland Township",
                    "Waynesburg borough†",
                    "Morgan Township",
                    "Dunkard Township",
                    "Jefferson Township",
                    "Monongahela Township",
                    "Perry Township",
                    "Center Township",
                    "Wayne Township",
                    "Washington Township",
                    "Richhill Township",
                    "Morris Township",
                    "Whiteley Township",
                    "Aleppo Township",
                    "Jackson Township",
                    "Carmichaels borough",
                    "Rices Landing borough",
                    "Greene Township",
                    "Springhill Township",
                    "Freeport Township",
                    "Jefferson borough",
                    "Greensboro borough",
                    "Gilmore Township",
                    "Clarksville borough",
                    "Gray Township"
                ]
            },
            {
                "countyName": "Huntingdon",
                "cities": [
                    "Huntingdon borough†",
                    "Smithfield Township",
                    "Shirley Township",
                    "Mount Union borough",
                    "Porter Township",
                    "Walker Township",
                    "Warriors Mark Township",
                    "Cromwell Township",
                    "Dublin Township",
                    "Brady Township",
                    "Cass Township",
                    "Penn Township",
                    "Oneida Township",
                    "Union Township",
                    "Todd Township",
                    "Henderson Township",
                    "Clay Township",
                    "Jackson Township",
                    "Wood Township",
                    "Logan Township",
                    "Tell Township",
                    "Springfield Township",
                    "Hopewell Township",
                    "West Township",
                    "Juniata Township",
                    "Petersburg borough",
                    "Barree Township",
                    "Franklin Township",
                    "Miller Township",
                    "Broad Top City borough",
                    "Three Springs borough",
                    "Mapleton borough",
                    "Orbisonia borough",
                    "Morris Township",
                    "Carbon Township",
                    "Rockhill borough",
                    "Saltillo borough",
                    "Alexandria borough",
                    "Lincoln Township",
                    "Mill Creek borough",
                    "Spruce Creek Township",
                    "Marklesburg borough",
                    "Dudley borough",
                    "Shirleysburg borough",
                    "Cassville Borough",
                    "Coalmont borough",
                    "Shade Gap borough",
                    "Birmingham borough"
                ]
            },
            {
                "countyName": "Indiana",
                "cities": [
                    "White Township",
                    "Indiana borough†",
                    "Center Township",
                    "Burrell Township",
                    "Green Township",
                    "Blairsville borough",
                    "Armstrong Township",
                    "Rayne Township",
                    "Cherryhill Township",
                    "East Wheatfield Township",
                    "West Wheatfield Township",
                    "Conemaugh Township",
                    "Pine Township",
                    "Brush Valley Township",
                    "South Mahoning Township",
                    "Washington Township",
                    "Young Township",
                    "Homer City borough",
                    "Montgomery Township",
                    "Canoe Township",
                    "North Mahoning Township",
                    "Clymer borough",
                    "West Mahoning Township",
                    "Buffington Township",
                    "Black Lick Township",
                    "East Mahoning Township",
                    "Banks Township",
                    "Saltsburg borough",
                    "Grant Township",
                    "Ernest borough",
                    "Marion Center borough",
                    "Cherry Tree borough",
                    "Creekside borough",
                    "Plumville Borough",
                    "Glen Campbell borough",
                    "Shelocta borough",
                    "Armagh borough",
                    "Smicksburg borough"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Punxsutawney borough",
                    "Brookville borough†",
                    "Reynoldsville borough",
                    "Winslow Township",
                    "Snyder Township",
                    "Brockway borough",
                    "Bell Township",
                    "Washington Township",
                    "Henderson Township",
                    "Young Township",
                    "Warsaw Township",
                    "Pine Creek Township",
                    "Rose Township",
                    "Perry Township",
                    "Eldred Township",
                    "Sykesville borough",
                    "Oliver Township",
                    "McCalmont Township",
                    "Knox Township",
                    "Union Township",
                    "Ringgold Township",
                    "Gaskill Township",
                    "Big Run borough",
                    "Summerville borough",
                    "Beaver Township",
                    "Clover Township",
                    "Corsica borough",
                    "Porter Township",
                    "Polk Township",
                    "Barnett Township",
                    "Timblin borough",
                    "Heath Township",
                    "Worthville borough"
                ]
            },
            {
                "countyName": "Jefferson, Clearfield",
                "cities": [
                    "Falls Creek borough (comb.)"
                ]
            },
            {
                "countyName": "Juniata",
                "cities": [
                    "Fayette Township",
                    "Fermanagh Township",
                    "Walker Township",
                    "Monroe Township",
                    "Milford Township",
                    "Delaware Township",
                    "Susquehanna Township",
                    "Tuscarora Township",
                    "Turbett Township",
                    "Mifflintown borough†",
                    "Port Royal borough",
                    "Spruce Hill Township",
                    "Beale Township",
                    "Lack Township",
                    "Thompsontown borough",
                    "Mifflin borough",
                    "Greenwood Township"
                ]
            },
            {
                "countyName": "Lackawanna",
                "cities": [
                    "Scranton city†",
                    "Dunmore borough",
                    "South Abington Township",
                    "Carbondale city",
                    "Old Forge borough",
                    "Archbald borough",
                    "Blakely borough",
                    "Taylor borough",
                    "Dickson City borough",
                    "Moosic borough",
                    "Olyphant borough",
                    "Clarks Summit borough",
                    "Scott Township",
                    "Jessup borough",
                    "Throop borough",
                    "Jefferson Township",
                    "Newton Township",
                    "Spring Brook Township",
                    "Madison Township",
                    "Covington Township",
                    "Fell Township",
                    "Jermyn borough",
                    "Greenfield Township",
                    "Moscow borough",
                    "Roaring Brook Township",
                    "Benton Township",
                    "Mayfield borough",
                    "Waverly Township",
                    "Clifton Township",
                    "Clarks Green borough",
                    "Ransom Township",
                    "Glenburn Township",
                    "Dalton borough",
                    "Carbondale Township",
                    "Thornhurst Township",
                    "Elmhurst Township",
                    "Vandling borough",
                    "North Abington Township",
                    "La Plume Township",
                    "West Abington Township"
                ]
            },
            {
                "countyName": "Lancaster",
                "cities": [
                    "Lancaster city†",
                    "Manheim Township",
                    "East Hempfield Township",
                    "Manor Township",
                    "Warwick Township",
                    "Lancaster Township",
                    "East Lampeter Township",
                    "West Hempfield Township",
                    "West Lampeter Township",
                    "Ephrata Borough",
                    "Elizabethtown borough",
                    "Salisbury Township",
                    "Rapho Township",
                    "Columbia borough",
                    "East Cocalico Township",
                    "Mount Joy Township",
                    "Ephrata Township",
                    "Lititz borough",
                    "Penn Township",
                    "Upper Leacock Township",
                    "West Donegal Township",
                    "Millersville borough",
                    "East Donegal Township",
                    "West Earl Township",
                    "Mount Joy borough",
                    "Brecknock Township",
                    "West Cocalico Township",
                    "Earl Township",
                    "Providence Township",
                    "East Earl Township",
                    "Clay Township",
                    "New Holland borough",
                    "Leacock Township",
                    "Paradise Township",
                    "Martic Township",
                    "Manheim borough",
                    "Caernarvon Township",
                    "Pequea Township",
                    "East Petersburg borough",
                    "Strasburg Township",
                    "Little Britain Township",
                    "Elizabeth Township",
                    "Akron borough",
                    "Denver borough",
                    "East Drumore Township",
                    "Conestoga Township",
                    "Colerain Township",
                    "Sadsbury Township",
                    "Conoy Township",
                    "Bart Township",
                    "Fulton Township",
                    "Strasburg borough",
                    "Mountville borough",
                    "Quarryville borough",
                    "Marietta borough",
                    "Drumore Township",
                    "Eden Township",
                    "Terre Hill borough",
                    "Christiana borough"
                ]
            },
            {
                "countyName": "Lancaster, Berks",
                "cities": [
                    "Adamstown borough"
                ]
            },
            {
                "countyName": "Lawrence",
                "cities": [
                    "New Castle city†",
                    "Neshannock Township",
                    "Shenango Township",
                    "Union Township",
                    "North Beaver Township",
                    "Pulaski Township",
                    "Slippery Rock Township",
                    "Mahoning Township",
                    "Wilmington Township",
                    "Wayne Township",
                    "New Wilmington borough",
                    "Hickory Township",
                    "Scott Township",
                    "Perry Township",
                    "New Beaver borough",
                    "Little Beaver Township",
                    "Ellport borough",
                    "Bessemer borough",
                    "Taylor Township",
                    "Plain Grove Township",
                    "Washington Township",
                    "Wampum borough",
                    "South New Castle borough",
                    "Enon Valley borough",
                    "Volant borough",
                    "S.N.P.J. borough"
                ]
            },
            {
                "countyName": "Lawrence, Beaver",
                "cities": [
                    "Ellwood City borough (comb.)"
                ]
            },
            {
                "countyName": "Lebanon",
                "cities": [
                    "Lebanon city†",
                    "North Lebanon Township",
                    "South Lebanon Township",
                    "Jackson Township",
                    "North Londonderry Township",
                    "North Cornwall Township",
                    "Palmyra borough",
                    "South Londonderry Township",
                    "Bethel Township",
                    "Annville Township",
                    "Swatara Township",
                    "Cornwall borough",
                    "Heidelberg Township",
                    "Millcreek Township",
                    "Union Township",
                    "Myerstown borough",
                    "South Annville Township",
                    "East Hanover Township",
                    "North Annville Township",
                    "Cleona borough",
                    "West Cornwall Township",
                    "Jonestown borough",
                    "Richland borough",
                    "West Lebanon Township",
                    "Mount Gretna borough",
                    "Cold Spring Township"
                ]
            },
            {
                "countyName": "Lehigh",
                "cities": [
                    "Allentown city†",
                    "Lower Macungie Township",
                    "Whitehall Township",
                    "Upper Macungie Township",
                    "South Whitehall Township",
                    "North Whitehall Township",
                    "Upper Saucon Township",
                    "Salisbury Township",
                    "Emmaus borough",
                    "Upper Milford Township",
                    "Washington Township",
                    "Catasauqua borough",
                    "Weisenberg Township",
                    "Fountain Hill borough",
                    "Lynn Township",
                    "Slatington borough",
                    "Lower Milford Township",
                    "Heidelberg Township",
                    "Coplay borough",
                    "Macungie borough",
                    "Coopersburg borough",
                    "Alburtis borough",
                    "Lowhill Township",
                    "Hanover Township"
                ]
            },
            {
                "countyName": "Luzerne",
                "cities": [
                    "Wilkes-Barre city†",
                    "Hazleton city",
                    "Kingston borough",
                    "Hanover Township",
                    "Nanticoke city",
                    "Plains Township",
                    "Hazle Township",
                    "Butler Township",
                    "Dallas Township",
                    "Pittston city",
                    "Kingston Township",
                    "Plymouth borough",
                    "Wright Township",
                    "Exeter borough",
                    "Newport Township",
                    "Swoyersville Borough",
                    "Duryea borough",
                    "West Pittston borough",
                    "Edwardsville borough",
                    "Jackson Township",
                    "West Hazleton borough",
                    "Fairview Township",
                    "Jenkins Township",
                    "Larksville borough",
                    "Salem Township",
                    "Sugarloaf Township",
                    "Forty Fort borough",
                    "Freeland borough",
                    "Lehman Township",
                    "Rice Township",
                    "Foster Township",
                    "Pittston Township",
                    "Wyoming borough",
                    "Wilkes-Barre Township",
                    "Ross Township",
                    "Luzerne borough",
                    "Dallas borough",
                    "Harveys Lake borough",
                    "Bear Creek Township",
                    "Ashley borough",
                    "West Wyoming borough",
                    "Dupont borough",
                    "Avoca borough",
                    "Hunlock Township",
                    "Exeter Township",
                    "Huntington Township",
                    "Dorrance Township",
                    "Lake Township",
                    "Black Creek Township",
                    "Union Township",
                    "Conyngham borough",
                    "Plymouth Township",
                    "Franklin Township",
                    "Nescopeck borough",
                    "Laflin borough",
                    "Conyngham Township",
                    "Hughestown borough",
                    "Fairmount Township",
                    "Hollenback Township",
                    "Nescopeck Township",
                    "Dennison Township",
                    "White Haven borough",
                    "Slocum Township",
                    "Sugar Notch borough",
                    "Pringle borough",
                    "Shickshinny borough",
                    "Courtdale borough",
                    "Nuangola borough",
                    "Yatesville Borough",
                    "Warrior Run borough",
                    "Laurel Run borough",
                    "Buck Township",
                    "Penn Lake Park borough",
                    "Bear Creek Village borough",
                    "New Columbus borough",
                    "Jeddo borough"
                ]
            },
            {
                "countyName": "Lycoming",
                "cities": [
                    "Williamsport city†",
                    "Loyalsock Township",
                    "South Williamsport borough",
                    "Old Lycoming Township",
                    "Montoursville borough",
                    "Jersey Shore borough",
                    "Clinton Township",
                    "Muncy Creek Township",
                    "Wolf Township",
                    "Fairfield Township",
                    "Hepburn Township",
                    "Muncy borough",
                    "Woodward Township",
                    "Eldred Township",
                    "Hughesville borough",
                    "Limestone Township",
                    "Upper Fairfield Township",
                    "Washington Township",
                    "Porter Township",
                    "Montgomery borough",
                    "Lycoming Township",
                    "Duboistown borough",
                    "Piatt Township",
                    "Muncy Township",
                    "Mifflin Township",
                    "Susquehanna Township",
                    "Penn Township",
                    "Lewis Township",
                    "Moreland Township",
                    "Cogan House Township",
                    "Franklin Township",
                    "Anthony Township",
                    "Jordan Township",
                    "Gamble Township",
                    "Nippenose Township",
                    "Armstrong Township",
                    "Plunketts Creek Township",
                    "Picture Rocks borough",
                    "Mill Creek Township",
                    "Bastress Township",
                    "Watson Township",
                    "Brady Township",
                    "McIntyre Township",
                    "Cascade Township",
                    "Shrewsbury Township",
                    "Jackson Township",
                    "Pine Township",
                    "Cummings Township",
                    "Salladasburg borough",
                    "McNett Township",
                    "McHenry Township",
                    "Brown Township"
                ]
            },
            {
                "countyName": "McKean",
                "cities": [
                    "Bradford city",
                    "Bradford Township",
                    "Foster Township",
                    "Kane borough",
                    "Keating Township",
                    "Lafayette Township",
                    "Port Allegany borough",
                    "Wetmore Township",
                    "Smethport borough†",
                    "Liberty Township",
                    "Eldred Township",
                    "Otto Township",
                    "Mount Jewett borough",
                    "Ceres Township",
                    "Eldred borough",
                    "Hamlin Township",
                    "Annin Township",
                    "Lewis Run borough",
                    "Norwich Township",
                    "Hamilton Township",
                    "Corydon Township",
                    "Sergeant Township"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Hermitage city",
                    "Sharon city",
                    "Grove City borough",
                    "Greenville borough",
                    "Pine Township",
                    "Farrell city",
                    "Sharpsville borough",
                    "Shenango Township",
                    "Hempfield Township",
                    "West Salem Township",
                    "Pymatuning Township",
                    "Findley Township",
                    "South Pymatuning Township",
                    "Lackawannock Township",
                    "Delaware Township",
                    "Coolspring Township",
                    "Springfield Township",
                    "Mercer borough†",
                    "Jefferson Township",
                    "East Lackawannock Township",
                    "Wilmington Township",
                    "Perry Township",
                    "Liberty Township",
                    "Jackson Township",
                    "Sandy Lake Township",
                    "Fairview Township",
                    "Greene Township",
                    "Stoneboro borough",
                    "Sugar Grove Township",
                    "Worth Township",
                    "West Middlesex borough",
                    "Wolf Creek Township",
                    "Sandy Creek Township",
                    "Lake Township",
                    "French Creek Township",
                    "Salem Township",
                    "Mill Creek Township",
                    "Sandy Lake borough",
                    "Clark borough",
                    "Wheatland borough",
                    "Jamestown borough",
                    "Otter Creek Township",
                    "New Vernon Township",
                    "Deer Creek Township",
                    "Fredonia borough",
                    "Jackson Center borough",
                    "New Lebanon borough",
                    "Sheakleyville borough"
                ]
            },
            {
                "countyName": "Mifflin",
                "cities": [
                    "Lewistown borough†",
                    "Derry Township",
                    "Granville Township",
                    "Brown Township",
                    "Armagh Township",
                    "Union Township",
                    "Decatur Township",
                    "Wayne Township",
                    "Oliver Township",
                    "Burnham borough",
                    "Menno Township",
                    "Bratton Township",
                    "Juniata Terrace borough",
                    "McVeytown borough",
                    "Kistler Borough",
                    "Newton Hamilton borough"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Coolbaugh Township",
                    "Stroud Township",
                    "Chestnuthill Township",
                    "Middle Smithfield Township",
                    "Pocono Township",
                    "East Stroudsburg borough",
                    "Hamilton Township",
                    "Tobyhanna Township",
                    "Polk Township",
                    "Smithfield Township",
                    "Jackson Township",
                    "Tunkhannock Township",
                    "Ross Township",
                    "Stroudsburg borough†",
                    "Barrett Township",
                    "Price Township",
                    "Paradise Township",
                    "Mount Pocono borough",
                    "Eldred Township",
                    "Delaware Water Gap borough"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Lower Merion Township",
                    "Abington Township",
                    "Cheltenham Township",
                    "Norristown borough†",
                    "Upper Merion Township",
                    "Horsham Township",
                    "Upper Dublin Township",
                    "Lower Providence Township",
                    "Montgomery Township",
                    "Upper Moreland Township",
                    "Pottstown borough",
                    "Upper Providence Township",
                    "Springfield Township",
                    "Whitpain Township",
                    "Limerick Township",
                    "Towamencin Township",
                    "Hatfield Township",
                    "Whitemarsh Township",
                    "Plymouth Township",
                    "Lansdale borough",
                    "Upper Gwynedd Township",
                    "West Norriton Township",
                    "Lower Salford Township",
                    "East Norriton Township",
                    "Skippack Township",
                    "Franconia Township",
                    "Lower Moreland Township",
                    "Lower Pottsgrove Township",
                    "New Hanover Township",
                    "Lower Gwynedd Township",
                    "Douglass Township",
                    "Worcester Township",
                    "Perkiomen Township",
                    "Conshohocken borough",
                    "Hatboro borough",
                    "Upper Hanover Township",
                    "Souderton borough",
                    "Ambler borough",
                    "Upper Pottsgrove Township",
                    "Collegeville borough",
                    "Lower Frederick Township",
                    "Royersford borough",
                    "Bridgeport borough",
                    "Jenkintown borough",
                    "Narberth borough",
                    "West Pottsgrove Township",
                    "Pennsburg borough",
                    "Trappe borough",
                    "Upper Frederick Township",
                    "Upper Salford Township",
                    "Hatfield borough",
                    "Marlborough Township",
                    "North Wales borough",
                    "East Greenville borough",
                    "Salford Township",
                    "Rockledge borough",
                    "Red Hill borough",
                    "Bryn Athyn borough",
                    "Schwenksville borough",
                    "West Conshohocken borough",
                    "Green Lane borough"
                ]
            },
            {
                "countyName": "Montgomery, Bucks",
                "cities": [
                    "Telford borough"
                ]
            },
            {
                "countyName": "Montour",
                "cities": [
                    "Danville borough†",
                    "Mahoning Township",
                    "Valley Township",
                    "Liberty Township",
                    "Anthony Township",
                    "Derry Township",
                    "Limestone Township",
                    "Cooper Township",
                    "West Hemlock Township",
                    "Washingtonville borough",
                    "Mayberry Township"
                ]
            },
            {
                "countyName": "Northampton",
                "cities": [
                    "Easton city†",
                    "Bethlehem Township",
                    "Palmer Township",
                    "Forks Township",
                    "Hanover Township",
                    "Lower Saucon Township",
                    "Lehigh Township",
                    "Northampton borough",
                    "Moore Township",
                    "Bushkill Township",
                    "Wilson borough",
                    "Upper Mount Bethel Township",
                    "Upper Nazareth Township",
                    "Plainfield Township",
                    "Williams Township",
                    "Hellertown borough",
                    "Nazareth borough",
                    "Lower Nazareth Township",
                    "Bangor borough",
                    "Washington Township",
                    "East Allen Township",
                    "Allen Township",
                    "Pen Argyl borough",
                    "Lower Mount Bethel Township",
                    "North Catasauqua borough",
                    "Wind Gap borough",
                    "Bath borough",
                    "Freemansburg borough",
                    "Walnutport borough",
                    "Roseto borough",
                    "East Bangor borough",
                    "West Easton borough",
                    "Tatamy borough",
                    "Stockertown borough",
                    "Portland borough",
                    "Glendon borough",
                    "Chapman borough"
                ]
            },
            {
                "countyName": "Northampton, Lehigh",
                "cities": [
                    "Bethlehem city"
                ]
            },
            {
                "countyName": "Northumberland",
                "cities": [
                    "Coal Township",
                    "Sunbury city†",
                    "Shamokin city",
                    "Milton borough",
                    "Mount Carmel borough",
                    "Delaware Township",
                    "Ralpho Township",
                    "Northumberland borough",
                    "Point Township",
                    "Mount Carmel Township",
                    "Kulpmont borough",
                    "West Chillisquaque Township",
                    "Upper Augusta Township",
                    "Shamokin Township",
                    "Watsontown borough",
                    "Rockefeller Township",
                    "Riverside borough",
                    "Lewis Township",
                    "Zerbe Township",
                    "Turbot Township",
                    "Lower Mahanoy Township",
                    "Rush Township",
                    "Lower Augusta Township",
                    "Jackson Township",
                    "Jordan Township",
                    "Upper Mahanoy Township",
                    "East Cameron Township",
                    "Washington Township",
                    "Turbotville borough",
                    "East Chillisquaque Township",
                    "Marion Heights borough",
                    "West Cameron Township",
                    "Little Mahanoy Township",
                    "Snydertown borough",
                    "Herndon Borough",
                    "McEwensville borough"
                ]
            },
            {
                "countyName": "Perry",
                "cities": [
                    "Carroll Township",
                    "Wheatfield Township",
                    "Penn Township",
                    "Marysville borough",
                    "Saville Township",
                    "Centre Township",
                    "Rye Township",
                    "Spring Township",
                    "Tyrone Township",
                    "Oliver Township",
                    "Newport borough",
                    "Duncannon borough",
                    "Juniata Township",
                    "Watts Township",
                    "Bloomfield borough†",
                    "Buffalo Township",
                    "Tuscarora Township",
                    "Miller Township",
                    "Liverpool Township",
                    "Greenwood Township",
                    "Southwest Madison Township",
                    "Liverpool borough",
                    "Northeast Madison Township",
                    "Millerstown borough",
                    "Jackson Township",
                    "Toboyne Township",
                    "Howe Township",
                    "Blain borough",
                    "Landisburg borough",
                    "New Buffalo borough"
                ]
            },
            {
                "countyName": "Philadelphia",
                "cities": [
                    "Philadelphia city†^"
                ]
            },
            {
                "countyName": "Pike",
                "cities": [
                    "Dingman Township",
                    "Lehman Township",
                    "Delaware Township",
                    "Lackawaxen Township",
                    "Blooming Grove Township",
                    "Greene Township",
                    "Palmyra Township",
                    "Matamoras borough",
                    "Shohola Township",
                    "Westfall Township",
                    "Milford Township",
                    "Milford borough†",
                    "Porter Township"
                ]
            },
            {
                "countyName": "Potter",
                "cities": [
                    "Coudersport borough†",
                    "Roulette Township",
                    "Galeton borough",
                    "Shinglehouse Borough",
                    "Harrison Township",
                    "Eulalia Township",
                    "Sweden Township",
                    "Sharon Township",
                    "Genesee Township",
                    "Bingham Township",
                    "Ulysses Township",
                    "Ulysses borough",
                    "Hebron Township",
                    "Austin borough",
                    "Homer Township",
                    "Allegany Township",
                    "West Branch Township",
                    "Hector Township",
                    "Pike Township",
                    "Keating Township",
                    "Oswayo Township",
                    "Abbott Township",
                    "Portage Township",
                    "Clara Township",
                    "Summit Township",
                    "Oswayo borough",
                    "Wharton Township",
                    "Pleasant Valley Township",
                    "Sylvania Township",
                    "Stewardson Township"
                ]
            },
            {
                "countyName": "Schuylkill",
                "cities": [
                    "Pottsville city†",
                    "Tamaqua borough",
                    "Schuylkill Haven borough",
                    "Butler Township",
                    "Wayne Township",
                    "Shenandoah borough",
                    "West Penn Township",
                    "Minersville borough",
                    "Pine Grove Township",
                    "Mahanoy City borough",
                    "Frackville borough",
                    "North Manheim Township",
                    "Hegins Township",
                    "Rush Township",
                    "West Brunswick Township",
                    "Mahanoy Township",
                    "Orwigsburg borough",
                    "Washington Township",
                    "St. Clair borough",
                    "West Mahanoy Township",
                    "South Manheim Township",
                    "Ryan Township",
                    "Norwegian Township",
                    "McAdoo borough",
                    "Coaldale borough",
                    "Pine Grove borough",
                    "Porter Township",
                    "Cass Township",
                    "Port Carbon borough",
                    "Branch Township",
                    "East Brunswick Township",
                    "Tremont borough",
                    "Cressona borough",
                    "East Union Township",
                    "Girardville borough",
                    "North Union Township",
                    "Kline Township",
                    "Tower City borough",
                    "Union Township",
                    "Schuylkill Township",
                    "New Philadelphia borough",
                    "Walker Township",
                    "Palo Alto borough",
                    "Barry Township",
                    "Blythe Township",
                    "East Norwegian Township",
                    "Hubley Township",
                    "Ringtown borough",
                    "Gilberton borough",
                    "Gordon borough",
                    "Eldred Township",
                    "Auburn borough",
                    "Reilly Township",
                    "Deer Lake borough",
                    "Upper Mahantongo Township",
                    "Mechanicsville borough",
                    "Delano Township",
                    "Frailey Township",
                    "New Castle Township",
                    "Middleport borough",
                    "Port Clinton borough",
                    "Tremont Township",
                    "New Ringgold borough",
                    "Foster Township",
                    "Landingville borough",
                    "Mount Carbon borough"
                ]
            },
            {
                "countyName": "Schuylkill, Columbia",
                "cities": [
                    "Ashland borough"
                ]
            },
            {
                "countyName": "Snyder",
                "cities": [
                    "Selinsgrove borough",
                    "Penn Township",
                    "Monroe Township",
                    "Center Township",
                    "Franklin Township",
                    "Perry Township",
                    "Middlecreek Township",
                    "Shamokin Dam borough",
                    "Washington Township",
                    "Spring Township",
                    "Chapman Township",
                    "Union Township",
                    "Jackson Township",
                    "Middleburg borough†",
                    "West Beaver Township",
                    "West Perry Township",
                    "Beavertown borough",
                    "McClure borough",
                    "Adams Township",
                    "Freeburg borough",
                    "Beaver Township"
                ]
            },
            {
                "countyName": "Somerset",
                "cities": [
                    "Somerset Township",
                    "Conemaugh Township",
                    "Somerset borough†",
                    "Windber borough",
                    "Jenner Township",
                    "Paint Township",
                    "Shade Township",
                    "Brothersvalley Township",
                    "Summit Township",
                    "Elk Lick Township",
                    "Stonycreek Township",
                    "Meyersdale borough",
                    "Berlin borough",
                    "Quemahoning Township",
                    "Milford Township",
                    "Lincoln Township",
                    "Jefferson Township",
                    "Boswell borough",
                    "Upper Turkeyfoot Township",
                    "Central City borough",
                    "Paint borough",
                    "Addison Township",
                    "Black Township",
                    "Rockwood borough",
                    "Middlecreek Township",
                    "Confluence borough",
                    "Salisbury Borough",
                    "Allegheny Township",
                    "Jennerstown borough",
                    "Greenville Township",
                    "Hooversville borough",
                    "Southampton Township",
                    "Lower Turkeyfoot Township",
                    "Larimer Township",
                    "Ogle Township",
                    "Garrett borough",
                    "Indian Lake borough",
                    "Stoystown borough",
                    "Northampton Township",
                    "Shanksville borough",
                    "Ursina borough",
                    "Addison borough",
                    "Benson borough",
                    "Wellersburg borough",
                    "New Baltimore borough",
                    "Fairhope Township",
                    "New Centerville borough",
                    "Casselman borough",
                    "Callimont borough"
                ]
            },
            {
                "countyName": "Sullivan",
                "cities": [
                    "Cherry Township",
                    "Colley Township",
                    "Dushore borough",
                    "Elkland Township",
                    "Davidson Township",
                    "Forks Township",
                    "Fox Township",
                    "Laporte Township",
                    "Shrewsbury Township",
                    "Laporte borough†",
                    "Hillsgrove Township",
                    "Forksville borough",
                    "Eagles Mere borough"
                ]
            },
            {
                "countyName": "Susquehanna",
                "cities": [
                    "Bridgewater Township",
                    "Clifford Township",
                    "New Milford Township",
                    "Great Bend Township",
                    "Auburn Township",
                    "Lenox Township",
                    "Forest City borough",
                    "Silver Lake Township",
                    "Susquehanna Depot borough",
                    "Springville Township",
                    "Montrose borough†",
                    "Dimock Township",
                    "Harford Township",
                    "Hallstead borough",
                    "Liberty Township",
                    "Rush Township",
                    "Gibson Township",
                    "Forest Lake Township",
                    "Brooklyn Township",
                    "Franklin Township",
                    "New Milford borough",
                    "Jackson Township",
                    "Lathrop Township",
                    "Great Bend Borough",
                    "Herrick Township",
                    "Choconut Township",
                    "Oakland borough",
                    "Ararat Township",
                    "Oakland Township",
                    "Jessup Township",
                    "Harmony Township",
                    "Apolacon Township",
                    "Lanesboro borough",
                    "Thompson Township",
                    "Middletown Township",
                    "Hop Bottom Borough",
                    "Thompson borough",
                    "Little Meadows borough",
                    "Union Dale borough",
                    "Friendsville borough"
                ]
            },
            {
                "countyName": "Tioga",
                "cities": [
                    "Mansfield borough",
                    "Charleston Township",
                    "Wellsboro borough†",
                    "Delmar Township",
                    "Richmond Township",
                    "Jackson Township",
                    "Elkland borough",
                    "Lawrence Township",
                    "Blossburg borough",
                    "Sullivan Township",
                    "Middlebury Township",
                    "Westfield borough",
                    "Westfield Township",
                    "Liberty Township",
                    "Covington Township",
                    "Union Township",
                    "Tioga Township",
                    "Rutland Township",
                    "Deerfield Township",
                    "Tioga borough",
                    "Osceola Township",
                    "Farmington Township",
                    "Knoxville borough",
                    "Lawrenceville borough",
                    "Morris Township",
                    "Chatham Township",
                    "Clymer Township",
                    "Nelson Township",
                    "Gaines Township",
                    "Shippen Township",
                    "Hamilton Township",
                    "Putnam Township",
                    "Brookfield Township",
                    "Bloss Township",
                    "Liberty borough",
                    "Duncan Township",
                    "Roseville borough",
                    "Ward Township",
                    "Elk Township"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "East Buffalo Township",
                    "Lewisburg borough†",
                    "Kelly Township",
                    "Gregg Township",
                    "White Deer Township",
                    "Buffalo Township",
                    "Mifflinburg borough",
                    "West Buffalo Township",
                    "Hartley Township",
                    "Limestone Township",
                    "Union Township",
                    "Lewis Township",
                    "New Berlin borough",
                    "Hartleton borough"
                ]
            },
            {
                "countyName": "Venango",
                "cities": [
                    "Oil City city",
                    "Cranberry Township",
                    "Franklin city†",
                    "Sugarcreek borough",
                    "Cornplanter Township",
                    "Sandycreek Township",
                    "Cherrytree Township",
                    "Frenchcreek Township",
                    "Oakland Township",
                    "Rockland Township",
                    "Irwin Township",
                    "Pinegrove Township",
                    "Jackson Township",
                    "Plum Township",
                    "Canal Township",
                    "Pleasantville Borough",
                    "Clinton Township",
                    "Oil Creek Township",
                    "Polk borough",
                    "Richland Township",
                    "Scrubgrass Township",
                    "President Township",
                    "Mineral Township",
                    "Rouseville borough",
                    "Clintonville borough",
                    "Cooperstown borough",
                    "Victory Township",
                    "Allegheny Township",
                    "Barkeyville borough",
                    "Utica borough"
                ]
            },
            {
                "countyName": "Venango, Clarion",
                "cities": [
                    "Emlenton borough (comb.)"
                ]
            },
            {
                "countyName": "Warren",
                "cities": [
                    "Warren city†",
                    "Conewango Township",
                    "Pine Grove Township",
                    "Pleasant Township",
                    "Glade Township",
                    "Sheffield Township",
                    "Columbus Township",
                    "Brokenstraw Township",
                    "Sugar Grove Township",
                    "Youngsville borough",
                    "Freehold Township",
                    "Pittsfield Township",
                    "Mead Township",
                    "Farmington Township",
                    "Spring Creek Township",
                    "Tidioute borough",
                    "Eldred Township",
                    "Sugar Grove borough",
                    "Southwest Township",
                    "Elk Township",
                    "Clarendon Borough",
                    "Limestone Township",
                    "Deerfield Township",
                    "Triumph Township",
                    "Watson Township",
                    "Cherry Grove Township",
                    "Bear Lake borough"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Peters Township",
                    "North Strabane Township",
                    "Washington City†",
                    "Cecil Township",
                    "South Strabane Township",
                    "Canonsburg borough",
                    "Canton Township",
                    "Chartiers Township",
                    "California borough",
                    "Union Township",
                    "Carroll Township",
                    "Donora borough",
                    "North Franklin Township",
                    "Smith Township",
                    "Fallowfield Township",
                    "Monongahela city",
                    "Charleroi borough",
                    "Amwell Township",
                    "Mount Pleasant Township",
                    "South Franklin Township",
                    "Centerville borough",
                    "Nottingham Township",
                    "Somerset Township",
                    "Hanover Township",
                    "Bentleyville borough",
                    "Donegal Township",
                    "East Bethlehem Township",
                    "East Washington borough",
                    "New Eagle borough",
                    "Buffalo Township",
                    "Robinson Township",
                    "North Bethlehem Township",
                    "West Pike Run Township",
                    "Cross Creek Township",
                    "Independence Township",
                    "West Bethlehem Township",
                    "East Finley Township",
                    "Burgettstown borough",
                    "North Charleroi borough",
                    "Houston borough",
                    "Jefferson Township",
                    "Speers borough",
                    "Morris Township",
                    "Ellsworth borough",
                    "West Brownsville Borough",
                    "Hopewell Township",
                    "Midway borough",
                    "West Finley Township",
                    "Claysville borough",
                    "Roscoe borough",
                    "Deemston borough",
                    "Blaine Township",
                    "Cokeburg borough",
                    "Allenport borough",
                    "Stockdale borough",
                    "Marianna borough",
                    "Beallsville borough",
                    "Finleyville borough",
                    "Long Branch borough",
                    "Dunlevy borough",
                    "Elco Borough",
                    "Twilight Borough",
                    "Coal Center borough",
                    "West Middletown borough",
                    "Green Hills borough"
                ]
            },
            {
                "countyName": "Washington, Allegheny",
                "cities": [
                    "McDonald borough (comb.)"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Lake Township",
                    "Honesdale borough†",
                    "Salem Township",
                    "Canaan Township",
                    "Paupack Township",
                    "Damascus Township",
                    "Texas Township",
                    "Berlin Township",
                    "Clinton Township",
                    "Cherry Ridge Township",
                    "Lehigh Township",
                    "South Canaan Township",
                    "Sterling Township",
                    "Dyberry Township",
                    "Dreher Township",
                    "Mount Pleasant Township",
                    "Palmyra Township",
                    "Waymart borough",
                    "Hawley borough",
                    "Preston Township",
                    "Manchester Township",
                    "Oregon Township",
                    "Lebanon Township",
                    "Scott Township",
                    "Buckingham Township",
                    "Prompton borough",
                    "Bethany borough",
                    "Starrucca borough"
                ]
            },
            {
                "countyName": "Westmoreland",
                "cities": [
                    "Hempfield Township",
                    "North Huntingdon Township",
                    "Unity Township",
                    "Murrysville borough",
                    "Penn Township",
                    "Greensburg city†",
                    "Derry Township",
                    "New Kensington city",
                    "Lower Burrell city",
                    "Rostraver Township",
                    "Mount Pleasant Township",
                    "Jeannette city",
                    "Allegheny Township",
                    "Latrobe city",
                    "East Huntingdon Township",
                    "Monessen city",
                    "Washington Township",
                    "Salem Township",
                    "Ligonier Township",
                    "Sewickley Township",
                    "South Huntingdon Township",
                    "Vandergrift borough",
                    "Arnold city",
                    "Mount Pleasant borough",
                    "Scottdale borough",
                    "Irwin borough",
                    "Manor borough",
                    "Youngwood borough",
                    "Delmont borough",
                    "Derry borough",
                    "West Newton borough",
                    "Fairfield Township",
                    "Donegal Township",
                    "Loyalhanna Township",
                    "Bell Township",
                    "Upper Burrell Township",
                    "Cook Township",
                    "New Stanton borough",
                    "Southwest Greensburg borough",
                    "South Greensburg borough",
                    "North Belle Vernon borough",
                    "Ligonier borough",
                    "St. Clair Township",
                    "West Leechburg borough",
                    "Avonmore borough",
                    "Export borough",
                    "North Irwin borough",
                    "Oklahoma borough",
                    "New Florence borough",
                    "East Vandergrift borough",
                    "Sutersville borough",
                    "New Alexandria borough",
                    "Hyde Park borough",
                    "Penn borough",
                    "Seward borough",
                    "Bolivar borough",
                    "Smithton borough",
                    "Madison borough",
                    "Arona borough",
                    "Youngstown borough",
                    "Hunker borough",
                    "Adamsburg borough",
                    "Laurel Mountain borough",
                    "Donegal borough"
                ]
            },
            {
                "countyName": "Westmoreland, Allegheny",
                "cities": [
                    "Trafford borough (comb.)"
                ]
            },
            {
                "countyName": "Wyoming",
                "cities": [
                    "Tunkhannock Township",
                    "Falls Township",
                    "Tunkhannock borough†",
                    "Overfield Township",
                    "Monroe Township",
                    "Northmoreland Township",
                    "Eaton Township",
                    "Washington Township",
                    "Nicholson Township",
                    "Clinton Township",
                    "Lemon Township",
                    "Factoryville borough",
                    "Meshoppen Township",
                    "Mehoopany Township",
                    "Noxen Township",
                    "Windham Township",
                    "Nicholson borough",
                    "Exeter Township",
                    "Meshoppen borough",
                    "Braintrim Township",
                    "Forkston Township",
                    "Laceyville borough",
                    "North Branch Township"
                ]
            },
            {
                "countyName": "York",
                "cities": [
                    "York city†",
                    "York Township",
                    "Springettsbury Township",
                    "Dover Township",
                    "West Manchester Township",
                    "Manchester Township",
                    "Windsor Township",
                    "Fairview Township",
                    "Penn Township",
                    "Hanover borough",
                    "Newberry Township",
                    "Spring Garden Township",
                    "North Codorus Township",
                    "West Manheim Township",
                    "Conewago Township",
                    "Jackson Township",
                    "Lower Windsor Township",
                    "East Manchester Township",
                    "Shrewsbury Township",
                    "Red Lion borough",
                    "Chanceford Township",
                    "Carroll Township",
                    "Hellam Township",
                    "Hopewell Township",
                    "Springfield Township",
                    "Franklin Township",
                    "Peach Bottom Township",
                    "West York borough",
                    "Warrington Township",
                    "New Freedom borough",
                    "Dallastown borough",
                    "Codorus Township",
                    "Paradise Township",
                    "Shrewsbury borough",
                    "Manheim Township",
                    "Fawn Township",
                    "Heidelberg Township",
                    "Lower Chanceford Township",
                    "North Hopewell Township",
                    "Manchester borough",
                    "Washington Township",
                    "Hallam borough",
                    "Monaghan Township",
                    "Dillsburg borough",
                    "East Hopewell Township",
                    "Stewartstown borough",
                    "Wrightsville borough",
                    "Spring Grove borough",
                    "Glen Rock borough",
                    "Dover borough",
                    "North York borough",
                    "Jacobus borough",
                    "Windsor borough",
                    "Mount Wolf borough",
                    "Loganville borough",
                    "Yoe Borough",
                    "Goldsboro borough",
                    "East Prospect borough",
                    "New Salem borough",
                    "Delta borough",
                    "Jefferson borough",
                    "York Haven borough",
                    "Winterstown borough",
                    "Seven Valleys borough",
                    "Cross Roads borough",
                    "Felton borough",
                    "Franklintown borough",
                    "Fawn Grove borough",
                    "Lewisberry borough",
                    "Railroad borough",
                    "Wellsville borough",
                    "Yorkana borough"
                ]
            }
        ]
    },
    {
        "stateId": "RI",
        "stateName": "Rhode Island",
        "county": [
            {
                "countyName": "Bristol",
                "cities": [
                    "Barrington",
                    "Bristol",
                    "Warren"
                ]
            },
            {
                "countyName": "Kent",
                "cities": [
                    "Coventry",
                    "East Greenwich",
                    "Warwick",
                    "West Greenwich",
                    "West Warwick"
                ]
            },
            {
                "countyName": "Newport",
                "cities": [
                    "Jamestown",
                    "Little Compton",
                    "Middletown",
                    "Newport",
                    "Portsmouth",
                    "Tiverton"
                ]
            },
            {
                "countyName": "Providence",
                "cities": [
                    "Burrillville",
                    "Central Falls",
                    "Cranston",
                    "Cumberland",
                    "East Providence",
                    "Foster",
                    "Glocester",
                    "Johnston",
                    "Lincoln",
                    "North Providence",
                    "North Smithfield",
                    "Pawtucket",
                    "Providence†",
                    "Scituate",
                    "Smithfield",
                    "Woonsocket"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Charlestown",
                    "Exeter",
                    "Hopkinton",
                    "Narragansett",
                    "New Shoreham",
                    "North Kingstown",
                    "Richmond",
                    "South Kingstown",
                    "Westerly"
                ]
            }
        ]
    },
    {
        "stateId": "SC",
        "stateName": "South Carolina",
        "county": [
            {
                "countyName": "Abbeville",
                "cities": [
                    "Abbeville†",
                    "Calhoun Falls",
                    "Donalds",
                    "Due West",
                    "Lowndesville"
                ]
            },
            {
                "countyName": "Aiken",
                "cities": [
                    "Aiken†",
                    "Burnettown",
                    "Jackson",
                    "New Ellenton",
                    "Perry",
                    "Salley",
                    "Wagener",
                    "Windsor"
                ]
            },
            {
                "countyName": "Aiken\nEdgefield",
                "cities": [
                    "North Augusta"
                ]
            },
            {
                "countyName": "Aiken\nSaluda",
                "cities": [
                    "Monetta"
                ]
            },
            {
                "countyName": "Allendale",
                "cities": [
                    "Allendale†",
                    "Fairfax",
                    "Sycamore",
                    "Ulmer"
                ]
            },
            {
                "countyName": "Anderson",
                "cities": [
                    "Anderson†",
                    "Belton",
                    "Iva",
                    "Pelzer",
                    "Pendleton",
                    "Starr",
                    "West Pelzer",
                    "Williamston"
                ]
            },
            {
                "countyName": "Anderson\nAbbeville",
                "cities": [
                    "Honea Path"
                ]
            },
            {
                "countyName": "Bamberg",
                "cities": [
                    "Bamberg†",
                    "Denmark",
                    "Ehrhardt",
                    "Govan",
                    "Olar"
                ]
            },
            {
                "countyName": "Barnwell",
                "cities": [
                    "Barnwell†",
                    "Blackville",
                    "Elko",
                    "Hilda",
                    "Kline",
                    "Snelling",
                    "Williston"
                ]
            },
            {
                "countyName": "Beaufort",
                "cities": [
                    "Beaufort†",
                    "Bluffton",
                    "Hilton Head Island",
                    "Port Royal"
                ]
            },
            {
                "countyName": "Beaufort\nHampton",
                "cities": [
                    "Yemassee"
                ]
            },
            {
                "countyName": "Berkeley",
                "cities": [
                    "Bonneau",
                    "Hanahan",
                    "Jamestown",
                    "Moncks Corner†",
                    "St. Stephen"
                ]
            },
            {
                "countyName": "Berkeley\nCharleston",
                "cities": [
                    "Goose Creek"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Cameron",
                    "St. Matthews†"
                ]
            },
            {
                "countyName": "Charleston",
                "cities": [
                    "Awendaw",
                    "Folly Beach",
                    "Hollywood",
                    "Isle of Palms",
                    "James Island[a]",
                    "Kiawah Island",
                    "McClellanville",
                    "Meggett",
                    "Mount Pleasant",
                    "Ravenel",
                    "Rockville",
                    "Seabrook Island",
                    "Sullivan's Island"
                ]
            },
            {
                "countyName": "Charleston\nBerkeley",
                "cities": [
                    "Charleston†"
                ]
            },
            {
                "countyName": "Charleston\nBerkeley\nDorchester",
                "cities": [
                    "North Charleston"
                ]
            },
            {
                "countyName": "Charleston\nDorchester",
                "cities": [
                    "Lincolnville"
                ]
            },
            {
                "countyName": "Cherokee",
                "cities": [
                    "Blacksburg",
                    "Gaffney†"
                ]
            },
            {
                "countyName": "Chester",
                "cities": [
                    "Chester†",
                    "Fort Lawn",
                    "Great Falls",
                    "Lowrys",
                    "Richburg"
                ]
            },
            {
                "countyName": "Chesterfield",
                "cities": [
                    "Cheraw",
                    "Chesterfield†",
                    "Jefferson",
                    "McBee",
                    "Mount Croghan",
                    "Pageland",
                    "Patrick",
                    "Ruby"
                ]
            },
            {
                "countyName": "Clarendon",
                "cities": [
                    "Manning†",
                    "Paxville",
                    "Summerton",
                    "Turbeville"
                ]
            },
            {
                "countyName": "Colleton",
                "cities": [
                    "Cottageville",
                    "Edisto Beach",
                    "Lodge",
                    "Smoaks",
                    "Walterboro†",
                    "Williams"
                ]
            },
            {
                "countyName": "Darlington",
                "cities": [
                    "Darlington†",
                    "Hartsville",
                    "Lamar",
                    "Society Hill"
                ]
            },
            {
                "countyName": "Dillon",
                "cities": [
                    "Dillon†",
                    "Lake View",
                    "Latta"
                ]
            },
            {
                "countyName": "Dorchester",
                "cities": [
                    "Harleyville",
                    "Reevesville",
                    "Ridgeville",
                    "St. George†"
                ]
            },
            {
                "countyName": "Dorchester\nBerkeley\nCharleston",
                "cities": [
                    "Summerville"
                ]
            },
            {
                "countyName": "Edgefield",
                "cities": [
                    "Edgefield†",
                    "Johnston",
                    "Trenton"
                ]
            },
            {
                "countyName": "Fairfield",
                "cities": [
                    "Jenkinsville",
                    "Ridgeway",
                    "Winnsboro†"
                ]
            },
            {
                "countyName": "Florence",
                "cities": [
                    "Coward",
                    "Florence†",
                    "Johnsonville",
                    "Lake City",
                    "Olanta",
                    "Pamplico",
                    "Quinby",
                    "Scranton",
                    "Timmonsville"
                ]
            },
            {
                "countyName": "Georgetown",
                "cities": [
                    "Georgetown†",
                    "Pawleys Island"
                ]
            },
            {
                "countyName": "Georgetown\nWilliamsburg",
                "cities": [
                    "Andrews"
                ]
            },
            {
                "countyName": "Greenville",
                "cities": [
                    "Greenville†",
                    "Mauldin",
                    "Simpsonville",
                    "Travelers Rest"
                ]
            },
            {
                "countyName": "Greenville\nLaurens",
                "cities": [
                    "Fountain Inn"
                ]
            },
            {
                "countyName": "Greenville\nSpartanburg",
                "cities": [
                    "Greer"
                ]
            },
            {
                "countyName": "Greenwood",
                "cities": [
                    "Greenwood†",
                    "Hodges",
                    "Ninety Six",
                    "Troy"
                ]
            },
            {
                "countyName": "Greenwood\nAbbeville\nLaurens",
                "cities": [
                    "Ware Shoals"
                ]
            },
            {
                "countyName": "Hampton",
                "cities": [
                    "Brunson",
                    "Estill",
                    "Furman",
                    "Gifford",
                    "Hampton†",
                    "Luray",
                    "Scotia",
                    "Varnville"
                ]
            },
            {
                "countyName": "Horry",
                "cities": [
                    "Atlantic Beach",
                    "Aynor",
                    "Briarcliffe Acres",
                    "Conway†",
                    "Loris",
                    "Myrtle Beach",
                    "North Myrtle Beach",
                    "Surfside Beach"
                ]
            },
            {
                "countyName": "Jasper",
                "cities": [
                    "Hardeeville",
                    "Ridgeland†"
                ]
            },
            {
                "countyName": "Kershaw",
                "cities": [
                    "Bethune",
                    "Camden†",
                    "Elgin"
                ]
            },
            {
                "countyName": "Lancaster",
                "cities": [
                    "Heath Springs",
                    "Kershaw",
                    "Lancaster†",
                    "Van Wyck[b]"
                ]
            },
            {
                "countyName": "Laurens",
                "cities": [
                    "Clinton",
                    "Cross Hill",
                    "Gray Court",
                    "Laurens†",
                    "Waterloo"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Bishopville†",
                    "Lynchburg"
                ]
            },
            {
                "countyName": "Lexington",
                "cities": [
                    "Chapin",
                    "Gaston",
                    "Gilbert",
                    "Lexington†",
                    "Pelion",
                    "Pine Ridge",
                    "South Congaree",
                    "Springdale",
                    "Summit",
                    "Swansea",
                    "West Columbia"
                ]
            },
            {
                "countyName": "Lexington\nRichland",
                "cities": [
                    "Cayce",
                    "Irmo"
                ]
            },
            {
                "countyName": "Lexington\nSaluda",
                "cities": [
                    "Batesburg-Leesville"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Marion†",
                    "Mullins",
                    "Nichols",
                    "Sellers"
                ]
            },
            {
                "countyName": "Marlboro",
                "cities": [
                    "Bennettsville†",
                    "Blenheim",
                    "Clio",
                    "McColl",
                    "Tatum"
                ]
            },
            {
                "countyName": "McCormick",
                "cities": [
                    "McCormick†",
                    "Parksville",
                    "Plum Branch"
                ]
            },
            {
                "countyName": "Newberry",
                "cities": [
                    "Little Mountain",
                    "Newberry†",
                    "Peak",
                    "Pomaria",
                    "Prosperity",
                    "Silverstreet",
                    "Whitmire"
                ]
            },
            {
                "countyName": "Oconee",
                "cities": [
                    "Salem",
                    "Seneca",
                    "Walhalla†",
                    "Westminster",
                    "West Union"
                ]
            },
            {
                "countyName": "Orangeburg",
                "cities": [
                    "Bowman",
                    "Branchville",
                    "Cope",
                    "Cordova",
                    "Elloree",
                    "Eutawville",
                    "Holly Hill",
                    "Livingston",
                    "Neeses",
                    "North",
                    "Norway",
                    "Orangeburg†",
                    "Rowesville",
                    "Santee",
                    "Springfield",
                    "Vance",
                    "Woodford"
                ]
            },
            {
                "countyName": "Pickens",
                "cities": [
                    "Central",
                    "Liberty",
                    "Norris",
                    "Pickens†",
                    "Six Mile"
                ]
            },
            {
                "countyName": "Pickens\nAnderson",
                "cities": [
                    "Clemson",
                    "Easley"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Arcadia Lakes",
                    "Eastover",
                    "Forest Acres"
                ]
            },
            {
                "countyName": "Richland\nFairfield",
                "cities": [
                    "Blythewood"
                ]
            },
            {
                "countyName": "Richland\nLexington",
                "cities": [
                    "Columbia‡"
                ]
            },
            {
                "countyName": "Saluda",
                "cities": [
                    "Ridge Spring",
                    "Saluda†",
                    "Ward"
                ]
            },
            {
                "countyName": "Spartanburg",
                "cities": [
                    "Campobello",
                    "Central Pacolet",
                    "Cowpens",
                    "Duncan",
                    "Inman",
                    "Landrum",
                    "Lyman",
                    "Pacolet",
                    "Reidville",
                    "Spartanburg†",
                    "Wellford",
                    "Woodruff"
                ]
            },
            {
                "countyName": "Spartanburg\nCherokee",
                "cities": [
                    "Chesnee"
                ]
            },
            {
                "countyName": "Sumter",
                "cities": [
                    "Mayesville",
                    "Pinewood",
                    "Sumter†"
                ]
            },
            {
                "countyName": "Union",
                "cities": [
                    "Carlisle",
                    "Jonesville",
                    "Lockhart",
                    "Union†"
                ]
            },
            {
                "countyName": "Williamsburg",
                "cities": [
                    "Greeleyville",
                    "Hemingway",
                    "Kingstree†",
                    "Lane",
                    "Stuckey"
                ]
            },
            {
                "countyName": "York",
                "cities": [
                    "Clover",
                    "Fort Mill",
                    "Hickory Grove",
                    "McConnells",
                    "Rock Hill",
                    "Sharon",
                    "Tega Cay",
                    "York†"
                ]
            },
            {
                "countyName": "York\nCherokee",
                "cities": [
                    "Smyrna"
                ]
            }
        ]
    },
    {
        "stateId": "TN",
        "stateName": "Tennessee",
        "county": [
            {
                "countyName": "Anderson County",
                "cities": [
                    "Clinton",
                    "Norris"
                ]
            },
            {
                "countyName": "Anderson County\nCampbell County",
                "cities": [
                    "Rocky Top[9]"
                ]
            },
            {
                "countyName": "Anderson County\nMorgan County\nRoane County",
                "cities": [
                    "Oliver Springs"
                ]
            },
            {
                "countyName": "Anderson County\nRoane County",
                "cities": [
                    "Oak Ridge"
                ]
            },
            {
                "countyName": "Bedford County",
                "cities": [
                    "Bell Buckle",
                    "Normandy",
                    "Shelbyville",
                    "Wartrace"
                ]
            },
            {
                "countyName": "Benton County",
                "cities": [
                    "Big Sandy",
                    "Camden"
                ]
            },
            {
                "countyName": "Bledsoe County",
                "cities": [
                    "Pikeville"
                ]
            },
            {
                "countyName": "Blount County",
                "cities": [
                    "Alcoa",
                    "Friendsville",
                    "Louisville",
                    "Maryville",
                    "Rockford",
                    "Townsend"
                ]
            },
            {
                "countyName": "Bradley County",
                "cities": [
                    "Charleston",
                    "Cleveland"
                ]
            },
            {
                "countyName": "Campbell County",
                "cities": [
                    "Caryville",
                    "Jacksboro",
                    "Jellico",
                    "LaFollette"
                ]
            },
            {
                "countyName": "Cannon County",
                "cities": [
                    "Auburntown",
                    "Woodbury"
                ]
            },
            {
                "countyName": "Carroll County",
                "cities": [
                    "Atwood",
                    "Bruceton",
                    "Clarksburg",
                    "Hollow Rock",
                    "Huntingdon",
                    "McLemoresville",
                    "Trezevant"
                ]
            },
            {
                "countyName": "Carroll County\nHenry County\nWeakley County",
                "cities": [
                    "McKenzie"
                ]
            },
            {
                "countyName": "Carter County",
                "cities": [
                    "Elizabethton",
                    "Watauga"
                ]
            },
            {
                "countyName": "Cheatham County",
                "cities": [
                    "Ashland City",
                    "Kingston Springs",
                    "Pegram",
                    "Pleasant View"
                ]
            },
            {
                "countyName": "Chester County",
                "cities": [
                    "Henderson"
                ]
            },
            {
                "countyName": "Chester County\nMcNairy County",
                "cities": [
                    "Enville"
                ]
            },
            {
                "countyName": "Claiborne County",
                "cities": [
                    "Cumberland Gap",
                    "Harrogate",
                    "New Tazewell",
                    "Tazewell"
                ]
            },
            {
                "countyName": "Clay County",
                "cities": [
                    "Celina"
                ]
            },
            {
                "countyName": "Cocke County",
                "cities": [
                    "Newport",
                    "Parrottsville"
                ]
            },
            {
                "countyName": "Coffee County",
                "cities": [
                    "Manchester"
                ]
            },
            {
                "countyName": "Coffee County\nFranklin County",
                "cities": [
                    "Tullahoma"
                ]
            },
            {
                "countyName": "Crockett County",
                "cities": [
                    "Alamo",
                    "Bells",
                    "Friendship",
                    "Gadsden",
                    "Maury City"
                ]
            },
            {
                "countyName": "Cumberland County",
                "cities": [
                    "Crab Orchard",
                    "Crossville",
                    "Pleasant Hill"
                ]
            },
            {
                "countyName": "Davidson County",
                "cities": [
                    "Belle Meade",
                    "Berry Hill",
                    "Forest Hills",
                    "Nashville",
                    "Oak Hill"
                ]
            },
            {
                "countyName": "Davidson County\nSumner County",
                "cities": [
                    "Goodlettsville"
                ]
            },
            {
                "countyName": "Decatur County",
                "cities": [
                    "Decaturville",
                    "Parsons"
                ]
            },
            {
                "countyName": "DeKalb County",
                "cities": [
                    "Alexandria",
                    "Dowelltown",
                    "Liberty",
                    "Smithville"
                ]
            },
            {
                "countyName": "Dickson County",
                "cities": [
                    "Burns",
                    "Charlotte",
                    "Dickson",
                    "Slayden",
                    "Vanleer",
                    "White Bluff"
                ]
            },
            {
                "countyName": "Dyer County",
                "cities": [
                    "Dyersburg",
                    "Newbern"
                ]
            },
            {
                "countyName": "Dyer County\nObion County",
                "cities": [
                    "Trimble"
                ]
            },
            {
                "countyName": "Fayette County",
                "cities": [
                    "Braden",
                    "Gallaway",
                    "La Grange",
                    "Moscow",
                    "Oakland",
                    "Piperton",
                    "Rossville",
                    "Somerville",
                    "Williston"
                ]
            },
            {
                "countyName": "Fentress County",
                "cities": [
                    "Allardt",
                    "Jamestown"
                ]
            },
            {
                "countyName": "Franklin County",
                "cities": [
                    "Cowan",
                    "Decherd",
                    "Estill Springs",
                    "Huntland",
                    "Winchester"
                ]
            },
            {
                "countyName": "Gibson County",
                "cities": [
                    "Bradford",
                    "Dyer",
                    "Gibson",
                    "Humboldt",
                    "Medina",
                    "Milan",
                    "Rutherford",
                    "Trenton",
                    "Yorkville"
                ]
            },
            {
                "countyName": "Gibson County\nObion County",
                "cities": [
                    "Kenton"
                ]
            },
            {
                "countyName": "Giles County",
                "cities": [
                    "Ardmore",
                    "Elkton",
                    "Lynnville",
                    "Minor Hill",
                    "Pulaski"
                ]
            },
            {
                "countyName": "Grainger County",
                "cities": [
                    "Bean Station",
                    "Blaine",
                    "Rutledge"
                ]
            },
            {
                "countyName": "Greene County",
                "cities": [
                    "Baileyton",
                    "Greeneville",
                    "Mosheim",
                    "Tusculum"
                ]
            },
            {
                "countyName": "Grundy County",
                "cities": [
                    "Altamont",
                    "Beersheba Springs",
                    "Coalmont",
                    "Gruetli-Laager",
                    "Palmer",
                    "Tracy City"
                ]
            },
            {
                "countyName": "Grundy County\nMarion County",
                "cities": [
                    "Monteagle"
                ]
            },
            {
                "countyName": "Hamblen County",
                "cities": [
                    "Morristown"
                ]
            },
            {
                "countyName": "Hamilton County",
                "cities": [
                    "Chattanooga",
                    "Collegedale",
                    "East Ridge",
                    "Lakesite",
                    "Lookout Mountain",
                    "Red Bank",
                    "Ridgeside",
                    "Signal Mountain",
                    "Soddy-Daisy",
                    "Walden"
                ]
            },
            {
                "countyName": "Hancock County",
                "cities": [
                    "Sneedville"
                ]
            },
            {
                "countyName": "Hardeman County",
                "cities": [
                    "Bolivar",
                    "Hickory Valley",
                    "Hornsby",
                    "Middleton",
                    "Saulsbury",
                    "Toone",
                    "Whiteville"
                ]
            },
            {
                "countyName": "Hardeman County\nChester County",
                "cities": [
                    "Silerton"
                ]
            },
            {
                "countyName": "Hardeman County\nFayette County",
                "cities": [
                    "Grand Junction"
                ]
            },
            {
                "countyName": "Hardin County",
                "cities": [
                    "Crump",
                    "Saltillo",
                    "Savannah"
                ]
            },
            {
                "countyName": "Hawkins County",
                "cities": [
                    "Bulls Gap",
                    "Church Hill",
                    "Mount Carmel",
                    "Rogersville",
                    "Surgoinsville"
                ]
            },
            {
                "countyName": "Haywood County",
                "cities": [
                    "Brownsville",
                    "Stanton"
                ]
            },
            {
                "countyName": "Henderson County",
                "cities": [
                    "Lexington",
                    "Parker's Crossroads",
                    "Sardis"
                ]
            },
            {
                "countyName": "Henderson County\nDecatur County",
                "cities": [
                    "Scotts Hill"
                ]
            },
            {
                "countyName": "Henry County",
                "cities": [
                    "Cottage Grove",
                    "Henry",
                    "Paris",
                    "Puryear"
                ]
            },
            {
                "countyName": "Hickman County",
                "cities": [
                    "Centerville"
                ]
            },
            {
                "countyName": "Houston County",
                "cities": [
                    "Erin"
                ]
            },
            {
                "countyName": "Houston County\nStewart County",
                "cities": [
                    "Tennessee Ridge"
                ]
            },
            {
                "countyName": "Humphreys County",
                "cities": [
                    "McEwen",
                    "New Johnsonville",
                    "Waverly"
                ]
            },
            {
                "countyName": "Jackson County",
                "cities": [
                    "Gainesboro"
                ]
            },
            {
                "countyName": "Jefferson County",
                "cities": [
                    "Baneberry",
                    "Dandridge",
                    "Jefferson City",
                    "New Market",
                    "White Pine"
                ]
            },
            {
                "countyName": "Johnson County",
                "cities": [
                    "Mountain City"
                ]
            },
            {
                "countyName": "Knox County",
                "cities": [
                    "Farragut",
                    "Knoxville"
                ]
            },
            {
                "countyName": "Lake County",
                "cities": [
                    "Ridgely",
                    "Tiptonville"
                ]
            },
            {
                "countyName": "Lauderdale County",
                "cities": [
                    "Gates",
                    "Halls",
                    "Henning",
                    "Ripley"
                ]
            },
            {
                "countyName": "Lawrence County",
                "cities": [
                    "Ethridge",
                    "Lawrenceburg",
                    "Loretto",
                    "St. Joseph"
                ]
            },
            {
                "countyName": "Lewis County",
                "cities": [
                    "Hohenwald"
                ]
            },
            {
                "countyName": "Lincoln County",
                "cities": [
                    "Fayetteville"
                ]
            },
            {
                "countyName": "Lincoln County\nMarshall County",
                "cities": [
                    "Petersburg"
                ]
            },
            {
                "countyName": "Loudon County",
                "cities": [
                    "Greenback",
                    "Lenoir City",
                    "Loudon",
                    "Philadelphia"
                ]
            },
            {
                "countyName": "Macon County",
                "cities": [
                    "Lafayette",
                    "Red Boiling Springs"
                ]
            },
            {
                "countyName": "Madison County",
                "cities": [
                    "Jackson",
                    "Medon",
                    "Three Way"
                ]
            },
            {
                "countyName": "Marion County",
                "cities": [
                    "Jasper",
                    "Kimball",
                    "New Hope",
                    "Orme",
                    "Powell's Crossroads",
                    "South Pittsburg",
                    "Whitwell"
                ]
            },
            {
                "countyName": "Marshall County",
                "cities": [
                    "Chapel Hill",
                    "Cornersville",
                    "Lewisburg"
                ]
            },
            {
                "countyName": "Maury County",
                "cities": [
                    "Columbia",
                    "Mount Pleasant"
                ]
            },
            {
                "countyName": "Maury County\nWilliamson County",
                "cities": [
                    "Spring Hill"
                ]
            },
            {
                "countyName": "McMinn County",
                "cities": [
                    "Athens",
                    "Calhoun",
                    "Englewood",
                    "Etowah",
                    "Niota"
                ]
            },
            {
                "countyName": "McNairy County",
                "cities": [
                    "Bethel Springs",
                    "Eastview",
                    "Finger",
                    "Guys",
                    "Michie",
                    "Ramer",
                    "Selmer",
                    "Stantonville"
                ]
            },
            {
                "countyName": "McNairy County\nChester County\nHardin County",
                "cities": [
                    "Milledgeville"
                ]
            },
            {
                "countyName": "McNairy County\nHardin County",
                "cities": [
                    "Adamsville"
                ]
            },
            {
                "countyName": "Meigs County",
                "cities": [
                    "Decatur"
                ]
            },
            {
                "countyName": "Monroe County",
                "cities": [
                    "Madisonville",
                    "Tellico Plains",
                    "Vonore"
                ]
            },
            {
                "countyName": "Monroe County\nMcMinn County",
                "cities": [
                    "Sweetwater"
                ]
            },
            {
                "countyName": "Montgomery County",
                "cities": [
                    "Clarksville"
                ]
            },
            {
                "countyName": "Moore County",
                "cities": [
                    "Lynchburg"
                ]
            },
            {
                "countyName": "Morgan County",
                "cities": [
                    "Oakdale",
                    "Sunbright",
                    "Wartburg"
                ]
            },
            {
                "countyName": "Obion County",
                "cities": [
                    "Hornbeak",
                    "Obion",
                    "Rives",
                    "Samburg",
                    "South Fulton",
                    "Troy",
                    "Union City",
                    "Woodland Mills"
                ]
            },
            {
                "countyName": "Overton County",
                "cities": [
                    "Livingston"
                ]
            },
            {
                "countyName": "Perry County",
                "cities": [
                    "Linden",
                    "Lobelville"
                ]
            },
            {
                "countyName": "Pickett County",
                "cities": [
                    "Byrdstown"
                ]
            },
            {
                "countyName": "Polk County",
                "cities": [
                    "Benton",
                    "Copperhill",
                    "Ducktown"
                ]
            },
            {
                "countyName": "Putnam County",
                "cities": [
                    "Algood",
                    "Baxter",
                    "Cookeville",
                    "Monterey"
                ]
            },
            {
                "countyName": "Rhea County",
                "cities": [
                    "Dayton",
                    "Graysville",
                    "Spring City"
                ]
            },
            {
                "countyName": "Roane County",
                "cities": [
                    "Harriman",
                    "Kingston",
                    "Rockwood"
                ]
            },
            {
                "countyName": "Robertson County",
                "cities": [
                    "Adams",
                    "Cedar Hill",
                    "Coopertown",
                    "Cross Plains",
                    "Greenbrier",
                    "Orlinda",
                    "Springfield"
                ]
            },
            {
                "countyName": "Robertson County\nDavidson County",
                "cities": [
                    "Ridgetop"
                ]
            },
            {
                "countyName": "Rutherford County",
                "cities": [
                    "Eagleville",
                    "La Vergne",
                    "Murfreesboro",
                    "Smyrna"
                ]
            },
            {
                "countyName": "Scott County",
                "cities": [
                    "Huntsville",
                    "Oneida",
                    "Winfield"
                ]
            },
            {
                "countyName": "Sequatchie County",
                "cities": [
                    "Dunlap"
                ]
            },
            {
                "countyName": "Sevier County",
                "cities": [
                    "Gatlinburg",
                    "Pigeon Forge",
                    "Pittman Center",
                    "Sevierville"
                ]
            },
            {
                "countyName": "Shelby County",
                "cities": [
                    "Arlington",
                    "Bartlett",
                    "Collierville",
                    "Germantown",
                    "Lakeland",
                    "Memphis",
                    "Millington"
                ]
            },
            {
                "countyName": "Smith County",
                "cities": [
                    "Carthage",
                    "Gordonsville",
                    "South Carthage"
                ]
            },
            {
                "countyName": "Stewart County",
                "cities": [
                    "Cumberland City",
                    "Dover"
                ]
            },
            {
                "countyName": "Sullivan County",
                "cities": [
                    "Bluff City",
                    "Bristol"
                ]
            },
            {
                "countyName": "Sullivan County\nHawkins County",
                "cities": [
                    "Kingsport"
                ]
            },
            {
                "countyName": "Sumner County",
                "cities": [
                    "Gallatin",
                    "Hendersonville",
                    "Mitchellville",
                    "Portland",
                    "Westmoreland"
                ]
            },
            {
                "countyName": "Sumner County\nRobertson County",
                "cities": [
                    "Millersville",
                    "White House"
                ]
            },
            {
                "countyName": "Tipton County",
                "cities": [
                    "Atoka",
                    "Brighton",
                    "Burlison",
                    "Covington",
                    "Garland",
                    "Gilt Edge",
                    "Mason",
                    "Munford"
                ]
            },
            {
                "countyName": "Trousdale County",
                "cities": [
                    "Hartsville"
                ]
            },
            {
                "countyName": "Unicoi County",
                "cities": [
                    "Erwin",
                    "Unicoi"
                ]
            },
            {
                "countyName": "Union County",
                "cities": [
                    "Luttrell",
                    "Maynardville",
                    "Plainview"
                ]
            },
            {
                "countyName": "Van Buren County",
                "cities": [
                    "Spencer"
                ]
            },
            {
                "countyName": "Warren County",
                "cities": [
                    "Centertown",
                    "McMinnville",
                    "Morrison",
                    "Viola"
                ]
            },
            {
                "countyName": "Washington County",
                "cities": [
                    "Jonesborough"
                ]
            },
            {
                "countyName": "Washington County\nCarter County\nSullivan County",
                "cities": [
                    "Johnson City"
                ]
            },
            {
                "countyName": "Wayne County",
                "cities": [
                    "Clifton",
                    "Collinwood",
                    "Waynesboro"
                ]
            },
            {
                "countyName": "Weakley County",
                "cities": [
                    "Dresden",
                    "Gleason",
                    "Greenfield",
                    "Martin",
                    "Sharon"
                ]
            },
            {
                "countyName": "White County",
                "cities": [
                    "Doyle",
                    "Sparta"
                ]
            },
            {
                "countyName": "Williamson County",
                "cities": [
                    "Brentwood",
                    "Fairview",
                    "Franklin",
                    "Nolensville",
                    "Thompson's Station"
                ]
            },
            {
                "countyName": "Wilson County",
                "cities": [
                    "Lebanon",
                    "Mount Juliet",
                    "Watertown"
                ]
            }
        ]
    },
    {
        "stateId": "TX",
        "stateName": "Texas",
        "county": [
            {
                "countyName": "Anderson",
                "cities": [
                    "Palestine",
                    "Elkhart",
                    "Frankston"
                ]
            },
            {
                "countyName": "Andrews",
                "cities": [
                    "Andrews"
                ]
            },
            {
                "countyName": "Angelina",
                "cities": [
                    "Lufkin",
                    "Hudson",
                    "Diboll",
                    "Huntington",
                    "Burke",
                    "Zavalla"
                ]
            },
            {
                "countyName": "Aransas",
                "cities": [
                    "Rockport",
                    "Fulton"
                ]
            },
            {
                "countyName": "Archer",
                "cities": [
                    "Archer City",
                    "Holliday",
                    "Lakeside City",
                    "Scotland",
                    "Windthorst",
                    "Megargel"
                ]
            },
            {
                "countyName": "Armstrong",
                "cities": [
                    "Claude"
                ]
            },
            {
                "countyName": "Atascosa",
                "cities": [
                    "Pleasanton",
                    "Jourdanton",
                    "Lytle",
                    "Poteet",
                    "Charlotte",
                    "Christine"
                ]
            },
            {
                "countyName": "Austin",
                "cities": [
                    "Sealy",
                    "Bellville",
                    "Wallis",
                    "San Felipe",
                    "Brazos Country",
                    "Industry",
                    "South Frydek[b]"
                ]
            },
            {
                "countyName": "Bailey",
                "cities": [
                    "Muleshoe"
                ]
            },
            {
                "countyName": "Bandera",
                "cities": [
                    "Bandera"
                ]
            },
            {
                "countyName": "Bastrop",
                "cities": [
                    "Elgin",
                    "Bastrop",
                    "Smithville"
                ]
            },
            {
                "countyName": "Baylor",
                "cities": [
                    "Seymour"
                ]
            },
            {
                "countyName": "Bee",
                "cities": [
                    "Beeville"
                ]
            },
            {
                "countyName": "Bell",
                "cities": [
                    "Killeen",
                    "Temple",
                    "Harker Heights",
                    "Belton",
                    "Nolanville",
                    "Morgan's Point Resort",
                    "Troy",
                    "Salado",
                    "Little River-Academy",
                    "Rogers",
                    "Holland"
                ]
            },
            {
                "countyName": "Bexar",
                "cities": [
                    "San Antonio",
                    "Converse",
                    "Universal City",
                    "Live Oak",
                    "Leon Valley",
                    "Fair Oaks Ranch",
                    "Helotes",
                    "Kirby",
                    "Alamo Heights",
                    "Windcrest",
                    "Sandy Oaks[b]",
                    "Terrell Hills",
                    "Castle Hills",
                    "Shavano Park",
                    "Hollywood Park",
                    "Balcones Heights",
                    "St. Hedwig",
                    "Elmendorf",
                    "Olmos Park",
                    "Somerset",
                    "Von Ormy",
                    "China Grove",
                    "Hill Country Village",
                    "Grey Forest"
                ]
            },
            {
                "countyName": "Blanco",
                "cities": [
                    "Blanco",
                    "Johnson City",
                    "Round Mountain"
                ]
            },
            {
                "countyName": "Bosque",
                "cities": [
                    "Clifton",
                    "Meridian",
                    "Valley Mills",
                    "Walnut Springs",
                    "Morgan",
                    "Iredell",
                    "Cranfills Gap"
                ]
            },
            {
                "countyName": "Bowie",
                "cities": [
                    "Texarkana",
                    "Wake Village",
                    "New Boston",
                    "Nash",
                    "Hooks",
                    "De Kalb",
                    "Maud",
                    "Red Lick",
                    "Redwater",
                    "Leary"
                ]
            },
            {
                "countyName": "Brazoria",
                "cities": [
                    "Pearland",
                    "Alvin",
                    "Lake Jackson",
                    "Angleton",
                    "Manvel",
                    "Iowa Colony",
                    "Clute",
                    "Freeport",
                    "Richwood",
                    "West Columbia",
                    "Sweeny",
                    "Brazoria",
                    "Jones Creek",
                    "Danbury",
                    "Brookside Village",
                    "Oyster Creek",
                    "Holiday Lakes",
                    "Bailey's Prairie",
                    "Hillcrest",
                    "Surfside Beach",
                    "Liverpool",
                    "Sandy Point[b]",
                    "Bonney",
                    "Quintana"
                ]
            },
            {
                "countyName": "Brazos",
                "cities": [
                    "College Station",
                    "Bryan",
                    "Kurten",
                    "Wixon Valley"
                ]
            },
            {
                "countyName": "Brewster",
                "cities": [
                    "Alpine"
                ]
            },
            {
                "countyName": "Briscoe",
                "cities": [
                    "Silverton",
                    "Quitaque"
                ]
            },
            {
                "countyName": "Brooks",
                "cities": [
                    "Falfurrias"
                ]
            },
            {
                "countyName": "Brown",
                "cities": [
                    "Brownwood",
                    "Early",
                    "Bangs",
                    "Blanket"
                ]
            },
            {
                "countyName": "Burleson",
                "cities": [
                    "Caldwell",
                    "Somerville",
                    "Snook"
                ]
            },
            {
                "countyName": "Burnet",
                "cities": [
                    "Marble Falls",
                    "Burnet",
                    "Granite Shoals",
                    "Horseshoe Bay",
                    "Bertram",
                    "Meadowlakes",
                    "Cottonwood Shores",
                    "Highland Haven"
                ]
            },
            {
                "countyName": "Caldwell",
                "cities": [
                    "Lockhart",
                    "Luling",
                    "Martindale"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Port Lavaca",
                    "Seadrift",
                    "Point Comfort"
                ]
            },
            {
                "countyName": "Callahan",
                "cities": [
                    "Clyde",
                    "Baird",
                    "Cross Plains",
                    "Putnam"
                ]
            },
            {
                "countyName": "Cameron",
                "cities": [
                    "Brownsville",
                    "Harlingen",
                    "San Benito",
                    "Los Fresnos",
                    "La Feria",
                    "Primera",
                    "Port Isabel",
                    "Laguna Vista",
                    "Combes",
                    "Rancho Viejo",
                    "Santa Rosa",
                    "Rio Hondo",
                    "South Padre Island",
                    "Palm Valley",
                    "Los Indios",
                    "Indian Lake",
                    "Bayview"
                ]
            },
            {
                "countyName": "Camp",
                "cities": [
                    "Pittsburg",
                    "Rocky Mound"
                ]
            },
            {
                "countyName": "Carson",
                "cities": [
                    "Panhandle",
                    "White Deer",
                    "Groom",
                    "Skellytown"
                ]
            },
            {
                "countyName": "Cass",
                "cities": [
                    "Atlanta",
                    "Linden",
                    "Hughes Springs",
                    "Queen City",
                    "Avinger",
                    "Bloomburg",
                    "Douglassville",
                    "Marietta",
                    "Domino"
                ]
            },
            {
                "countyName": "Castro",
                "cities": [
                    "Dimmitt",
                    "Hart",
                    "Nazareth"
                ]
            },
            {
                "countyName": "Chambers",
                "cities": [
                    "Mont Belvieu",
                    "Beach City",
                    "Anahuac",
                    "Old River-Winfree",
                    "Cove"
                ]
            },
            {
                "countyName": "Cherokee",
                "cities": [
                    "Jacksonville",
                    "Rusk",
                    "Alto",
                    "Wells",
                    "New Summerfield",
                    "Reklaw",
                    "Gallatin",
                    "Cuney"
                ]
            },
            {
                "countyName": "Childress",
                "cities": [
                    "Childress"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Henrietta",
                    "Petrolia",
                    "Dean",
                    "Byers",
                    "Bellevue",
                    "Jolly"
                ]
            },
            {
                "countyName": "Cochran",
                "cities": [
                    "Morton",
                    "Whiteface"
                ]
            },
            {
                "countyName": "Coke",
                "cities": [
                    "Robert Lee",
                    "Bronte"
                ]
            },
            {
                "countyName": "Coleman",
                "cities": [
                    "Coleman",
                    "Santa Anna",
                    "Novice"
                ]
            },
            {
                "countyName": "Collin",
                "cities": [
                    "Plano",
                    "Frisco",
                    "McKinney",
                    "Allen",
                    "Wylie",
                    "Prosper",
                    "Celina",
                    "Anna",
                    "Princeton",
                    "Murphy",
                    "Melissa",
                    "Fairview",
                    "Lucas",
                    "Josephine",
                    "Lavon",
                    "Parker",
                    "Farmersville",
                    "Lowry Crossing",
                    "Nevada",
                    "Blue Ridge",
                    "St. Paul",
                    "New Hope",
                    "Weston"
                ]
            },
            {
                "countyName": "Collingsworth",
                "cities": [
                    "Wellington",
                    "Dodson"
                ]
            },
            {
                "countyName": "Colorado",
                "cities": [
                    "Columbus",
                    "Eagle Lake",
                    "Weimar"
                ]
            },
            {
                "countyName": "Comal",
                "cities": [
                    "New Braunfels",
                    "Bulverde",
                    "Garden Ridge",
                    "Spring Branch[b]"
                ]
            },
            {
                "countyName": "Comanche",
                "cities": [
                    "Comanche",
                    "De Leon",
                    "Gustine"
                ]
            },
            {
                "countyName": "Concho",
                "cities": [
                    "Eden",
                    "Paint Rock"
                ]
            },
            {
                "countyName": "Cooke",
                "cities": [
                    "Gainesville",
                    "Muenster",
                    "Lindsay",
                    "Road Runner[b]",
                    "Valley View",
                    "Callisburg",
                    "Oak Ridge"
                ]
            },
            {
                "countyName": "Coryell",
                "cities": [
                    "Copperas Cove",
                    "Gatesville",
                    "Evant",
                    "Oglesby",
                    "South Mountain"
                ]
            },
            {
                "countyName": "Cottle",
                "cities": [
                    "Paducah"
                ]
            },
            {
                "countyName": "Crane",
                "cities": [
                    "Crane"
                ]
            },
            {
                "countyName": "Crosby",
                "cities": [
                    "Ralls",
                    "Crosbyton",
                    "Lorenzo"
                ]
            },
            {
                "countyName": "Culberson",
                "cities": [
                    "Van Horn"
                ]
            },
            {
                "countyName": "Dallam",
                "cities": [
                    "Dalhart",
                    "Texline"
                ]
            },
            {
                "countyName": "Dallas",
                "cities": [
                    "Dallas",
                    "Irving",
                    "Garland",
                    "Grand Prairie",
                    "Mesquite",
                    "Carrollton",
                    "Richardson",
                    "Rowlett",
                    "DeSoto",
                    "Cedar Hill",
                    "Coppell",
                    "Lancaster",
                    "Duncanville",
                    "Farmers Branch",
                    "Sachse",
                    "Balch Springs",
                    "University Park",
                    "Seagoville",
                    "Glenn Heights",
                    "Addison",
                    "Highland Park",
                    "Sunnyvale",
                    "Wilmer",
                    "Hutchins",
                    "Cockrell Hill"
                ]
            },
            {
                "countyName": "Dawson",
                "cities": [
                    "Lamesa",
                    "Ackerly",
                    "Los Ybanez"
                ]
            },
            {
                "countyName": "Deaf Smith",
                "cities": [
                    "Hereford"
                ]
            },
            {
                "countyName": "Delta",
                "cities": [
                    "Cooper",
                    "Pecan Gap"
                ]
            },
            {
                "countyName": "Denton",
                "cities": [
                    "Denton",
                    "Lewisville",
                    "Flower Mound",
                    "Little Elm",
                    "The Colony",
                    "Corinth",
                    "Highland Village",
                    "Trophy Club",
                    "Roanoke",
                    "Sanger",
                    "Northlake",
                    "Providence Village[b]",
                    "Aubrey",
                    "Lake Dallas",
                    "Krum",
                    "Oak Point",
                    "Justin",
                    "Argyle",
                    "Hickory Creek",
                    "Pilot Point",
                    "Double Oak",
                    "Hackberry",
                    "Shady Shores",
                    "Ponder",
                    "Copper Canyon",
                    "Krugerville",
                    "Cross Roads",
                    "Bartonville",
                    "Lakewood Village",
                    "DISH",
                    "Hebron",
                    "Draper"
                ]
            },
            {
                "countyName": "DeWitt",
                "cities": [
                    "Cuero",
                    "Yorktown",
                    "Nordheim"
                ]
            },
            {
                "countyName": "Dickens",
                "cities": [
                    "Spur",
                    "Dickens"
                ]
            },
            {
                "countyName": "Dimmit",
                "cities": [
                    "Carrizo Springs",
                    "Asherton",
                    "Big Wells"
                ]
            },
            {
                "countyName": "Donley",
                "cities": [
                    "Clarendon",
                    "Howardwick",
                    "Hedley"
                ]
            },
            {
                "countyName": "Duval",
                "cities": [
                    "San Diego",
                    "Freer",
                    "Benavides"
                ]
            },
            {
                "countyName": "Eastland",
                "cities": [
                    "Cisco",
                    "Eastland",
                    "Ranger",
                    "Gorman",
                    "Rising Star",
                    "Carbon"
                ]
            },
            {
                "countyName": "Ector",
                "cities": [
                    "Odessa",
                    "Goldsmith"
                ]
            },
            {
                "countyName": "Edwards",
                "cities": [
                    "Rocksprings"
                ]
            },
            {
                "countyName": "El Paso",
                "cities": [
                    "El Paso",
                    "Socorro",
                    "Horizon City",
                    "San Elizario[b]",
                    "Anthony",
                    "Vinton",
                    "Clint"
                ]
            },
            {
                "countyName": "Ellis",
                "cities": [
                    "Waxahachie",
                    "Midlothian",
                    "Ennis",
                    "Red Oak",
                    "Ovilla",
                    "Ferris",
                    "Palmer",
                    "Italy",
                    "Oak Leaf",
                    "Maypearl",
                    "Garrett",
                    "Milford",
                    "Pecan Hill",
                    "Bardwell",
                    "Alma"
                ]
            },
            {
                "countyName": "Erath",
                "cities": [
                    "Stephenville",
                    "Dublin"
                ]
            },
            {
                "countyName": "Falls",
                "cities": [
                    "Marlin",
                    "Rosebud",
                    "Golinda",
                    "Lott"
                ]
            },
            {
                "countyName": "Fannin",
                "cities": [
                    "Bonham",
                    "Leonard",
                    "Honey Grove",
                    "Trenton",
                    "Ector",
                    "Savoy",
                    "Ladonia",
                    "Dodd City",
                    "Bailey",
                    "Windom",
                    "Ravenna"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "La Grange",
                    "Schulenburg",
                    "Flatonia",
                    "Ellinger[a]",
                    "Fayetteville",
                    "Carmine",
                    "Round Top"
                ]
            },
            {
                "countyName": "Fisher",
                "cities": [
                    "Rotan",
                    "Roby"
                ]
            },
            {
                "countyName": "Floyd",
                "cities": [
                    "Floydada",
                    "Lockney"
                ]
            },
            {
                "countyName": "Foard",
                "cities": [
                    "Crowell"
                ]
            },
            {
                "countyName": "Fort Bend",
                "cities": [
                    "Sugar Land",
                    "Missouri City",
                    "Rosenberg",
                    "Fulshear",
                    "Stafford",
                    "Richmond",
                    "Meadows Place",
                    "Weston Lakes",
                    "Needville",
                    "Arcola",
                    "Pleak",
                    "Fairchilds",
                    "Simonton",
                    "Beasley",
                    "Kendleton",
                    "Orchard",
                    "Thompsons"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Mount Vernon"
                ]
            },
            {
                "countyName": "Freestone",
                "cities": [
                    "Teague",
                    "Fairfield",
                    "Streetman",
                    "Kirvin"
                ]
            },
            {
                "countyName": "Frio",
                "cities": [
                    "Pearsall",
                    "Dilley"
                ]
            },
            {
                "countyName": "Gaines",
                "cities": [
                    "Seminole",
                    "Seagraves"
                ]
            },
            {
                "countyName": "Galveston",
                "cities": [
                    "League City",
                    "Texas City",
                    "Galveston",
                    "Friendswood",
                    "Dickinson",
                    "La Marque",
                    "Santa Fe",
                    "Hitchcock",
                    "Kemah",
                    "Bayou Vista",
                    "Clear Lake Shores",
                    "Tiki Island",
                    "Jamaica Beach"
                ]
            },
            {
                "countyName": "Garza",
                "cities": [
                    "Post"
                ]
            },
            {
                "countyName": "Gillespie",
                "cities": [
                    "Fredericksburg"
                ]
            },
            {
                "countyName": "Goliad",
                "cities": [
                    "Goliad"
                ]
            },
            {
                "countyName": "Gonzales",
                "cities": [
                    "Gonzales",
                    "Nixon",
                    "Waelder",
                    "Smiley"
                ]
            },
            {
                "countyName": "Gray",
                "cities": [
                    "Pampa",
                    "McLean",
                    "Lefors"
                ]
            },
            {
                "countyName": "Grayson",
                "cities": [
                    "Sherman",
                    "Denison",
                    "Van Alstyne",
                    "Whitesboro",
                    "Howe",
                    "Pottsboro",
                    "Gunter",
                    "Collinsville",
                    "Whitewright",
                    "Bells",
                    "Tioga",
                    "Southmayd",
                    "Tom Bean",
                    "Knollwood",
                    "Sadler",
                    "Dorchester"
                ]
            },
            {
                "countyName": "Gregg",
                "cities": [
                    "Longview",
                    "Kilgore",
                    "Gladewater",
                    "White Oak",
                    "Lakeport",
                    "Clarksville City",
                    "Easton",
                    "Warren City"
                ]
            },
            {
                "countyName": "Grimes",
                "cities": [
                    "Navasota",
                    "Plantersville[b]",
                    "Bedias",
                    "Iola",
                    "Anderson",
                    "Todd Mission"
                ]
            },
            {
                "countyName": "Guadalupe",
                "cities": [
                    "Schertz",
                    "Cibolo",
                    "Seguin",
                    "Selma",
                    "Marion",
                    "Santa Clara",
                    "New Berlin",
                    "Staples",
                    "Kingsbury[b]"
                ]
            },
            {
                "countyName": "Hale",
                "cities": [
                    "Plainview",
                    "Abernathy",
                    "Hale Center",
                    "Petersburg",
                    "Edmonson"
                ]
            },
            {
                "countyName": "Hall",
                "cities": [
                    "Memphis",
                    "Turkey",
                    "Estelline",
                    "Lakeview"
                ]
            },
            {
                "countyName": "Hamilton",
                "cities": [
                    "Hamilton",
                    "Hico"
                ]
            },
            {
                "countyName": "Hansford",
                "cities": [
                    "Spearman",
                    "Gruver"
                ]
            },
            {
                "countyName": "Hardeman",
                "cities": [
                    "Quanah",
                    "Chillicothe"
                ]
            },
            {
                "countyName": "Hardin",
                "cities": [
                    "Lumberton",
                    "Silsbee",
                    "Kountze",
                    "Sour Lake",
                    "Rose Hill Acres"
                ]
            },
            {
                "countyName": "Harris",
                "cities": [
                    "Houston",
                    "Pasadena",
                    "Baytown",
                    "La Porte",
                    "Deer Park",
                    "Katy",
                    "Bellaire",
                    "Humble",
                    "South Houston",
                    "West University Place",
                    "Seabrook",
                    "Tomball",
                    "Webster",
                    "Galena Park",
                    "Jacinto City",
                    "Jersey Village",
                    "Nassau Bay",
                    "Hunters Creek Village",
                    "Spring Valley Village",
                    "Bunker Hill Village",
                    "Taylor Lake Village",
                    "Piney Point Village",
                    "El Lago",
                    "Hedwig Village",
                    "Southside Place",
                    "Shoreacres",
                    "Hilshire Village",
                    "Morgan's Point"
                ]
            },
            {
                "countyName": "Harrison",
                "cities": [
                    "Marshall",
                    "Hallsville",
                    "Waskom",
                    "Scottsville",
                    "Uncertain"
                ]
            },
            {
                "countyName": "Hartley",
                "cities": [
                    "Channing"
                ]
            },
            {
                "countyName": "Haskell",
                "cities": [
                    "Haskell",
                    "Stamford",
                    "Rule",
                    "Rochester",
                    "Weinert",
                    "O'Brien"
                ]
            },
            {
                "countyName": "Hays",
                "cities": [
                    "San Marcos",
                    "Kyle",
                    "Buda",
                    "Dripping Springs",
                    "Wimberley",
                    "Woodcreek",
                    "Uhland",
                    "Mountain City",
                    "Niederwald",
                    "Bear Creek",
                    "Hays"
                ]
            },
            {
                "countyName": "Hemphill",
                "cities": [
                    "Canadian"
                ]
            },
            {
                "countyName": "Henderson",
                "cities": [
                    "Athens",
                    "Gun Barrel City",
                    "Chandler",
                    "Malakoff",
                    "Tool",
                    "Seven Points",
                    "Brownsboro",
                    "Eustace",
                    "Berryville",
                    "Trinidad",
                    "Log Cabin",
                    "Payne Springs",
                    "Murchison",
                    "Star Harbor",
                    "Enchanted Oaks",
                    "Poynor",
                    "Coffee City",
                    "Caney City",
                    "Moore Station"
                ]
            },
            {
                "countyName": "Hidalgo",
                "cities": [
                    "McAllen",
                    "Edinburg",
                    "Mission",
                    "Pharr",
                    "Weslaco",
                    "San Juan",
                    "Alamo",
                    "Alton",
                    "Donna",
                    "Mercedes",
                    "Palmview",
                    "Hidalgo",
                    "Peñitas",
                    "Elsa",
                    "Progreso",
                    "La Joya",
                    "Sullivan City",
                    "La Villa",
                    "Edcouch",
                    "Palmhurst",
                    "Granjeno",
                    "Progreso Lakes"
                ]
            },
            {
                "countyName": "Hill",
                "cities": [
                    "Hillsboro",
                    "Whitney",
                    "Itasca",
                    "Hubbard",
                    "Blum",
                    "Abbott",
                    "Mount Calm",
                    "Covington",
                    "Malone",
                    "Carl's Corner",
                    "Penelope",
                    "Bynum",
                    "Mertens",
                    "Aquilla"
                ]
            },
            {
                "countyName": "Hockley",
                "cities": [
                    "Levelland",
                    "Sundown",
                    "Anton",
                    "Smyer",
                    "Ropesville",
                    "Opdyke West"
                ]
            },
            {
                "countyName": "Hood",
                "cities": [
                    "Granbury",
                    "DeCordova",
                    "Tolar",
                    "Lipan",
                    "Stockton Bend"
                ]
            },
            {
                "countyName": "Hopkins",
                "cities": [
                    "Sulphur Springs",
                    "Como",
                    "Cumby",
                    "Tira"
                ]
            },
            {
                "countyName": "Houston",
                "cities": [
                    "Crockett",
                    "Grapeland",
                    "Lovelady",
                    "Kennard",
                    "Latexo"
                ]
            },
            {
                "countyName": "Howard",
                "cities": [
                    "Big Spring",
                    "Coahoma",
                    "Forsan"
                ]
            },
            {
                "countyName": "Hudspeth",
                "cities": [
                    "Dell City"
                ]
            },
            {
                "countyName": "Hunt",
                "cities": [
                    "Greenville",
                    "Commerce",
                    "Caddo Mills",
                    "West Tawakoni",
                    "Quinlan",
                    "Wolfe City",
                    "Celeste",
                    "Lone Oak",
                    "Campbell",
                    "Hawk Cove",
                    "Union Valley",
                    "Neylandville"
                ]
            },
            {
                "countyName": "Hutchinson",
                "cities": [
                    "Borger",
                    "Fritch",
                    "Stinnett",
                    "Sanford"
                ]
            },
            {
                "countyName": "Irion",
                "cities": [
                    "Mertzon"
                ]
            },
            {
                "countyName": "Jack",
                "cities": [
                    "Jacksboro",
                    "Bryson"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Edna",
                    "Ganado",
                    "La Ward"
                ]
            },
            {
                "countyName": "Jasper",
                "cities": [
                    "Jasper",
                    "Kirbyville",
                    "Browndell"
                ]
            },
            {
                "countyName": "Jeff Davis",
                "cities": [
                    "Valentine"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Beaumont",
                    "Port Arthur",
                    "Nederland",
                    "Groves",
                    "Port Neches",
                    "China",
                    "Bevil Oaks",
                    "Nome",
                    "Taylor Landing"
                ]
            },
            {
                "countyName": "Jim Wells",
                "cities": [
                    "Alice",
                    "Premont",
                    "Orange Grove"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Burleson",
                    "Cleburne",
                    "Joshua",
                    "Venus",
                    "Keene",
                    "Alvarado",
                    "Godley",
                    "Grandview",
                    "Rio Vista",
                    "Briaroaks",
                    "Cross Timber",
                    "Coyote Flats[b]"
                ]
            },
            {
                "countyName": "Jones",
                "cities": [
                    "Anson",
                    "Hamlin",
                    "Hawley",
                    "Lueders"
                ]
            },
            {
                "countyName": "Karnes",
                "cities": [
                    "Kenedy",
                    "Karnes City",
                    "Runge",
                    "Falls City"
                ]
            },
            {
                "countyName": "Kaufman",
                "cities": [
                    "Forney",
                    "Terrell",
                    "Kaufman",
                    "Mabank",
                    "Crandall",
                    "Talty",
                    "Combine",
                    "Kemp",
                    "Oak Ridge",
                    "Post Oak Bend City",
                    "Scurry",
                    "Oak Grove",
                    "Grays Prairie",
                    "Rosser",
                    "Cottonwood"
                ]
            },
            {
                "countyName": "Kendall",
                "cities": [
                    "Boerne"
                ]
            },
            {
                "countyName": "Kent",
                "cities": [
                    "Jayton"
                ]
            },
            {
                "countyName": "Kerr",
                "cities": [
                    "Kerrville",
                    "Ingram"
                ]
            },
            {
                "countyName": "Kimble",
                "cities": [
                    "Junction"
                ]
            },
            {
                "countyName": "Kinney",
                "cities": [
                    "Brackettville",
                    "Spofford"
                ]
            },
            {
                "countyName": "Kleberg",
                "cities": [
                    "Kingsville"
                ]
            },
            {
                "countyName": "Knox",
                "cities": [
                    "Munday",
                    "Knox City",
                    "Benjamin",
                    "Goree"
                ]
            },
            {
                "countyName": "La Salle",
                "cities": [
                    "Cotulla",
                    "Encinal"
                ]
            },
            {
                "countyName": "Lamar",
                "cities": [
                    "Paris",
                    "Reno",
                    "Blossom",
                    "Roxton",
                    "Deport",
                    "Toco",
                    "Sun Valley"
                ]
            },
            {
                "countyName": "Lamb",
                "cities": [
                    "Littlefield",
                    "Olton",
                    "Sudan",
                    "Earth",
                    "Amherst",
                    "Springlake"
                ]
            },
            {
                "countyName": "Lampasas",
                "cities": [
                    "Lampasas",
                    "Kempner",
                    "Lometa"
                ]
            },
            {
                "countyName": "Lavaca",
                "cities": [
                    "Yoakum",
                    "Hallettsville",
                    "Shiner",
                    "Moulton"
                ]
            },
            {
                "countyName": "Lee",
                "cities": [
                    "Giddings",
                    "Lexington"
                ]
            },
            {
                "countyName": "Leon",
                "cities": [
                    "Buffalo",
                    "Centerville",
                    "Jewett",
                    "Normangee",
                    "Oakwood",
                    "Marquez",
                    "Leona"
                ]
            },
            {
                "countyName": "Liberty",
                "cities": [
                    "Dayton",
                    "Liberty",
                    "Cleveland",
                    "Plum Grove",
                    "Ames",
                    "Daisetta",
                    "Hardin",
                    "Kenefick",
                    "Devers",
                    "North Cleveland",
                    "Dayton Lakes"
                ]
            },
            {
                "countyName": "Limestone",
                "cities": [
                    "Mexia",
                    "Groesbeck",
                    "Coolidge",
                    "Kosse",
                    "Thornton",
                    "Tehuacana"
                ]
            },
            {
                "countyName": "Lipscomb",
                "cities": [
                    "Booker",
                    "Follett",
                    "Higgins",
                    "Darrouzett"
                ]
            },
            {
                "countyName": "Live Oak",
                "cities": [
                    "George West",
                    "Three Rivers"
                ]
            },
            {
                "countyName": "Llano",
                "cities": [
                    "Llano",
                    "Sunrise Beach Village"
                ]
            },
            {
                "countyName": "Lubbock",
                "cities": [
                    "Lubbock",
                    "Wolfforth",
                    "Slaton",
                    "Shallowater",
                    "Idalou",
                    "Ransom Canyon",
                    "New Deal",
                    "Buffalo Springs"
                ]
            },
            {
                "countyName": "Lynn",
                "cities": [
                    "Tahoka",
                    "O'Donnell",
                    "Wilson",
                    "New Home"
                ]
            },
            {
                "countyName": "Madison",
                "cities": [
                    "Madisonville",
                    "Midway"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Jefferson"
                ]
            },
            {
                "countyName": "Martin",
                "cities": [
                    "Stanton"
                ]
            },
            {
                "countyName": "Mason",
                "cities": [
                    "Mason"
                ]
            },
            {
                "countyName": "Matagorda",
                "cities": [
                    "Bay City",
                    "Palacios"
                ]
            },
            {
                "countyName": "Maverick",
                "cities": [
                    "Eagle Pass"
                ]
            },
            {
                "countyName": "McCulloch",
                "cities": [
                    "Brady",
                    "Melvin"
                ]
            },
            {
                "countyName": "McLennan",
                "cities": [
                    "Waco",
                    "Hewitt",
                    "Robinson",
                    "Bellmead",
                    "Woodway",
                    "Lacy-Lakeview",
                    "McGregor",
                    "West",
                    "Beverly Hills",
                    "Mart",
                    "Lorena",
                    "Moody",
                    "Bruceville-Eddy",
                    "Gholson",
                    "Riesel",
                    "Crawford",
                    "Hallsburg",
                    "Leroy",
                    "Ross"
                ]
            },
            {
                "countyName": "Medina",
                "cities": [
                    "Hondo",
                    "Devine",
                    "Castroville",
                    "Natalia",
                    "LaCoste"
                ]
            },
            {
                "countyName": "Menard",
                "cities": [
                    "Menard"
                ]
            },
            {
                "countyName": "Midland",
                "cities": [
                    "Midland"
                ]
            },
            {
                "countyName": "Milam",
                "cities": [
                    "Rockdale",
                    "Cameron",
                    "Thorndale",
                    "Milano",
                    "Buckholts"
                ]
            },
            {
                "countyName": "Mills",
                "cities": [
                    "Goldthwaite",
                    "Mullin"
                ]
            },
            {
                "countyName": "Mitchell",
                "cities": [
                    "Colorado City",
                    "Loraine",
                    "Westbrook"
                ]
            },
            {
                "countyName": "Montague",
                "cities": [
                    "Bowie",
                    "Nocona",
                    "St. Jo"
                ]
            },
            {
                "countyName": "Montgomery",
                "cities": [
                    "Conroe",
                    "Willis",
                    "Shenandoah",
                    "Magnolia",
                    "Oak Ridge North",
                    "Panorama Village",
                    "Montgomery",
                    "Roman Forest",
                    "Splendora",
                    "Patton Village",
                    "Woodbranch",
                    "Cut and Shoot",
                    "Stagecoach",
                    "Woodloch"
                ]
            },
            {
                "countyName": "Moore",
                "cities": [
                    "Dumas",
                    "Cactus",
                    "Sunray"
                ]
            },
            {
                "countyName": "Morris",
                "cities": [
                    "Daingerfield",
                    "Naples",
                    "Lone Star",
                    "Omaha"
                ]
            },
            {
                "countyName": "Motley",
                "cities": [
                    "Matador",
                    "Roaring Springs"
                ]
            },
            {
                "countyName": "Nacogdoches",
                "cities": [
                    "Nacogdoches",
                    "Garrison",
                    "Cushing",
                    "Appleby",
                    "Chireno"
                ]
            },
            {
                "countyName": "Navarro",
                "cities": [
                    "Corsicana",
                    "Kerens",
                    "Rice",
                    "Wortham",
                    "Blooming Grove",
                    "Dawson",
                    "Frost",
                    "Angus",
                    "Retreat",
                    "Mildred",
                    "Oak Valley",
                    "Eureka",
                    "Richland",
                    "Navarro",
                    "Barry",
                    "Emhouse",
                    "Goodlow",
                    "Powell",
                    "Mustang"
                ]
            },
            {
                "countyName": "Newton",
                "cities": [
                    "Newton"
                ]
            },
            {
                "countyName": "Nolan",
                "cities": [
                    "Sweetwater",
                    "Roscoe",
                    "Blackwell"
                ]
            },
            {
                "countyName": "Nueces",
                "cities": [
                    "Corpus Christi",
                    "Robstown",
                    "Aransas Pass",
                    "Port Aransas",
                    "Bishop",
                    "Agua Dulce",
                    "Driscoll",
                    "Petronila"
                ]
            },
            {
                "countyName": "Ochiltree",
                "cities": [
                    "Perryton"
                ]
            },
            {
                "countyName": "Oldham",
                "cities": [
                    "Vega",
                    "Adrian"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Orange",
                    "Vidor",
                    "Bridge City",
                    "West Orange",
                    "Pinehurst",
                    "Pine Forest",
                    "Rose City"
                ]
            },
            {
                "countyName": "Palo Pinto",
                "cities": [
                    "Mineral Wells",
                    "Graford",
                    "Strawn",
                    "Gordon",
                    "Mingus"
                ]
            },
            {
                "countyName": "Panola",
                "cities": [
                    "Carthage",
                    "Beckville",
                    "Gary City"
                ]
            },
            {
                "countyName": "Parker",
                "cities": [
                    "Weatherford",
                    "Aledo",
                    "Willow Park",
                    "Springtown",
                    "Reno",
                    "Annetta",
                    "Hudson Oaks",
                    "Cresson",
                    "Annetta South",
                    "Annetta North",
                    "Millsap",
                    "Sanctuary",
                    "Cool"
                ]
            },
            {
                "countyName": "Parmer",
                "cities": [
                    "Friona",
                    "Bovina",
                    "Farwell"
                ]
            },
            {
                "countyName": "Pecos",
                "cities": [
                    "Fort Stockton",
                    "Iraan"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Livingston",
                    "Onalaska",
                    "Corrigan",
                    "Goodrich",
                    "Seven Oaks"
                ]
            },
            {
                "countyName": "Potter",
                "cities": [
                    "Amarillo",
                    "Bishop Hills"
                ]
            },
            {
                "countyName": "Presidio",
                "cities": [
                    "Presidio",
                    "Marfa"
                ]
            },
            {
                "countyName": "Rains",
                "cities": [
                    "Emory",
                    "East Tawakoni",
                    "Point"
                ]
            },
            {
                "countyName": "Randall",
                "cities": [
                    "Canyon",
                    "Lake Tanglewood",
                    "Timbercreek Canyon",
                    "Palisades"
                ]
            },
            {
                "countyName": "Reagan",
                "cities": [
                    "Big Lake"
                ]
            },
            {
                "countyName": "Real",
                "cities": [
                    "Camp Wood",
                    "Leakey"
                ]
            },
            {
                "countyName": "Red River",
                "cities": [
                    "Clarksville",
                    "Bogata",
                    "Detroit",
                    "Avery",
                    "Annona"
                ]
            },
            {
                "countyName": "Reeves",
                "cities": [
                    "Pecos",
                    "Balmorhea",
                    "Toyah"
                ]
            },
            {
                "countyName": "Refugio",
                "cities": [
                    "Refugio",
                    "Woodsboro",
                    "Bayside",
                    "Austwell"
                ]
            },
            {
                "countyName": "Roberts",
                "cities": [
                    "Miami"
                ]
            },
            {
                "countyName": "Robertson",
                "cities": [
                    "Hearne",
                    "Franklin",
                    "Calvert",
                    "Bremond"
                ]
            },
            {
                "countyName": "Rockwall",
                "cities": [
                    "Rockwall",
                    "Fate",
                    "Royse City",
                    "Heath",
                    "McLendon-Chisholm",
                    "Mobile City"
                ]
            },
            {
                "countyName": "Runnels",
                "cities": [
                    "Ballinger",
                    "Winters",
                    "Miles"
                ]
            },
            {
                "countyName": "Rusk",
                "cities": [
                    "Henderson",
                    "Overton",
                    "Tatum",
                    "New London",
                    "Mount Enterprise"
                ]
            },
            {
                "countyName": "Sabine",
                "cities": [
                    "Hemphill",
                    "Pineland"
                ]
            },
            {
                "countyName": "San Augustine",
                "cities": [
                    "San Augustine",
                    "Broaddus"
                ]
            },
            {
                "countyName": "San Jacinto",
                "cities": [
                    "Shepherd",
                    "Coldspring",
                    "Point Blank"
                ]
            },
            {
                "countyName": "San Patricio",
                "cities": [
                    "Portland",
                    "Ingleside",
                    "Sinton",
                    "Mathis",
                    "Taft",
                    "Odem",
                    "Gregory",
                    "Ingleside on the Bay",
                    "Lake City",
                    "San Patricio",
                    "Lakeside"
                ]
            },
            {
                "countyName": "San Saba",
                "cities": [
                    "San Saba",
                    "Richland Springs"
                ]
            },
            {
                "countyName": "Schleicher",
                "cities": [
                    "Eldorado"
                ]
            },
            {
                "countyName": "Scurry",
                "cities": [
                    "Snyder"
                ]
            },
            {
                "countyName": "Shackelford",
                "cities": [
                    "Albany",
                    "Moran"
                ]
            },
            {
                "countyName": "Shelby",
                "cities": [
                    "Center",
                    "Tenaha",
                    "Timpson",
                    "Joaquin",
                    "Huxley"
                ]
            },
            {
                "countyName": "Sherman",
                "cities": [
                    "Stratford",
                    "Texhoma"
                ]
            },
            {
                "countyName": "Smith",
                "cities": [
                    "Tyler",
                    "Whitehouse",
                    "Lindale",
                    "Bullard",
                    "Hideaway",
                    "Troup",
                    "Arp",
                    "Winona",
                    "New Chapel Hill",
                    "Noonday"
                ]
            },
            {
                "countyName": "Somervell",
                "cities": [
                    "Glen Rose"
                ]
            },
            {
                "countyName": "Starr",
                "cities": [
                    "Rio Grande City",
                    "Roma",
                    "Escobares",
                    "La Grulla"
                ]
            },
            {
                "countyName": "Stephens",
                "cities": [
                    "Breckenridge"
                ]
            },
            {
                "countyName": "Sterling",
                "cities": [
                    "Sterling City"
                ]
            },
            {
                "countyName": "Stonewall",
                "cities": [
                    "Aspermont"
                ]
            },
            {
                "countyName": "Sutton",
                "cities": [
                    "Sonora"
                ]
            },
            {
                "countyName": "Swisher",
                "cities": [
                    "Tulia",
                    "Happy",
                    "Kress"
                ]
            },
            {
                "countyName": "Tarrant",
                "cities": [
                    "Fort Worth",
                    "Arlington",
                    "Mansfield",
                    "North Richland Hills",
                    "Euless",
                    "Grapevine",
                    "Bedford",
                    "Haltom City",
                    "Keller",
                    "Hurst",
                    "Southlake",
                    "Colleyville",
                    "Saginaw",
                    "Benbrook",
                    "Watauga",
                    "Crowley",
                    "White Settlement",
                    "Azle",
                    "Forest Hill",
                    "Kennedale",
                    "Richland Hills",
                    "River Oaks",
                    "Everman",
                    "Sansom Park",
                    "Lake Worth",
                    "Edgecliff Village",
                    "Haslet",
                    "Westworth Village",
                    "Pantego",
                    "Pelican Bay",
                    "Blue Mound",
                    "Dalworthington Gardens",
                    "Westlake",
                    "Lakeside",
                    "Westover Hills"
                ]
            },
            {
                "countyName": "Taylor",
                "cities": [
                    "Abilene",
                    "Merkel",
                    "Tye",
                    "Tuscola",
                    "Buffalo Gap",
                    "Lawn",
                    "Trent",
                    "Impact"
                ]
            },
            {
                "countyName": "Terry",
                "cities": [
                    "Brownfield",
                    "Meadow",
                    "Wellman"
                ]
            },
            {
                "countyName": "Throckmorton",
                "cities": [
                    "Throckmorton",
                    "Woodson"
                ]
            },
            {
                "countyName": "Titus",
                "cities": [
                    "Mount Pleasant",
                    "Talco",
                    "Winfield",
                    "Miller's Cove"
                ]
            },
            {
                "countyName": "Tom Green",
                "cities": [
                    "San Angelo"
                ]
            },
            {
                "countyName": "Travis",
                "cities": [
                    "Austin",
                    "Pflugerville",
                    "Lakeway",
                    "Manor",
                    "Lago Vista",
                    "Bee Cave",
                    "West Lake Hills",
                    "Jonestown",
                    "The Hills",
                    "Briarcliff",
                    "Rollingwood",
                    "Point Venture",
                    "Mustang Ridge",
                    "Sunset Valley",
                    "Volente",
                    "San Leanna",
                    "Webberville",
                    "Creedmoor"
                ]
            },
            {
                "countyName": "Trinity",
                "cities": [
                    "Trinity",
                    "Groveton"
                ]
            },
            {
                "countyName": "Tyler",
                "cities": [
                    "Woodville",
                    "Ivanhoe",
                    "Colmesneil",
                    "Chester"
                ]
            },
            {
                "countyName": "Upshur",
                "cities": [
                    "Gilmer",
                    "Big Sandy",
                    "Ore City",
                    "East Mountain",
                    "Union Grove"
                ]
            },
            {
                "countyName": "Upton",
                "cities": [
                    "McCamey",
                    "Rankin"
                ]
            },
            {
                "countyName": "Uvalde",
                "cities": [
                    "Uvalde",
                    "Sabinal"
                ]
            },
            {
                "countyName": "Val Verde",
                "cities": [
                    "Del Rio"
                ]
            },
            {
                "countyName": "Van Zandt",
                "cities": [
                    "Canton",
                    "Wills Point",
                    "Grand Saline",
                    "Van",
                    "Edgewood",
                    "Fruitvale",
                    "Edom"
                ]
            },
            {
                "countyName": "Victoria",
                "cities": [
                    "Victoria"
                ]
            },
            {
                "countyName": "Walker",
                "cities": [
                    "Huntsville",
                    "New Waverly",
                    "Riverside"
                ]
            },
            {
                "countyName": "Waller",
                "cities": [
                    "Prairie View",
                    "Hempstead",
                    "Brookshire",
                    "Waller",
                    "Pine Island",
                    "Pattison"
                ]
            },
            {
                "countyName": "Ward",
                "cities": [
                    "Monahans",
                    "Thorntonville",
                    "Wickett",
                    "Grandfalls",
                    "Barstow",
                    "Pyote"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Brenham",
                    "Burton"
                ]
            },
            {
                "countyName": "Webb",
                "cities": [
                    "Laredo",
                    "Rio Bravo",
                    "El Cenizo"
                ]
            },
            {
                "countyName": "Wharton",
                "cities": [
                    "El Campo",
                    "Wharton",
                    "East Bernard"
                ]
            },
            {
                "countyName": "Wheeler",
                "cities": [
                    "Shamrock",
                    "Wheeler",
                    "Mobeetie"
                ]
            },
            {
                "countyName": "Wichita",
                "cities": [
                    "Wichita Falls",
                    "Burkburnett",
                    "Iowa Park",
                    "Electra",
                    "Pleasant Valley",
                    "Cashion Community"
                ]
            },
            {
                "countyName": "Wilbarger",
                "cities": [
                    "Vernon"
                ]
            },
            {
                "countyName": "Willacy",
                "cities": [
                    "Raymondville",
                    "Lyford",
                    "San Perlita"
                ]
            },
            {
                "countyName": "Williamson",
                "cities": [
                    "Round Rock",
                    "Georgetown",
                    "Cedar Park",
                    "Leander",
                    "Hutto",
                    "Taylor",
                    "Liberty Hill",
                    "Jarrell",
                    "Bartlett",
                    "Florence",
                    "Granger",
                    "Thrall",
                    "Weir",
                    "Coupland[b]"
                ]
            },
            {
                "countyName": "Wilson",
                "cities": [
                    "Floresville",
                    "Poth",
                    "Stockdale",
                    "La Vernia"
                ]
            },
            {
                "countyName": "Winkler",
                "cities": [
                    "Kermit",
                    "Wink"
                ]
            },
            {
                "countyName": "Wise",
                "cities": [
                    "Decatur",
                    "Bridgeport",
                    "Runaway Bay",
                    "Rhome",
                    "Boyd",
                    "New Fairview",
                    "Aurora",
                    "Alvord",
                    "Newark",
                    "Chico",
                    "Paradise",
                    "Lake Bridgeport"
                ]
            },
            {
                "countyName": "Wood",
                "cities": [
                    "Mineola",
                    "Winnsboro",
                    "Quitman",
                    "Hawkins",
                    "Alba",
                    "Yantis"
                ]
            },
            {
                "countyName": "Yoakum",
                "cities": [
                    "Denver City",
                    "Plains"
                ]
            },
            {
                "countyName": "Young",
                "cities": [
                    "Graham",
                    "Olney",
                    "Newcastle"
                ]
            },
            {
                "countyName": "Zavala",
                "cities": [
                    "Crystal City"
                ]
            }
        ]
    },
    {
        "stateId": "UT",
        "stateName": "Utah",
        "county": [
            {
                "countyName": "Beaver",
                "cities": [
                    "Beaver*",
                    "Milford",
                    "Minersville"
                ]
            },
            {
                "countyName": "Box Elder",
                "cities": [
                    "Bear River City",
                    "Brigham City*",
                    "Corinne",
                    "Deweyville",
                    "Elwood",
                    "Fielding",
                    "Garland",
                    "Honeyville",
                    "Howell",
                    "Mantua",
                    "Perry",
                    "Plymouth",
                    "Portage",
                    "Snowville",
                    "Tremonton",
                    "Willard"
                ]
            },
            {
                "countyName": "Cache",
                "cities": [
                    "Amalga",
                    "Clarkston",
                    "Cornish",
                    "Hyde Park",
                    "Hyrum",
                    "Lewiston",
                    "Logan*",
                    "Mendon",
                    "Millville",
                    "Newton",
                    "Nibley",
                    "North Logan",
                    "Paradise",
                    "Providence",
                    "Richmond",
                    "River Heights",
                    "Smithfield",
                    "Trenton",
                    "Wellsville"
                ]
            },
            {
                "countyName": "Carbon",
                "cities": [
                    "East Carbon",
                    "Helper",
                    "Price*",
                    "Scofield",
                    "Wellington"
                ]
            },
            {
                "countyName": "Daggett",
                "cities": [
                    "Dutch John",
                    "Manila*"
                ]
            },
            {
                "countyName": "Davis",
                "cities": [
                    "Bountiful",
                    "Centerville",
                    "Clearfield",
                    "Clinton",
                    "Farmington*",
                    "Fruit Heights",
                    "Kaysville",
                    "Layton",
                    "North Salt Lake",
                    "South Weber",
                    "Sunset",
                    "Syracuse",
                    "West Bountiful",
                    "West Point",
                    "Woods Cross"
                ]
            },
            {
                "countyName": "Duchesne",
                "cities": [
                    "Altamont",
                    "Duchesne*",
                    "Myton",
                    "Roosevelt",
                    "Tabiona"
                ]
            },
            {
                "countyName": "Emery",
                "cities": [
                    "Castle Dale*",
                    "Clawson",
                    "Cleveland",
                    "Elmo",
                    "Emery",
                    "Ferron",
                    "Green River",
                    "Huntington",
                    "Orangeville"
                ]
            },
            {
                "countyName": "Garfield",
                "cities": [
                    "Antimony",
                    "Boulder",
                    "Bryce Canyon City",
                    "Cannonville",
                    "Escalante",
                    "Hatch",
                    "Henrieville",
                    "Panguitch*",
                    "Tropic"
                ]
            },
            {
                "countyName": "Grand",
                "cities": [
                    "Castle Valley",
                    "Moab*"
                ]
            },
            {
                "countyName": "Iron",
                "cities": [
                    "Brian Head",
                    "Cedar City",
                    "Enoch",
                    "Kanarraville",
                    "Paragonah",
                    "Parowan*"
                ]
            },
            {
                "countyName": "Juab",
                "cities": [
                    "Eureka",
                    "Levan",
                    "Mona",
                    "Nephi*",
                    "Rocky Ridge"
                ]
            },
            {
                "countyName": "Kane",
                "cities": [
                    "Alton",
                    "Big Water",
                    "Glendale",
                    "Kanab*",
                    "Orderville"
                ]
            },
            {
                "countyName": "Millard",
                "cities": [
                    "Delta",
                    "Fillmore*",
                    "Hinckley",
                    "Holden",
                    "Kanosh",
                    "Leamington",
                    "Lynndyl",
                    "Meadow",
                    "Oak City",
                    "Scipio"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Morgan*"
                ]
            },
            {
                "countyName": "Piute",
                "cities": [
                    "Circleville",
                    "Junction*",
                    "Kingston",
                    "Marysvale"
                ]
            },
            {
                "countyName": "Rich",
                "cities": [
                    "Garden City",
                    "Laketown",
                    "Randolph*",
                    "Woodruff"
                ]
            },
            {
                "countyName": "Salt Lake",
                "cities": [
                    "Alta",
                    "Brighton",
                    "Copperton",
                    "Cottonwood Heights",
                    "Emigration Canyon",
                    "Herriman",
                    "Holladay",
                    "Kearns",
                    "Magna",
                    "Midvale",
                    "Millcreek",
                    "Murray",
                    "Riverton",
                    "Salt Lake City*",
                    "Sandy",
                    "South Jordan",
                    "South Salt Lake",
                    "Taylorsville",
                    "West Jordan",
                    "West Valley City",
                    "White City"
                ]
            },
            {
                "countyName": "Salt Lake/ Utah",
                "cities": [
                    "Draper"
                ]
            },
            {
                "countyName": "Salt Lake/Utah",
                "cities": [
                    "Bluffdale"
                ]
            },
            {
                "countyName": "San Juan",
                "cities": [
                    "Blanding",
                    "Bluff",
                    "Monticello*"
                ]
            },
            {
                "countyName": "Sanpete",
                "cities": [
                    "Centerfield",
                    "Ephraim",
                    "Fairview",
                    "Fayette",
                    "Fountain Green",
                    "Gunnison",
                    "Manti*",
                    "Mayfield",
                    "Moroni",
                    "Mount Pleasant",
                    "Spring City",
                    "Sterling",
                    "Wales"
                ]
            },
            {
                "countyName": "Sevier",
                "cities": [
                    "Annabella",
                    "Aurora",
                    "Central Valley",
                    "Elsinore",
                    "Glenwood",
                    "Joseph",
                    "Koosharem",
                    "Monroe",
                    "Redmond",
                    "Richfield*",
                    "Salina",
                    "Sigurd"
                ]
            },
            {
                "countyName": "Summit",
                "cities": [
                    "Coalville*",
                    "Francis",
                    "Henefer",
                    "Kamas",
                    "Oakley"
                ]
            },
            {
                "countyName": "Summit/Wasatch",
                "cities": [
                    "Park City"
                ]
            },
            {
                "countyName": "Tooele",
                "cities": [
                    "Erda",
                    "Grantsville",
                    "Lake Point",
                    "Rush Valley",
                    "Stockton",
                    "Tooele*",
                    "Vernon",
                    "Wendover"
                ]
            },
            {
                "countyName": "Uintah",
                "cities": [
                    "Ballard",
                    "Naples",
                    "Vernal*"
                ]
            },
            {
                "countyName": "Utah",
                "cities": [
                    "Alpine",
                    "American Fork",
                    "Cedar Fort",
                    "Cedar Hills",
                    "Eagle Mountain",
                    "Elk Ridge",
                    "Fairfield",
                    "Genola",
                    "Goshen",
                    "Highland",
                    "Lehi",
                    "Lindon",
                    "Mapleton",
                    "Orem",
                    "Payson",
                    "Pleasant Grove",
                    "Provo*",
                    "Salem",
                    "Saratoga Springs",
                    "Spanish Fork",
                    "Springville",
                    "Vineyard",
                    "Woodland Hills"
                ]
            },
            {
                "countyName": "Utah/Juab",
                "cities": [
                    "Santaquin"
                ]
            },
            {
                "countyName": "Wasatch",
                "cities": [
                    "Charleston",
                    "Daniel",
                    "Heber City*",
                    "Hideout",
                    "Independence",
                    "Interlaken",
                    "Midway",
                    "Wallsburg"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Apple Valley",
                    "Enterprise",
                    "Hildale",
                    "Hurricane",
                    "Ivins",
                    "La Verkin",
                    "Leeds",
                    "New Harmony",
                    "Rockville",
                    "St. George*",
                    "Santa Clara",
                    "Springdale",
                    "Toquerville",
                    "Virgin",
                    "Washington"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Bicknell",
                    "Hanksville",
                    "Loa*",
                    "Lyman",
                    "Torrey"
                ]
            },
            {
                "countyName": "Weber",
                "cities": [
                    "Farr West",
                    "Harrisville",
                    "Hooper",
                    "Huntsville",
                    "Marriott-Slaterville",
                    "North Ogden",
                    "Ogden*",
                    "Plain City",
                    "Pleasant View",
                    "Riverdale",
                    "Roy",
                    "South Ogden",
                    "Uintah",
                    "Washington Terrace",
                    "West Haven"
                ]
            }
        ]
    },
    {
        "stateId": "VA",
        "stateName": "Virginia",
        "county": [
            {
                "countyName": "Accomack\n37°33′17″N 75°49′26″W",
                "cities": [
                    "Belle Haven"
                ]
            },
            {
                "countyName": "Accomack\n37°35′7″N 75°47′1″W",
                "cities": [
                    "Painter"
                ]
            },
            {
                "countyName": "Accomack\n37°36′23″N 75°41′23″W",
                "cities": [
                    "Wachapreague"
                ]
            },
            {
                "countyName": "Accomack\n37°37′16″N 75°45′51″W",
                "cities": [
                    "Keller"
                ]
            },
            {
                "countyName": "Accomack\n37°38′57″N 75°44′27″W",
                "cities": [
                    "Melfa"
                ]
            },
            {
                "countyName": "Accomack\n37°41′29″N 75°43′2″W",
                "cities": [
                    "Onley"
                ]
            },
            {
                "countyName": "Accomack\n37°42′36″N 75°44′37″W",
                "cities": [
                    "Onancock"
                ]
            },
            {
                "countyName": "Accomack\n37°43′10″N 75°40′4″W",
                "cities": [
                    "Accomac"
                ]
            },
            {
                "countyName": "Accomack\n37°47′11″N 75°39′14″W",
                "cities": [
                    "Parksley"
                ]
            },
            {
                "countyName": "Accomack\n37°49′24″N 75°59′34″W",
                "cities": [
                    "Tangier"
                ]
            },
            {
                "countyName": "Accomack\n37°49′45″N 75°37′17″W",
                "cities": [
                    "Bloxom"
                ]
            },
            {
                "countyName": "Accomack\n37°52′39″N 75°35′22″W",
                "cities": [
                    "Hallwood"
                ]
            },
            {
                "countyName": "Accomack\n37°55′33″N 75°43′25″W",
                "cities": [
                    "Saxis"
                ]
            },
            {
                "countyName": "Accomack\n37°56′58″N 75°21′9″W",
                "cities": [
                    "Chincoteague"
                ]
            },
            {
                "countyName": "Albemarle\n37°48′41″N 78°29′18″W",
                "cities": [
                    "Scottsville"
                ]
            },
            {
                "countyName": "Alleghany\n37°47′56″N 79°47′26″W",
                "cities": [
                    "Iron Gate"
                ]
            },
            {
                "countyName": "Alleghany\n37°49′24″N 79°49′31″W",
                "cities": [
                    "Clifton Forge"
                ]
            },
            {
                "countyName": "Amherst\n37°34′55″N 79°3′7″W",
                "cities": [
                    "Amherst"
                ]
            },
            {
                "countyName": "Appomattox\n37°15′59″N 78°41′1″W",
                "cities": [
                    "Pamplin City"
                ]
            },
            {
                "countyName": "Appomattox\n37°21′31″N 78°49′35″W",
                "cities": [
                    "Appomattox"
                ]
            },
            {
                "countyName": "Augusta\n38°16′11″N 78°49′33″W",
                "cities": [
                    "Grottoes"
                ]
            },
            {
                "countyName": "Augusta\n38°5′4″N 79°23′4″W",
                "cities": [
                    "Craigsville"
                ]
            },
            {
                "countyName": "Bedford\n37°20′11″N 79°31′4″W",
                "cities": [
                    "Bedford"
                ]
            },
            {
                "countyName": "Botetourt\n37°25′0″N 79°52′41″W",
                "cities": [
                    "Troutville"
                ]
            },
            {
                "countyName": "Botetourt\n37°29′58″N 79°52′32″W",
                "cities": [
                    "Fincastle"
                ]
            },
            {
                "countyName": "Botetourt\n37°31′15″N 79°41′28″W",
                "cities": [
                    "Buchanan"
                ]
            },
            {
                "countyName": "Brunswick\n36°42′11″N 78°1′55″W",
                "cities": [
                    "Brodnax"
                ]
            },
            {
                "countyName": "Brunswick\n36°45′23″N 77°51′14″W",
                "cities": [
                    "Lawrenceville"
                ]
            },
            {
                "countyName": "Brunswick\n36°51′14″N 77°53′38″W",
                "cities": [
                    "Alberta"
                ]
            },
            {
                "countyName": "Buchanan\n37°16′2″N 82°5′42″W",
                "cities": [
                    "Grundy"
                ]
            },
            {
                "countyName": "Buckingham\n37°32′26″N 78°27′40″W",
                "cities": [
                    "Dillwyn"
                ]
            },
            {
                "countyName": "Campbell\n37°2′52″N 78°57′14″W",
                "cities": [
                    "Brookneal"
                ]
            },
            {
                "countyName": "Campbell\n37°7′24″N 79°17′0″W",
                "cities": [
                    "Altavista"
                ]
            },
            {
                "countyName": "Caroline\n38°3′12″N 77°20′51″W",
                "cities": [
                    "Bowling Green"
                ]
            },
            {
                "countyName": "Caroline\n38°9′54″N 77°11′57″W",
                "cities": [
                    "Port Royal"
                ]
            },
            {
                "countyName": "Carroll\n36°45′42″N 80°44′12″W",
                "cities": [
                    "Hillsville"
                ]
            },
            {
                "countyName": "Charlotte\n36°59′36″N 78°36′3″W",
                "cities": [
                    "Drakes Branch"
                ]
            },
            {
                "countyName": "Charlotte\n37°2′20″N 78°28′49″W",
                "cities": [
                    "Keysville"
                ]
            },
            {
                "countyName": "Charlotte\n37°3′23″N 78°38′16″W",
                "cities": [
                    "Charlotte Court House"
                ]
            },
            {
                "countyName": "Charlotte\n37°4′52″N 78°44′55″W",
                "cities": [
                    "Phenix"
                ]
            },
            {
                "countyName": "Clarke\n39°5′36″N 78°3′37″W",
                "cities": [
                    "Boyce"
                ]
            },
            {
                "countyName": "Clarke\n39°9′4″N 77°58′56″W",
                "cities": [
                    "Berryville"
                ]
            },
            {
                "countyName": "Craig\n37°30′4″N 80°6′40″W",
                "cities": [
                    "New Castle"
                ]
            },
            {
                "countyName": "Culpeper\n38°28′13″N 77°59′59″W",
                "cities": [
                    "Culpeper"
                ]
            },
            {
                "countyName": "Cumberland\n37°17′51″N 78°23′58″W",
                "cities": [
                    "Farmville"
                ]
            },
            {
                "countyName": "Dickenson\n37°13′1″N 82°17′38″W",
                "cities": [
                    "Haysi"
                ]
            },
            {
                "countyName": "Dickenson\n37°9′11″N 82°27′36″W",
                "cities": [
                    "Clintwood"
                ]
            },
            {
                "countyName": "Dickenson\n37°9′27″N 82°21′16″W",
                "cities": [
                    "Clinchco"
                ]
            },
            {
                "countyName": "Dinwiddie\n36°59′7″N 77°43′19″W",
                "cities": [
                    "McKenney"
                ]
            },
            {
                "countyName": "Essex\n37°54′45″N 76°51′58″W",
                "cities": [
                    "Tappahannock"
                ]
            },
            {
                "countyName": "Fairfax\n38°46′48″N 77°23′10″W",
                "cities": [
                    "Clifton"
                ]
            },
            {
                "countyName": "Fairfax\n38°53′55″N 77°15′30″W",
                "cities": [
                    "Vienna"
                ]
            },
            {
                "countyName": "Fairfax\n38°58′13″N 77°23′13″W",
                "cities": [
                    "Herndon"
                ]
            },
            {
                "countyName": "Fauquier\n38°32′5″N 77°48′30″W",
                "cities": [
                    "Remington"
                ]
            },
            {
                "countyName": "Fauquier\n38°43′4″N 77°47′50″W",
                "cities": [
                    "Warrenton"
                ]
            },
            {
                "countyName": "Fauquier\n38°51′43″N 77°46′28″W",
                "cities": [
                    "The Plains"
                ]
            },
            {
                "countyName": "Floyd\n36°54′44″N 80°19′5″W",
                "cities": [
                    "Floyd"
                ]
            },
            {
                "countyName": "Fluvanna\n37°48′41″N 78°29′18″W",
                "cities": [
                    "Scottsville"
                ]
            },
            {
                "countyName": "Franklin\n37°0′16″N 79°53′4″W",
                "cities": [
                    "Rocky Mount"
                ]
            },
            {
                "countyName": "Franklin\n37°7′8″N 79°57′2″W",
                "cities": [
                    "Boones Mill"
                ]
            },
            {
                "countyName": "Frederick\n39°1′45″N 78°16′40″W",
                "cities": [
                    "Middletown"
                ]
            },
            {
                "countyName": "Frederick\n39°5′35″N 78°13′6″W",
                "cities": [
                    "Stephens City"
                ]
            },
            {
                "countyName": "Giles\n37°19′20″N 80°38′10″W",
                "cities": [
                    "Pembroke"
                ]
            },
            {
                "countyName": "Giles\n37°19′43″N 80°43′38″W",
                "cities": [
                    "Pearisburg"
                ]
            },
            {
                "countyName": "Giles\n37°19′53″N 80°48′33″W",
                "cities": [
                    "Narrows"
                ]
            },
            {
                "countyName": "Giles\n37°22′25″N 80°51′4″W",
                "cities": [
                    "Glen Lyn"
                ]
            },
            {
                "countyName": "Giles\n37°23′0″N 80°49′14″W",
                "cities": [
                    "Rich Creek"
                ]
            },
            {
                "countyName": "Grayson\n36°37′39″N 81°8′57″W",
                "cities": [
                    "Independence"
                ]
            },
            {
                "countyName": "Grayson\n36°41′46″N 81°26′15″W",
                "cities": [
                    "Troutdale"
                ]
            },
            {
                "countyName": "Grayson\n36°42′51″N 80°58′30″W",
                "cities": [
                    "Fries"
                ]
            },
            {
                "countyName": "Greene\n38°17′57″N 78°26′12″W",
                "cities": [
                    "Stanardsville"
                ]
            },
            {
                "countyName": "Greensville\n36°48′58″N 77°28′8″W",
                "cities": [
                    "Jarratt"
                ]
            },
            {
                "countyName": "Halifax\n36°32′44″N 78°46′31″W",
                "cities": [
                    "Virgilina"
                ]
            },
            {
                "countyName": "Halifax\n36°42′43″N 78°54′48″W",
                "cities": [
                    "South Boston"
                ]
            },
            {
                "countyName": "Halifax\n36°45′33″N 78°47′28″W",
                "cities": [
                    "Scottsburg"
                ]
            },
            {
                "countyName": "Halifax\n36°45′40″N 78°55′39″W",
                "cities": [
                    "Halifax"
                ]
            },
            {
                "countyName": "Hanover\n37°45′37″N 77°28′15″W",
                "cities": [
                    "Ashland"
                ]
            },
            {
                "countyName": "Henry\n36°34′41″N 79°51′30″W",
                "cities": [
                    "Ridgeway"
                ]
            },
            {
                "countyName": "Highland\n38°24′42″N 79°34′51″W",
                "cities": [
                    "Monterey"
                ]
            },
            {
                "countyName": "Isle of Wight\n36°48′39″N 76°44′17″W",
                "cities": [
                    "Windsor"
                ]
            },
            {
                "countyName": "Isle of Wight\n36°58′19″N 76°36′47″W",
                "cities": [
                    "Smithfield"
                ]
            },
            {
                "countyName": "King William\n37°33′8″N 76°48′7″W",
                "cities": [
                    "West Point"
                ]
            },
            {
                "countyName": "Lancaster\n37°38′41″N 76°23′24″W",
                "cities": [
                    "White Stone"
                ]
            },
            {
                "countyName": "Lancaster\n37°39′45″N 76°25′12″W",
                "cities": [
                    "Irvington"
                ]
            },
            {
                "countyName": "Lancaster\n37°42′39″N 76°22′58″W",
                "cities": [
                    "Kilmarnock"
                ]
            },
            {
                "countyName": "Lee\n36°41′14″N 83°6′55″W",
                "cities": [
                    "Jonesville"
                ]
            },
            {
                "countyName": "Lee\n36°45′33″N 83°1′46″W",
                "cities": [
                    "Pennington Gap"
                ]
            },
            {
                "countyName": "Lee\n36°48′18″N 83°3′27″W",
                "cities": [
                    "St. Charles"
                ]
            },
            {
                "countyName": "Loudoun\n38°58′17″N 77°44′23″W",
                "cities": [
                    "Middleburg"
                ]
            },
            {
                "countyName": "Loudoun\n39°12′2″N 77°43′31″W",
                "cities": [
                    "Hillsboro"
                ]
            },
            {
                "countyName": "Loudoun\n39°16′26″N 77°38′21″W",
                "cities": [
                    "Lovettsville"
                ]
            },
            {
                "countyName": "Loudoun\n39°6′23″N 77°33′20″W",
                "cities": [
                    "Leesburg"
                ]
            },
            {
                "countyName": "Loudoun\n39°7′53″N 77°46′2″W",
                "cities": [
                    "Round Hill"
                ]
            },
            {
                "countyName": "Loudoun\n39°8′15″N 77°42′41″W",
                "cities": [
                    "Purcellville"
                ]
            },
            {
                "countyName": "Loudoun\n39°8′5″N 77°39′51″W",
                "cities": [
                    "Hamilton"
                ]
            },
            {
                "countyName": "Louisa\n38°0′27″N 77°54′22″W",
                "cities": [
                    "Mineral"
                ]
            },
            {
                "countyName": "Louisa\n38°1′0″N 77°59′59″W",
                "cities": [
                    "Louisa"
                ]
            },
            {
                "countyName": "Lunenburg\n36°57′39″N 78°7′42″W",
                "cities": [
                    "Kenbridge"
                ]
            },
            {
                "countyName": "Lunenburg\n36°59′40″N 78°13′27″W",
                "cities": [
                    "Victoria"
                ]
            },
            {
                "countyName": "Madison\n38°22′44″N 78°15′31″W",
                "cities": [
                    "Madison"
                ]
            },
            {
                "countyName": "Mecklenburg\n36°37′6″N 78°33′52″W",
                "cities": [
                    "Clarksville"
                ]
            },
            {
                "countyName": "Mecklenburg\n36°39′58″N 78°23′27″W",
                "cities": [
                    "Boydton"
                ]
            },
            {
                "countyName": "Mecklenburg\n36°42′11″N 78°1′55″W",
                "cities": [
                    "Brodnax"
                ]
            },
            {
                "countyName": "Mecklenburg\n36°42′5″N 78°5′39″W",
                "cities": [
                    "La Crosse"
                ]
            },
            {
                "countyName": "Mecklenburg\n36°43′32″N 78°7′44″W",
                "cities": [
                    "South Hill"
                ]
            },
            {
                "countyName": "Mecklenburg\n36°47′59″N 78°27′39″W",
                "cities": [
                    "Chase City"
                ]
            },
            {
                "countyName": "Middlesex\n37°38′24″N 76°34′30″W",
                "cities": [
                    "Urbanna"
                ]
            },
            {
                "countyName": "Montgomery\n37°13′53″N 80°25′36″W",
                "cities": [
                    "Blacksburg"
                ]
            },
            {
                "countyName": "Montgomery\n37°8′28″N 80°24′5″W",
                "cities": [
                    "Christiansburg"
                ]
            },
            {
                "countyName": "Northampton\n37°14′59″N 76°0′51″W",
                "cities": [
                    "Cape Charles"
                ]
            },
            {
                "countyName": "Northampton\n37°17′40″N 75°57′56″W",
                "cities": [
                    "Cheriton"
                ]
            },
            {
                "countyName": "Northampton\n37°21′4″N 75°56′26″W",
                "cities": [
                    "Eastville"
                ]
            },
            {
                "countyName": "Northampton\n37°28′35″N 75°51′33″W",
                "cities": [
                    "Nassawadox"
                ]
            },
            {
                "countyName": "Northampton\n37°31′46″N 75°49′42″W",
                "cities": [
                    "Exmore"
                ]
            },
            {
                "countyName": "Northampton\n37°33′17″N 75°49′26″W",
                "cities": [
                    "Belle Haven"
                ]
            },
            {
                "countyName": "Northumberland\n37°42′39″N 76°22′58″W",
                "cities": [
                    "Kilmarnock"
                ]
            },
            {
                "countyName": "Nottoway\n37°10′52″N 78°7′49″W",
                "cities": [
                    "Crewe"
                ]
            },
            {
                "countyName": "Nottoway\n37°11′16″N 78°11′40″W",
                "cities": [
                    "Burkeville"
                ]
            },
            {
                "countyName": "Nottoway\n37°4′55″N 78°0′10″W",
                "cities": [
                    "Blackstone"
                ]
            },
            {
                "countyName": "Orange\n38°15′1″N 78°6′41″W",
                "cities": [
                    "Orange"
                ]
            },
            {
                "countyName": "Orange\n38°8′11″N 78°11′20″W",
                "cities": [
                    "Gordonsville"
                ]
            },
            {
                "countyName": "Page\n38°29′14″N 78°36′59″W",
                "cities": [
                    "Shenandoah"
                ]
            },
            {
                "countyName": "Page\n38°34′52″N 78°29′58″W",
                "cities": [
                    "Stanley"
                ]
            },
            {
                "countyName": "Page\n38°39′53″N 78°27′18″W",
                "cities": [
                    "Luray"
                ]
            },
            {
                "countyName": "Patrick\n36°38′28″N 80°16′8″W",
                "cities": [
                    "Stuart"
                ]
            },
            {
                "countyName": "Pittsylvania\n36°48′43″N 79°24′0″W",
                "cities": [
                    "Chatham"
                ]
            },
            {
                "countyName": "Pittsylvania\n36°56′58″N 79°21′45″W",
                "cities": [
                    "Gretna"
                ]
            },
            {
                "countyName": "Pittsylvania\n37°5′47″N 79°18′12″W",
                "cities": [
                    "Hurt"
                ]
            },
            {
                "countyName": "Prince Edward\n37°15′59″N 78°41′1″W",
                "cities": [
                    "Pamplin City"
                ]
            },
            {
                "countyName": "Prince Edward\n37°17′51″N 78°23′58″W",
                "cities": [
                    "Farmville"
                ]
            },
            {
                "countyName": "Prince William\n38°31′21″N 77°17′25″W",
                "cities": [
                    "Quantico"
                ]
            },
            {
                "countyName": "Prince William\n38°34′2″N 77°19′21″W",
                "cities": [
                    "Dumfries"
                ]
            },
            {
                "countyName": "Prince William\n38°40′48″N 77°15′31″W",
                "cities": [
                    "Occoquan"
                ]
            },
            {
                "countyName": "Prince William\n38°48′44″N 77°38′11″W",
                "cities": [
                    "Haymarket"
                ]
            },
            {
                "countyName": "Pulaski\n37°3′10″N 80°45′43″W",
                "cities": [
                    "Pulaski"
                ]
            },
            {
                "countyName": "Pulaski\n37°6′20″N 80°41′23″W",
                "cities": [
                    "Dublin"
                ]
            },
            {
                "countyName": "Rappahannock\n38°42′42″N 78°9′36″W",
                "cities": [
                    "Washington"
                ]
            },
            {
                "countyName": "Richmond\n37°57′37″N 76°45′40″W",
                "cities": [
                    "Warsaw"
                ]
            },
            {
                "countyName": "Roanoke\n37°16′27″N 79°53′17″W",
                "cities": [
                    "Vinton"
                ]
            },
            {
                "countyName": "Rockbridge\n37°37′54″N 79°27′30″W",
                "cities": [
                    "Glasgow"
                ]
            },
            {
                "countyName": "Rockbridge\n37°59′23″N 79°30′26″W",
                "cities": [
                    "Goshen"
                ]
            },
            {
                "countyName": "Rockingham\n38°16′11″N 78°49′33″W",
                "cities": [
                    "Grottoes"
                ]
            },
            {
                "countyName": "Rockingham\n38°21′39″N 78°56′28″W",
                "cities": [
                    "Mount Crawford"
                ]
            },
            {
                "countyName": "Rockingham\n38°23′19″N 78°57′52″W",
                "cities": [
                    "Bridgewater"
                ]
            },
            {
                "countyName": "Rockingham\n38°24′38″N 78°36′58″W",
                "cities": [
                    "Elkton"
                ]
            },
            {
                "countyName": "Rockingham\n38°25′2″N 78°56′28″W",
                "cities": [
                    "Dayton"
                ]
            },
            {
                "countyName": "Rockingham\n38°36′31″N 78°48′5″W",
                "cities": [
                    "Broadway"
                ]
            },
            {
                "countyName": "Rockingham\n38°38′0″N 78°46′13″W",
                "cities": [
                    "Timberville"
                ]
            },
            {
                "countyName": "Russell\n36°53′58″N 82°4′43″W",
                "cities": [
                    "Lebanon"
                ]
            },
            {
                "countyName": "Russell\n36°54′28″N 82°18′57″W",
                "cities": [
                    "St. Paul"
                ]
            },
            {
                "countyName": "Russell\n36°56′37″N 82°9′9″W",
                "cities": [
                    "Cleveland"
                ]
            },
            {
                "countyName": "Russell\n37°0′56″N 81°58′7″W",
                "cities": [
                    "Honaker"
                ]
            },
            {
                "countyName": "Scott\n36°37′25″N 82°33′48″W",
                "cities": [
                    "Weber City"
                ]
            },
            {
                "countyName": "Scott\n36°38′46″N 82°34′42″W",
                "cities": [
                    "Gate City"
                ]
            },
            {
                "countyName": "Scott\n36°40′48″N 82°44′39″W",
                "cities": [
                    "Clinchport"
                ]
            },
            {
                "countyName": "Scott\n36°43′11″N 82°47′46″W",
                "cities": [
                    "Duffield"
                ]
            },
            {
                "countyName": "Scott\n36°44′55″N 82°25′2″W",
                "cities": [
                    "Nickelsville"
                ]
            },
            {
                "countyName": "Scott\n36°49′42″N 82°28′5″W",
                "cities": [
                    "Dungannon"
                ]
            },
            {
                "countyName": "Shenandoah\n38°38′25″N 78°40′8″W",
                "cities": [
                    "New Market"
                ]
            },
            {
                "countyName": "Shenandoah\n38°44′21″N 78°38′45″W",
                "cities": [
                    "Mount Jackson"
                ]
            },
            {
                "countyName": "Shenandoah\n38°49′22″N 78°33′46″W",
                "cities": [
                    "Edinburg"
                ]
            },
            {
                "countyName": "Shenandoah\n38°52′20″N 78°31′2″W",
                "cities": [
                    "Woodstock"
                ]
            },
            {
                "countyName": "Shenandoah\n38°56′51″N 78°26′22″W",
                "cities": [
                    "Toms Brook"
                ]
            },
            {
                "countyName": "Shenandoah\n38°59′43″N 78°20′42″W",
                "cities": [
                    "Strasburg"
                ]
            },
            {
                "countyName": "Smyth\n36°48′1″N 81°40′58″W",
                "cities": [
                    "Chilhowie"
                ]
            },
            {
                "countyName": "Smyth\n36°50′20″N 81°30′50″W",
                "cities": [
                    "Marion"
                ]
            },
            {
                "countyName": "Smyth\n36°52′33″N 81°45′46″W",
                "cities": [
                    "Saltville"
                ]
            },
            {
                "countyName": "Southampton\n36°34′11″N 77°15′0″W",
                "cities": [
                    "Branchville"
                ]
            },
            {
                "countyName": "Southampton\n36°34′40″N 77°11′56″W",
                "cities": [
                    "Boykins"
                ]
            },
            {
                "countyName": "Southampton\n36°37′34″N 77°7′29″W",
                "cities": [
                    "Newsoms"
                ]
            },
            {
                "countyName": "Southampton\n36°42′34″N 77°12′5″W",
                "cities": [
                    "Capron"
                ]
            },
            {
                "countyName": "Southampton\n36°42′44″N 77°3′43″W",
                "cities": [
                    "Courtland"
                ]
            },
            {
                "countyName": "Southampton\n36°54′4″N 76°53′41″W",
                "cities": [
                    "Ivor"
                ]
            },
            {
                "countyName": "Surry\n37°13′38″N 76°57′59″W",
                "cities": [
                    "Claremont"
                ]
            },
            {
                "countyName": "Surry\n37°2′11″N 76°55′27″W",
                "cities": [
                    "Dendron"
                ]
            },
            {
                "countyName": "Surry\n37°8′13″N 76°50′0″W",
                "cities": [
                    "Surry"
                ]
            },
            {
                "countyName": "Sussex\n36°48′58″N 77°28′8″W",
                "cities": [
                    "Jarratt"
                ]
            },
            {
                "countyName": "Sussex\n36°56′53″N 77°24′0″W",
                "cities": [
                    "Stony Creek"
                ]
            },
            {
                "countyName": "Sussex\n36°58′9″N 76°59′17″W",
                "cities": [
                    "Wakefield"
                ]
            },
            {
                "countyName": "Sussex\n37°2′2″N 77°5′44″W",
                "cities": [
                    "Waverly"
                ]
            },
            {
                "countyName": "Tazewell\n37°14′7″N 81°16′29″W",
                "cities": [
                    "Bluefield"
                ]
            },
            {
                "countyName": "Tazewell\n37°18′35″N 81°20′45″W",
                "cities": [
                    "Pocahontas"
                ]
            },
            {
                "countyName": "Tazewell\n37°5′13″N 81°45′51″W",
                "cities": [
                    "Cedar Bluff"
                ]
            },
            {
                "countyName": "Tazewell\n37°5′17″N 81°48′37″W",
                "cities": [
                    "Richlands"
                ]
            },
            {
                "countyName": "Tazewell\n37°7′44″N 81°30′23″W",
                "cities": [
                    "Tazewell"
                ]
            },
            {
                "countyName": "Warren\n38°55′30″N 78°11′0″W",
                "cities": [
                    "Front Royal"
                ]
            },
            {
                "countyName": "Washington\n36°37′58″N 81°47′22″W",
                "cities": [
                    "Damascus"
                ]
            },
            {
                "countyName": "Washington\n36°42′33″N 81°58′15″W",
                "cities": [
                    "Abingdon"
                ]
            },
            {
                "countyName": "Washington\n36°47′24″N 81°46′20″W",
                "cities": [
                    "Glade Spring"
                ]
            },
            {
                "countyName": "Washington\n36°52′33″N 81°45′46″W",
                "cities": [
                    "Saltville"
                ]
            },
            {
                "countyName": "Westmoreland\n38°15′52″N 76°59′37″W",
                "cities": [
                    "Colonial Beach"
                ]
            },
            {
                "countyName": "Westmoreland\n38°5′33″N 76°48′57″W",
                "cities": [
                    "Montross"
                ]
            },
            {
                "countyName": "Wise\n36°51′40″N 82°46′39″W",
                "cities": [
                    "Big Stone Gap"
                ]
            },
            {
                "countyName": "Wise\n36°54′18″N 82°46′48″W",
                "cities": [
                    "Appalachia"
                ]
            },
            {
                "countyName": "Wise\n36°54′28″N 82°18′57″W",
                "cities": [
                    "St. Paul"
                ]
            },
            {
                "countyName": "Wise\n36°56′51″N 82°28′4″W",
                "cities": [
                    "Coeburn"
                ]
            },
            {
                "countyName": "Wise\n36°58′36″N 82°34′50″W",
                "cities": [
                    "Wise"
                ]
            },
            {
                "countyName": "Wise\n37°7′34″N 82°36′26″W",
                "cities": [
                    "Pound"
                ]
            },
            {
                "countyName": "Wythe\n36°54′18″N 81°16′38″W",
                "cities": [
                    "Rural Retreat"
                ]
            },
            {
                "countyName": "Wythe\n36°57′12″N 81°5′18″W",
                "cities": [
                    "Wytheville"
                ]
            }
        ]
    },
    {
        "stateId": "VT",
        "stateName": "Vermont",
        "county": [
            {
                "countyName": "Addison",
                "cities": [
                    "Vergennes[4]",
                    "Addison",
                    "Bridport",
                    "Bristol",
                    "Cornwall",
                    "Ferrisburgh",
                    "Goshen",
                    "Granville",
                    "Hancock",
                    "Leicester",
                    "Lincoln",
                    "Middlebury",
                    "Monkton",
                    "New Haven",
                    "Orwell",
                    "Panton",
                    "Ripton",
                    "Salisbury",
                    "Shoreham",
                    "Starksboro",
                    "Waltham",
                    "Weybridge",
                    "Whiting"
                ]
            },
            {
                "countyName": "Bennington",
                "cities": [
                    "Arlington",
                    "Bennington",
                    "Dorset",
                    "Glastenbury",
                    "Landgrove",
                    "Manchester",
                    "Peru",
                    "Pownal",
                    "Readsboro",
                    "Rupert",
                    "Sandgate",
                    "Searsburg",
                    "Shaftsbury",
                    "Stamford",
                    "Sunderland",
                    "Winhall",
                    "Woodford"
                ]
            },
            {
                "countyName": "Caledonia",
                "cities": [
                    "Barnet",
                    "Burke",
                    "Danville",
                    "Groton",
                    "Hardwick",
                    "Kirby",
                    "Lyndon",
                    "Newark",
                    "Peacham",
                    "Ryegate",
                    "Sheffield",
                    "St. Johnsbury",
                    "Stannard",
                    "Sutton",
                    "Walden",
                    "Waterford",
                    "Wheelock"
                ]
            },
            {
                "countyName": "Chittenden",
                "cities": [
                    "Winooski[7]",
                    "Essex Junction",
                    "South Burlington[11]",
                    "Burlington[12]*",
                    "Bolton",
                    "Buels Gore",
                    "Charlotte",
                    "Colchester",
                    "Essex",
                    "Hinesburg",
                    "Huntington",
                    "Jericho",
                    "Milton",
                    "Richmond",
                    "Shelburne",
                    "St. George",
                    "Underhill",
                    "Westford",
                    "Williston"
                ]
            },
            {
                "countyName": "Essex",
                "cities": [
                    "Averill",
                    "Averys Gore",
                    "Bloomfield",
                    "Brighton",
                    "Brunswick",
                    "Canaan",
                    "Concord",
                    "East Haven",
                    "Ferdinand",
                    "Granby",
                    "Guildhall",
                    "Lemington",
                    "Lewis",
                    "Lunenburg",
                    "Maidstone",
                    "Norton",
                    "Victory",
                    "Warner's Grant",
                    "Warren's Gore"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "St. Albans[6]*",
                    "Bakersfield",
                    "Berkshire",
                    "Enosburgh",
                    "Fairfax",
                    "Fairfield",
                    "Fletcher",
                    "Franklin",
                    "Georgia",
                    "Highgate",
                    "Montgomery",
                    "Richford",
                    "Sheldon",
                    "St. Albans",
                    "Swanton"
                ]
            },
            {
                "countyName": "Grand Isle",
                "cities": [
                    "Alburgh",
                    "Grand Isle",
                    "Isle La Motte",
                    "North Hero",
                    "South Hero"
                ]
            },
            {
                "countyName": "Lamoille",
                "cities": [
                    "Belvidere",
                    "Cambridge",
                    "Eden",
                    "Elmore",
                    "Hyde Park",
                    "Johnson",
                    "Morristown",
                    "Stowe",
                    "Waterville",
                    "Wolcott"
                ]
            },
            {
                "countyName": "Orange",
                "cities": [
                    "Bradford",
                    "Braintree",
                    "Brookfield",
                    "Chelsea",
                    "Corinth",
                    "Fairlee",
                    "Newbury",
                    "Orange",
                    "Randolph",
                    "Strafford",
                    "Thetford",
                    "Topsham",
                    "Tunbridge",
                    "Vershire",
                    "Washington",
                    "West Fairlee",
                    "Williamstown"
                ]
            },
            {
                "countyName": "Orleans",
                "cities": [
                    "Newport[5]*",
                    "Albany",
                    "Barton",
                    "Brownington",
                    "Charleston",
                    "Coventry",
                    "Craftsbury",
                    "Derby",
                    "Glover",
                    "Greensboro",
                    "Holland",
                    "Irasburg",
                    "Jay",
                    "Lowell",
                    "Morgan",
                    "Newport",
                    "Troy",
                    "Westfield",
                    "Westmore"
                ]
            },
            {
                "countyName": "Rutland",
                "cities": [
                    "Rutland[10]*",
                    "Benson",
                    "Brandon",
                    "Castleton",
                    "Chittenden",
                    "Clarendon",
                    "Danby",
                    "Fair Haven",
                    "Hubbardton",
                    "Ira",
                    "Killington",
                    "Mendon",
                    "Middletown Springs",
                    "Mount Holly",
                    "Mount Tabor",
                    "Pawlet",
                    "Pittsfield",
                    "Pittsford",
                    "Poultney",
                    "Proctor",
                    "Rutland",
                    "Shrewsbury",
                    "Sudbury",
                    "Tinmouth",
                    "Wallingford",
                    "Wells",
                    "West Haven",
                    "West Rutland"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "Montpelier[8]*",
                    "Barre[9]",
                    "Barre",
                    "Berlin",
                    "Cabot",
                    "Calais",
                    "Duxbury",
                    "East Montpelier",
                    "Fayston",
                    "Marshfield",
                    "Middlesex",
                    "Moretown",
                    "Northfield",
                    "Plainfield",
                    "Roxbury",
                    "Waitsfield",
                    "Warren",
                    "Waterbury",
                    "Woodbury",
                    "Worcester"
                ]
            },
            {
                "countyName": "Windham",
                "cities": [
                    "Athens",
                    "Brattleboro",
                    "Brookline",
                    "Dover",
                    "Dummerston",
                    "Grafton",
                    "Guilford",
                    "Halifax",
                    "Jamaica",
                    "Londonderry",
                    "Marlboro",
                    "Newfane",
                    "Putney",
                    "Rockingham",
                    "Somerset",
                    "Stratton",
                    "Townshend",
                    "Vernon",
                    "Wardsboro",
                    "Westminster",
                    "Whitingham",
                    "Wilmington",
                    "Windham"
                ]
            },
            {
                "countyName": "Windsor",
                "cities": [
                    "Andover",
                    "Baltimore",
                    "Barnard",
                    "Bethel",
                    "Bridgewater",
                    "Cavendish",
                    "Chester",
                    "Hartford",
                    "Hartland",
                    "Ludlow",
                    "Norwich",
                    "Plymouth",
                    "Pomfret",
                    "Reading",
                    "Rochester",
                    "Royalton",
                    "Sharon",
                    "Springfield",
                    "Stockbridge",
                    "Weathersfield",
                    "West Windsor",
                    "Weston",
                    "Windsor",
                    "Woodstock"
                ]
            }
        ]
    },
    {
        "stateId": "WA",
        "stateName": "Washington",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Othello",
                    "Ritzville"
                ]
            },
            {
                "countyName": "Asotin",
                "cities": [
                    "Asotin",
                    "Clarkston"
                ]
            },
            {
                "countyName": "Benton",
                "cities": [
                    "Benton City",
                    "Kennewick",
                    "Prosser",
                    "Richland",
                    "West Richland"
                ]
            },
            {
                "countyName": "Chelan",
                "cities": [
                    "Cashmere",
                    "Chelan",
                    "Entiat",
                    "Leavenworth",
                    "Wenatchee"
                ]
            },
            {
                "countyName": "Clallam",
                "cities": [
                    "Forks",
                    "Port Angeles",
                    "Sequim"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Battle Ground",
                    "Camas",
                    "La Center",
                    "Ridgefield",
                    "Vancouver",
                    "Washougal"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Dayton"
                ]
            },
            {
                "countyName": "Cowlitz",
                "cities": [
                    "Castle Rock",
                    "Kalama",
                    "Kelso",
                    "Longview"
                ]
            },
            {
                "countyName": "Cowlitz\nClark[Note 1]",
                "cities": [
                    "Woodland"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Bridgeport",
                    "East Wenatchee",
                    "Rock Island"
                ]
            },
            {
                "countyName": "Ferry",
                "cities": [
                    "Republic"
                ]
            },
            {
                "countyName": "Franklin",
                "cities": [
                    "Connell",
                    "Kahlotus",
                    "Mesa",
                    "Pasco"
                ]
            },
            {
                "countyName": "Garfield",
                "cities": [
                    "Pomeroy"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Electric City",
                    "Ephrata",
                    "George",
                    "Grand Coulee",
                    "Mattawa",
                    "Moses Lake",
                    "Quincy",
                    "Royal City",
                    "Soap Lake",
                    "Warden"
                ]
            },
            {
                "countyName": "Grays Harbor",
                "cities": [
                    "Aberdeen",
                    "Cosmopolis",
                    "Elma",
                    "Hoquiam",
                    "McCleary",
                    "Montesano",
                    "Oakville",
                    "Ocean Shores",
                    "Westport"
                ]
            },
            {
                "countyName": "Island",
                "cities": [
                    "Langley",
                    "Oak Harbor"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Port Townsend"
                ]
            },
            {
                "countyName": "King",
                "cities": [
                    "Algona",
                    "Bellevue",
                    "Black Diamond",
                    "Burien",
                    "Carnation",
                    "Clyde Hill",
                    "Covington",
                    "Des Moines",
                    "Duvall",
                    "Federal Way",
                    "Issaquah",
                    "Kenmore",
                    "Kent",
                    "Kirkland",
                    "Lake Forest Park",
                    "Maple Valley",
                    "Medina",
                    "Mercer Island",
                    "Newcastle",
                    "Normandy Park",
                    "North Bend",
                    "Redmond",
                    "Renton",
                    "Sammamish",
                    "SeaTac",
                    "Seattle",
                    "Shoreline",
                    "Snoqualmie",
                    "Tukwila",
                    "Woodinville"
                ]
            },
            {
                "countyName": "King\nPierce[Note 1]",
                "cities": [
                    "Auburn",
                    "Enumclaw",
                    "Pacific"
                ]
            },
            {
                "countyName": "King\nSnohomish[Note 1]",
                "cities": [
                    "Bothell"
                ]
            },
            {
                "countyName": "Kitsap",
                "cities": [
                    "Bainbridge Island",
                    "Bremerton",
                    "Port Orchard",
                    "Poulsbo"
                ]
            },
            {
                "countyName": "Kittitas",
                "cities": [
                    "Cle Elum",
                    "Ellensburg",
                    "Kittitas",
                    "Roslyn"
                ]
            },
            {
                "countyName": "Klickitat",
                "cities": [
                    "Bingen",
                    "Goldendale",
                    "White Salmon"
                ]
            },
            {
                "countyName": "Lewis",
                "cities": [
                    "Centralia",
                    "Chehalis",
                    "Morton",
                    "Mossyrock",
                    "Napavine",
                    "Toledo",
                    "Vader",
                    "Winlock"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Davenport",
                    "Harrington",
                    "Sprague"
                ]
            },
            {
                "countyName": "Mason",
                "cities": [
                    "Shelton"
                ]
            },
            {
                "countyName": "Okanogan",
                "cities": [
                    "Brewster",
                    "Okanogan",
                    "Omak",
                    "Oroville",
                    "Pateros",
                    "Tonasket"
                ]
            },
            {
                "countyName": "Pacific",
                "cities": [
                    "Ilwaco",
                    "Long Beach",
                    "Raymond",
                    "South Bend"
                ]
            },
            {
                "countyName": "Pend Oreille",
                "cities": [
                    "Newport"
                ]
            },
            {
                "countyName": "Pierce",
                "cities": [
                    "Bonney Lake",
                    "Buckley",
                    "DuPont",
                    "Edgewood",
                    "Fife",
                    "Fircrest",
                    "Gig Harbor",
                    "Lakewood",
                    "Orting",
                    "Puyallup",
                    "Roy",
                    "Ruston",
                    "Sumner",
                    "Tacoma",
                    "University Place"
                ]
            },
            {
                "countyName": "Pierce\nKing[Note 1]",
                "cities": [
                    "Milton"
                ]
            },
            {
                "countyName": "Skagit",
                "cities": [
                    "Anacortes",
                    "Burlington",
                    "Mount Vernon",
                    "Sedro-Woolley"
                ]
            },
            {
                "countyName": "Skamania",
                "cities": [
                    "North Bonneville",
                    "Stevenson"
                ]
            },
            {
                "countyName": "Snohomish",
                "cities": [
                    "Arlington",
                    "Brier",
                    "Edmonds",
                    "Everett",
                    "Gold Bar",
                    "Granite Falls",
                    "Lake Stevens",
                    "Lynnwood",
                    "Marysville",
                    "Mill Creek",
                    "Monroe",
                    "Mountlake Terrace",
                    "Mukilteo",
                    "Snohomish",
                    "Stanwood",
                    "Sultan",
                    "Woodway"
                ]
            },
            {
                "countyName": "Spokane",
                "cities": [
                    "Airway Heights",
                    "Cheney",
                    "Deer Park",
                    "Liberty Lake",
                    "Medical Lake",
                    "Millwood",
                    "Spangle",
                    "Spokane",
                    "Spokane Valley"
                ]
            },
            {
                "countyName": "Stevens",
                "cities": [
                    "Chewelah",
                    "Colville",
                    "Kettle Falls"
                ]
            },
            {
                "countyName": "Thurston",
                "cities": [
                    "Lacey",
                    "Olympia",
                    "Rainier",
                    "Tenino",
                    "Tumwater",
                    "Yelm"
                ]
            },
            {
                "countyName": "Walla Walla",
                "cities": [
                    "College Place",
                    "Prescott",
                    "Waitsburg",
                    "Walla Walla"
                ]
            },
            {
                "countyName": "Whatcom",
                "cities": [
                    "Bellingham",
                    "Blaine",
                    "Everson",
                    "Ferndale",
                    "Lynden",
                    "Nooksack",
                    "Sumas"
                ]
            },
            {
                "countyName": "Whitman",
                "cities": [
                    "Colfax",
                    "Palouse",
                    "Pullman",
                    "Tekoa"
                ]
            },
            {
                "countyName": "Yakima",
                "cities": [
                    "Grandview",
                    "Granger",
                    "Mabton",
                    "Moxee",
                    "Selah",
                    "Sunnyside",
                    "Tieton",
                    "Toppenish",
                    "Union Gap",
                    "Wapato",
                    "Yakima",
                    "Zillah"
                ]
            }
        ]
    },
    {
        "stateId": "WI",
        "stateName": "Wisconsin",
        "county": [
            {
                "countyName": "Adams",
                "cities": [
                    "Adams"
                ]
            },
            {
                "countyName": "Adams\nColumbia\nJuneau\nSauk",
                "cities": [
                    "Wisconsin Dells"
                ]
            },
            {
                "countyName": "Ashland",
                "cities": [
                    "Mellen"
                ]
            },
            {
                "countyName": "Ashland\nBayfield",
                "cities": [
                    "Ashland"
                ]
            },
            {
                "countyName": "Barron",
                "cities": [
                    "Barron",
                    "Chetek",
                    "Cumberland",
                    "Rice Lake"
                ]
            },
            {
                "countyName": "Bayfield",
                "cities": [
                    "Bayfield",
                    "Washburn"
                ]
            },
            {
                "countyName": "Brown",
                "cities": [
                    "De Pere",
                    "Green Bay"
                ]
            },
            {
                "countyName": "Buffalo",
                "cities": [
                    "Alma",
                    "Buffalo City",
                    "Fountain City",
                    "Mondovi"
                ]
            },
            {
                "countyName": "Calumet",
                "cities": [
                    "Brillion",
                    "Chilton",
                    "New Holstein"
                ]
            },
            {
                "countyName": "Calumet\nManitowoc",
                "cities": [
                    "Kiel"
                ]
            },
            {
                "countyName": "Calumet\nOutagamie\nWinnebago",
                "cities": [
                    "Appleton"
                ]
            },
            {
                "countyName": "Calumet\nWinnebago",
                "cities": [
                    "Menasha"
                ]
            },
            {
                "countyName": "Chippewa",
                "cities": [
                    "Bloomer",
                    "Chippewa Falls",
                    "Cornell"
                ]
            },
            {
                "countyName": "Chippewa\nClark",
                "cities": [
                    "Stanley"
                ]
            },
            {
                "countyName": "Chippewa\nEau Claire",
                "cities": [
                    "Eau Claire"
                ]
            },
            {
                "countyName": "Clark",
                "cities": [
                    "Greenwood",
                    "Loyal",
                    "Neillsville",
                    "Owen",
                    "Thorp"
                ]
            },
            {
                "countyName": "Clark\nMarathon",
                "cities": [
                    "Abbotsford",
                    "Colby"
                ]
            },
            {
                "countyName": "Columbia",
                "cities": [
                    "Lodi",
                    "Portage"
                ]
            },
            {
                "countyName": "Columbia\nDodge",
                "cities": [
                    "Columbus"
                ]
            },
            {
                "countyName": "Crawford",
                "cities": [
                    "Prairie du Chien"
                ]
            },
            {
                "countyName": "Dane",
                "cities": [
                    "Fitchburg",
                    "Madison",
                    "Middleton",
                    "Monona",
                    "Stoughton",
                    "Sun Prairie",
                    "Verona"
                ]
            },
            {
                "countyName": "Dane\nRock",
                "cities": [
                    "Edgerton"
                ]
            },
            {
                "countyName": "Dodge",
                "cities": [
                    "Beaver Dam",
                    "Fox Lake",
                    "Horicon",
                    "Juneau",
                    "Mayville"
                ]
            },
            {
                "countyName": "Dodge\nFond du Lac",
                "cities": [
                    "Waupun"
                ]
            },
            {
                "countyName": "Dodge\nJefferson",
                "cities": [
                    "Watertown"
                ]
            },
            {
                "countyName": "Dodge\nWashington",
                "cities": [
                    "Hartford"
                ]
            },
            {
                "countyName": "Door",
                "cities": [
                    "Sturgeon Bay"
                ]
            },
            {
                "countyName": "Douglas",
                "cities": [
                    "Superior"
                ]
            },
            {
                "countyName": "Dunn",
                "cities": [
                    "Menomonie"
                ]
            },
            {
                "countyName": "Eau Claire",
                "cities": [
                    "Altoona",
                    "Augusta"
                ]
            },
            {
                "countyName": "Fond du Lac",
                "cities": [
                    "Fond du Lac",
                    "Ripon"
                ]
            },
            {
                "countyName": "Forest",
                "cities": [
                    "Crandon"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Boscobel",
                    "Fennimore",
                    "Lancaster",
                    "Platteville"
                ]
            },
            {
                "countyName": "Grant\nLafayette",
                "cities": [
                    "Cuba City"
                ]
            },
            {
                "countyName": "Green",
                "cities": [
                    "Monroe"
                ]
            },
            {
                "countyName": "Green\nRock",
                "cities": [
                    "Brodhead"
                ]
            },
            {
                "countyName": "Green Lake",
                "cities": [
                    "Green Lake",
                    "Markesan",
                    "Princeton"
                ]
            },
            {
                "countyName": "Green Lake\nWaushara",
                "cities": [
                    "Berlin"
                ]
            },
            {
                "countyName": "Iowa",
                "cities": [
                    "Dodgeville",
                    "Mineral Point"
                ]
            },
            {
                "countyName": "Iron",
                "cities": [
                    "Hurley",
                    "Montreal"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Black River Falls"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Fort Atkinson",
                    "Jefferson",
                    "Lake Mills",
                    "Waterloo"
                ]
            },
            {
                "countyName": "Jefferson\nWalworth",
                "cities": [
                    "Whitewater"
                ]
            },
            {
                "countyName": "Juneau",
                "cities": [
                    "Elroy",
                    "Mauston",
                    "New Lisbon"
                ]
            },
            {
                "countyName": "Kenosha",
                "cities": [
                    "Kenosha"
                ]
            },
            {
                "countyName": "Kewaunee",
                "cities": [
                    "Algoma",
                    "Kewaunee"
                ]
            },
            {
                "countyName": "La Crosse",
                "cities": [
                    "La Crosse",
                    "Onalaska",
                    "West Salem"
                ]
            },
            {
                "countyName": "Lafayette",
                "cities": [
                    "Darlington",
                    "Shullsburg"
                ]
            },
            {
                "countyName": "Langlade",
                "cities": [
                    "Antigo"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Merrill",
                    "Tomahawk"
                ]
            },
            {
                "countyName": "Manitowoc",
                "cities": [
                    "Manitowoc",
                    "Two Rivers"
                ]
            },
            {
                "countyName": "Marathon",
                "cities": [
                    "Mosinee",
                    "Schofield",
                    "Wausau"
                ]
            },
            {
                "countyName": "Marathon\nWood",
                "cities": [
                    "Marshfield"
                ]
            },
            {
                "countyName": "Marinette",
                "cities": [
                    "Marinette",
                    "Niagara",
                    "Peshtigo"
                ]
            },
            {
                "countyName": "Marquette",
                "cities": [
                    "Montello"
                ]
            },
            {
                "countyName": "Milwaukee",
                "cities": [
                    "Cudahy",
                    "Franklin",
                    "Glendale",
                    "Greenfield",
                    "Oak Creek",
                    "St. Francis",
                    "South Milwaukee",
                    "Wauwatosa",
                    "West Allis"
                ]
            },
            {
                "countyName": "Milwaukee\nWashington\nWaukesha",
                "cities": [
                    "Milwaukee"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Sparta",
                    "Tomah"
                ]
            },
            {
                "countyName": "Oconto",
                "cities": [
                    "Gillett",
                    "Oconto",
                    "Oconto Falls"
                ]
            },
            {
                "countyName": "Oneida",
                "cities": [
                    "Rhinelander"
                ]
            },
            {
                "countyName": "Outagamie",
                "cities": [
                    "Kaukauna",
                    "Seymour"
                ]
            },
            {
                "countyName": "Outagamie\nWaupaca",
                "cities": [
                    "New London"
                ]
            },
            {
                "countyName": "Ozaukee",
                "cities": [
                    "Cedarburg",
                    "Mequon",
                    "Port Washington"
                ]
            },
            {
                "countyName": "Pepin",
                "cities": [
                    "Durand"
                ]
            },
            {
                "countyName": "Pierce",
                "cities": [
                    "Prescott"
                ]
            },
            {
                "countyName": "Pierce\nSt. Croix",
                "cities": [
                    "River Falls"
                ]
            },
            {
                "countyName": "Polk",
                "cities": [
                    "Amery",
                    "St. Croix Falls"
                ]
            },
            {
                "countyName": "Portage",
                "cities": [
                    "Stevens Point"
                ]
            },
            {
                "countyName": "Price",
                "cities": [
                    "Park Falls",
                    "Phillips"
                ]
            },
            {
                "countyName": "Racine",
                "cities": [
                    "Racine"
                ]
            },
            {
                "countyName": "Racine\nWalworth",
                "cities": [
                    "Burlington"
                ]
            },
            {
                "countyName": "Richland",
                "cities": [
                    "Richland Center"
                ]
            },
            {
                "countyName": "Rock",
                "cities": [
                    "Beloit",
                    "Evansville",
                    "Janesville",
                    "Milton"
                ]
            },
            {
                "countyName": "Rusk",
                "cities": [
                    "Ladysmith"
                ]
            },
            {
                "countyName": "Sauk",
                "cities": [
                    "Baraboo",
                    "Reedsburg"
                ]
            },
            {
                "countyName": "Sawyer",
                "cities": [
                    "Hayward"
                ]
            },
            {
                "countyName": "Shawano",
                "cities": [
                    "Shawano"
                ]
            },
            {
                "countyName": "Shawano\nWaupaca",
                "cities": [
                    "Marion"
                ]
            },
            {
                "countyName": "Sheboygan",
                "cities": [
                    "Plymouth",
                    "Sheboygan",
                    "Sheboygan Falls"
                ]
            },
            {
                "countyName": "St. Croix",
                "cities": [
                    "Glenwood City",
                    "Hudson",
                    "New Richmond"
                ]
            },
            {
                "countyName": "Taylor",
                "cities": [
                    "Medford"
                ]
            },
            {
                "countyName": "Trempealeau",
                "cities": [
                    "Arcadia",
                    "Blair",
                    "Galesville",
                    "Independence",
                    "Osseo",
                    "Whitehall"
                ]
            },
            {
                "countyName": "Vernon",
                "cities": [
                    "Hillsboro",
                    "Viroqua",
                    "Westby"
                ]
            },
            {
                "countyName": "Vilas",
                "cities": [
                    "Eagle River"
                ]
            },
            {
                "countyName": "Walworth",
                "cities": [
                    "Delavan",
                    "Elkhorn",
                    "Lake Geneva"
                ]
            },
            {
                "countyName": "Washburn",
                "cities": [
                    "Shell Lake",
                    "Spooner"
                ]
            },
            {
                "countyName": "Washington",
                "cities": [
                    "West Bend"
                ]
            },
            {
                "countyName": "Waukesha",
                "cities": [
                    "Brookfield",
                    "Delafield",
                    "Muskego",
                    "New Berlin",
                    "Oconomowoc",
                    "Pewaukee",
                    "Waukesha"
                ]
            },
            {
                "countyName": "Waupaca",
                "cities": [
                    "Clintonville",
                    "Manawa",
                    "Waupaca",
                    "Weyauwega"
                ]
            },
            {
                "countyName": "Waushara",
                "cities": [
                    "Wautoma"
                ]
            },
            {
                "countyName": "Winnebago",
                "cities": [
                    "Neenah",
                    "Omro",
                    "Oshkosh"
                ]
            },
            {
                "countyName": "Wood",
                "cities": [
                    "Nekoosa",
                    "Pittsville",
                    "Wisconsin Rapids"
                ]
            }
        ]
    },
    {
        "stateId": "WV",
        "stateName": "West Virginia",
        "county": [
            {
                "countyName": "Barbour",
                "cities": [
                    "Philippi †",
                    "Belington",
                    "Junior"
                ]
            },
            {
                "countyName": "Berkeley",
                "cities": [
                    "Martinsburg †",
                    "Hedgesville"
                ]
            },
            {
                "countyName": "Boone",
                "cities": [
                    "Madison †",
                    "Danville",
                    "Whitesville",
                    "Sylvester"
                ]
            },
            {
                "countyName": "Braxton",
                "cities": [
                    "Sutton †",
                    "Gassaway",
                    "Burnsville",
                    "Flatwoods"
                ]
            },
            {
                "countyName": "Brooke",
                "cities": [
                    "Follansbee",
                    "Wellsburg †",
                    "Bethany",
                    "Beech Bottom",
                    "Windsor Heights"
                ]
            },
            {
                "countyName": "Cabell",
                "cities": [
                    "Barboursville",
                    "Milton"
                ]
            },
            {
                "countyName": "Cabell\nWayne",
                "cities": [
                    "Huntington †"
                ]
            },
            {
                "countyName": "Calhoun",
                "cities": [
                    "Grantsville †"
                ]
            },
            {
                "countyName": "Clay",
                "cities": [
                    "Clay †"
                ]
            },
            {
                "countyName": "Doddridge",
                "cities": [
                    "West Union †"
                ]
            },
            {
                "countyName": "Fayette",
                "cities": [
                    "Oak Hill",
                    "Fayetteville †",
                    "Ansted",
                    "Mount Hope",
                    "Gauley Bridge",
                    "Meadow Bridge",
                    "Pax",
                    "Thurmond"
                ]
            },
            {
                "countyName": "Fayette\nKanawha",
                "cities": [
                    "Montgomery",
                    "Smithers"
                ]
            },
            {
                "countyName": "Gilmer",
                "cities": [
                    "Glenville †",
                    "Sand Fork"
                ]
            },
            {
                "countyName": "Grant",
                "cities": [
                    "Petersburg †",
                    "Bayard"
                ]
            },
            {
                "countyName": "Greenbrier",
                "cities": [
                    "Lewisburg †",
                    "White Sulphur Springs",
                    "Ronceverte",
                    "Rainelle",
                    "Rupert",
                    "Quinwood",
                    "Falling Spring"
                ]
            },
            {
                "countyName": "Greenbrier\nMonroe",
                "cities": [
                    "Alderson"
                ]
            },
            {
                "countyName": "Hampshire",
                "cities": [
                    "Romney †",
                    "Capon Bridge"
                ]
            },
            {
                "countyName": "Hancock",
                "cities": [
                    "Chester",
                    "New Cumberland †"
                ]
            },
            {
                "countyName": "Hancock\nBrooke",
                "cities": [
                    "Weirton"
                ]
            },
            {
                "countyName": "Hardy",
                "cities": [
                    "Moorefield †",
                    "Wardensville"
                ]
            },
            {
                "countyName": "Harrison",
                "cities": [
                    "Clarksburg †",
                    "Bridgeport",
                    "Shinnston",
                    "Stonewood",
                    "Salem",
                    "Nutter Fort",
                    "Lumberport",
                    "Anmoore",
                    "West Milford",
                    "Lost Creek"
                ]
            },
            {
                "countyName": "Jackson",
                "cities": [
                    "Ravenswood",
                    "Ripley †"
                ]
            },
            {
                "countyName": "Jefferson",
                "cities": [
                    "Charles Town †",
                    "Ranson",
                    "Shepherdstown",
                    "Bolivar",
                    "Harpers Ferry"
                ]
            },
            {
                "countyName": "Kanawha",
                "cities": [
                    "Charleston ††",
                    "South Charleston",
                    "St. Albans",
                    "Dunbar",
                    "Marmet",
                    "Chesapeake",
                    "Belle",
                    "Clendenin",
                    "East Bank",
                    "Cedar Grove",
                    "Glasgow",
                    "Pratt",
                    "Handley"
                ]
            },
            {
                "countyName": "Kanawha\nPutnam",
                "cities": [
                    "Nitro"
                ]
            },
            {
                "countyName": "Lewis",
                "cities": [
                    "Weston †",
                    "Jane Lew"
                ]
            },
            {
                "countyName": "Lincoln",
                "cities": [
                    "Hamlin †",
                    "West Hamlin"
                ]
            },
            {
                "countyName": "Logan",
                "cities": [
                    "Logan †",
                    "Chapmanville",
                    "Man",
                    "West Logan",
                    "Mitchell Heights"
                ]
            },
            {
                "countyName": "Marion",
                "cities": [
                    "Fairmont †",
                    "Pleasant Valley",
                    "Mannington",
                    "Barrackville",
                    "Monongah",
                    "Rivesville",
                    "White Hall",
                    "Grant Town",
                    "Farmington",
                    "Fairview",
                    "Worthington"
                ]
            },
            {
                "countyName": "Marshall",
                "cities": [
                    "Moundsville †",
                    "McMechen",
                    "Glen Dale",
                    "Benwood",
                    "Cameron"
                ]
            },
            {
                "countyName": "Mason",
                "cities": [
                    "Point Pleasant †",
                    "New Haven",
                    "Mason",
                    "Hartford City",
                    "Henderson",
                    "Leon"
                ]
            },
            {
                "countyName": "McDowell",
                "cities": [
                    "Welch †",
                    "Gary",
                    "War",
                    "Kimball",
                    "Iaeger",
                    "Northfork",
                    "Davy",
                    "Bradshaw",
                    "Keystone",
                    "Anawalt"
                ]
            },
            {
                "countyName": "Mercer",
                "cities": [
                    "Bluefield",
                    "Princeton †",
                    "Athens",
                    "Bramwell",
                    "Matoaka",
                    "Oakvale"
                ]
            },
            {
                "countyName": "Mineral",
                "cities": [
                    "Keyser †",
                    "Carpendale",
                    "Piedmont",
                    "Ridgeley",
                    "Elk Garden"
                ]
            },
            {
                "countyName": "Mingo",
                "cities": [
                    "Williamson †",
                    "Delbarton",
                    "Matewan",
                    "Gilbert",
                    "Kermit"
                ]
            },
            {
                "countyName": "Monongalia",
                "cities": [
                    "Morgantown †",
                    "Westover",
                    "Star City",
                    "Granville",
                    "Blacksville"
                ]
            },
            {
                "countyName": "Monroe",
                "cities": [
                    "Peterstown",
                    "Union †"
                ]
            },
            {
                "countyName": "Morgan",
                "cities": [
                    "Berkeley Springs (Bath) †",
                    "Paw Paw"
                ]
            },
            {
                "countyName": "Nicholas",
                "cities": [
                    "Summersville †",
                    "Richwood"
                ]
            },
            {
                "countyName": "Ohio",
                "cities": [
                    "Bethlehem",
                    "West Liberty",
                    "Triadelphia",
                    "Clearview",
                    "Valley Grove"
                ]
            },
            {
                "countyName": "Ohio\nMarshall",
                "cities": [
                    "Wheeling †"
                ]
            },
            {
                "countyName": "Pendleton",
                "cities": [
                    "Franklin †"
                ]
            },
            {
                "countyName": "Pleasants",
                "cities": [
                    "St. Marys †",
                    "Belmont"
                ]
            },
            {
                "countyName": "Pocahontas",
                "cities": [
                    "Marlinton †",
                    "Durbin",
                    "Hillsboro"
                ]
            },
            {
                "countyName": "Preston",
                "cities": [
                    "Kingwood †",
                    "Terra Alta",
                    "Reedsville",
                    "Masontown",
                    "Rowlesburg",
                    "Tunnelton",
                    "Newburg",
                    "Albright",
                    "Brandonville",
                    "Bruceton Mills"
                ]
            },
            {
                "countyName": "Putnam",
                "cities": [
                    "Hurricane",
                    "Winfield †",
                    "Eleanor",
                    "Buffalo",
                    "Poca",
                    "Bancroft"
                ]
            },
            {
                "countyName": "Raleigh",
                "cities": [
                    "Beckley †",
                    "Mabscott",
                    "Sophia",
                    "Lester",
                    "Rhodell"
                ]
            },
            {
                "countyName": "Randolph",
                "cities": [
                    "Elkins †",
                    "Beverly",
                    "Mill Creek",
                    "Womelsdorf (Coalton)",
                    "Huttonsville",
                    "Montrose",
                    "Harman"
                ]
            },
            {
                "countyName": "Ritchie",
                "cities": [
                    "Harrisville †",
                    "Pennsboro",
                    "Ellenboro",
                    "Cairo",
                    "Pullman",
                    "Auburn"
                ]
            },
            {
                "countyName": "Roane",
                "cities": [
                    "Spencer †",
                    "Reedy"
                ]
            },
            {
                "countyName": "Summers",
                "cities": [
                    "Hinton †"
                ]
            },
            {
                "countyName": "Taylor",
                "cities": [
                    "Grafton †",
                    "Flemington"
                ]
            },
            {
                "countyName": "Tucker",
                "cities": [
                    "Parsons †",
                    "Thomas",
                    "Davis",
                    "Hendricks",
                    "Hambleton"
                ]
            },
            {
                "countyName": "Tyler",
                "cities": [
                    "Sistersville",
                    "Middlebourne †",
                    "Friendly"
                ]
            },
            {
                "countyName": "Upshur",
                "cities": [
                    "Buckhannon †"
                ]
            },
            {
                "countyName": "Wayne",
                "cities": [
                    "Kenova",
                    "Wayne †",
                    "Ceredo",
                    "Fort Gay"
                ]
            },
            {
                "countyName": "Webster",
                "cities": [
                    "Webster Springs (Addison) †",
                    "Cowen",
                    "Camden-on-Gauley"
                ]
            },
            {
                "countyName": "Wetzel",
                "cities": [
                    "New Martinsville †",
                    "Pine Grove",
                    "Hundred",
                    "Smithfield"
                ]
            },
            {
                "countyName": "Wetzel\nTyler",
                "cities": [
                    "Paden City"
                ]
            },
            {
                "countyName": "Wirt",
                "cities": [
                    "Elizabeth †"
                ]
            },
            {
                "countyName": "Wood",
                "cities": [
                    "Parkersburg †",
                    "Vienna",
                    "Williamstown",
                    "North Hills"
                ]
            },
            {
                "countyName": "Wyoming",
                "cities": [
                    "Mullens",
                    "Oceana",
                    "Pineville †"
                ]
            }
        ]
    },
    {
        "stateId": "WY",
        "stateName": "Wyoming",
        "county": [
            {
                "countyName": "Lincoln",
                "cities": [
                    "Afton",
                    "Alpine",
                    "Cokeville",
                    "Diamondville",
                    "Kemmerer†",
                    "La Barge",
                    "Opal",
                    "Star Valley Ranch",
                    "Thayne"
                ]
            },
            {
                "countyName": "Laramie",
                "cities": [
                    "Albin",
                    "Burns",
                    "Cheyenne‡",
                    "Pine Bluffs"
                ]
            },
            {
                "countyName": "Carbon",
                "cities": [
                    "Baggs",
                    "Dixon",
                    "Elk Mountain",
                    "Encampment",
                    "Hanna",
                    "Medicine Bow",
                    "Rawlins†",
                    "Riverside",
                    "Saratoga",
                    "Sinclair"
                ]
            },
            {
                "countyName": "Sweetwater",
                "cities": [
                    "Bairoil",
                    "Granger",
                    "Green River†",
                    "Rock Springs",
                    "Superior",
                    "Wamsutter"
                ]
            },
            {
                "countyName": "Natrona",
                "cities": [
                    "Bar Nunn",
                    "Casper†",
                    "Edgerton",
                    "Evansville",
                    "Midwest",
                    "Mills"
                ]
            },
            {
                "countyName": "Big Horn",
                "cities": [
                    "Basin†",
                    "Burlington",
                    "Byron",
                    "Cowley",
                    "Deaver",
                    "Greybull",
                    "Lovell",
                    "Manderson"
                ]
            },
            {
                "countyName": "Uinta",
                "cities": [
                    "Bear River",
                    "Evanston†",
                    "Lyman",
                    "Mountain View"
                ]
            },
            {
                "countyName": "Sublette",
                "cities": [
                    "Big Piney",
                    "Marbleton",
                    "Pinedale†"
                ]
            },
            {
                "countyName": "Johnson",
                "cities": [
                    "Buffalo†",
                    "Kaycee"
                ]
            },
            {
                "countyName": "Platte",
                "cities": [
                    "Chugwater",
                    "Glendo",
                    "Guernsey",
                    "Hartville",
                    "Wheatland†"
                ]
            },
            {
                "countyName": "Sheridan",
                "cities": [
                    "Clearmont",
                    "Dayton",
                    "Ranchester",
                    "Sheridan†"
                ]
            },
            {
                "countyName": "Park",
                "cities": [
                    "Cody†",
                    "Meeteetse",
                    "Powell"
                ]
            },
            {
                "countyName": "Converse",
                "cities": [
                    "Douglas†",
                    "Glenrock",
                    "Lost Springs",
                    "Rolling Hills"
                ]
            },
            {
                "countyName": "Fremont",
                "cities": [
                    "Dubois",
                    "Hudson",
                    "Lander†",
                    "Pavillion",
                    "Riverton",
                    "Shoshoni"
                ]
            },
            {
                "countyName": "Hot Springs",
                "cities": [
                    "East Thermopolis",
                    "Kirby",
                    "Thermopolis†"
                ]
            },
            {
                "countyName": "Goshen",
                "cities": [
                    "Fort Laramie",
                    "La Grange",
                    "Lingle",
                    "Torrington†",
                    "Yoder"
                ]
            },
            {
                "countyName": "Big Horn, Park",
                "cities": [
                    "Frannie"
                ]
            },
            {
                "countyName": "Campbell",
                "cities": [
                    "Gillette†",
                    "Wright"
                ]
            },
            {
                "countyName": "Crook",
                "cities": [
                    "Hulett",
                    "Moorcroft",
                    "Pine Haven",
                    "Sundance†"
                ]
            },
            {
                "countyName": "Teton",
                "cities": [
                    "Jackson†"
                ]
            },
            {
                "countyName": "Albany",
                "cities": [
                    "Laramie†",
                    "Rock River"
                ]
            },
            {
                "countyName": "Niobrara",
                "cities": [
                    "Lusk†",
                    "Manville",
                    "Van Tassell"
                ]
            },
            {
                "countyName": "Weston",
                "cities": [
                    "Newcastle†",
                    "Upton"
                ]
            },
            {
                "countyName": "Washakie",
                "cities": [
                    "Ten Sleep",
                    "Worland†"
                ]
            }
        ]
    }
]

