import React from "react";

import Tier1MemberScreen from "../../components/tier1/tier1Member";

const Tier1Member = () => {
  return (
    <>
      <Tier1MemberScreen />
    </>
  );
};

export default Tier1Member;
