import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import ApprovalScreen from "../../components/approval/approval";

export async function loader({ params }) {
  try {
    const id = params.id;
    const API_URL = `${END_POINT}/v1/applications/web/${id}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        return errorMessage;
      }
    }
    const data = await fetchData();
    return defer({
      data: data,
    });
  } catch (error) {
    return "Failed to load data";
  }
}
function Approval() {
  return (
    <div>
      <ApprovalScreen />
    </div>
  );
}

export default Approval;
