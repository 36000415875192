import React from "react";

import { Typography } from "antd";
import { Card, Row, Col, Empty } from "antd";
import { useLoaderData } from "react-router-dom";

const { Paragraph } = Typography;

const { Meta } = Card;

function SuggestionScreen() {
  const { data } = useLoaderData();

  return (
    <>
      <div className="main_container_div">
        <div className="main_inner_div">
          <h1 className="primary-heading">Suggestions</h1>
          <Row gutter={[16, 16]}>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <Col key={index} xs={24} sm={12} lg={8}>
                  <Card>
                    <Meta
                      title={item.name}
                      description={
                        <div>
                          <p>
                            <strong>Email:</strong> {item.email}
                          </p>
                          <p>
                            <strong>Suggestion:</strong>
                            <Paragraph
                              ellipsis={{
                                rows: 2,
                                expandable: true,
                                symbol: "more",
                              }}
                            >
                              {item.suggestion}
                            </Paragraph>
                          </p>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              ))
            ) : (
              <div>
                <Empty />
              </div>
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default SuggestionScreen;
