import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./Translation/en/translation.json";
import it from "./Translation/it/translation.json";
import de from "./Translation/de/translation.json";
import ar from "./Translation/ar/translation.json";
import bn from "./Translation/bn/translation.json";
import yue from "./Translation/yue/translation.json";
import fa from "./Translation/fa/translation.json";
import fr from "./Translation/fr/translation.json";
import he from "./Translation/he/translation.json";
import hi from "./Translation/hi/translation.json";
import hu from "./Translation/hu/translation.json";
import ja from "./Translation/ja/translation.json";
import ko from "./Translation/ko/translation.json";
import zh from "./Translation/zh/translation.json";
import pl from "./Translation/pl/translation.json";
import pt from "./Translation/pt/translation.json";
import ru from "./Translation/ru/translation.json";
import es from "./Translation/es/translation.json";
import tl from "./Translation/tl/translation.json";
import th from "./Translation/th/translation.json";
import tr from "./Translation/tr/translation.json";
import vi from "./Translation/vi/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en },
      ar: { translations: ar },
      bn: { translations: bn },
      yue: { translations: yue },
      fa: { translations: fa },
      fr: { translations: fr },
      de: { translations: de },
      he: { translations: he },
      hi: { translations: hi },
      hu: { translations: hu },
      it: { translations: it },
      ja: { translations: ja },
      ko: { translations: ko },
      zh: { translations: zh },
      pl: { translations: pl },
      pt: { translations: pt },
      ru: { translations: ru },
      es: { translations: es },
      tl: { translations: tl },
      th: { translations: th },
      tr: { translations: tr },
      vi: { translations: vi },
    },
    lng: "en",
    fallbackLng: "en",
    debug: false,

    ns: ["translations"],
    defaultNS: "translations",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
