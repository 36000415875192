import React from "react";

import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { Button, Card, Col, Row, Typography, Popconfirm } from "antd";

import { deleteCategory } from "../../services/helpers";
import AddCategoriesModal from "../../models/addCategoriesModal";
import EditCategoriesModal from "../../models/editCategoriesModal";

function CategoriesScreen() {
  const { data } = useLoaderData();
  const revalidator = useRevalidator();
  const confirm = async (e, id) => {
    await deleteCategory(id);
    revalidator.revalidate();
  };

  const cancel = (e) => {
  };

  return (
    <div>
      <AddCategoriesModal>
        <Button className="text-hide" style={{ margin: "20px" }} type="primary">
          + Add Categories
        </Button>
      </AddCategoriesModal>

      <div className="screen">
        <Typography.Title className="primary-heading" level={4}>
          {" "}
          Categories
        </Typography.Title>
        <Row gutter={[16, 16]}>
          {data.length > 0 &&
            data?.map((item, index) => (
              <Col xs={24} sm={12} key={index}>
                <Card className="custom-card">
                  <div key={index} className="card-content">
                    <div className="content-container">
                      <Typography.Title className="primary-heading" level={4}>
                        {item.name}
                      </Typography.Title>
                    </div>

                    <div className="image-container">
                      <img
                        src={item.thumbnail}
                        className="image_styling"
                        alt=""
                      />
                    </div>

                    <EditCategoriesModal item={item}>
                      <FaEdit style={{ fontSize: "30px" }} />
                    </EditCategoriesModal>

                    <Popconfirm
                      title="Delete the task"
                      description="Are you sure to delete this category?"
                      onConfirm={(e) => confirm(e, item?._id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MdDeleteOutline style={{ fontSize: "30px" }} />
                    </Popconfirm>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
}

export default CategoriesScreen;
