import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import CategoriesScreen from "../../components/categories/categories";

export async function loader({ request }) {
  try {
    const API_URL = `${END_POINT}/v1/categories`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    const order = [
      "Automotive",
      "Health & Beauty",
      "Home",
      "Pet Services",
      "Travel",
      "Restaurant",
    ];

    const { categories } = data;
    let sortedCategories =
      categories?.length > 0
        ? categories.sort((a, b) => {
            return order.indexOf(a.name) - order.indexOf(b.name);
          })
        : [];
    return defer({
      data: sortedCategories,
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}
function Categories() {
  return (
    <div>
      <CategoriesScreen />
    </div>
  );
}

export default Categories;
