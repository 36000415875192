import React from "react";

import { TiArrowBack } from "react-icons/ti";

import useHeader from "../../hooks/header/useHeader";
import SearchBox from "../../components/search/searchBox";

import {
  AppBar,
  Container,
  Toolbar,
  Box,
  Button,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

function Header({ showBackButton, showSearchBox }) {
  const { rightItems, navigate } = useHeader();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#ffffff",
        height: "75px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {showBackButton && (
              <Button
                onClick={() => navigate(-1)}
                style={{
                  marginRight: "10px",
                  height: "35px",
                  width: "0px",
                  border: "2px solid whitesmoke",
                }}
              >
                <TiArrowBack size="large" />
              </Button>
            )}
            <Box style={{ marginTop: "25px" }}>
              {showSearchBox && <SearchBox />}
            </Box>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip className="text-hide" title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  className="text-hide"
                  alt="Remy Sharp"
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {rightItems?.map((item) => (
                <MenuItem key={item?.key} onClick={() => item?.onClick()}>
                  <Typography textAlign="center">{item?.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
