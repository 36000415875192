import axios from "axios";
import { END_POINT, RESPONSE } from "../assets/constants/global";
import { message, notification } from "antd";
import vCard from "vcf";
import { saveVCard } from "./utils";

export const createRefferAccount = async (
  values,
  primaryAreaInfo,
  statesInfo,
  referee
) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${END_POINT}/v1/recruiter/auth/signup`, {
        // username: values?.username,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        companyName: values?.companyName,
        teamName: values?.teamName,
        statesInfo: statesInfo,
        // password: values?.password,
        bell: values?.bell,
        primaryAreaInfo: primaryAreaInfo,
        primaryLanguage: values?.primary,
        secondaryLanguage: values?.secondary,
        referee,
      })

      .then((response) => {
        console.log(response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(RESPONSE.CANCEL);

        if (error?.response?.data?.message) {
          message?.error(error?.response?.data?.message);
        } else {
          message?.error(error?.message);
        }
      });
  });
};

export const createCategories = async (name, url) => {
  return new Promise(async (resolve, reject) => {
    const API_URL = `${END_POINT}/v1/categories/create`;
    const data = {
      name: name,
      thumbnail: url,
    };
    await axios
      .post(API_URL, data)
      .then(function (response) {
        console.log(response);
        resolve(RESPONSE.OK);

        message?.success("Add category successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editCategories = async (object, id) => {
  console.log(id);
  return new Promise(async (resolve, reject) => {
    const API_URL = `${END_POINT}/v1/categories/${id}/update`;
    await axios
      .patch(API_URL, object)
      .then(function (response) {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("category updated successfully !");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(
          error?.response?.data?.message || "we are working to fix the problem"
        );
      });
  });
};

export const deleteCategory = async (id) => {
  console.log(id);
  return new Promise(async (resolve, reject) => {
    const API_URL = `${END_POINT}/v1/categories/${id}/delete`;
    await axios
      .delete(API_URL)
      .then(function (response) {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Delete category successfully !");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(
          error?.response?.data?.message || "we are working to fix the problem"
        );
      });
  });
};

export const editUserAccount = async (
  values,
  API_URL,
  statesInfo,
  primaryAreaInfo
) => {
  const updatedData = {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    // password: values?.password,
    phone: values?.phone || "",
    companyName: values?.companyName || "",
    teamName: values?.teamName || "",
    statesInfo: statesInfo || [],
    primaryAreaInfo: primaryAreaInfo || {},
    behance: values?.behance || "",
    cashApp: values?.cashApp || "",
    facebook: values?.facebook || "",
    instagram: values?.instagram || "",
    linkedin: values?.linkedin || "",
    paypal: values?.paypal || "",
    personal: values?.personal || "",
    pintrest: values?.pintrest || "",
    snapchat: values?.snapchat || "",
    tiktok: values?.tiktok || "",
    venmo: values?.venmo || "",
    website: values?.website || "",
    whatsapp: values?.whatsapp || "",
    x: values?.x || "",
    youtube: values?.youtube || "",
    zelle: values?.zelle || "",
    status: values?.status || "Pending",
    qoute: values?.qoute || "",
    primaryLanguage: values?.primaryLanguage || "",
    secondaryLanguage: values?.secondaryLanguage || "",
  };

  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, updatedData)
      .then((response) => {
        console.log("response", response);
        resolve(RESPONSE.OK);
        message?.success("Profile is updated successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const addCompany = async (API_URL, payload) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(API_URL, payload)
      .then((response) => {
        resolve(RESPONSE.OK);
        message?.success("Company saved successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(
          error?.response?.data?.message ||
            "Failed to save! Something went wrong."
        );
      });
  });
};

export const updateCompany = async (API_URL, payload) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, payload)
      .then((response) => {
        console.log("response", response);
        resolve(RESPONSE.OK);
        message?.success("Company saved successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(
          error?.response?.data?.message ||
            "Failed to save! Something went wrong."
        );
      });
  });
};

export const deleteCompany = async (API_URL) => {
  try {
    const response = await axios.delete(API_URL);
    message?.success("Company deleted successfully");
    return response;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const editInterviewerAccount = async (
  values,
  licensedStates,
  API_URL
) => {
  const updatedData = {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    // password: values?.password,
    phone: values?.phone,
    // username: values?.username,
    companyName: values?.companyName,
    dbName: values?.dbaName,
    licenseNumber: values?.licenseNumber,
    localState: values?.state,
    licenseStates: licensedStates,
    speciality: values?.speciality,
    behance: values?.behance || "",
    cashApp: values?.cashApp || "",
    facebook: values?.facebook || "",
    instagram: values?.instagram || "",
    linkedin: values?.linkedin || "",
    paypal: values?.paypal || "",
    personal: values?.personal || "",
    pintrest: values?.pintrest || "",
    snapchat: values?.snapchat || "",
    tiktok: values?.tiktok || "",
    venmo: values?.venmo || "",
    website: values?.website || "",
    whatsapp: values?.whatsapp || "",
    x: values?.x || "",
    youtube: values?.youtube || "",
    zelle: values?.zelle || "",
    status: values?.status,
    specialty: values?.specialty || "",
    primaryLanguage: values?.primaryLanguage || "",
    secondaryLanguage: values?.secondaryLanguage || "",
  };
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, updatedData)
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Profile is updated successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editApplicationAccount = async (
  values,
  licensedStates,
  API_URL
) => {
  const updatedData = {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    // password: values?.password,
    phone: values?.phone,
    // username: values?.username,
    companyName: values?.companyName,
    dbName: values?.dbaName,
    licenseNumber: values?.licenseNumber,
    state: values?.state,
    priceRange: values?.priceRange,
    licenseStates: licensedStates,
    speciality: values?.speciality,
    primaryLanguage: values?.primaryLanguage,
    status: values?.status,
    military: values?.military,
  };
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, updatedData)
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Profile is updated successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editBusinessCard = async (
  values,
  API_URL,
  profileImageURL,
  coverImage
) => {
  console.log(values, "values ");
  const updatedData = {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    phone: values?.phone || "",
    companyName: values?.companyName || "",
    behance: values?.behance || "",
    cashApp: values?.cashApp || "",
    facebook: values?.facebook || "",
    instagram: values?.instagram || "",
    linkedin: values?.linkedin || "",
    paypal: values?.paypal || "",
    pintrest: values?.pintrest || "",
    snapchat: values?.snapchat || "",
    tiktok: values?.tiktok || "",
    venmo: values?.venmo || "",
    website: values?.website || "",
    whatsapp: values?.whatsapp || "",
    x: values?.x || "",
    youtube: values?.youtube || "",
    zelle: values?.zelle || "",
    applicationLink: values?.applicationLink || "",
    profileImage: profileImageURL,
    coverImage: coverImage,
  };
  console.log(updatedData, "updated-Data");
  console.log(API_URL, "API URL");
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, updatedData)
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Profile is updated successfully");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editInterviewerAffiliation = async (checked, API_URL) => {
  const updatedData = {
    isAffiliate: checked,
  };
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, updatedData)
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Affiliation updated");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const updatePassword = async (id, newPassword, type) => {
  console.log("🚀 ~ updatePassword ~ newPassword:", newPassword, id);
  const API_URL = `${END_POINT}/v1/admin/password/update/${id}`;
  console.log(`${END_POINT}/v1/admin/password/update/${id}`);
  const requestData = { password: newPassword, type };

  try {
    const response = await axios.patch(API_URL, requestData);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveCard = async (id, userType, data) => {
  const API_URL = `${END_POINT}/v1/businessForm/create/${id}?userType=${userType}`;
  console.log(
    "APi URl"`${END_POINT}/v1/businessForm/create/${id}?userType=${userType}`
  );
  try {
    const response = await axios.post(API_URL, data);
    return response.data;
  } catch (error) {
    console.error("Error saving card:", error);
    throw error;
  }
};

export const deleteUser = async (type, id) => {
  const API_URL = `${END_POINT}/v1/admin/delete/${id}`;
  const requestData = { type };
  console.log("🚀 ~ deleteUser ~ API_URL:", API_URL);
  console.log("🚀 ~ deleteUser ~ requestData:", requestData);
  try {
    const response = await axios.patch(API_URL, requestData);
    console.log("🚀 ~ deleteUser ~ response:", response);
    return response;
  } catch (error) {
    console.error("🚀 ~ deleteUser ~ error:", error);
    throw error;
  }
};

export const suspendUser = async (type, id) => {
  const API_URL = `${END_POINT}/v1/admin/suspend/${id}`;
  const requestData = { type };
  console.log(`Suspending user with ID: ${id} and type: ${type}`);
  console.log(`API URL: ${API_URL}`);
  console.log(`Request Data:`, requestData);

  try {
    const response = await axios.patch(API_URL, requestData);
    console.log("API Response:", response);

    // Assuming your API returns the updated data
    notification.success({
      message: "User Suspended",
      description: "The user has been successfully suspended.",
    });
    return response;
  } catch (error) {
    console.error("Error suspending user:", error);

    notification.error({
      message: "Error",
      description: "An error occurred while suspending the user.",
    });
    throw error;
  }
};

export const resumeUser = async (type, id) => {
  const API_URL = `${END_POINT}/v1/admin/resume/${id}`;
  const requestData = { type };
  console.log("🚀 ~ resumeUser ~ API_URL:", API_URL);
  try {
    const response = await axios.patch(API_URL, requestData);
    notification.success({
      message: "User Resumed",
      description: "The user has been successfully resumed.",
    });
    return response; // Assuming your API returns the updated data
  } catch (error) {
    console.error(error);
    notification.error({
      message: "Error",
      description: "An error occurred while resuming the user.",
    });
    throw error;
  }
};

export const editBypassPayment = async (checked, API_URL) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, {
        bypassPayment: checked,
      })
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Skip Payment updated");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editTier3BypassPayment = async (checked, API_URL) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, {
        tier3BypassPayment: checked,
      })
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("Skip Payment updated");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editGeneral = async (checked, API_URL) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, {
        isGeneral: checked,
      })
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("General updated");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const editChargesPerUser = async (amount) => {
  const API_URL = `${END_POINT}/v1/billing/update`;
  const updatedData = {
    charges: amount,
  };
  return new Promise(async (resolve, reject) => {
    await axios
      .patch(API_URL, updatedData)
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
        message?.success("charges per user updated");
      })
      .catch(function (error) {
        console.log(error, "error");
        reject(RESPONSE.CANCEL);
        message?.error(error?.response?.data?.message);
      });
  });
};

export const remappedRefferData = (values) => {
  const transformedData = {
    firstName: values?.firstName,
    lastName: values?.lastName,
    email: values?.email,
    // password: values?.password,
    // username: values?.username,
    phone: values?.phone || "",
    companyName: values?.companyName || "",
    teamName: values?.teamName || "",
    behance: values?.social?.behance || "",
    cashApp: values?.social?.cashApp || "",
    facebook: values?.social?.facebook || "",
    instagram: values?.social?.instagram || "",
    linkedin: values?.social?.linkedin || "",
    paypal: values?.social?.paypal || "",
    personal: values?.social?.personal || "",
    pintrest: values?.social?.pintrest || "",
    snapchat: values?.social?.snapchat || "",
    tiktok: values?.social?.tiktok || "",
    venmo: values?.social?.venmo || "",
    website: values?.website || "",
    whatsapp: values?.social?.whatsapp || "",
    x: values?.social?.x || "",
    youtube: values?.social?.youtube || "",
    status: values?.status || "",
    zelle: values?.social?.zelle || "",
    primaryArea:
      values?.statesInfo?.length > 0 ? values?.primaryAreaInfo?.stateName : "",
    county: values?.primaryAreaInfo?.countyName || "",
    qoute: values?.qoute,
  };
  values?.statesInfo?.forEach((item, index) => {
    transformedData[`state${index + 1}`] = item.stateName || "";
    transformedData[`licenseNumber${index + 1}`] = item.licenseNumber || "";
  });

  return transformedData;
};

export const remappedCompanyData = (values) => {
  const transformedData = {
    companyId: values?.companyId,
    name: values?.name || "",
    addressStreet: values?.addressStreet || "",
    city: values?.city || "",
    state: values?.state || "",
    zipCode: values?.zipCode || "",
    county: values?.county || "",
  };

  return transformedData;
};

export const remappedInterviewerData = (values) => {
  const transformedData = {
    firstName: values?.firstName,
    lastName: values?.lastName || "",
    email: values?.email,
    // password: values?.password,
    // username: values?.username,
    phone: values?.phone || "",
    companyName: values?.companyName || "",
    dbaName: values?.dbName || "",
    teamName: values?.teamName || "",
    licenseNumber: values?.licenseNumber || "",
    state: values?.localState || "",
    behance: values?.social?.behance || "",
    cashApp: values?.social?.cashApp || "",
    facebook: values?.social?.facebook || "",
    instagram: values?.social?.instagram || "",
    linkedin: values?.social?.linkedin || "",
    paypal: values?.social?.paypal || "",
    personal: values?.social?.personal || "",
    pintrest: values?.social?.pintrest || "",
    snapchat: values?.social?.snapchat || "",
    tiktok: values?.social?.tiktok || "",
    venmo: values?.social?.venmo || "",
    website: values?.website || "",
    whatsapp: values?.social?.whatsapp || "",
    x: values?.social?.x || "",
    youtube: values?.social?.youtube || "",
    status: values?.status || "",
    zelle: values?.social?.zelle || "",
    county: values?.primaryAreaInfo?.countyName || "",
    qoute: values?.qoute,
    speciality: values?.speciality || "",
    primaryLanguage: values?.primaryLanguage,
  };

  return transformedData;
};

export const createInterviewerAccount = async (
  values,
  licensedStates,
  form,
  id = "666acb02dc1a41bed678f32a",
  isGeneral = true
) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${END_POINT}/v1/interviewer/auth/signup`, {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        // password: values?.password,
        phone: values?.phone,
        referee: id,
        // username: values?.username,
        companyName: values?.companyName,
        dbName: values?.dbaName,
        licenseNumber: values?.licenseNumber,
        localState: values?.state,
        licenseStates: licensedStates,
        speciality: values?.specialty,
        primaryLanguage: values?.primary,
        secondaryLanguage: values?.secondary,
        isGeneral: isGeneral,
      })
      .then((response) => {
        console.log(response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(RESPONSE.CANCEL);

        if (error?.response?.data?.message) {
          message?.error(error?.response?.data?.message);
        } else {
          message?.error(error?.message);
        }
      });
  });
};

export const createAdminFormAccount = async (
  values,
  licensedStates,
  id = "666acb02dc1a41bed678f32a",
  isGeneral = true
) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${END_POINT}/v1/interviewer/auth/signup`, {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        // password: values?.password,
        phone: values?.phone,
        referee: id,
        // username: values?.username,
        companyName: values?.companyName,
        dbName: values?.dbaName,
        licenseNumber: values?.licenseNumber,
        localState: values?.state,
        licenseStates: licensedStates,
        speciality: values?.specialty,
        primaryLanguage: values?.primary,
        secondaryLanguage: values?.secondary,
        isGeneral: isGeneral,
      })
      .then((response) => {
        console.log(response);
        resolve(RESPONSE.OK);
      })
      .catch((error) => {
        console.log(error);
        reject(RESPONSE.CANCEL);

        if (error?.response?.data?.message) {
          message?.error(error?.response?.data?.message);
        } else {
          message?.error(error?.message);
        }
      });
  });
};

export const saveContact = (data) => {
  const vCardObj = new vCard();
  const fieldMappings = {
    firstName: { keyword: "fn", type: "fn" },
    lastName: { keyword: "fn", type: "fn" },
    email: { keyword: "email", type: "email" },
    phone: { keyword: "tel", type: "tel" },
    companyName: { keyword: "org", type: "org" },
    website: { keyword: "url", type: "work" },
    applicationLink: { keyword: "url", type: "Application Link" },
    behance: { keyword: "url", type: "Behance" },
    cashApp: { keyword: "url", type: "Cash App" },
    facebook: { keyword: "url", type: "Facebook" },
    instagram: { keyword: "url", type: "Instagram" },
    linkedin: { keyword: "url", type: "LinkedIn" },
    paypal: { keyword: "url", type: "PayPal" },
    pintrest: { keyword: "url", type: "Pinterest" },
    snapchat: { keyword: "url", type: "Snapchat" },
    tiktok: { keyword: "url", type: "TikTok" },
    venmo: { keyword: "url", type: "Venmo" },
    whatsapp: { keyword: "url", type: "WhatsApp" },
    x: { keyword: "url", type: "X" },
    youtube: { keyword: "url", type: "YouTube" },
    zelle: { keyword: "url", type: "Zelle" },
  };
  Object.entries(data).forEach(([key, value]) => {
    const mapping = fieldMappings[key];
    if (mapping && value !== undefined) {
      const { keyword, type } = mapping;
      if (keyword && type !== undefined) {
        if (keyword === "fn") {
          const fullName = `${data.firstName} ${data.lastName}`.trim();
          if (fullName) {
            vCardObj.add(keyword, fullName, { type });
          }
        } else {
          vCardObj.add(keyword, value, { type });
        }
      }
    }
  });
  saveVCard(vCardObj);
};

export const remappedEditBusinessForm = (businessCard) => {
  const keysWithValue =
    businessCard &&
    Object.keys(businessCard)?.filter((key) => businessCard[key] !== "");
  const remappedObject = {};
  keysWithValue &&
    keysWithValue?.forEach((key) => {
      remappedObject[`${key}checkbox`] = true;
    });
  return {
    ...businessCard,
    ...remappedObject,
  };
};
