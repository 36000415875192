import React from "react";

import Box from "@mui/material/Box";
import { FaUsers } from "react-icons/fa";
import { PiUsersFill } from "react-icons/pi";
import { SiInformatica } from "react-icons/si";
import { PiBugDroidFill } from "react-icons/pi";
import { TbCategoryFilled } from "react-icons/tb";
import { MdOutlinePayment } from "react-icons/md";
import PeopleIcon from "@mui/icons-material/People";

import useHeader from "../header/useHeader";
import { MdDashboard } from "react-icons/md";
import { images } from "../../assets/images";
import { SiteMap } from "../../navigation/SiteMap";
import { generateMenuItems } from "../../libs/sidebar/generateMenuItems";

export default function useSidebarMenu() {
  const { navigate } = useHeader();

  const handleClick = (path) => {
    navigate(path);
  };

  const menuItems = [
    {
      key: "home",
      label: (
        <Box
          onClick={() => navigate(SiteMap?.home?.path)}
          component="img"
          src={images.privont_logo}
          alt="724.One"
          sx={{
            cursor: "pointer",
            display: {
              xs: "none",
              md: "flex",
            },
            width: "4rem",
            height: "4rem",
          }}
        />
      ),
    },
    {
      path: SiteMap?.dashboard?.path,
      label: "Dashboard",
      key: "dashboard",
      icon: (
        <MdDashboard onClick={() => handleClick(SiteMap?.dashboard?.path)} />
      ),
      onClick: () => navigate(SiteMap?.dashboard?.path),
    },
    {
      path: SiteMap?.tier1?.path,
      label: "Tier 1",
      key: "tier1",
      icon: <FaUsers onClick={() => handleClick(SiteMap?.tier1?.path)} />,
    },
    {
      path: SiteMap?.tier2?.path,
      label: "Tier 2",
      key: "tier2",
      icon: <PiUsersFill onClick={() => handleClick(SiteMap?.tier2?.path)} />,
    },
    {
      path: SiteMap?.tier3?.path,
      label: "Tier 3",
      key: "tier3",
      icon: <PeopleIcon onClick={() => handleClick(SiteMap?.tier3?.path)} />,
    },
    {
      path: SiteMap?.tier4?.path,
      label: "Tier 4",
      key: "tier4",
      icon: <PeopleIcon onClick={() => handleClick(SiteMap?.tier4?.path)} />,
    },
    {
      path: SiteMap?.Categories?.path,
      label: "Categories",
      key: "categories",
      icon: (
        <TbCategoryFilled
          onClick={() => handleClick(SiteMap?.Categories?.path)}
        />
      ),
    },
    {
      path: SiteMap?.Suggestions?.path,
      label: "Suggestions",
      key: "suggestions",
      icon: (
        <SiInformatica
          onClick={() => handleClick(SiteMap?.Suggestions?.path)}
        />
      ),
    },
    {
      path: SiteMap?.Bugs?.path,
      label: "Bugs",
      key: "bugs",
      icon: <PiBugDroidFill onClick={() => handleClick(SiteMap?.Bugs?.path)} />,
    },
    {
      path: SiteMap?.BillingInfo?.path,
      label: "Billing-Info",
      key: "billing-info",
      icon: (
        <MdOutlinePayment
          onClick={() => handleClick(SiteMap?.BillingInfo?.path)}
        />
      ),
    },
  ];

  const items = generateMenuItems(menuItems);

  return { items };
}
