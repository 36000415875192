import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";

import {
  persistStore,
  persistCombineReducers,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import userSlice from "../reduxSlice/userSlice";
import dataSlice from "../reduxSlice/dataSlice";
import cartSlice from "../reduxSlice/cartSlice";
import globalSlice from "../reduxSlice/globalSlice";
import counterSlice from "../reduxSlice/counterSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["userSlice"],
};

const rootReducer = {
  userSlice: userSlice,
  cartSlice: cartSlice,
  counterSlice: counterSlice,
  globalSlice: globalSlice,
  languages: dataSlice,
};

const _persistedReducer = persistCombineReducers(persistConfig, rootReducer);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistedStore = persistStore(store);
