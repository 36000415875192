import * as React from "react";

import { BarChart } from "@mui/x-charts";
import { FaPeopleCarry } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { useLoaderData } from "react-router-dom";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaPeopleRobbery } from "react-icons/fa6";
import { Box, Card, Typography } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

import "../../styles/Dashboards.scss";
import Loading from "../loading/Loading";

export default function DashboardScreen() {
  const tiersData = useLoaderData();
  const tier4 = tiersData.tier4 || 0;
  const tier1 = tiersData?.data?.recruiters?.total || 0;
  const tier2 = tiersData?.data?.interviewers?.total || 0;
  const tier3 = tiersData?.data?.applications?.total || 0;
  const TOTAL = tier1 + tier2 + tier3;
  const data = [
    { label: "Tier1", value: tier1, color: "#0088FE" },
    { label: "Tier2", value: tier2, color: "#00C49F" },
    { label: "Tier3", value: tier3, color: "#FFBB28" },
    { label: "Tier4", value: tier4, color: "#FF8042" },
  ];
  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;

    return `${(percent * 100).toFixed(0)}%`;
  };

  const pData = [tier1, tier2, tier3, tier4];
  const xLabels = ["Tier1", "Tier2", "Tier3", "Tier4"];
  const cardsdata = [
    {
      id: 1,
      icon: <PiUsersThreeFill />,
      numbers: tier1,
      title: "Tier1",
    },
    {
      id: 1,
      icon: <FaPeopleCarry />,
      numbers: tier2,
      title: "Tier2",
    },
    {
      id: 1,
      icon: <FaPeopleRobbery />,
      numbers: tier3,
      title: "Tier3",
    },
    {
      id: 1,
      icon: <FaPeopleGroup />,
      numbers: tier4,
      title: "Tier4",
    },
  ];
  if (!tiersData?.data) return <Loading />;
  return (
    <Box className="main_container_div">
      <Box className="inner_div_cards">
        {cardsdata?.map((item, index) => {
          return (
            <Box className="card_div" key={index}>
              <Card className="dashboard-card">
                <div className="card-icon">{item?.icon}</div>
                <div>
                  <Typography className="card-text">{item?.numbers}</Typography>
                  <Typography className="card-text">{item?.title}</Typography>
                </div>
              </Card>
            </Box>
          );
        })}
      </Box>
      <div className="dashboard-chart">
        <div className="chart">
          <BarChart
            width={500}
            height={300}
            sx={{
              "& .MuiBarElement-root:nth-of-type(1)": {
                fill: "rgb(0, 136, 254)",
              },
              "& .MuiBarElement-root:nth-of-type(3)": {
                fill: "rgb(255, 187, 40)",
              },
            }}
            series={[{ data: pData, label: "users", id: "pvId" }]}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
          />
        </div>
        <div className="chart">
          <PieChart
            series={[
              {
                outerRadius: 100,
                data,
                arcLabel: getArcLabel,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
              },
            }}
            {...sizing}
          />
        </div>
      </div>
    </Box>
  );
}
