import React, { cloneElement, useState } from "react";

import { useRevalidator } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { Modal, Spin, Upload, message, Button, Form, Input } from "antd";

import { uploadProfilePicture } from "../services/utils";
import { createCategories } from "../services/helpers";

function AddCategoriesModal({ children }) {
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const { name } = values;
      const fileList = values?.thumbnail?.fileList;
      if (!fileList) return message.error("Please Select File");
      const file = values?.thumbnail?.file?.originFileObj;
      const url = await uploadProfilePicture("categories", file);
      await createCategories(name, url);
      revalidator.revalidate();
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      message.error("we are working to fix the problem");
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };
  const props = {
    name: "file",
  };

  return (
    <div>
      {children &&
        cloneElement(children, {
          onClick: showModal,
        })}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ padding: "20px" }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              className="text-hide"
              label="Category Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your category name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="text-hide"
              label="Thumbnail"
              name="thumbnail"
              rules={[
                {
                  required: true,
                  message: "Please select your  thumbnail photo!",
                },
              ]}
            >
              <Upload {...props} showUploadList={true} maxCount={1}>
                <Button className="text-hide" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                className="text-hide"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AddCategoriesModal;
