import React from "react";

import { Button, Typography } from "antd";
import { NavLink } from "react-router-dom";

export default function GlobalHeader2({ textHeadline, showCompanyLink }) {
  return (
    <div className="inner_div_2 main-header">
      {showCompanyLink && (
        <NavLink to="/tier2/company">
          <Button className="text-hide">COMPANY</Button>
        </NavLink>
      )}
      <Typography className="heading_text">{textHeadline}</Typography>
      <NavLink to="/tier-adminForm">
        <Button className="text-hide">Add+</Button>
      </NavLink>
    </div>
  );
}
