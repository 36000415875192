import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { Avatar, Image, Table, Typography } from "antd";
import dayjs from "dayjs";
import axios from "axios";
import { END_POINT } from "../../assets/constants/global";

const Tier3MemberScreen = () => {
  const location = useLocation();
  const { id } = useParams();
  const [application, setApplication] = useState({});

  async function fetchApplication() {
    try {
      const { data } = await axios.get(`${END_POINT}/v1/applications/${id}`);
      if (data.success) {
        setApplication(data?.application || {});
      }
    } catch (error) {
      console.error("error:", error);
    }
  }
  useEffect(() => {
    if (
      (!location?.state?.memberID ||
        !location?.state?.referee ||
        !location?.state?.interviewers) &&
      id
    ) {
      fetchApplication();
    }
  }, [location, id]);

  const memberID = location?.state?.memberID || application?.memberID;
  const referee = location?.state?.referee || application?.referee || {};
  const interviewers =
    location?.state?.interviewers || application?.interviewers;

  const refereeColumns = [
    {
      title: "Profile Image",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (text, record) =>
        record?.profileImage ? (
          <Image
            width={50}
            height={50}
            src={record?.profileImage}
            alt="Referee Profile Image"
            style={{ borderRadius: "50%" }}
          />
        ) : (
          <Avatar
            size={50}
            style={{
              backgroundColor: "#87d068",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {record?.firstName?.[0] || "R"}
          </Avatar>
        ),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Member ID",
      dataIndex: "memberID",
      key: "memberID",
    },
  ];
  const interviewerColumns = [
    {
      title: "Profile Image",
      dataIndex: "profileImage",
      key: "profileImage",
      render: (text, record) =>
        record?.details?.profileImage ? (
          <Image
            width={50}
            height={50}
            src={record?.details?.profileImage}
            alt="Interviewer Profile Image"
            style={{ borderRadius: "50%" }}
          />
        ) : (
          <Avatar
            size={50}
            style={{
              backgroundColor: "#87d068",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {record?.details?.firstName?.[0] || "I"}
          </Avatar>
        ),
    },
    {
      title: "First Name",
      dataIndex: ["details", "firstName"],
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: ["details", "lastName"],
      key: "lastName",
    },
    {
      title: "Member ID",
      dataIndex: ["details", "memberID"],
      key: "memberID",
    },
    {
      title: "Status",
      dataIndex: ["isApproved"],
      key: "isApproved",
      render: (isApproved) => {
        return isApproved ? "Approved" : "Pending";
      },
    },
    {
      title: "Claimed At",
      dataIndex: ["claimedAt"],
      key: "claimedAt",
      render: (claimedAt, record) => {
        return record.isClaimed && claimedAt
          ? dayjs(claimedAt).format("DD/MM/YYYY HH:MM:ss")
          : "-";
      },
    },
    {
      title: "Company Name",
      dataIndex: ["details", "companyName"],
      key: "companyName",
    },
  ];

  return (
    <>
      <div className="main_container_div">
        <div className="main_inner_div">
          <Typography.Title level={5} style={{ textAlign: "center" }}>
            Member ID: {memberID || application?.memberID || "N/A"}
          </Typography.Title>
          <Typography
            className="heading_text"
            style={{ color: "black", marginBottom: "20px" }}
          >
            Referee (Tier-1)
          </Typography>
          <Table
            columns={refereeColumns}
            dataSource={
              referee
                ? [referee]
                : application?.referee
                ? [application.referee]
                : [referee]
            }
            pagination={false}
            rowKey="memberID"
          />

          <Typography
            className="heading_text"
            style={{ color: "black", marginBottom: "20px", marginTop: "20px" }}
          >
            Interviewer Details (Tier-2)
          </Typography>
          {interviewers && interviewers.length > 0 ? (
            <Table
              columns={interviewerColumns}
              dataSource={interviewers}
              pagination={false}
              rowKey={(record) => record?.details?.memberID}
            />
          ) : (
            <Typography>No interviewers available</Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default Tier3MemberScreen;
