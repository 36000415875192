import React, { useState } from "react";

import { Layout } from "antd";

import Sidebar from "./sidebar/Sidebar";
import AppHeader from "./header/Header";

const { Header, Content, Sider } = Layout;

const AppLayout = ({ children, showBackButton, showSearchBox }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Sider
        style={{
          padding: 0,
        }}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Sidebar />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
          }}
        >
          <AppHeader
            showBackButton={showBackButton}
            showSearchBox={showSearchBox}
          />
        </Header>
        <Content
          style={{
            margin: "0 1rem",
            marginBottom: "1.5rem",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default AppLayout;
