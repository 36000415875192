import React from "react";

import { Button, Typography } from "antd";
import { NavLink } from "react-router-dom";

export default function GlobalHeader({ showCompanyLink, textHeadline }) {
  return (
    <div className="inner_div_2 main-header">
      {showCompanyLink && (
        <NavLink to="/tier1/company">
          <Button className="text-hide">COMPANY</Button>
        </NavLink>
      )}
      <Typography className="heading_text">{textHeadline}</Typography>
      <NavLink to="/tier-form">
        <Button className="text-hide">Add+</Button>
      </NavLink>
    </div>
  );
}
