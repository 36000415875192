import React from "react";

import Tier2MemberScreen from "../../components/tier2/tier2Member";

const Tier2Member = () => {
  return (
    <>
      <Tier2MemberScreen />
    </>
  );
};

export default Tier2Member;
