import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    user: null,
    profileImageUrl: null,
    coverImageUrl: null,
  },
  reducers: {
    dispatchToken: (state, action) => {
      state.token = action.payload;
    },
    dispatchUser: (state, action) => {
      state.user = action.payload;
    },
    setProfileImage: (state, action) => {
      state.profileImageUrl = action.payload;
    },
    setCoverImage: (state, action) => {
      state.coverImageUrl = action.payload;
    },
  },
});

export const { dispatchToken, dispatchUser, setProfileImage, setCoverImage } =
  userSlice.actions;

export default userSlice.reducer;
