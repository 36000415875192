import React, { cloneElement, useEffect, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Modal, Spin, Upload, Button, Form, Input } from "antd";

import { useRevalidator } from "react-router-dom";
import { editCategories } from "../services/helpers";
import { uploadProfilePicture } from "../services/utils";

function EditCategoriesModal({ children, item }) {
  const [form] = Form.useForm();
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const { name } = values;
      const fileList = values?.thumbnail?.fileList;
      if (fileList) {
        const file = values?.thumbnail?.file?.originFileObj;
        const url = await uploadProfilePicture("categories", file);
        const object = {
          ...(name && { name: name }),
          ...(url && { thumbnail: url }),
        };
        await editCategories(object, item?._id);
        revalidator.revalidate();
        setIsLoading(false);
        setIsModalOpen(false);
      } else {
        const object = {
          ...(name && { name: name }),
        };
        await editCategories(object, item?._id);
        revalidator.revalidate();
        setIsLoading(false);
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };
  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
  };

  useEffect(() => {
    form?.setFieldsValue({ name: item?.name });
  }, []);

  return (
    <div>
      {children &&
        cloneElement(children, {
          onClick: showModal,
        })}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ padding: "20px" }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <Form.Item label="Category Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Thumbnail" name="thumbnail">
              <Upload {...props} showUploadList={true} maxCount={1}>
                <Button className="text-hide" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button
                className="text-hide"
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : "Submit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default React.memo(EditCategoriesModal);
