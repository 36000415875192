import React from "react";
import { Menu } from "antd";
import useSidebarMenu from "../../hooks/sidebar/useSidebarMenu";
import { useLocation } from "react-router-dom";
import "../.././styles/sidebar.scss";

export default function Sidebar() {
  const { items } = useSidebarMenu();
  const { pathname } = useLocation();
  const extractPathName = pathname.split("/")[1];

  return (
    <>
      <Menu
        className="main-sidebar"
        theme="dark"
        selectedKeys={[`${extractPathName}`]}
        mode="vertical"
      >
        {items}
      </Menu>
    </>
  );
}
