import React from "react";

import { useLoaderData } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Typography, Avatar, Button, Card } from "antd";
import {
  WhatsAppOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { FaSnapchat } from "react-icons/fa6";

import { images } from "../../assets/images";
import { saveContact } from "../../services/helpers";

function BusinessCard() {
  const { data } = useLoaderData();
  const businessCard = data?.businessCard;

  const iconStyle = {
    padding: "10px",
    fontSize: "25px",
  };

  const formatLink = (link) => {
    if (link && !link.startsWith("http")) {
      return `https://${link}`;
    }
    return link;
  };

  return (
    <div className="details_card_div">
      <div className="card_inner">
        {businessCard ? (
          <>
            <div className="new_card">
              <div className="cover">
                {businessCard?.coverImage ? (
                  <>
                    <div style={{ border: "1px solid white" }}>
                      <img
                        src={businessCard?.coverImage}
                        alt="724.one"
                        className="cover_img"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="cover_img"
                      style={{ border: "1px solid white" }}
                    >
                      <img src={images?.no_image} alt="724.one" />
                    </div>
                  </>
                )}
              </div>
              <div className="profile">
                <div className="profile_inner">
                  {businessCard?.profileImage ? (
                    <>
                      <Avatar src={businessCard?.profileImage} size={100} />
                    </>
                  ) : (
                    <>
                      <Avatar
                        size={100}
                        icon={<UserOutlined />}
                        style={{ background: "black" }}
                      />
                    </>
                  )}
                </div>

                <div className="profile_inner_2">
                  <div className="title_Div">
                    <Typography.Title
                      level={4}
                      style={{ textTransform: "uppercase", color: "black" }}
                    >
                      {businessCard?.firstName} {businessCard?.lastName}
                    </Typography.Title>
                  </div>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    {businessCard?.email}
                  </Typography>
                  <div>
                    {businessCard?.whatsapp && (
                      <a
                        href={formatLink(businessCard?.whatsapp)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WhatsAppOutlined style={iconStyle} />
                      </a>
                    )}
                    {businessCard?.facebook && (
                      <a
                        href={formatLink(businessCard?.facebook)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FacebookOutlined style={iconStyle} />
                      </a>
                    )}
                    {businessCard?.linkedin && (
                      <a
                        href={formatLink(businessCard?.linkedin)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedinOutlined style={iconStyle} />
                      </a>
                    )}
                    {businessCard?.youtube && (
                      <a
                        href={formatLink(businessCard?.youtube)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <YoutubeOutlined style={iconStyle} />
                      </a>
                    )}
                    {businessCard?.instagram && (
                      <a
                        href={formatLink(businessCard?.instagram)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramOutlined style={iconStyle} />
                      </a>
                    )}
                    {businessCard?.snapchat && (
                      <a
                        href={formatLink(businessCard?.snapchat)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaSnapchat style={{ fontSize: "25px" }} />
                      </a>
                    )}
                  </div>
                  <Button onClick={() => saveContact(businessCard)}>
                    Save Contact
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Card>
              <h1 style={{ color: "white" }}>{data?.message}</h1>
            </Card>
          </>
        )}
      </div>
    </div>
  );
}

export default BusinessCard;
