import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import BillingInfoScreen from "../../components/billing/billingInfo";

export async function loader() {
  try {
    const API_URL = `${END_POINT}/v1/billing`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data.billing ? data.billing : "",
    });
  } catch (error) {
    return "Failed to load data";
  }
}
export default function BillingInfo() {
  return (
    <div>
      <BillingInfoScreen />
    </div>
  );
}
