import React from "react";

import { Box, Card, Typography } from "@mui/material";

const TierCard = ({ tierName, count, onViewAllClick, showViewAll }) => {
  return (
    <Box className="card_div">
      <Card className="dashboard-card">
        <div>
          <Typography className="primary-heading">{tierName}</Typography>
          <Typography
            className="primary-heading"
            style={{ textAlign: "center" }}
          >
            {count}
          </Typography>
          {showViewAll && <a onClick={onViewAllClick}>view all</a>}
        </div>
      </Card>
    </Box>
  );
};

export default TierCard;
