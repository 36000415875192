import React from "react";

import { Navigate } from "react-router-dom";

function Public({ children }) {
  let token = localStorage.getItem("token");

  return <div>{token ? <Navigate to="/dashboard" /> : children};</div>;
}

export default Public;
