import React, { cloneElement, useEffect, useState } from "react";

import { useRevalidator } from "react-router-dom";
import { Button, Form, Input, Modal, Spin } from "antd";

import { editChargesPerUser } from "../services/helpers";

const BillingModal = ({ children, amount }) => {
  const [form] = Form.useForm();
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    setIsLoading(true);
    const { amount } = values;
    await editChargesPerUser(amount);
    setIsLoading(false);
    setIsModalOpen(false);
    revalidator.revalidate();
  };
  useEffect(() => {
    form.setFieldsValue({ amount: amount });
  }, [amount]);

  return (
    <>
      {children &&
        cloneElement(children, {
          onClick: showModal,
        })}
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ padding: "20px" }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            layout="vertical"
          >
            <Form.Item
              label="Charges Per user"
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Please input your amount!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? <Spin /> : "Update Charges"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default React.memo(BillingModal);
