import React from "react";

import { Typography } from "antd";

export default function GlobalHeader1({ textHeadline }) {
  return (
    <div className="inner_div_2 main-header ">
      <Typography className="heading_text">{textHeadline}</Typography>
    </div>
  );
}
