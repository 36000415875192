import React, { useEffect } from "react";

import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { TbError404 } from "react-icons/tb";

export default function ErrorScreen() {
  const navigate = useNavigate();
  
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000); // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="globalDisplay globalFullWidth globalFullPageHeight globalJustifyContentCenter globalAlignItemsCenter">
        <div
          className="globalDisplay globalFullWidth globalFullPageHeight globalJustifyContentCenter globalAlignItemsCenter globalFlexColumn globalTextCenter"
          style={{
            background: "#ffffff",
          }}
        >
          <TbError404 size={100} />
          <div>
            <h2>Sorry, We can't find the page you're looking for.</h2>
          </div>
          <Button variant="contained" onClick={() => navigate("/")}>
            Back to Home
          </Button>
        </div>
      </div>
    </>
  );
}
