import React from "react";
import { cloneElement, useState } from "react";

import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { Button, Modal, message, Upload } from "antd";
import { MdOutlineCloudUpload } from "react-icons/md";

import { uploadProfilePicture } from "../services/utils";
import { setCoverImage, setProfileImage } from "../redux/reduxSlice/userSlice";

const { Dragger } = Upload;

function UploadFileModal({ children, imageType }) {
  const dispatch = useDispatch();
  const openModal = () => setIsVisible(true);
  const [image, setImage] = useState(null);
  const [fileSize, setFileSize] = useState();
  const closeModal = () => setIsVisible(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadFirebase = async () => {
    try {
      if (!image) {
        return message.error("Please select image !");
      }
      if (fileSize > 5000000) {
        return message.error("Your file size not large than 5MB");
      }
      setIsLoading(true);
      const url = await uploadProfilePicture("images", image);
      setImage(null);
      if (imageType === "cover") {
        dispatch(setCoverImage(url));
      } else {
        dispatch(setProfileImage(url));
      }
      setIsLoading(false);
      setIsVisible(false);
    } catch (error) {
      setImage(null);
    }
  };
  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      setFileSize(info.file.size);
      setTimeout(() => {
        if (status === "removed") {
          setImage(null);
        } else {
          setImage(info.file.originFileObj);
        }
      }, 2000);
    },
    onDrop(e) {},
  };
  return (
    <>
      {children &&
        cloneElement(children, {
          onClick: openModal,
        })}

      <Modal
        open={isVisible}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
        style={{ width: "90%" }}
      >
        <Box style={{ padding: "20px" }}>
          <Dragger
            listType="picture"
            multiple={false}
            maxCount={1}
            accept="image/png,image/jpg ,image/jpeg"
            {...props}
          >
            <p>
              <MdOutlineCloudUpload
                style={{
                  fontSize: "50px",
                }}
              />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Only png ,jpg and jpeg files are allowed
            </p>
          </Dragger>

          <Button
            type="primary"
            style={{
              margin: "10px",
            }}
            onClick={() => handleUploadFirebase()}
            isLoading={isLoading}
          >
            {"upload"}
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default React.memo(UploadFileModal);
