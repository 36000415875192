import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import Tier2EditScreen from "../../components/tier2/tier2Edit";

export async function loader({ params }) {
  try {
    const id = params.id;
    const API_URL = `${END_POINT}/v1/interviewers/${id}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data.interviewer ? data.interviewer : "",
    });
  } catch (error) {
    return "Failed to load data";
  }
}
export default function Tier2Edit() {
  return (
    <>
      <Tier2EditScreen />
    </>
  );
}
