import React from "react";

import Tier3MemberScreen from "../../components/tier3/tier3Member";

const Tier3Member = () => {
  return (
    <>
      <Tier3MemberScreen />
    </>
  );
};

export default Tier3Member;
