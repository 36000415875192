import React, { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { AiOutlineCamera } from "react-icons/ai";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";

import UploadFileModal from "../../models/uploadFileModal";
import {
  Avatar,
  Button,
  Col,
  Form,
  Row,
  Spin,
  Typography,
  Checkbox,
} from "antd";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  END_POINT,
  EditBusinessFormItems,
} from "../../assets/constants/global";
import {
  editBusinessCard,
  remappedEditBusinessForm,
} from "../../services/helpers";

function EditBusinessScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const { pathname } = useLocation();
  const businessCard = data && data?.businessCard;
  const { profileImageUrl, coverImageUrl } = useSelector(
    (state) => state.userSlice
  );

  const extractPathName = pathname.split("/").includes("tier-1");
  const API_URL = extractPathName
    ? `${END_POINT}/v1/recruiter/update/business-card/${id}`
    : `${END_POINT}/v1/interviewers/update/business-card/${id}`;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const imageURL = profileImageUrl || businessCard?.profileImage || "";
  const coverImage = coverImageUrl || businessCard?.coverImage || "";
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await editBusinessCard(values, API_URL, imageURL, coverImage);
      setLoading(false);
      navigate("/business-details", {
        state: {
          ...values,
          profileImage: imageURL,
          coverImage: coverImage,
        },
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const formData = useMemo(() => {
    return remappedEditBusinessForm(businessCard);
  }, [data]);

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [data]);

  return (
    <div className="main_container_div_form">
      <div className="main_inner_div_form">
        <div className="form_1st">
          <Typography className="heading_text_form">
            Edit Business Card
          </Typography>
        </div>
        <div className="upload_img">
          <div className="profile_img">
            {imageURL || businessCard?.profileImage ? (
              <>
                <Avatar
                  size={120}
                  style={{
                    verticalAlign: "middle",
                  }}
                  src={imageURL ? imageURL : businessCard?.profileImage}
                />
              </>
            ) : (
              <>
                <Avatar
                  size={120}
                  style={{
                    verticalAlign: "middle",
                  }}
                  icon={<UserOutlined />}
                />
              </>
            )}
            <UploadFileModal imageType={"profile"}>
              <AiOutlineCamera className="camera_icon" />
            </UploadFileModal>
          </div>
        </div>

        <Box style={{ marginTop: "20px" }}>
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              {EditBusinessFormItems?.map((item, index) => (
                <Col key={index} xs={24} lg={12}>
                  <Row gutter={[16, 16]} align="middle">
                    <Col>
                      <Form.Item
                        name={`${item?.name}checkbox`}
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                        onChange={() => form.validateFields([item?.name])}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item
                        label={<Typography>{item?.label}</Typography>}
                        name={item?.name}
                        rules={[
                          {
                            required: item.required,
                            message: item.message,
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                value &&
                                !getFieldValue(`${item?.name}checkbox`)
                              ) {
                                return Promise.reject(
                                  new Error(
                                    "Please ensure the checkbox for this field is checked."
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        {item?.field}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ))}

              <Col xs={24} sm={12}>
                <UploadFileModal imageType={"cover"}>
                  <Form.Item label="Upload cover photo">
                    <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                      Upload a cover photo
                    </Button>
                  </Form.Item>
                </UploadFileModal>
              </Col>
            </Row>

            <Form.Item>
              <Box
                sx={{ width: "100%", textAlign: "center", marginTop: "20px" }}
              >
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {loading ? <Spin /> : "Submit"}
                </Button>
              </Box>
            </Form.Item>
          </Form>
        </Box>
      </div>
    </div>
  );
}

export default EditBusinessScreen;
