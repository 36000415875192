import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyC3S2hwBy8M1o4x2GL6e9KwaWF07gAx2wo",
  authDomain: "privont-bcb4b.firebaseapp.com",
  projectId: "privont-bcb4b",
  storageBucket: "privont-bcb4b.appspot.com",
  messagingSenderId: "365953146710",
  appId: "1:365953146710:web:21d14b172e0d83836f00f7",
  measurementId: "G-ME9WW6DMPE",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase();
export { db, auth, storage, database };
