import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import CompanyScreen from "../../components/tier2/Company";

export async function loader(props) {
  const { request } = props;
  try {
    const url = new URL(request.url);
    const userType = "tier-2";
    const pageNumber = url.searchParams.get("pageNumber") || "";
    const API_URL = `${END_POINT}/v1/companies?userType=${userType}&page=${pageNumber}`;

    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data.data ? data.data : [],
      length: data?.total || 0,
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}

const Company = () => {
  return <CompanyScreen />;
};

export default Company;
