import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import BusinessCard from "../../components/businessCard/businessCard";

export async function loader({ params }) {
  try {
    const id = params.id;
    const API_URL = `${END_POINT}/v1/businessForm/${id}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data ? data : "",
    });
  } catch (error) {
    return "Failed to load data";
  }
}
function Business() {
  return (
    <>
      <BusinessCard />
    </>
  );
}

export default Business;
