import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "languages",
  initialState: {
    selectedLanguage: "",
  },
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
export const { setSelectedLanguage } = dataSlice.actions;
export default dataSlice.reducer;
