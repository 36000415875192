import React from "react";

import { Card, Row, Col, Typography } from "antd";
import { useLoaderData } from "react-router-dom";

const { Meta } = Card;
const { Paragraph } = Typography;

function BugsScreen() {
  const { data } = useLoaderData();

  return (
    <>
      <div className="main_container_div">
        <div className="main_inner_div">
          <Row gutter={[16, 16]}>
            {data?.map((item, index) => (
              <Col key={index} xs={24} sm={12} lg={8}>
                <Card>
                  <Meta
                    description={
                      <div>
                        <p>
                          <strong>Bug:</strong>
                          <Paragraph
                            ellipsis={{
                              rows: 2,
                              expandable: true,
                              symbol: "more",
                            }}
                          >
                            {item?.bug}
                          </Paragraph>
                        </p>
                      </div>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default BugsScreen;
