import React, { useEffect, useState } from "react";

import { Button, Switch, Table } from "antd";

import Loading from "../loading/Loading";
import GlobalHeader2 from "../../common/globalHeader2";
import { END_POINT, PAGE_LIMIT } from "../../assets/constants/global";
import UpdatePasswordModal from "../../models/updatePasswordModal";
import {
  useLoaderData,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";
import {
  editBypassPayment,
  editGeneral,
  editInterviewerAffiliation,
  editTier3BypassPayment,
} from "../../services/helpers";
import dayjs from "dayjs";

export default function Tier2Screen() {
  const data = useLoaderData();
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleTableChange = async (page) => {
    const pageNumber = page?.current;
    setSearchParams({ pageNumber: pageNumber });
  };

  const onChange = async (checked, id) => {
    setIsLoading(true);
    const API_URL = `${END_POINT}/v1/admin/tier-2/update/${id}`;
    await editInterviewerAffiliation(checked, API_URL);
    setIsLoading(false);
    revalidator.revalidate();
  };

  const onChangeSkipPayment = async (checked, id) => {
    setIsLoading(true);
    const API_URL = `${END_POINT}/v1/admin/tier-2/update/${id}`;
    await editBypassPayment(checked, API_URL);
    setIsLoading(false);
    revalidator.revalidate();
  };

  const onChangeTier3SkipPayment = async (checked, id) => {
    setIsLoading(true);
    const API_URL = `${END_POINT}/v1/admin/tier-2/update/${id}`;
    await editTier3BypassPayment(checked, API_URL);
    setIsLoading(false);
    revalidator.revalidate();
  };

  const onChangeGeneral = async (checked, id) => {
    setIsLoading(true);
    const API_URL = `${END_POINT}/v1/admin/tier-2/update/${id}`;
    await editGeneral(checked, API_URL);
    setIsLoading(false);
    revalidator.revalidate();
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        return dayjs(text).format("DD/MM/YYYY HH:MM:ss");
      },
    },
    {
      title: "Member ID",
      dataIndex: "memberID",
      key: "memberID",
      render: (text, record) => (
        <a
          onClick={() =>
            navigate(`/tier2/info/detail2/${record?._id}`, {
              state: {
                memberID: record?.memberID,
                referee: record?.referee,
                interviewers: record?.interviewers,
              },
            })
          }
          className="member-id-link"
        >
          {text || "N/A"}
        </a>
      ),
    },
    {
      title: "Referee",
      dataIndex: ["referee", "memberID"],
      key: "referee",
      render: (text) => text || "N/A",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => text || "N/A",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => text || "N/A",
    },
    // {
    //   title: "User Name",
    //   dataIndex: "username",
    //   key: "username",
    //   render: (text) => text || "N/A",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Affiliate",
      key: "affiliate",
      render: (record) => (
        <Switch
          checked={record?.isAffiliate}
          onChange={(checked) => onChange(checked, record?._id)}
        />
      ),
    },
    {
      title: () => (
        <span>
          Skip Payment <br />
          (Tier 1)
        </span>
      ),
      key: "skipPayment1",
      render: (record) => (
        <Switch
          isLoading={isLoading}
          checked={record?.bypassPayment}
          onChange={(checked) => onChangeSkipPayment(checked, record?._id)}
        />
      ),
    },
    {
      title: () => (
        <span>
          Skip Payment <br />
          (Tier 3)
        </span>
      ),
      key: "skipPayment2",
      render: (record) => (
        <Switch
          isLoading={isLoading}
          checked={record?.tier3BypassPayment}
          onChange={(checked) => onChangeTier3SkipPayment(checked, record?._id)}
        />
      ),
    },
    {
      title: "General",
      key: "isGeneral",
      render: (record) => (
        <Switch
          isLoading={isLoading}
          checked={record?.isGeneral}
          onChange={(checked) => onChangeGeneral(checked, record?._id)}
        />
      ),
    },
    {
      title: "Edit",
      key: "action",
      render: (record) => (
        <Button
          onClick={() =>
            navigate(`/tier2/${record?._id}`, {
              state: record,
            })
          }
        >
          Edit
        </Button>
      ),
    },
    {
      title: "Password Update",
      key: "action",
      render: (record) => (
        <Button onClick={() => setIsModalVisible(record?._id)}>
          Change Password
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setSearchParams({ pageNumber: 1 });
  }, []);

  if (!data?.data || isLoading) return <Loading />;

  return (
    <div className="main_container_div">
      <GlobalHeader2
        textHeadline={
          <span className="primary-heading" style={{ color: "black" }}>
            Tier 2 users
          </span>
        }
        showCompanyLink={true}
      />
      <div className="main_inner_div">
        <Table
          dataSource={data?.data}
          columns={columns}
          onChange={(page) => handleTableChange(page)}
          pagination={{
            pageSize: PAGE_LIMIT,
            total: data?.length,
          }}
          scroll={{
            x: 700,
          }}
        />
      </div>
      <UpdatePasswordModal
        visible={!!isModalVisible}
        recordId={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => setIsModalVisible(false)}
        tier="tier-2"
      />
    </div>
  );
}
