import React, { useEffect, useState } from "react";

import axios from "axios";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import TierCard from "./tierCrad";
import { END_POINT } from "../../assets/constants/global";

const Tier1MemberScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tierUsersCounts1, setTierUsersCounts1] = useState("");

  useEffect(() => {
    axios
      .get(`${END_POINT}/v1/admin/tier-1/info/details/${id}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          setTierUsersCounts1(data.details);
        } else {
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, [id]);

  const navigateToTier1 = () => {
    navigate(`/tier1/info/detailTier1/${id}`);
  };

  const navigateToTier2 = () => {
    navigate(`/tier1/info/detailTier2/${id}`);
  };
  const navigateToTier3 = () => {
    navigate(`/tier1/info/detailTier3/${id}`);
  };
  const navigateToTier4 = () => {
    navigate(`/tier1/info/detailTier4/${id}`);
  };

  return (
    <Box className="main_container_div">
      <Box className="inner_div_cards">
        <TierCard
          tierName="Tier 1"
          count={tierUsersCounts1.tierOneReferredCount || 0}
          onViewAllClick={navigateToTier1}
          showViewAll={tierUsersCounts1.tierOneReferredCount > 0}
        />
        <TierCard
          tierName="Tier 2"
          count={tierUsersCounts1.tierTwoReferredCount || 0}
          onViewAllClick={navigateToTier2}
          showViewAll={true}
        />
        <TierCard
          tierName="Tier 3"
          count={tierUsersCounts1.tierThreeReferredCount || 0}
          onViewAllClick={navigateToTier3}
          showViewAll={tierUsersCounts1.tierThreeReferredCount > 0}
        />
        <TierCard
          tierName="Tier 4"
          count={tierUsersCounts1.tierFourReferredCount || 0}
          onViewAllClick={navigateToTier4}
          showViewAll={tierUsersCounts1.tierFourReferredCount > 0}
        />
      </Box>
    </Box>
  );
};

export default Tier1MemberScreen;
