import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import Tier3Screen from "../../components/tier3/tier3";
import { END_POINT } from "../../assets/constants/global";

export async function loader({ request }) {
  try {
    const url = new URL(request.url);
    const pageNumber = url.searchParams.get("pageNumber") || "";
    const firstName = url.searchParams.get("firstName") || "";
    const API_URL = firstName
      ? `${END_POINT}/v1/applications?query=${firstName}&page=${pageNumber}`
      : `${END_POINT}/v1/applications?page=${pageNumber}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return [];
      }
    }
    const data = await fetchData();
    return defer({
      data: data?.tierThree ? data?.tierThree : [],
      length: data?.total || 0,
    });
  } catch (error) {
    throw new Error("Failed to load data");
  }
}
function Tier3() {
  return (
    <>
      <Tier3Screen />
    </>
  );
}

export default Tier3;
