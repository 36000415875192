import React, { useEffect, useState } from "react";

import { CloseOutlined } from "@mui/icons-material";
import { Modal, Button, Form, Input, Spin, message } from "antd";

import { updatePassword } from "../services/helpers";

function UpdatePasswordModal({ recordId, visible, onOk, onCancel, tier }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const response = await updatePassword(recordId, values.newpassword, tier);
      message.success(response.data.message);
      setIsLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while updating the password";
      message.error(errorMessage);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  return (
    <Modal
      title={
        <div style={{ marginLeft: "20px", padding: "12px 0" }}>
          New Password
          <CloseOutlined
            style={{
              position: "absolute",
              right: "15px",
              top: "16px",
              cursor: "pointer",
            }}
            onClick={onCancel}
          />
        </div>
      }
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <div style={{ padding: "20px" }}>
        <Form name="basic" onFinish={onFinish} autoComplete="off" form={form}>
          <Form.Item label="New Password" name="newpassword">
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              {isLoading ? <Spin /> : "UPDATE"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default React.memo(UpdatePasswordModal);
