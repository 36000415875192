import React from "react";

import axios from "axios";
import { defer } from "react-router-dom";

import { END_POINT } from "../../assets/constants/global";
import EditBusinessScreen from "../../components/tier1/editBusiness";

export async function loader({ request, params }) {
  try {
    const id = params.id;
    const API_URL = `${END_POINT}/v1/interviewers/${id}`;
    async function fetchData() {
      try {
        const response = await axios.get(API_URL);
        return response.data;
      } catch (error) {
        return "Failed to Load Data";
      }
    }
    const data = await fetchData();
    return defer({
      data: data.interviewer ? data.interviewer : "",
    });
  } catch (error) {
    return "Failed to load data";
  }
}
function Tier2BusinessCard() {
  return (
    <div>
      <EditBusinessScreen />
    </div>
  );
}

export default Tier2BusinessCard;
